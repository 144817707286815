/* -------------------------------------------------------------------------- *\
   #TOOLTIPS
\* -------------------------------------------------------------------------- */

.tooltip,
.ui-tooltip {
    font-family: $font;
    font-size: 0.875em; /* 14 / 16 */
    line-height: 1.3;
    transition: opacity 0.25s;
    z-index: $z-index-tooltips;
}

.ui-tooltip {
    padding: 0;
    border: none !important;
    box-shadow: none;
    background: none;

    &:before {
        content: '';
        border-color: transparent;
        border-style: solid;
        height: 0;
        position: absolute;
        width: 0;
        border-top-color: #374456;
        border-width: 5px 5px 0;
        bottom: -5px;
        left: 50%;
        margin-left: -5px;
    }
}

.tooltip.in {
    filter: alpha(opacity=100);
    opacity: 1;
}

.tooltip-inner,
.ui-tooltip-content {
    background-color: $color-secondary;
    border-radius: 0.5em;
    color: $color-white;
    max-width: 320px;
    padding: 1.5em;
    text-align: center;
}

.tooltip.top .tooltip-arrow {
    border-top-color: $color-secondary;
}

.tooltip.top-left .tooltip-arrow {
    border-top-color: $color-secondary;
}

.tooltip.top-right .tooltip-arrow {
    border-top-color: $color-secondary;
}

.tooltip.right .tooltip-arrow {
    border-right-color: $color-secondary;
}

.tooltip.left .tooltip-arrow {
    border-left-color: $color-secondary;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: $color-secondary;
}

.tooltip.bottom-left .tooltip-arrow {
    border-bottom-color: $color-secondary;
}

.tooltip.bottom-right .tooltip-arrow {
    border-bottom-color: $color-secondary;
}
