/* -------------------------------------------------------------------------- *\
   #ALERT
\* -------------------------------------------------------------------------- */

.alert {
    @include clearfix();
    background-color: $color-white;
    border-radius: 0.25em;
    box-shadow: 0 0.125em 2em rgba(0,0,0, 0.1);
    margin-bottom: 2em;
    padding: 1em 1.25em;
    position: relative;

    /* Title */

    .alert__title {
        float: left;
        font-weight: $weight-bold;
        margin-right: 0.75em;
    }

    /* Message */

    .alert__message {
        margin-bottom: 0;
    }

    /* Dismiss */

    .alert__dismiss {
        background-color: transparent;
        border: 0;
        color: $color-gray;
        cursor: pointer;
        display: block;
        position: absolute;
        right: 0.25em;
        top: 0.25em;
        transition: color 0.25s;

        &:focus,
        &:hover {
            color: $color-black;
            outline: 0;
        }
    }

    /**
     * Success
     */

    &.alert--success {
        border-color: $color-green;

        .alert__title {
            color: $color-green;
        }

        .alert__message {

            a {
                @include anchor($border-bottom-color-hover: $color-green, $color-hover: $color-green);
            }
        }
    }

    /**
     * Information
     */

    &.alert--information {
        border-color: $color-blue;

        .alert__title {
            color: $color-blue;
        }

        .alert__message {

            a {
                @include anchor($border-bottom-color-hover: $color-blue, $color-hover: $color-blue);
            }
        }
    }

    /**
     * Warning
     */

    &.alert--warning {
        border-color: $color-orange;

        .alert__title {
            color: $color-orange;
        }

        .alert__message {

            a {
                @include anchor($border-bottom-color-hover: $color-orange, $color-hover: $color-orange);
            }
        }
    }

    /**
     * Error
     */

    &.alert--error {
        border-color: $color-red;

        .alert__title {
            color: $color-red;
        }

        .alert__message {

            a {
                @include anchor($border-bottom-color-hover: $color-red, $color-hover: $color-red);
            }
        }
    }
}
