.client-video-slider-wrapper {
  position: relative;
  background: $color-white-dark__alt;
  padding-top: 2.5em;

  @media (min-width: $bp-small) {
    padding-top: 4em;
    padding-bottom: 5em;
  }

  .header {
    max-width: 100%;
    text-align: center;
  }

  .client-video-slider {
    .video-slide {
      height: 0;
      margin: 4px;
      padding: 55% 0 0 0;
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
      will-change: box-shadow;
      transition: .4s ease-out;

      &:hover {
        box-shadow: 2px 4px 6px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
      }

      &.active {
        .video-slide-overlay {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }
      }

      .video-slide-overlay {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 1.6em;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        background: {
          size: cover;
          position: center;
        }
        cursor: pointer;
        transition: .4s ease-out;
        z-index: 1;

        .overlay-text {
          max-width: 55%;
          font-size: 1rem;
          font-weight: 400;
          color: $color-white;

          @media (max-width: $bp-small) {
            font-size: .8rem;
          }
        }

        .video-play-btn {
          max-width: 32px;
          margin-top: auto;

          @media (max-width: $bp-small) {
            max-width: 40px;
          }
        }
      }

      .video-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        .wistia_responsive_padding {
          padding: 56.25% 0 0 0;
          position: relative;

          .wistia_embed {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }

    .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      .swiper-pagination-bullet {
        display: block !important;
        width: 8px;
        height: 8px;
        margin: 0 4px;
        border-radius: 100%;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
        background: #9DA8B6;

        &.swiper-pagination-bullet-active {
          background: $color-primary;
        }
      }
    }

    .button-holder {
      margin-top: 2em;
      text-align: center;

      .btn {
        border-radius: 40px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
      }
    }
  }
}