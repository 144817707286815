/* -------------------------------------------------------------------------- *\
   #SUB HEADER
\* -------------------------------------------------------------------------- */

.sub-header {
    color: $color-primary;
    font-size: 0.875em; /* 14 / 16 */
    margin-bottom: 0.25em;
    text-transform: uppercase;

    & + .hr {
        margin-top: 1em;
    }
}

.sub-header__large {
    font-size: 1.4em;

    & + .hr {
        display: none;
    }
}

@media all and (min-width: $bp-small) {
    .sub-header { 
        font-size: 1.125em; /* 20 / 16 */
    }

    .sub-header__large {
        font-size: 2.813em;

        & + .hr {
            display: block;
            margin-top: 0;
        }
    }
}









