/**
 * LISTS
 */

/* -------------------------------------------------------------------------- *\
   #TABLE OF CONTENTS
\* -------------------------------------------------------------------------- */

/**
 * DESCRIPTION
 * LISTS
 * CUSTOM
 */





/* -------------------------------------------------------------------------- *\
   #DESCRIPTION
\* -------------------------------------------------------------------------- */

dl {
    @include list();

    dt {
        font-weight: $weight-bold;
    }
}






/* -------------------------------------------------------------------------- *\
   #LISTS
\* -------------------------------------------------------------------------- */

ul,
ol {
    font-size: 0.875em;
    font-weight: $weight-light;
}

@media all and (min-width: $bp-small) {

    ul,
    ol {
        font-size: 1em;
    }
}





/* -------------------------------------------------------------------------- *\
   #CUSTOM
\* -------------------------------------------------------------------------- */

.list {
    @include list();

    li {

        a {
            @include anchor($color: inherit);
        }
    }

    /**
     * Inline
     */

    &.list--inline {

        li {
            display: inline-block;
            margin-right: 1rem;
            margin-bottom: 1rem;

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }
        }

        /**
         * Separator
         */

        &.list--separator {

            li {
                padding-left: 1.125em;
                position: relative;

                &:first-child {
                    padding-left: 0;

                    &:before {
                        display: none;
                    }
                }

                &:before {
                    color: $color-gray-dark;
                    content: "|";
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }

    /**
     * Ordered
     */

    &.list--ordered {
        list-style: decimal;
        margin-left: 1.5rem;
    }

    /**
     * Unordered
     */

    &.list--unordered {
        list-style: square;
        margin-left: 1.5rem;
    }

    /**
     * Unstyled
     */

    &.list--unstyled {
        @include list();
    }

    /**
     * Condensed
     */


     &.list--condensed {
        margin-bottom: 23px; 

            li {
                margin-bottom: 0.2em; 
            }
      }
}
