/* -------------------------------------------------------------------------- *\
   #RESET
\* -------------------------------------------------------------------------- */


.Button_button__2Iz0I {
    color: white;
}

.button__2Iz0I .w-full .Button_block__1KlY- .Button_primary__36M1h {
    background-color: #3f81e4 !important;
}

[data-testid="register_for_self_submit"] {
    /* Styles */
    background-color: #3f81e4 !important;
}
[data-testid="register_for_someone_else_submit"] {
    /* Styles */
    background-color: #3f81e4 !important;
}

.Button_button__2Iz0I:disabled {
    background-color: #ebecee !important;
}

//.Button_block__1KlY- {
//    background-color: #3f81e4 !important;
//}

.Button_primary__36M1h {
    color: white !important;
}


//3f81e4
.nav__list::before {
    display: none !important;
}

nav li::before {
    display: none !important;
}


/**
 * Apply a natural box layout model to all elements
 */

*,
*:before,
*:after {
    box-sizing: inherit;
}

/**
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection rule sets have to be separate
 * Customize the background color to match your design
 */

::-moz-selection {
    background: $color-primary;
    color: $color-white;
    text-shadow: none;
}

::selection {
    background: $color-primary;
    color: $color-white;
    text-shadow: none;
}

/**
 * Correct the `line-height` of placeholder text in Chrome.
 * http://stackoverflow.com/a/17681447/648203
 */

::-webkit-input-placeholder {
    line-height: normal;
}

/**
 * Remove the gap between audio, canvas, iframes, images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}
