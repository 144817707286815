/* -------------------------------------------------------------------------- *\
   #HERO
\* -------------------------------------------------------------------------- */

$bp-hero-desktop: 1294px;


.hero {
  background: $color-secondary no-repeat center center;
  background-size: cover;
  position: relative;

  &.tax-hero {
    background: transparent linear-gradient(90deg, #00AEEF 0%, #0072EC 100%) 0 0 no-repeat padding-box;
    overflow: hidden;

    .l-wrapper {
      max-width: 100%;
    }

    .tax-hero-inner {
      display: flex;
      align-items: stretch;
      padding: 5em 0;
      background: {
        repeat: no-repeat;
        size: contain;
        position: right 20px;
      }

      @media (max-width: $bp-medium-2) {
        flex-direction: column;
        padding: 3em 0;
        background: none !important;
      }

      @media (max-width: $bp-small) {
        padding: 2.2em 0;
      }

      .tax-hero-title,
      .tax-hero-content {
        flex-grow: 1;
      }

      .tax-hero-title {
        display: flex;
        align-items: center;
        width: 50%;
        margin-right: 4.5em;
        padding-right: 4.5em;
        border-right: 1px solid white;

        @media (max-width: 1500px) {
          width: 34%;
          margin-right: 3em;
          padding-right: 3em;
        }

        @media (max-width: $bp-large) {
          width: 28%;
          padding-right: 2em;
          margin-right: 2em;
        }

        @media (max-width: $bp-medium-2) {
          display: block;
          width: 100%;
          margin-right: 0;
          padding-right: 0;
          margin-bottom: 1.8em;
          padding-bottom: 1.8em;
          border-right: none;
          border-bottom: 1px solid white;
        }

        @media (max-width: $bp-small) {
          margin-bottom: 3.8em;
          padding-bottom: 1.8em;
        }

        .hero-title {
          margin: 0;
          color: #E7F0FF;
          line-height: 1.2 !important;
          letter-spacing: 2.6px;

          @media (max-width: 1705px) {
            font-size: 52px;
          }

          @media (max-width: 1500px) {
            font-size: 40px;
          }

          @media (max-width: 1300px) {
            font-size: 36px;
          }

          @media (max-width: $bp-large) {
            font-size: 28px;
          }

          @media (max-width: $bp-medium-2) {
            font-size: 26px;
          }

          @media (max-width: $bp-small) {
            font-size: 24px;
            line-height: 1.4 !important;
            letter-spacing: 2px;
          }

          span {
            display: block;
          }

          .hero-title-small {
            font-weight: 300;
          }

          .hero-title-large {
            font-weight: 800;
          }
        }
      }

      .tax-hero-content {
        width: 42%;
        position: relative;
        color: #E7F0FF;

        @media (max-width: 1500px) {
          width: 36%;
        }

        @media (max-width: $bp-medium-2) {
          width: 100%;
        }

        .tax-hero-content-inner {
          margin-right: 180px;
          position: relative;
          z-index: 1;

          @media (max-width: $bp-large) {
            margin-right: 160px;
          }

          @media (max-width: $bp-small) {
            margin-right: 140px;
          }

          .sars-content {
            margin-bottom: 1.2em;

            .sars-content-title {
              margin: 0;
              font-size: 2.5em;

              @media (max-width: 1500px) {
                font-size: 2em;
              }

              @media (max-width: 1230px) {
                font-size: 1.5em;
              }

              @media (max-width: $bp-small) {
                font-size: 1.2em;
              }
            }

            .sars-content-image {
              display: block;
              max-width: 340px;
              margin: 1em 0 0.9em;

              @media (max-width: 1500px) {
                max-width: 260px;
              }

              @media (max-width: 1230px) {
                max-width: 220px;
              }

              @media (max-width: $bp-large) {
                max-width: 200px;
              }

              @media (max-width: $bp-small) {
                max-width: 148px;
                margin: 0.4em 0 0.6em;
              }
            }
          }

          .info-text {
            font-size: 20px;
            line-height: 30px;

            @media (max-width: 1600px) {
              font-size: 16px;
              line-height: 26px;
            }

            @media (max-width: 1230px) {
              font-size: 14px;
              line-height: 22px;
            }

            @media (max-width: $bp-small) {
              font-size: 12px;
              line-height: 18px;

              br {
                display: none;
              }
            }

            .info-text-small,
            .info-text-large {
              display: block;
              letter-spacing: 0.88px;
            }

            .info-text-small {
              font-weight: 300;
            }

            .info-text-large {
              font-weight: 800;
            }
          }

          .sars-btn {
            margin-top: 1em;
            box-shadow: 3px 3px 4px rgba(0, 0, 0, .15), -3px -3px 4px #FFFFFF99;
            font-size: 18px;
            font-weight: 600;

            @media (max-width: $bp-large) {
              font-size: inherit;
              font-weight: inherit;
            }

            @media (max-width: $bp-small) {
              margin-top: .5em;
              font-size: 0.875em;
              font-weight: 700;
            }
          }
        }

        .tax-hero-content-image {
          display: none;
          max-width: 380px;
          position: absolute;
          bottom: -60px;
          right: 0;

          @media (max-width: $bp-medium-2) {
            display: block;
          }

          @media (max-width: $bp-small) {
            bottom: -40px;
          }
        }
      }
    }
  }

  &.hero-fee-free {
    background: $color-primary;
    height: 80vh !important;

    @media (max-width: $bp-medium) {
      display: flex;
      flex-direction: column;
      height: auto !important;
      min-height: 82vh;
    }

    .hero-inner {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: {
        size: cover;
        position: bottom;
      }

      @media (max-width: $bp-medium) {
        flex-grow: 1;
        position: relative;
      }

      .hero-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        max-width: 62%;
        padding: 5% 0;
        position: relative;
        text-align: center;
        z-index: 1;

        @media (max-width: $bp-hero-desktop) {
          max-width: 62%;
          padding: 8% 0 5% 2%;
        }

        @media (max-width: $bp-medium) {
          max-width: 100%;
          padding: 40px 24px;
        }

        .zero-image {
          max-width: 700px;
          margin-bottom: 12px;

          @media (max-width: $bp-hero-desktop) {
            max-width: 620px;
          }

          @media (max-width: $bp-large) {
            max-width: 520px;
          }

          @media (max-width: $bp-small) {
            max-width: 320px;
          }
        }

        .hero-title,
        .hero-text {
          color: $color-white;
        }

        .hero-title {
          font: {
            size: 3.8em;
            weight: 800;
          }
          letter-spacing: 2px;

          @media (min-width: 1025px) and (max-width: 1800px) {
            font-size: 2.2em !important;
          }

          @media (max-width: $bp-hero-desktop) {
            font-size: 3.2em;
          }

          @media (max-width: $bp-large) {
            font-size: 2.8em;
          }

          @media (max-width: $bp-small) {
            font-size: 1.8em;
          }

          @media (max-width: $bp-xs) {
            font-size: 1.5em;
          }
        }

        .hero-text {
          font-size: 1.5rem;
          letter-spacing: 2px;

          @media (max-width: $bp-hero-desktop) {
            font-size: 1.3rem;
          }

          @media (max-width: $bp-large) {
            font-size: 1.2rem;
          }

          @media (max-width: $bp-small) {
            margin-bottom: 1em;
            font-size: 1rem;

            br {
              display: none;
            }
          }

          @media (max-width: $bp-xs) {
            line-height: 1.6;
          }

          strong {
            font-weight: 800;
          }
        }

        .hero-mobile-image {
          display: none;
          max-width: 400px;
          max-height: 320px;

          @media (max-width: $bp-medium) {
            display: block;
          }

          @media (max-width: $bp-small) {
            max-height: 26vh;
          }
        }

        .btn {
          margin-top: auto;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .5);

          @media (max-width: $bp-large) {
            margin-top: 4rem;
          }

          @media (max-width: $bp-medium) {
            margin-top: 1rem;
          }
        }

        .terms-link {
          display: block;
          margin-top: 6px;
          color: $color-white;
          text-decoration: none;
          opacity: .5;
          font-size: .8rem;

          &:hover {
            opacity: 1;
          }
        }
      }

      .hero-image {
        width: 50%;
        height: 90%;
        position: absolute;
        top: 5%;
        right: 0;
        background: {
          size: contain;
          position: center;
          repeat: no-repeat;
        }

        @media (max-width: $bp-medium) {
          display: none;
        }
      }
    }
  }

  /*Contact Page Background Positioning*/
  &.contact-10x-hero {
    background-size: 135%;
    background-position: 8% 67%;

    @media (max-width: 1024px) and (min-height: 1366px) {
      background-position: 8% 90%;
    }

    @media (min-width: 1000.1px) and (max-width: $bp_medium) {
      background-size: 138%;
      background-position: 0% 12%;
    }

    @media (min-width: 768.1px) and (max-width: 1000px) {
      background-size: 176%;
      background-position: 21% 17%;
    }

    @media (max-width: 768.1px) and (min-width: 741.1px) {
      background-size: 184%;
      background-position: 32% 9%;

      @media (min-height: 900px) {
        background-position: 32% 50%;
      }

    }

    @media(min-width: 500.1px) and (max-width: 741px) {
      background-size: 220%;
      background-position: 43% 57%;
    }

    @media all and (max-width: 500px) {
      background-size: 220%;
      background-position: 43% 67%;
    }

    @media all and (max-width: 25em) {
      background-size: 330% !important;
      background-position: 43% 64%;
    }
  }

  /* Body */

  .hero__body {
    background-color: rgba($color-secondary, 0.5);
    height: 100%;
    padding-top: 6em;
    padding-bottom: 2em;
    position: relative;
    width: 100%;

    &.no_overlay {
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }

  /**
   * Light
   */

  &.hero--light {
    background-color: transparent;

    .hero__body {
      background-color: rgba($color-white-dark, 0.8);
    }

    .hero__title,
    .hero__synopsis,
    .sub-header {
      color: $color-primary;
      text-shadow: none;
    }
  }

  /**
   * Small
   */

  &.hero--small {

    .hero__title {
      font-size: 2.125em; /* 34 / 16 */
    }

    .hero__scroll {
      bottom: -8.5em;
    }
  }

  /**
   * half
   */

  &.hero--half {
    background-color: $color-primary;
    width: 100%;
    display: inline-block;

    .half--1-2 {
      color: $color-white;
      display: block;
      width: 100%;
      float: none;
      margin: 0 auto;
    }

    .hero__right {
      padding: 2em;
    }

    .hero__title--small {
      font-size: 2.125em;
      text-transform: uppercase;
    }

    .title--shadow {
      text-shadow: 0px 3px 5px rgba(55, 68, 86, 0.4);
    }
  }

  .hero--centered {
    width: 100%;
    background-color: rgba(0, 174, 239, 0.8);
  }

  /**
   * Video
   */

  .fee-free-campaign_slider {

    @media all and (max-width: $bp-small) {
      margin-bottom: 18em;
    }

    @media all and (max-width: $bp-xs) {
      margin-bottom: 16em;
    }

    @media all and (max-width: 400px) {
      margin-bottom: 12em;
    }

    @media (max-width: $bp-xs) and (min-height: 750px) {
      margin-bottom: 20em;
    }

    @media all and (max-width: 350px) {
      margin-bottom: 12em;
    }

    .hero-column {
      display: block;
      @media all and (max-width: $bp-small) {
        display: none;
      }
    }

  }

  &.home-hero {
    height: 80vh !important;

    @media all and (min-width: $bp-medium) and (max-height: $bp-small) {
      //height: auto !important;

      .hero-slider-wrapper {
        padding-bottom: 23%;
      }
    }

    @media all and (max-width: $bp-small) {
      height: 70vh !important;
      overflow: visible !important;
      margin-bottom: 25vh;
    }
  }

  .hero-slider-home {
    background-image: url('/assets/images/heros/homepage-hero.jpg');
    background-position: bottom center !important;
    justify-content: flex-start !important;
    padding-top: 40px;

    @media all and (max-width: $bp-medium) {
      background-image: url('/assets/images/heros/homepage-hero-mobile.jpg');
      background-position: bottom center !important;
    }

    @media all and (max-width: $bp-small) {
      background-image: url(/assets/images/heros/homepage-hero-mobile.jpg);
      background-position: top center !important;
      background-size: 100%;
      background-repeat: no-repeat;
      position: relative;
    }

    .hero-slide-inner {
      max-width: 100% !important;
      padding: 20px 32px 0;

      @media all and (max-width: $bp-small) {
        max-width: 430px !important;
      }

      @media all and (max-width: $bp-small) {
        max-width: 300px !important;
        padding: 0 12px;
      }
    }

    .bottom-cta {
      @media all and (max-width: $bp-small) {
        position: absolute;
        background-color: #E7F0FE;
        left: 0;
        width: 100vw;
        padding: 10px 25px 60px;
        bottom: -25vh;
      }

      .hero__title {
        @media all and (max-width: $bp-xl) {
          font-size: 1.4em !important;
        }

        @media all and (max-width: $bp-small) {
          color: #334152 !important;
        }
      }
    }

    .hero__title {
      margin-bottom: 0.8em !important;
      font-size: 1.9rem !important;

      @media all and (max-width: $bp-large) {
        font-size: 1.9rem;
      }

      @media all and (max-width: $bp-medium-2) {
        font-size:  1.9rem !important;
        line-height: 1.5;
      }

      @media all and (max-width: $bp-small) {
        font-size: 1.3rem !important;
      }

      .hero__title--light,
      .hero__title--bold {
        display: block;
      }

      .hero__title--bold {
        font-weight: 600;
      }

      .hero__title--light {
        font-weight: 300;
      }

    }

    .same-name-hr {
      width: 303px;
      border-width: 1.5px;
      border-color: #E7F0FE;
      border-style: solid;
      margin: 40px auto;

      @media all and (max-width: $bp-xl) {
        margin: 40px auto 25px;
      }

      @media all and (max-width: $bp-medium-2) {
        margin: 57px auto;
      }

      @media all and (max-width: $bp-small) {
        display: none;
      }
    }

    .btn {
      @media all and (max-width: $bp-medium-2) {
        font-weight: 400;
      }

      @media all and (max-width: $bp-small) {
        white-space: normal;
        line-height: 1;
        height: auto;
        font-weight: 600;
      }

      &.btn-start-quiz {
        border-radius: 40px;
        box-shadow: -2px -2px 4px #FFFFFF, 4px 4px 6px #29394933;;
      }

      &.btn-speak-to-consultant {

        @media all and (max-width: $bp-large) {
          //font-size: 1.9rem !important;
        }

        @media all and (max-width: $bp-medium-2) {
          max-width: 300px;
          display: block;
          margin: 0 auto;
          height: fit-content;
          white-space: normal;
        }

        @media all and (max-width: $bp-small) {
          font-size: 1.4rem !important;
        }
      }
    }
  }


  .fee-free-campaign_desktop {
    display: block;

    @media all and (max-width: $bp-small) {
      display: none;
    }
  }

  .fee-free-campaign-br {
    @media all and (max-width: 830px) {
      display: none;
    }
  }

  &.hero--video,
  &.hero--image {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 88vh;
    overflow: hidden;
    background-color: #000;
    transition: background 0.5s;
    -webkit-transition: background 0.5s;
    -moz-transition: background 0.5s;

    @media all and (min-width: $bp-large) {
      height: calc(90vh - 80px);
    }

    @media all and (max-width: 321px) {
      height: 94vh;
    }

    @media all and (max-width: 321px) {
      height: 94vh;
    }

    &.mobile-landscape {
      height: auto;

      .hero-slider-wrapper {
        position: relative !important;

        .hero-slider-container {
          margin-top: 4rem;
          padding-bottom: 4rem;
        }
      }
    }

    .wistia_responsive_padding {
      opacity: 0;
      width: 100%;
      height: 100%;
      background: {
        size: cover;
        position: center top !important;
        repeat: no-repeat;
      }
    }

    .hero-slider-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 10;
      background: {
        position: top center;
        color: rgba(8, 32, 60, .65);
        size: cover;
      }
      width: 100%;
      height: 100%;
      position: static;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      transition: .5s ease-in;
      background-color: #00aeef;


      .hero-slider-container {
        opacity: 0;

        &.hero-slider-container-ut {
          opacity: 1;
        }
      }

      &:before {
        content: '';
        display: block;
        //position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #334152;
        opacity: .4;


      }

      .hero-slider-campaign {
        &:before {
          position: static !important;
        }

        @media all and (max-width: $bp-small) {
          background-position: center bottom 125px !important;
        }
      }

      .tns-outer {
        z-index: 1;

        .tns-nav {
          display: block;
          position: absolute;
          bottom: .5em;
          width: 100%;

          button {
            height: .8rem;
            width: .8rem;
            padding: 0;
            background: none;
            border: 1px solid $color-white;
            border-radius: 2em;
            margin: 0 .4em;
            outline: none !important;

            @media all and (min-width: $bp-small) {
              height: 1rem;
              width: 1rem;
            }

            &:hover {
              background: $color-white;
            }

            &.tns-nav-active {
              background: $color-white;
            }
          }

          @media all and (min-width: $bp-small) {
            display: none;
          }
        }
      }

      .hero-slider-hr {
        margin: 0;
        margin-top: 0.5em;
        height: 0em;
        border-top: 12px solid #ffffff !important;
        max-width: 0.5em;

        @media all and (max-width: $bp-medium) {
          border-top: 6px solid #ffffff !important;
          max-width: 0.75em;
        }
      }

      .fee-free-campaign_play {
        width: 45%;
        display: none;

        @media all and (max-width: $bp-small) {
          display: block;
        }
      }

      .fee-free-campaign_mobile-panel {

        display: none;

        @media all and (max-width: $bp-small) {
          display: block;
          position: absolute;
          z-index: 2;
          background: linear-gradient(to bottom, #00aeef, #00aeef, #3571b4);
          width: 100%;
          height: 50%;
          border-radius: 25px;
          bottom: -15%;

          .hero-campaign-panel {
            position: relative;
            top: 10%;

            @media all and (max-width: 350px) {
              font-size: 16px;
            }
            @media all and (max-width: 320px) {
              font-size: 13px;
            }
          }
        }

      }

      .fee-free-campaign_button {
        /* margin: 0; */
        //margin: auto;
        //width: 100%;
        margin-top: 3em;
        display: inline-block;

        @media all and (max-width: $bp-small) {
          bottom: -85%;

          position: absolute;
          bottom: -85%;
          margin: 0 auto;
          display: block;
          /* text-align: center; */
          width: 100%;
        }
      }

      .fee-free-campaign_terms {

        font-size: 18px;
        text-decoration: none;
        color: white;
        display: block;
        //width: 35%;

        @media all and (max-width: $bp-small) {
          font-size: 14px;
          display: block;
          width: 100px;
          margin: 0 auto;
        }

      }

      .hero-slider-wrapper-inner {
        transition: .3s ease-in;
        z-index: 1;

        .hero-slider {
          .hero-slide {
            font-size: 18px;
            color: #fff;
            transition: .85s ease;
            vertical-align: middle;

            .hero-slide-inner {
              max-width: 82%;
              margin: 0 auto;

              @media all and (min-width: $bp-small) {
                max-width: 75%;
              }

              .hero__title {
                margin-bottom: 0.4em;
                font: {
                  size: 1.875rem;
                  weight: 700;
                }
                text-transform: none;
                line-height: 1.4;
                max-width: 100% !important;

                @media all and (min-width: $bp-xs) {
                  font-size: 3rem;
                  line-height: 1.2;
                }

                @media all and (min-width: $bp-medium) {
                  font-size: 4.0rem;
                }

                &.hero__title__small {
                  margin: {
                    top: 1em;
                    bottom: 2em;
                  }
                }
              }

              .hero__subtitle {
                display: inline-block;
                max-width: 70%;
                font: {
                  size: .8em;
                  weight: 300;
                }
                line-height: 1.5;
                letter-spacing: .4px;

                strong {
                  font: {
                    size: 1.2em;
                    weight: 600;
                  }
                }

                @media (min-width: 1025px) and (max-width: 1800px) {
                  max-width: 50%;
                  font-size: 0.95rem;
                }

                @media all and (min-width: $bp-small) {
                  font-size: 1.2rem;
                }

                @media all and (min-width: $bp-medium) {
                  font-size: 1.5rem;
                }
              }

              .hero__subtitle_your_future {

                font: {
                  size: 0.5em;
                  weight: 300;
                }

                margin-top: 0em;
                margin-bottom: 0.5em;

                @media all and (min-width: $bp-xs) {
                  font-size: 0.8rem;
                }

                @media all and (min-width: $bp-small) {
                  font-size: 1.5rem;
                }

                @media all and (min-width: $bp-xl) {
                  font-size: 1.5rem;
                }
                @media all and (max-width: 1640px) {
                  font-size: 1.25rem;
                }

                @media all and (max-width: 1250px) {
                  font-size: 1.15rem;
                }
                @media all and (max-width: 1440px) {
                  font-size: 1.25rem;
                }
                @media all and (max-width: 1240px) {
                  font-size: 1.15rem;
                }
                @media all and (max-width: $bp-large) {
                  font-size: 1.2rem;
                }
                @media all and (max-width: 1000px) {
                  font-size: 1rem;
                }
                @media all and (max-width: $bp-small) {
                  font-size: 1.5rem;
                  margin-top: 0em;
                  margin-bottom: 0.5em;
                }
                @media all and (max-width: 500px) {
                  font-size: 1.25rem;
                  margin-top: 0em;
                  margin-bottom: 0.5em;
                }
                @media all and (max-width: $bp-xs) {
                  font-size: 1rem;
                }
                @media all and (max-width: 350px) {
                  font-size: 0.85rem;
                }

              }

              .hero_your_future-btn {
                //box-shadow: -5px -5px 5px #fff, ;
                box-shadow: -5px -5px 5px rgba(255, 255, 255, 0.75);
                filter: drop-shadow(5px 5px 4px rgba(16, 62, 114, 0.5));

                border-radius: 30px;
                padding-left: 4em;
                padding-right: 4em;

                @media all and (max-width: $bp-large) {
                  padding-left: 1.5em;
                  padding-right: 1.5em;
                }

                @media all and (max-width: $bp-medium) {
                  padding-left: 1em;
                  padding-right: 1em;
                }
              }

              .hero_your_future-btn_no_shadow {

                border-radius: 30px;
                padding-left: 4em;
                padding-right: 4em;

                @media all and (max-width: $bp-large) {
                  padding-left: 1.5em;
                  padding-right: 1.5em;
                }

                @media all and (max-width: $bp-medium) {
                  padding-left: 1em;
                  padding-right: 1em;
                }
              }

              .btn-speak-to-consultant {
                border-radius: 0;
                box-shadow: 2px 4px 6px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .75);
              }

              //10X-338

              .parent {
                position: relative;
                max-width: 1300px;
                @media (min-width: 1201px) {
                  padding-right: 25%;
                }

                @media (max-width: $bp-large) /* and (min-width: $bp-small)*/
                {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  height: 90vh;
                  padding-top: 4em;
                  justify-content: space-between;
                }

                .banner_copy {
                  @media (max-width: $bp-large) and (min-width: $bp-medium) {
                    transform: scale(1.3);
                    transform-origin: top;
                  }

                  @media (max-width: $bp-medium) and (min-width: $bp-small) {
                    transform: scale(1.2);
                    transform-origin: top;
                  }

                  @media (max-width: 1024px) and(max-height: 500px) {
                    width: 100%;
                  }

                  .banner_copy_inner {
                    z-index: 1;

                    @media (max-width: 1024px) and(max-height: 500px) {
                      .ut_hero-text {
                        line-height: 2.5rem !important;
                      }

                      #speak-to-a-retirement-expert-middle {
                        float: left;
                        font-size: 0.875rem;
                        margin-top: 35px;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                        height: auto;
                      }

                      .hero__title {
                        display: none;
                      }
                    }


                    @media (max-width: 1024px) and(max-height: 400px) {
                      #speak-to-a-retirement-expert-middle {
                        float: left;
                        font-size: 0.875rem;
                        margin-top: 25px;
                      }

                      .hero__title {
                        display: none;
                      }
                    }


                    .hero__tax {
                      display: inline-block;
                      padding-right: 0;
                      text-transform: none;
                      text-align: left;

                      .ut_hero-text {
                        font-family: Open Sans, sans-serif;
                        font-weight: 800;
                        text-align: center;
                        line-height: 4.5rem;

                        @media (max-width: 1024px) and(max-height: 500px) {
                          text-align: left;
                        }

                        @media all and (max-width: $bp-xl) {
                          line-height: 3.5rem;
                        }
                        @media all and (max-width: $bp-large) {
                          line-height: 3rem;
                        }

                        span {
                          font-weight: 100;
                        }
                      }
                    }

                    .hero_your_future_hr {
                      margin-top: 1em;
                      margin-bottom: 1em;
                    }
                  }
                }

                .ut_hero_image-background {
                  z-index: -1;

                  @media (min-width: $bp-large) {
                    position: absolute;
                    right: -55%;
                    top: -180%;
                  }

                  @media (max-width: 1600px) {

                    position: absolute;
                    right: -55%;
                    top: -160%;
                  }

                  @media (max-width: 1440px) {

                    position: absolute;
                    right: -55%;
                    top: -150%;
                  }

                  @media (max-width: 1200px) and (min-width: $bp-medium-2) {
                    width: 100vw;
                    position: absolute;
                    top: 15%;
                    right: -25%;
                  }


                  @media (max-width: $bp-medium-2) {
                    width: 100vw;
                    position: absolute;
                    top: 15%;
                    right: -20%;
                  }


                  @media (max-width: $bp-small) {
                    width: 100vw;
                    position: absolute;
                    top: 15%;
                    right: -12.5%;
                  }

                  @media (max-width: $bp-xs) {
                    width: 155vw;
                    position: absolute;
                    top: 24%;
                    right: -42.5%;
                  }

                  @media (max-width: 760px) and (min-height: 640px) and (max-height: 749px) {
                    width: 130vw !important;
                    //position: unset !important;
                    top: 32% !important;
                    right: unset !important;
                  }

                  @media (max-width: 760px) and (min-height: 750px) {
                    width: 130vw !important;
                    //position: unset !important;
                    top: 42% !important;
                    right: unset !important;
                  }

                  @media (max-width: 760px) and (min-height: 580px) and (max-height: 639px) {
                    width: 130vw !important;
                    position: unset !important;
                    top: 35% !important;
                    right: unset !important;
                  }

                  @media (min-width: 760px) and (max-width: 1023px) and(min-height: 750px) {
                    width: 115vw !important;
                    position: unset !important;
                    bottom: 0 !important;
                    top: unset !important;
                    right: unset !important;
                    max-width: 900px;
                  }


                  @media (max-width: 1024px) and(max-height: 500px) {
                    top: -62.5%;
                    right: -55%;
                  }

                  @media (max-width: 1024px) and(max-height: 400px) {
                    top: -67.5%;
                    right: -55%;
                  }


                  .desktop-img {
                    display: block;
                    //width: 1850px;
                    @media (max-width: $bp-medium) {
                      display: none;
                    }
                  }

                  .mobile-img {
                    display: none;
                    @media (max-width: $bp-medium) {
                      display: block;
                    }
                  }
                }

              }

              //END 10X-338

              //.fee-free-campaign {}

              #fee-free-campaign_mobile-panel {
                display: none;

                @media all and (max-width: $bp-small) {
                  display: block;
                  position: absolute;
                  z-index: 2;
                  background: linear-gradient(to bottom, #00aeef, #00aeef, #3571b4);
                  width: 100%;
                  height: 50%;
                  border-radius: 25px;
                  bottom: -15%;
                }
              }

              .fee-free-campaign_title {

                @media all and (min-width: 1800px) {
                  font-size: 3rem;
                }
                @media all and (max-width: 1800px) {
                  font-size: 3rem;
                }
                @media all and (max-width: 1750px) {
                  font-size: 2.75rem;
                }
                @media all and (max-width: 1600px) {
                  font-size: 2.5rem;
                }
                @media all and (max-width: 1430px) {
                  font-size: 2.25rem;
                }
                @media all and (max-width: 1300px) {
                  font-size: 2rem;
                }
                @media all and (max-width: 1150px) {
                  font-size: 2rem;
                }

                @media all and (max-width: $bp-small) {
                  text-shadow: none;
                  top: 15%;
                  text-align: left;
                  font-size: 1.875rem;
                }

                @media all and (max-width: 350px) {
                  font-size: 1.625rem;
                }

                .hero_your_future_hr {
                  text-align: left;
                  margin-left: 0;
                  width: 0.5em;
                  border: 0;
                  border-top: 8px solid #FFFFFF;
                  margin-top: 0.5em;
                  margin-bottom: 0em;
                }


              }

              .fee-free-campaign_desktop {
                display: block;

                @media all and (max-width: $bp-small) {
                  display: none;
                }
              }


              .hero_your_future_hr {
                width: 7.75em;
                border: 1px solid #FFFFFF;

                @media all and (min-width: $bp-medium) {
                  margin-top: 1.25em;
                  margin-bottom: 0.75em;
                }

                @media all and (max-width: 1640px) {
                  width: 6.75em;
                  margin-top: 0.75em;
                  margin-bottom: 0.5em;
                }

                @media all and (max-width: 1440px) {
                  width: 5.85em;
                }

                @media all and (max-width: $bp-large) {
                  width: 4.675em;
                }

                @media all and (max-width: 1000px) {
                  width: 4em;
                }

                @media all and (max-width: $bp-medium) {
                  margin-top: 0.75em;
                  margin-bottom: 0.5em;
                }

                @media all and (max-width: $bp-small) {
                  width: 7.75em;
                  margin-top: 0.75em;
                  margin-bottom: 0.5em;
                }

              }

              .hero_your_future_image {

                max-width: 100%;

                @media all and (max-width: $bp-small) {
                  max-width: 375px;
                }

                @media all and (max-width: $bp-xs) {
                  max-width: 100%;
                }
              }

              .hero-column {
                @media all and (max-width: $bp-medium) {
                  margin-bottom: 0;
                }
              }

              .fee-free-campaign_column {
                margin-top: 9rem;
                text-align: left;
                @media all and (max-width: $bp-large) {
                  //margin-bottom: 0;
                  margin-top: 4.5rem;
                }
              }

              .fee-free-campaign_column2 {

                @media all and (min-width: $bp-small) {
                  //margin-bottom: 0;
                  width: 37.5%;
                  margin-left: 15%;
                }
              }

              .hero_your_future_image-2 {

                max-width: 500px;
                margin-bottom: 0.5em;

                @media all and (max-width: 1640px) {
                  max-width: 450px;
                }

                @media all and (max-width: 1440px) {
                  max-width: 380px;
                }

                @media all and (max-width: $bp-large) {
                  max-width: 300px;
                }

                @media all and (max-width: 1000px) {
                  max-width: 260px;
                }

                @media all and (max-width: $bp-small) {
                  max-width: 275px;
                }

                @media all and (max-width: $bp-xs) {
                  max-width: 225px;
                }

                @media all and (max-width: 350px) {
                  max-width: 200px;
                }
                //@media all and (max-width: $bp-medium) {
                //    max-width: 70%;
                //}

              }

              //.fee-free-campaign {}


              .hero-slide-sub-blocks {
                .hero-slide-sub-block {
                  display: inline-block;
                  max-width: 212px;
                  padding: 12px 0;
                  margin: 0 30px;
                  border: {
                    top: 1px solid rgba(255, 255, 255, .2);
                    bottom: 1px solid rgba(255, 255, 255, .2);
                  }
                  font-weight: 300;
                }
              }
            }
          }
        }
      }

      .hero-slider-btn-container {
        margin-top: 1.8em;

        @media all and (min-width: $bp-small) {
          margin-top: 3em;
        }

        .btn {
          @media all and (min-width: $bp-small) {
            width: 330px;
            height: 64px;
            padding: 20px 22.5px;
            font-size: 18px;
          }
        }
      }

      .tns-controls {
        display: none;
        position: absolute;
        width: 100%;
        top: 50%;
        outline: none;
        z-index: 1;

        @media all and (min-width: $bp-small) {
          display: block;
        }

        button {
          position: absolute;
          background: none;
          border: 1px solid rgba(255, 255, 255, .5);
          padding: .625em;
          border-radius: 3.125em;
          outline: none;
          width: 2.375rem;
          height: 2.375rem;
          font-size: 0.875rem;
          transition: .2s ease;
          color: transparent;

          &[data-controls="prev"] {
            left: 6%;

            &:before {
              content: "\f053";
              margin-right: 3px;
            }
          }

          &[data-controls="next"] {
            right: 6%;

            &:before {
              content: "\f054";
            }
          }

          &[disabled] {
            opacity: .45;
            pointer-events: none;
          }

          &:before {
            display: inline-block;
            margin-top: 3px;
            color: $color-white;
            width: 0.6em;
            height: 1em;
            font-family: 'icon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          &:hover {
            border: 1px solid $color-white;
            cursor: pointer;
          }
        }
      }
    }

    &.home-hero {

      &.banner-open {
        height: calc(90vh - 245px);

        @media (max-width: 1309px) {
          height: calc(90vh - 212px);
        }

        @media (max-width: 425px) {
          height: 72vh;
        }
      }

      &:before {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        background: {
          image: url('../../../assets/images/heros/taking-the-leap.jpg');
          position: center;
          size: cover;
          repeat: no-repeat;
        }
        transition: .4s;
      }

      &.slide3 {
        &:before {
          opacity: 1;
        }
      }
    }

    &.product-hero {
      &.banner-open {
        height: calc(90vh - 245px);

        @media (max-width: 1309px) {
          height: calc(90vh - 212px);
        }

        @media (max-width: 425px) {
          height: 72vh;
        }
      }
    }

    &:not(.home-hero) {
      .hero-slide-1 {
        .hero__title {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
      }

      &.mobile-landscape {
        .hero-slide-1 {
          .hero__title {
            margin-bottom: 0 !important;
          }
        }
      }
    }

    &.hero-video--promo {
      height: 80vh;

      @media all and (min-width: $bp-large) {
        height: calc(80vh - 80px);
      }

      .wistia_responsive_padding {
        opacity: 1;
      }

      .hero-slider-wrapper {
        background: none;

        &:before {
          background: none;
        }
      }

      .hero--promo-slider {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 2rem;

        .hero-slide {
          width: 100%;
          margin-top: .3em;

          .hero-slide-inner {
            width: 100%;
            margin: 0 auto;
            padding: 0 .6em;

            h3 {
              margin-bottom: .2em;
            }

            h4 {
              margin-bottom: .6em;
            }

            .hero-slide-title {
              font-size: 5.5vw;
              font-weight: 300;
              line-height: 1.4;

              @media all and (min-width: $bp-small) {
                font-size: 2.6rem;
              }

              strong {
                font-weight: 700;
              }
            }

            .animate-line {
              height: 0.8vw;
              width: 15vw;
              left: 35%;
              top: 58%;
              background: white;
              position: absolute;
              margin: 0 auto;
              transition: .7s ease-out;

              @media all and (min-width: $bp-small) {
                height: 4px;
                width: 112px;
                background: white;
                position: absolute;
                left: 33.5%;
                top: 86px;
              }

              &.diagonal {
                transform: rotate(-56deg);
                -webkit-transform: rotate(-56deg);
                -moz-transform: rotate(-56deg);
              }
            }

            .btn {
              width: 100%;
              max-width: 260px;
              font-size: .9rem;
              height: auto;
              line-height: unset;
              padding: .8em 1.2em;

              @media all and (min-width: $bp-small) {
                width: auto;
                max-width: none;
                font-size: 1.2rem;
                padding: .8em 2em;
              }
            }
          }
        }
      }
    }

    &.hero-video--polygraph {
      height: 50vh;
      background-position: center top;

      &.mobile-landscape {
        height: 330px;

        .play-btn {
          max-width: 70vw;
        }
      }

      @media all and (min-width: 980px) {
        height: 80vh;
      }

      .hero-mobile-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000;
        opacity: .6;

        @media all and (min-width: $bp-small) {
          display: none;
        }
      }

      .blue-bg {
        display: none;
        position: absolute;
        left: -42vw;
        max-width: 130%;
        height: 10000px;
        bottom: 50%;
        margin-bottom: -5000px;

        @media all and (min-width: $bp-small) {
          display: block;
        }

        @media all and (min-width: 980px) {
          left: -52vw;
        }
      }

      .blue-bg-ie {
        display: none;
        position: absolute;
        left: -42vw;
        max-width: 147%;
        bottom: 0;

        @media all and (min-width: $bp-small) {
          display: block;
        }

        @media all and (min-width: 980px) {
          left: -52vw;
        }
      }

      .hero-slider-wrapper {
        background: {
          repeat: no-repeat;
          size: cover;
          position: right center;
        }
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        text-align: left;
        max-width: 100%;
        transition: none;
        margin-top: 1em;

        @media all and (min-width: $bp-small) {
          margin-top: 1.2em;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }

        @media all and (min-width: 980px) {
          margin-top: 0;
          max-width: 70vw;
        }

        .truth-mobile {
          margin-bottom: 3em;
          margin-top: 3em;
          max-width: 85vw;

          @media all and (min-width: $bp-small) {
            display: none;
          }
        }

        .hero__title {
          display: none;
          max-width: 100%;
          text-shadow: none;
          font-size: 1.4em;
          line-height: 1.2;
          margin-left: -30%;
          text-transform: none;

          @media all and (min-width: $bp-small) {
            display: block;
          }

          @media all and (min-width: 980px) {
            margin-left: -10%;
            font-size: 1.4em;
          }

          @media all and (min-width: 1200px) {
            font-size: 2.4em;
          }
        }

        .play-btn {
          margin-bottom: 3em;
          z-index: 1;
          max-width: 80vw;

          &:hover {
            cursor: pointer;
          }

          @media all and (min-width: $bp-small) {
            max-width: 100%;
            margin-bottom: 0;
            width: 80px;
            position: absolute;
            margin-top: -40px;
            right: 29vw;
            top: 50%;
          }

          @media all and (min-width: 980px) {
            right: 13%;
          }

          @media all and (min-width: 1200px) {
            width: 100px;
            margin-top: -50px;

          }

          .play-btn-img {
            width: 100%;
          }
        }
      }
    }
  }

  &.hero--image {
    display: flex;
    align-items: center;
    justify-content: center;
    background: {
      size: cover;
      position: center;
      repeat: no-repeat;
    }
    text-align: center;

    .hero-image-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(8, 32, 60, 0.65);
    }

    .hero-image-inner {
      max-width: 82%;
      margin: 0 auto;
      padding: 8em 0;

      @media only screen and (min-width: 1400px) {
        max-width: 43%;
      }
      @media all and (min-width: $bp-small) {
        max-width: 70%;
      }

      .hero__title {
        margin-bottom: 0.4em;
        font: {
          size: 1.875rem;
          weight: 700;
        }
        text-transform: none;
        line-height: 1.4;
        max-width: 100% !important;

        @media all and (min-width: $bp-xs) {
          font-size: 3rem;
          line-height: 1.2;
        }

        @media all and (min-width: $bp-medium) {
          font-size: 4.5rem;
        }

        @media (min-width: 1025px) and (max-width: 1366px) {
          font-size: 2.4rem !important;
        }

        &.hero__title__small {
          margin: {
            top: 1em;
            bottom: 2em;
          }
        }
      }

      .hero__subtitle {
        display: inline-block;
        max-width: 92%;
        font: {
          size: .8em;
          weight: 300;
        }
        line-height: 1.5;
        letter-spacing: .4px;
        color: $color-white;
        opacity: .99;

        strong {
          font: {
            size: 1.2em;
            weight: 600;
          }
        }

        @media only screen and (min-width: 1400px) {
          font-size: 1.10rem;
        }
        @media all and (min-width: $bp-small) {
          font-size: 1.25rem;
        }
      }

      .btn {
        display: block;
        max-width: 200px;
        margin: 3em auto 0;

        @media (min-width: 1025px) and (max-width: 1366px) {
          margin: 2em auto 0 !important;
        }
      }

      .hero-link {
        display: inline-block;
        margin-top: 1em;
        color: $color-white;
        opacity: .99;
      }
    }
  }


  /**
  * Split
  */

  &.hero--split {

    .hero__body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .hero__title {
      font-weight: $weight-light;
      text-shadow: none;
    }

    @media all and (min-width: $bp-small) {
      .hero__body {
        padding-top: 9.625em;
      }
    }

    @media all and (min-width: $bp-large) {
      .hero__body {
        padding-top: 5.375em;
      }
    }
  }

  /**
   * Transparent
   */

  &.hero--transparent {
    background-color: transparent;
    padding-top: 2em;

    .hero__title,
    .hero__synopsis,
    .sub-header {
      color: $color-primary;
      text-shadow: none;
    }
  }

  /* Title */

  .hero__title {
    animation: animation-fade 0.75s forwards;
    color: $color-white;
    font-size: 2.125em; /* 34 / 16 */
    font-weight: $weight-heavy;
    line-height: 1;
    margin-bottom: 0;
    text-shadow: 0 2px 10px rgba($color-black, 0.2);
    text-transform: uppercase;

    & + .hero__synopsis {
      margin-top: 1em;
    }
  }

  /* Synopsis */

  .hero__synopsis {
    animation: animation-fade 0.5s forwards;
    color: $color-white;
    font-size: 1em;
    text-shadow: 0 2px 10px rgba($color-black, 0.2);
  }

  /* Scroll */

  .hero__scroll {
    animation: bounce 3s infinite;
    color: $color-white;
    font-size: 2em;
    margin-top: 1em;
    opacity: 0.7;
    text-align: center;
    z-index: $z-index-scroll;
  }

  /* Sub Header */

  .sub-header {
    animation: animation-fade 0.5s forwards;
    color: $color-white;
  }
}

.mid-year-promotion {

  .birthday-hero--body {

    @media all and (max-width: $bp-small) {
      padding-top: 0em;
    }
  }

  .hero__body {
    @media all and (min-width: $bp-small) {
      padding-top: 8em !important;
      padding-bottom: 16em !important;
    }
  }

  .hero-promo-margin {
    margin-left: 30% !important;

    @media all and (min-width: 2000px) {
      margin-left: 37.5% !important;
    }

    @media all and (max-width: $bp-large) {
      margin-left: 20% !important;
    }

    @media all and (max-width: $bp-small) {
      margin-left: auto !important;
    }
  }

  .bottom-text-box {
    margin-left: 1em;
    margin-right: 1em;
  }

  .promotion-cta-box {
    font-size: 1.5em;

    .tcs-link {
      font-size: 18px;
    }

    @media all and (max-width: $bp-xs) {
      font-size: 1em;

      .tcs-link {
        font-size: 12px;
      }
    }
  }


  .hero-background {
    background-size: cover;
    background-image: url('../../../assets/images/heros/portal-desktop-small.jpg');

    padding-top: 2em;
    padding-bottom: 18em;

    @media all and (min-width: 1100px) {
      background-image: url('../../../assets/images/heros/portal-desktop-medium.jpg');
    }

    @media all and (min-width: 1500px) {
      background-image: url('../../../assets/images/heros/portal-desktop-large.jpg');
    }

    @media all and (min-width: 2000px) {
      background-image: url('../../../assets/images/heros/portal-desktop-xl.jpg');
    }


    //@media all and (max-width: $bp-xl) {
    //    background-image: url('../../../assets/images/heros/invest-portal-desktop-large.jpg');
    //}
    //
    //@media all and (max-width: $bp-large) {
    //    background-image: url('../../../assets/images/heros/invest-portal-desktop-medium.jpg');
    //}

    @media all and (max-width: $bp-small) {
      padding-top: 1em;
      padding-bottom: 22em;
      background-image: url('../../../assets/images/heros/invest-portal-mobile.jpg');
    }

    @media all and (max-width: 38.75em) {
      padding-top: 1em;
      padding-bottom: 20em;

    }

    @media all and (max-width: $bp-xs) {
      padding-top: 0em;
      padding-bottom: 20em;
      background-image: url('../../../assets/images/heros/invest-portal-mobile-small.jpg');
    }
  }

  .invest-portal-desktop {
    display: inline-block;
  }

  .invest-portal-mobile {
    display: none !important;
  }

  @media all and (max-width: $bp-small) {
    .invest-portal-desktop {
      display: none;
    }
    .invest-portal-mobile {
      display: block !important;
    }
  }

}

.invest-hero-promotion {
  .birthday-hero-background {

    background-size: cover;
    background-image: url('../../../assets/images/heros/mid-year-campaign-desktop.jpg');

    @media all and (max-width: $bp-small) {
      background-image: url('../../../assets/images/heros/background_half_candle_invest_portal_mobile.jpg');
    }
  }

  .hero-promo-small {

    display: block;
    padding-top: 16em !important;
    padding-bottom: 16em !important;

    @media all and (max-width: $bp-xxl) {
      padding-top: 10em !important;
      padding-bottom: 12em !important;
      display: block;
    }

    @media all and (max-width: $bp-large) {
      padding-top: 6em !important;
      display: block;
    }
    @media all and (max-width: $bp-medium-2) {
      padding-top: 6em !important;
      padding-bottom: 8em !important;
      display: block;
    }
    @media all and (max-width: $bp-medium) {
      padding-top: 4em !important;
      display: block;
    }
    @media all and (max-width: $bp-small) {
      display: none;
    }
  }

  .hero-birthday-promo-mobile {
    display: block;
    padding-top: 2em;
    padding-bottom: 14em;

    @media all and (min-width: $bp-small) {
      display: none;
    }
  }

  .hero__text-large {
    margin-top: 0em;
    font-size: 4em;

    @media all and (max-width: $bp-large) {
      font-size: 2.5em;
    }

    @media all and (max-width: $bp-medium) {
      font-size: 1.5em;
    }
  }

  .hero__text-medium {
    font-size: 2.25em;

    @media all and (max-width: $bp-xs) {
      font-size: 1em;
    }
  }

  .hero__text-small {
    font-size: 1.25em;
    padding-top: 0.5em;

    @media all and (max-width: $bp-large) {
      font-size: 1em;
    }

  }

  .hero-promo-margin {

    margin-left: 40% !important;
  }

}

@media all and (min-width: $bp-small) {

  .hero {

    .hero__body {
      padding-top: 12em;
      padding-bottom: 3em;
    }

    &.hero--flat {

      .hero__body {
        padding-top: 10em !important;
        padding-bottom: 0 !important;
      }

      .form {
        transform: translateY(1.5em);
      }
    }

    &.hero--light {

      .hero__body {
        padding-top: 9em;
      }
    }

    &.hero--small {

      .hero__body {
        padding-top: 12em;
        padding-bottom: 4em;
      }

      .hero__title {
        font-size: 4.25em; /* 68 / 16 */
        max-width: 100%;
      }
    }

    &.hero.hero--half {
      .half--1-2 {
        color: $color-white;
        display: block;
        width: 60%;
        float: none;
        margin: 0 auto;
      }

      .hero__right {
        background-color: transparent;
        padding: 12em 0 6.5em 0;
      }

      .hero__title--small {
        font-size: 3em;
      }
    }

    &.hero--transparent {
      padding-top: 9em;
    }

    .hero__title {
      font-size: 4.25em; /* 68 / 16 */
    }

    .hero__synopsis {
      font-size: 1.375em; /* 22 / 16 */
      max-width: 85%;
    }
  }
}

@media all and (min-width: $bp-large) {

  .hero {

    .hero__body {
      padding-top: 9em;
    }

    &.hero--small {

      .hero__body {
        padding-top: 10em;
      }
    }

    &.hero.hero--half {
      .half--1-2 {
        color: $color-white;
        display: inline-block;
        width: 49%;
        float: right;
      }

      .hero__right {
        background-color: rgba(0, 174, 239, 0.8);
        padding: 12em 2.5em 6.5em 5em;
      }

      .hero .hero__title--small {
        font-size: 2em;
      }
    }
  }

  .hero-promo-small {
    padding-bottom: 8em !important;
  }

}

@media all and (min-width: $bp-small) and (min-height: $bp-medium) {

  .hero {

    .hero__body {
      padding-top: 12em;
      padding-bottom: 8em;
    }

    .hero__title {
      font-size: 5.5em;
      max-width: 7em;

      &.hero__title--small {
        font-size: 4.25em;
        max-width: none;
      }
    }

    &.hero--flat {

      .hero__body {
        padding-top: 12em;
      }
    }

    &.hero--small {

      .hero__body {
        padding-top: 12em;
        padding-bottom: 8em;
      }
    }

    &.hero--transparent {
      padding-top: 10em;
    }
  }
}

@keyframes bounce {

  0% {
    opacity: 0.7;
    transform: translateY(-10px);
  }

  50% {
    opacity: 1;
    transform: translateY(10px);
  }

  100% {
    opacity: 0.7;
    transform: translateY(-10px);
  }
}

/* June 2020 Fee Free*/
.full-height {
  height: 100% !important;
}

.june2020feefree {
  //background-image: url('url{{'/assets/images/heros/lifebuoy.jpg'}}');
  height: 100%;
  background-position: 40% 40%;
  background-size: cover;
  display: flex;
  align-items: center;

  @media screen and (max-width: $bp-small) {
    background-position: 100% 100%;
    background-size: unset;
  }

  @media (min-width: 46.26em) and (max-width: $bp-medium) {
    background-position: bottom;
    background-size: 300%;
  }

  .june2020container {
    width: 80%;
    height: 55vh;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $bp-medium) {
      width: 100%;
      //height: unset;
      //display: block;
    }

    .june2020inner {
      width: 45%;
      flex-direction: column;
      display: flex;
      justify-content: center;

      @media screen and (max-width: $bp-medium) {
        width: 100%;
        //display: block;
      }

      .hero__title {
        text-transform: none !important;
        font-weight: 200;
        text-align: left;
        text-shadow: none;
        font-size: 60px;
        letter-spacing: 1px;
        line-height: 66px;
        max-width: unset;

        span {
          font-weight: 800;
        }

        @media (min-height: 651px) and (max-height: 750px) {
          font-size: 55px;
          line-height: 55px;
        }

        @media screen and (max-height: 650px) {
          font-size: 36px;
          line-height: 36px;
        }

        @media screen and (max-width: $bp-medium) {
          display: none;
        }
      }

      .lifebuoy-title-mobile {
        display: none;
        @media screen and (max-width: $bp-medium) {
          display: flex;
          align-items: center;
        }

        .lifebuoy-img {
          margin-left: 12px;
          width: 40vw;
        }

        .hero__title {
          @media screen and (max-width: $bp-medium) {
            display: block;
            font-size: 55px;
            line-height: 55px;
            margin: 0 12px 0 8px;
          }
          @media screen and (max-width: $bp-small) {
            font-size: 25px;
            line-height: 25px;
          }
        }
      }

      .hr--short {
        border-top-width: 4px !important;
        margin: 1em 0;

        @media screen and (max-width: $bp-medium) {
          display: none;
        }
      }

      .hero-subtext {
        text-align: left;
        color: #ffffff;
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 37px;
        margin-bottom: 1rem;

        @media screen and (max-height: 635px) {
          font-size: 18px;
          line-height: 24px;
        }

        @media screen and (max-width: $bp-medium) {
          text-align: center;
          font-size: 24px;
        }

        @media screen and (max-width: $bp-small) {
          text-align: center;
          font-size: 15px;
          line-height: 20px;
        }

        span {
          font-weight: 700;
        }
      }

      .hero-slider-btn-container {
        text-align: left;
        width: 250px;
        margin-top: unset;
        @media screen and (max-width: $bp-medium) {
          margin: 0 auto;
        }


        .btn {
          width: 250px !important;
          @media screen and (max-height: 635px) {
            height: 40px;
            line-height: 0px;
          }
          @media screen and (max-width: $bp-small) {
            width: 150px !important;
            height: 35px;
            margin: 0 auto;
            font-size: 12px;
            display: block;
            line-height: 12px;
          }
        }

        a {
          color: #ffffff;
          text-decoration: none;
          font-weight: 300;

          .smallsize {
            text-align: center;
            color: #ffffff;
            font-size: 12px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

}

.hero__tax {

  font-size: 4.125rem;
  padding-right: 1em;
  vertical-align: middle;

  @media screen and (max-width: 1680px) {
    font-size: 3.75rem
  }

  @media screen and (max-width: 1540px) {
    font-size: 3.25rem;
  }

  @media screen and (max-width: 1340px) {
    font-size: 2.5rem;
    padding: 0;
  }

  @media screen and (max-width: 968px) {
    font-size: 2rem;
    padding: 0;
  }

  @media screen and (max-width: 800px) {
    font-size: 1.8rem;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 2rem;
    padding: 0;
  }
}

.hero__tax-2 {

  font-size: 2.75rem;

  @media screen and (max-width: 1680px) {
    font-size: 2rem
  }

  @media screen and (max-width: 1540px) {
    font-size: 1.75rem;
    padding: 0;
  }

  @media screen and (max-width: 1340px) {
    font-size: 1.5rem;
    padding: 0;
  }
}

.hero__tax-image {
  width: 250px !important;
  display: inline-block;
  vertical-align: middle;
  //padding-top: 0.5em;

  @media screen and (max-width: 1540px) {
    width: 225px !important;
  }

  @media screen and (max-width: 1340px) {
    width: 150px !important;
  }

  @media screen and (max-width: 968px) {
    width: 150px !important;
  }

  @media screen and (max-width: 805px) {
    width: 125px !important;
  }

  @media screen and (max-width: 768px) {
    width: 175px !important;
  }
  @media screen and (max-width: 400px) {
    width: 150px !important;
  }
  @media screen and (max-width: 360px) {
    width: 125px !important;
  }
}

.hero__tax-image-2 {
  width: 275px !important;
  display: inline-block;
  vertical-align: middle;
  //padding-top: 0.5em;

  @media screen and (max-width: 1540px) {
    width: 250px !important;
    vertical-align: middle;
  }

  @media screen and (max-width: 1340px) {
    width: 175px !important;
    vertical-align: middle;
  }

  @media screen and (max-width: 968px) {
    width: 150px !important;
    vertical-align: middle;
  }

  @media screen and (max-width: 768px) {
    width: 175px !important;
    vertical-align: bottom;
  }

  @media screen and (max-width: 450px) {
    width: 150px !important;
    vertical-align: bottom;
  }

  @media screen and (max-width: 325px) {
    width: 125px !important;
    vertical-align: bottom;
  }
}

.hero__tax-span {
  vertical-align: middle;
  padding: 0em;
  padding-left: 0;

  @media screen and (max-width: 1340px) {
    //vertical-align: bottom;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    padding-top: 0.75em;
    vertical-align: top;
  }
}


.hero__tax-hr {
  width: 1px;
  height: 5em;
  display: inline-block;
  border: 0;

  text-align: right;
  vertical-align: middle;
  border-left: 1px solid white;
  margin: 0px;

  @media screen and (max-width: 1680px) {
    height: 5em;
  }

  @media screen and (max-width: 1340px) {
    height: 3em;
  }

  @media screen and (max-width: 768px) {
    width: auto;
    height: 1em;
    border: 0;
    border-bottom: 1px solid #ffffff;
    display: block;

    width: 70%;
    margin: auto;
  }
  @media screen and (max-width: 550px) {
    width: auto;
    height: 1em;
    border: 0;
    border-bottom: 1px solid #ffffff;
    display: block;

    width: 80%;
    margin: auto;
  }
  @media screen and (max-width: 400px) {
    width: auto;
    height: 1em;
    border: 0;
    border-bottom: 1px solid #ffffff;
    display: block;
  }
}

.hero__tax-body {
  padding-top: 4em !important;
  padding-bottom: 4em !important;

}

/* Why 10X -> Performance Page Hero */
.hero__performance-outer {
  .hero__body {
    background: linear-gradient(to right, #00AEEF 0%, #007BEC 50%, #0072EC 100%);
    height: auto;
    padding-bottom: 0 !important;
    @media (min-width: $bp-large) and (max-width: $bp-xl) {
      padding-top: 2em !important;
    }
    @media (max-width: $bp-small) {
      padding-top: 30px !important;
    }

    .l-wrapper {
      max-width: 100%;
      text-align: center;
      @media (min-width: $bp-small) and (max-width: 1024px) {
        width: 92%;
      }
      @media (max-width: $bp-small) {
        width: 92%;
      }

      .hero__title {
        span, div {
          &.hero__tax {
            display: inline-block;
            text-transform: none;
            text-align: left;

            div {
              font-family: Open Sans, sans-serif;
              font-weight: 800;
              text-align: center;

              .hero__tax-hr {
                width: auto;
                height: 1em;
                border: 0;
                border-bottom: 1px solid #ffffff;
                display: block;
                margin: auto;
              }

              .hero__performance-text {
                font-weight: 100;
                text-align: center;
                padding-top: 1em;
                font-size: 2.75rem;

                @media screen and (max-width: 768px) {
                  font-size: 1.5rem
                }
              }
            }
          }

          &.hero__performance-div {
            display: inline-block;
            text-transform: none;
            text-align: left;

            @media screen and (max-width: 768px) {
              display: block;
              text-transform: none;
              text-align: center;
            }

            div {
              .hero__performance-image {
                @media screen and (max-width: 768px) {
                  width: 125px !important;
                }
                @media screen and (max-width: 550px) {
                  width: 100px !important;
                }
              }
            }
          }
        }
      }

      .hero__headings {
        margin-bottom: 42px;
        @media (max-width: $bp-small) {
          margin-bottom: 23px;
        }

        h1 {
          &.hero__title {
            color: #E7F0FF;
            text-transform: unset;
            letter-spacing: 0;
            font-size: 40px;
            line-height: 60px;
            font-weight: bold;
            text-shadow: none;
            margin-bottom: 8px;
            @media (min-width: $bp-large) and (max-width: $bp-xl) {
              font-size: 30px;
              line-height: 42px;
            }

            @media (min-width: $bp-small) and (max-width: 1024px) {
              margin-bottom: 32px;
            }

            @media (max-width: $bp-small) {
              font-size: 20px;
              line-height: 27px;
              margin-bottom: 10px;
            }

          }
        }

        h2 {
          &.hero__secondary__title {
            color: #E7F0FF;
            text-transform: none;
            font-weight: 500;
            font-size: 30px;
            line-height: 34px;
            @media (min-width: $bp-large) and (max-width: $bp-xl) {
              font-size: 20px;
              line-height: 34px;
            }
            @media (min-width: $bp-small) and (max-width: 1024px) {
              line-height: 40px;
            }
            @media (max-width: $bp-small) {
              font-size: 14px;
              line-height: 18px;
            }

          }
        }
      }

      .graph-outer {
        margin-bottom: 99px;
        @media (min-width: $bp-large) and (max-width: $bp-xl) {
          margin-bottom: 37px;
        }
        @media (min-width: $bp-small) and (max-width: 1024px) {
          margin-bottom: 64px;
        }
        @media (max-width: $bp-small) {
          display: none;
        }

        &.mobile {
          display: none;
          @media (max-width: $bp-small) {
            display: block;
            margin-bottom: 17px;
          }
        }

        .graph-inner {
          max-width: 92%;
          margin: 0 auto;
          display: block;
          @media (min-width: $bp-large) and (max-width: $bp-xl) {
            max-width: 59%;
          }
          @media (min-width: $bp-small) and (max-width: 1024px) {
            max-width: unset;
          }
          @media (max-width: $bp-small) {
            max-width: unset;
          }

          img {

          }
        }
      }
    }
  }
}

// June 2022 Promo 6 Months Free Hero

.promo-hero {
  height: calc(100vh - 200px);
  background-color: #2680EB;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-repeat: no-repeat;
  background-position: 15%;
  background-size: contain;
  color: #E7F0FF;
  text-align: left;
  line-height: 50px;
  position: relative;

  @media (min-width: $bp-large) and (max-width: $bp-xl) {
    height: calc(100vh - 140px);
  }
  @media (min-width: $bp-small) and (max-width: 1024px) {
    height: 100%;
  }
  @media (max-width: $bp-small) {
    height: 100%;
  }

  .hero-inner {
    display: flex;
    width: 60%;
    max-width: 920px;
    align-items: center;
    height: 100%;

    @media (min-width: $bp-xl) {
      position: relative;
    }

    @media (min-width: 1024px) and (max-width: $bp-xl) {
      width: 75%;
      position: relative;
    }
    @media (min-width: $bp-small) and (max-width: 1024px) {
      flex-direction: column-reverse;
      width: 69%;
      padding: 22px 0;
      position: relative;
    }
    @media (max-width: $bp-small) {
      flex-direction: column-reverse;
      width: 80%;
      padding: 22px 0;
    }

    .hero-image-outer {
      height: 100%;

      .image-underlay {
        position: absolute;
        top: 0;
        left: -25%;

        @media (min-width: $bp-large) and (max-width: $bp-xl) {
          top: -5%;
        }
        @media (min-width: $bp-small) and (max-width: 1024px) {
          position: absolute;
          top: 25%;
          left: 0;
        }
        @media (max-width: $bp-small) {
          position: absolute;
          left: 0;
        }

        svg {
          width: 100%;
          z-index: 1;
          @media (min-width: $bp-large) and (max-width: $bp-xl) {
            //width: 75%;
          }
          @media (min-width: $bp-small) and (max-width: 1024px) {
            display: block;
            margin: 0 auto;
            max-width: 670px;
          }
          @media (max-width: $bp-small) {

          }
        }
      }

      .hero-image-inner {
        z-index: 2;
        position: relative;
        height: 100%;

        @media (min-width: $bp-large) and (max-width: $bp-xl) {
          position: relative !important;
        }
        @media (min-width: $bp-small) and (max-width: 1024px) {
          position: relative;
          height: 50vh;
        }
        @media (max-width: $bp-small) {
          height: 50vh;
        }

        img {
          height: 100%;
          width: auto;
          max-width: unset;

          &.desktop {
            @media (min-width: $bp-large) and (max-width: $bp-xl) {

            }
            @media (min-width: $bp-small) and (max-width: 1024px) {
              display: none;
            }
            @media (max-width: $bp-small) {
              display: none;
            }
          }

          &.mobile {
            display: none;
            @media (min-width: $bp-large) and (max-width: $bp-xl) {

            }
            @media (min-width: $bp-small) and (max-width: 1024px) {
              display: block;
            }
            @media (max-width: $bp-small) {
              display: block;
            }
          }
        }
      }
    }

    .text-outer {
      z-index: 3;

      .hero-title {
        font-size: 4em;
        @media (min-width: $bp-large) and (max-width: $bp-xl) {
          font-size: 3.2em;
        }
        @media (min-width: $bp-small) and (max-width: 1024px) {
          line-height: 1.2;
          font-size: 3.6em;
          br {
            display: none;
          }
        }
        @media (max-width: $bp-small) {
          font-size: 2.5em;
          line-height: 1.2;
          margin-bottom: 12px;
        }

        .mobile {
          display: none;
          @media (min-width: $bp-small) and (max-width: 1024px) {
            display: inline-block;
          }
        }
      }

      .hero__subtitle {
        font-size: 2.2em;
        line-height: 1.5;
        display: block;
        @media (min-width: $bp-large) and (max-width: $bp-xl) {
          font-size: 1.8em;
          line-height: 1.5;
          width: 75%;
        }
        @media (min-width: $bp-small) and (max-width: 1024px) {
          font-size: 30px;
          br {
            display: none;
          }
        }
        @media (max-width: $bp-small) {
          font-size: 18px;
          line-height: 25px;
        }
      }

      hr {
        border: 4px solid white;
        width: 5%;
        margin: 20px 0;

        @media (min-width: $bp-large) and (max-width: $bp-xl) {
          margin: 15px 0;
        }

        @media (min-width: $bp-small) and (max-width: 1024px) {
          margin: 10px 0;
        }
        @media (max-width: $bp-small) {
          border: 2px solid white;
          width: 5%;
          margin: 10px 0;
        }

      }

      .terms {
        font-size: 19px;
        color: white;
        text-decoration: none;
        margin-bottom: 40px;
        display: block;
        line-height: 1.2;
        @media (min-width: $bp-large) and (max-width: $bp-xl) {
          font-size: 12px;
          margin-bottom: 20px;
        }
        @media (min-width: $bp-small) and (max-width: 1024px) {
          font-size: 20px;
          margin-bottom: 0;
        }
        @media (max-width: $bp-small) {
          font-size: 12px;
          margin-bottom: 0;
        }
      }

      .btn-container {
        @media (min-width: $bp-large) and (max-width: $bp-xl) {

        }
        @media (min-width: $bp-small) and (max-width: 1024px) {
          position: absolute;
          display: flex;
          bottom: 10%;
          align-items: center;
          justify-content: center;
          width: 100%;
          left: 0;
        }
        @media (max-width: $bp-small) {
          position: absolute;
          display: flex;
          bottom: 10%;
          align-items: center;
          justify-content: center;
          width: 100%;
          left: 0;
        }

        .btn {
          border-radius: 100px;
          font-size: 30px;
          text-align: center;
          box-shadow: -1px -1px 3px 2px rgba(255, 255, 255, 0.75);
          display: block;
          width: fit-content;
          height: fit-content;
          padding: 0.7em 1.2em;

          .tablet {
            display: none;
          }

          .mobile {
            display: none;
          }

          @media (min-width: $bp-large) and (max-width: $bp-xl) {
            width: 360px;
            font-size: 20px;
            height: 58px;
            transform: scale(0.8);
            transform-origin: left;
          }

          @media (min-width: $bp-small) and (max-width: 1024px) {
            width: 350px;
            height: fit-content;
            border-radius: 5px;
            font-size: 20px;
            padding-bottom: 1.3em;
            font-weight: 400;
            line-height: 1.2em;
            margin: 0 auto;

            .tablet {
              display: block;
            }
          }
          @media (max-width: $bp-small) {
            width: 160px;
            height: 114px;
            border-radius: 5px;
            font-size: 20px;
            padding-bottom: 1.3em;
            font-weight: 400;
            line-height: 1.2em;

            .tablet {
              display: none;
            }
            .mobile {
              display: block;
            }
          }
        }
      }
    }
  }
}


.our-purpose {
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }

  .hero--small {
    background: linear-gradient(to bottom, #00aeef, #00aeef, #3482eb 90%, #3482eb);
    @media screen and (max-width: 768px) {

    }
  }

  .hero__performance-text {
    @media screen and (max-width: 1152px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 950px) {
      font-size: 1.75rem;
    }
    @media screen and (max-width: 600px) {
      font-size: 1.5rem;
    }
  }

  .hero__tax {
    @media screen and (max-width: 1152px) {
      font-size: 3rem;
    }
    @media screen and (max-width: 950px) {
      font-size: 2.5rem;
    }
  }

  .hero__purpose-body {
    padding-top: 12em !important;
    padding-bottom: 8em !important;
    background-size: cover;
    height: auto;
    background-image: url('../../../assets/images/heros/our_purpose_header@2x.png');
    background-color: transparent;

    @media screen and (max-width: 1800px) {
      background-image: url('../../../assets/images/heros/our_purpose_header@2x1-min.png');
    }
    @media screen and (max-width: 1420px) {
      background-image: url('../../../assets/images/heros/our_purpose_header@3x3-min.png');
    }
    @media screen and (max-width: 1152px) {
      background-image: url('../../../assets/images/heros/our_purpose_header@3x7-min.png');
    }
    @media screen and (max-width: 900px) {
      background-image: url('../../../assets/images/heros/our_purpose_header@3x8-min.png');
    }
    @media screen and (max-width: 768px) {
      padding-top: 6em !important;
      padding-bottom: 12em !important;

      background-image: url('../../../assets/images/heros/our_purpose-mobile-4a-min.png');
    }
    @media screen and (max-width: 550px) {
      padding-top: 4em !important;
      background-color: transparent;
      background-image: url('../../../assets/images/heros/our_purpose-mobile-4b-min.png');
    }
    @media screen and (max-width: 470px) {
      background-color: transparent;
      background-image: url('../../../assets/images/heros/our_purpose-mobile-4b-min.png');
    }
  }
}

//TFSA
.hero-slide-inner {
  .parent {
    .banner_copy {
      h2.hero__title {
        hr.hero_your_future_hr.tfsa {
          width: 25rem !important;
          margin-top: 2rem !important;
        }
      }
    }
  }
}

.hero.hero--video .hero-slider-wrapper .hero-slider-wrapper-inner .hero-slider .hero-slide .hero-slide-inner .hero_your_future-btn, .hero.hero--image .hero-slider-wrapper .hero-slider-wrapper-inner .hero-slider .hero-slide .hero-slide-inner .hero_your_future-btn.tfsa {
  padding-left: 3em !important;
  padding-right: 3em !important;
}

.hero-slider-wrapper.tfsa.hero-slider-campaign {
  background: url('../../../assets/images/general/gradient_background.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.hero-slide.hero-slide-0.tfsa {
  .parent {
    padding-right: 0px !important;
    margin-top: 7rem;
  }
}

.hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
  content: url('../../../assets/images/heros/tfsa-banne-illustration.png'); /* with class ModalCarrot ??*/
  position: absolute; /*or absolute*/
  z-index: -1; /*a number that's more than the modal box*/
  left: -1.5em;
  top: -90%;
}

@media screen and (max-width: 1810px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -2em;
  }
}

@media screen and (max-width: 1810px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -2em;
  }
}

@media screen and (max-width: 1688px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -2.3em;
  }
}

@media screen and (max-width: 1660px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -2.3em;
  }
}

@media screen and (max-width: 1632px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -2.5em;
  }
}

@media screen and (max-width: 1560px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -2.9em;
  }
}

@media screen and (max-width: 1529px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -3.9em;
  }
}

@media screen and (max-width: 1398px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -4em;
    top: -6.7em;
  }
}

@media screen and (max-width: 1329px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -5em;
    top: -8.7em;
  }
}

@media screen and (max-width: 1267px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -5.5em;
    top: -8.7em;
  }
}

@media screen and (max-width: 1200px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    content: url('../../../assets/images/heros/banner-mobile-asset-tfsa.png'); /* with class ModalCarrot ??*/
    left: -2.5em;
    top: 3.3em;
  }
}

@media screen and (max-width: 739px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -0.5em;
    top: 6.3em;
  }
}

@media screen and (max-width: 542px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -2.5em;
    top: 6.3em;
  }
}

@media screen and (max-width: 500px) {
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -2.5em;
    top: 6.3em;
  }
}

@media screen and (max-width: 320px) {
  .hero.hero--video .hero-slider-wrapper .hero-slider-wrapper-inner .hero-slider .hero-slide .hero-slide-inner .hero_your_future-btn, .hero.hero--image .hero-slider-wrapper .hero-slider-wrapper-inner .hero-slider .hero-slide .hero-slide-inner .hero_your_future-btn.tfsa {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
}


