.preservation-hero {
  .hero--image {
    .hero-image-bg {

    }
    .preservation-desktop {

      @media screen and (max-width: 768px) {
        display: none !important;
      }

      display: block;
      width: 100%;

      .col {
        .hero__left-image {
          background-position: bottom 0 left 0 !important;
          background-size: auto !important;

          .row-items {
            display: flex;

            .col-img {
              margin-bottom: unset;
              .col {
                img {
                  max-width: 260px;
                  margin-left: auto;
                  margin-right: 60px;
                  display: block;
                }

                .pres-btn {
                  max-width: 260px;
                  margin-left: auto;
                  margin-right: 60px;

                  a {
                    max-width: 260px;
                    display:block;
                    padding: 20px;
                    background-color: #63c29d ;
                    text-decoration: none;

                    p{
                      color: #ffffff;
                      margin: 0 auto;
                      text-align: center;
                    }
                  }
                }

                .pres-promo {
                  color: #ffffff;
                  text-align: center;
                  max-width: 260px;
                  margin-left: auto;
                  margin-right: 60px;

                  span {
                    font-weight: bold;
                  }
                }
              }
            }

            .content-right-container--width {
              width: 100% !important;
              margin: unset !important;

              .pres_hero {
                padding-top: unset !important;
                padding-bottom: unset !important;
                align-items: center;
                align-content: center;
                display: flex;

                .l-wrapper--medium {
                  margin: unset !important;
                  width: unset !important;
                  max-width: unset !important;

                  .row {
                    .col {
                      h2 {
                        color: #1c3e6f;
                        text-align: left;
                        text-transform: none;
                        line-height: 50px;
                        max-width: 440px;

                        .hero-subtext {
                          letter-spacing: 1px;
                          text-align: left;

                          &.smallsize {
                            font-size: 30px;
                            font-weight: 600;
                          }
                          &.medsize {
                            font-size: 40px;
                            line-height: 30px;
                          }
                          &.bigsize {
                            font-size: 60px;
                          }
                        }

                      }

                      .hr {
                        border-top: #1c3e6f 4px solid !important;
                        max-width: 30px;
                      }

                      .hero-text-below-line {
                        h2 {
                          text-transform: none;
                          color: #ffffff;
                          font-size: 30px;
                        }
                        .hero-subtext {
                          font-size: 30px;
                          line-height: 37px;
                          color: #ffffff;
                        }
                        .tcs-link {
                          text-align: left;
                          color: #ffffff;
                          text-decoration: none;

                          a {
                            color: #ffffff;
                            text-decoration: none;

                            span {
                              color: #ffffff;
                              text-decoration: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

            }
          }
        }
      }

    }

    .preservation-mobile {

      @media screen and (min-width: 769px) {
        display: none !important;
      }

      display: block;
      width: 100%;

      .col {
        .hero__left-image {
          background-position: bottom 0 left 0 !important;
          background-size: auto !important;

          .row-items {
            display: flex;
            margin-bottom: 30px;
            padding: 0 30px;

            .col-img {
              margin-bottom: unset;
              width: 45%;
              align-items: center;
              align-content: center;
              display: flex;

              .col {

                @media (min-width: 501px) and (max-width: 768px) {
                  padding-left: 20px;
                }

                img {
                  max-width: 100%;
                  margin-left: auto;
                  margin-right: 30px;
                  display: block;
                }
              }
            }

            .content-right-container--width {
              width: 100% !important;
              margin: unset !important;
              padding: 12px;

              @media (min-width: 501px) and (max-width: 768px) {
                padding-left: 30px;
              }

              .pres_hero {
                padding-top: unset !important;
                padding-bottom: unset !important;
                align-items: center;
                align-content: center;
                display: flex;

                .l-wrapper--medium {
                  margin: unset !important;
                  width: unset !important;
                  max-width: unset !important;

                  .row {
                    .col {
                      h2 {
                        color: #1c3e6f;
                        text-align: left;
                        text-transform: none;
                        line-height: 50px;
                        max-width: 440px;

                        @media screen and (max-width: 450px) {
                          line-height: 25px;
                        }

                        @media (max-width: 768px) and (min-width: 451px) {
                          line-height: 29px;
                        }

                        .hero-subtext {
                          letter-spacing: 1px;
                          text-align: left;

                          &.smallsize {
                            font-size: 30px;
                            font-weight: 600;
                            @media screen and (max-width: 450px) {
                              font-size: 15px;
                            }
                            @media (max-width: 650px) and (min-width: 451px) {
                             font-size: 24px;
                            }
                          }
                          &.medsize {
                            font-size: 40px;
                            line-height: 35px;
                            @media screen and (max-width: 450px) {
                              font-size: 20px;
                              line-height: 15px;
                            }
                            @media (max-width: 650px) and (min-width: 451px) {
                              font-size: 30px;
                              line-height: 28px;
                            }
                          }
                          &.bigsize {
                            font-size: 60px;
                            line-height: 50px;

                            @media screen and (max-width: 450px) {
                              font-size: 30px;
                              line-height: 29px;
                            }
                            @media (max-width: 650px) and (min-width: 451px) {
                              font-size: 50px;
                              line-height: 46px;
                            }
                          }
                        }

                      }


                    }
                  }
                }
              }

            }
          }

          .row {
            .col {
              .hero-text-below-line {
                padding: 0 25px;

                .hero-subheader {
                  text-transform: none;
                  color: #ffffff;
                  font-size: 20px;

                  @media (min-width: 451px) and (max-width: 650px) {
                    font-size: 24px;
                    line-height: 29px;
                  }

                  @media screen and (max-width: 450px) {
                    font-size: 24px !important;
                    line-height: 29px !important;
                  }
                }
                .hero-subtext {
                  font-size: 20px;
                  line-height: 20px;
                  color: #ffffff;

                  @media (min-width: 451px) and (max-width: 650px) {
                    font-size: 24px;
                    line-height: 29px;
                  }

                  @media screen and (max-width: 450px) {
                    font-size: 24px !important;
                    line-height: 27px !important;
                  }
                }
                .tcs-link {
                  text-align: left;
                  color: #ffffff;
                  text-decoration: none;

                  a {
                    color: #ffffff;
                    text-decoration: none;

                    span {
                      color: #ffffff;
                      text-decoration: none;
                    }
                  }
                }
              }

              .pres-btn {
                max-width: 260px;
                margin:15px auto;

                a {
                  max-width: 260px;
                  display:block;
                  padding: 12px;
                  background-color: #63c29d ;
                  text-decoration: none;

                  p{
                    color: #ffffff;
                    margin: 0;
                    text-align: center;
                  }
                }
              }

              .pres-promo {
                color: #ffffff;
                text-align: center;
                max-width: 260px;
                margin: 0 auto;

                span {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

    }
    
    //rotated phones 
    @media screen and (max-height: 500px) {
      .preservation-desktop {
        display: none !important;
      }

      .preservation-mobile {
        display: block !important;
        .col {
          .hero__left-image  {
            .row-items {
              margin-bottom: 0;
              padding: 0 45px 0 0;
              .col {
                width: 100% !important;
                &.content-right-container--width {
                  padding-left: 10px;
                }
                &.col-img {
                  img {
                    padding-right: 10px !important;
                  }
                }
              }
              img {
                height: 27vh;
                margin-right: 0!important;
                //padding-left: 10px !important;
              }
            }
            .row {
              h2 {
                font-size: 15px !important;
              }
              .hero-subtext {
                display: block;
                font-size: 15px !important;
                line-height: 18px !important;
                br {
                  display: block !important;
                }
              }
              br {
                display: none;
              }
              .tcs-link {
                font-size: 10px !important;
              }
              .pres-btn {
                margin: 5px auto !important;
                a {
                  padding: 4px !important;
                  max-width: 200px !important;
                  margin: 0 auto;
                  p {
                    font-size: 10px !important;
                  }
                }
              }
              .pres-promo {
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }

  }
}