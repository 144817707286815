/* -------------------------------------------------------------------------- *\
   #PARAGRAPH
\* -------------------------------------------------------------------------- */

p {
    font-size: 0.875em; /* 14 / 16 */
    font-weight: $weight-light;
    margin-top: 0;
    margin-bottom: 2rem;

    /* Anchor */

    a {
        @include anchor();
    }

    /* Mark */

    mark {
        background-color: $color-yellow;
        color: $color-black;
    }
}

@media all and (min-width: $bp-small) {

    p {
        font-size: 1.25em;
    }
}
