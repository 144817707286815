/* -------------------------------------------------------------------------- *\
   #MEDIA
\* -------------------------------------------------------------------------- */

audio,
video {
    height: auto;
    max-width: 100%;
    width: 100%;
}
