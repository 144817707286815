/* -------------------------------------------------------------------------- *\
   #SARS Banner
\* -------------------------------------------------------------------------- */

.sars-banner-wrapper {
  margin: 3em 0 4em;

  .sars-banner {
    display: flex;
    align-items: center;
    background: transparent linear-gradient(90deg, #00AEEF 0%, #0072EC 100%) 0 0 no-repeat padding-box;
    border-radius: 4px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, .2);

    @media (max-width: $bp-medium) {
      padding: 2em 1.6em;
    }

    .sars-banner-image {
      width: 22%;
      position: relative;

      @media (max-width: $bp-medium) {
        width: 38%;
      }

      img {
        position: absolute;
        top: -110px;
        right: 0;

        @media (max-width: $bp-medium) {
          position: relative;
          top: auto;
          right: auto;
        }
      }
    }

    .sars-banner-content {
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: -1em;

      @media (max-width: $bp-medium) {
        display: block;
        margin-left: 0;
        padding-left: 2em;
      }

      .sars-banner-content-left {
        max-width: 42%;

        @media (max-width: $bp-medium) {
          max-width: 100%;
          margin-top: 0;
          margin-bottom: 1em;
        }
      }

      .sars-banner-content-right {
        max-width: 58%;
        padding: 2em 1em;

        @media (max-width: $bp-medium) {
          max-width: 100%;
          padding: 0;
        }

        .sars-banner-content-text {
          color: $white;
          letter-spacing: 1px;
          font-size: .8em;

          @media (max-width: $bp-medium) {
            font-size: .9em;
            margin-bottom: 2em;
          }

          .light {
            font-weight: 300;
          }

          .heavy {
            font-weight: 800;
          }
        }

        .btn {
          font-size: .9em;
          box-shadow: 3px 3px 4px rgba(0, 0, 0, .15), -3px -3px 4px #ffffff99;
        }
      }
    }
  }
}

.l-section.tfsa {
  .content-block__synopsis {
    br {
      @media (max-width: $bp-medium) {
        display: none;
      }
    }
  }
}