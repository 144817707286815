/**
 * AWARD
 */

/* -------------------------------------------------------------------------- *\
   #TABLE OF CONTENTS
\* -------------------------------------------------------------------------- */

/**
 * AWARD
 * AWARDS
 */





/* -------------------------------------------------------------------------- *\
   #AWARD
\* -------------------------------------------------------------------------- */

.award {
    display: inline-block;
    margin-right: 0.5em;
    margin-bottom: 1em;
    margin-left: 0.5em;
    width: 20em; /* 224 / 16 */
    max-width: 100%;

    @media all and (max-width: 768px) {
        margin: {
            left: auto;
            right: auto;
        }
    }

    /* Image */

    .award__img {
        display: inline-block;
        width: 2.5em; /* 40 / 16 */
    }

    /* Title */

    .award__title {
        display: inline-block;
        color: $color-white;
        font: {
            size: .85em;
            weight: 600;
        }
        padding-top: 0.375em;
        vertical-align: middle;
        width: calc(100% - 9em);

        @media all and (max-width: 768px) {
            font-size: .8em;
        }
    }
}





/* -------------------------------------------------------------------------- *\
   #AWARDS
\* -------------------------------------------------------------------------- */

.awards {
    border-top: 0.063em solid rgba($color-white, 0.2);
    padding-top: 2.5em;
    text-align: center;

    /**
     * Borderless
     */

    &.awards--borderless {
        border-top: 0;
        margin-top: 0;
        padding-top: 0;
    }

    /* Label */

    .awards__label {
        color: $color-white;
        font-weight: $weight-bold;
        margin-bottom: 1em;
        text-transform: uppercase;
    }

    /* Synopsis */

    .awards__synopsis {
        color: $color-gray;
        font-size: 1em;
        line-height: 1.4;
        font-weight: 400;
        margin-bottom: 1em;
    }
}
