/* -------------------------------------------------------------------------- *\
   #FILTER
\* -------------------------------------------------------------------------- */

.filter {

    .btn {
        margin-bottom: 2em;
        width: 100%;
    }
}

@media all and (min-width: $bp-small) {

    .filter {
        margin-bottom: 0;

        .btn {
            display: none;
        }

        .nav {
            display: block;
        }
    }
}














