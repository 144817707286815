/* -------------------------------------------------------------------------- *\
   #IMAGE
\* -------------------------------------------------------------------------- */

img {
    height: auto;
    max-width: 100%;
}

img.small{
	width: 80%;
}

img.img--offset{
	margin-top: 4em;
}

@media all and (max-width: $bp-small) {
	img.img--offset{
		margin-top: 1em;
	}
}