/* -------------------------------------------------------------------------- *\
   #HTML
\* -------------------------------------------------------------------------- */

html {
    background-color: $color-white;
    box-sizing: border-box;
    color: $color-gray-dark;
    font: 100%/1.75 $font;
}


