/* -------------------------------------------------------------------------- *\
   #SCROLL ARROW
\* -------------------------------------------------------------------------- */

.scroll-arrow {
    animation: bounce 3s infinite;
    color: $color-white;
    font-size: 2em;
    margin-top: 1em;
    opacity: 0.7;
    text-align: center;
    z-index: $z-index-scroll;
}