/* -------------------------------------------------------------------------- *\
   #SOCIAL
\* -------------------------------------------------------------------------- */

.social {
    line-height: 1;
    margin-bottom: 1em;

    /* List */

    .social__list {
        @include clearfix();
        @include list();

        /* Item */

        .social__item {
            float: left;
            height: 2.5em;
            margin-bottom: 0;
            width: 2.5em;
            text-align: center;

            /**
             * Circle
             */

            &.social__item--circle {
                margin-right: 0.5em;

                .social__link {
                    border-radius: 100%;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    /* Link */

    .social__link {
        background-color: $color-gray-dark;
        border-bottom: 0;
        color: $color-white;
        display: block;
        font-size: 1.25em; /* 20 / 16 */
        height: 100%;
        padding-top: 0.5em;
        text-decoration: none;
        transition: background-color 0.25s;

        &.is-facebook {
            background-color: $color-facebook;
        }

        &.is-linkedin {
            background-color: $color-linkedin;
        }

        &.is-twitter {
            background-color: $color-twitter;
        }

        &:focus,
        &:hover {
            background-color: $color-black;
            color: $color-white;
        }
    }
}

@media all and (min-width: $bp-small) {

    .social {

        /**
         * Vertical
         */

        &.social--vertical {

            .social__list {

                .social__item {
                    float: none;
                }
            }
        }
    }
}
