html,
body {
  &.card-modal-active {
    overflow: hidden;
  }
}

.card-image {
  height: 220px;

  bottom: 0;
  position: absolute;
  right:0;
  float: right;

  @media (max-width: 1322px) {
    height: 200px
  }

  @media (max-width: $bp-large) {
    height: 190px;
  }

  @media (max-width: $bp-medium) {
    height: 180px;
  }
}

.card-navigation-wrapper {
  position: relative;
  background: $color-white-dark__alt;

  @media (min-width: $bp-small) {
    padding-top: 3.5em;
    padding-bottom: 4.5em;
  }

  .l-wrapper {
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .header {
    max-width: 100%;
    text-align: center;

    @media (max-width: $bp-large) {
      margin: 0 72px;
    }

    @media (max-width: $bp-small) {
      margin: 0 40px;
    }

    @media (max-width: 600px) {
      margin: 0 60px;
    }

    .header__title {
      margin-bottom: 1.2em;
    }
  }

  .card-navigation {
    .tns-outer {
      .tns-inner {
        padding: 4px;

        //@media (max-width: 600px) {
        //  margin: 0 100px;
        //}
        //
        //@media (max-width: $bp-small) {
        //  margin: 0 60px;
        //}
        //
        //@media (max-width: 400px) {
        //  margin: 0 40px;
        //}
      }
    }

    .card-row {
      @media (min-width: $bp-large) {
        display: flex;
        justify-content: center;
      }
      .card-col {
        padding: {
          left: 12px;
          right: 12px;
        }

        .card {
          position: relative;
          border-radius: 4px;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
          will-change: transform, box-shadow;
          transition: .4s ease-out;
          min-height: 400px;

          &:hover {
            transform: translateY(-6px);
            box-shadow: 2px 4px 6px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
          }

          .card-link {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }

          .card-top {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: $color-white-dark__alt;
            padding: 16px 20px;
            border-radius: 4px 4px 0 0;
            height: 127px !important;

            .card-subtitle {
              margin-bottom: .2rem;
              font-size: 1rem;

              @media (max-width: 1322px) {
                font-size: .8rem;
              }
            }

            .card-title {
              margin-bottom: 0;
              font-size: 1.3rem;
              line-height: 1.4;

              @media (max-width: 1322px) {
                font-size: 1.1rem;
              }

              @media (max-width: $bp-large) {
                font-size: 1rem;
              }
            }

            .card-title-overflow {
              margin-bottom: 0;
              font-size: 1.232rem;
              line-height: 1.4;

              @media (max-width: 1322px) {
                font-size: 1.1rem;
              }

              @media (max-width: $bp-large) {
                font-size: 1rem;
              }
            }

            .ts-cs-promo {
              color: #474c50;
              font-size: 0.6em;
            }

            .card-top-link {
              display: inline-flex;
              align-items: center;
              width: fit-content;
              font: {
                size: .8rem;
                weight: 700;
              }
              color: $color-primary;
              text-decoration: none;
              border-bottom: 2px solid;
              white-space: nowrap;

              @media (max-width: 1322px) {
                font-size: .7rem;
              }

              @media (max-width: $bp-large) {
                font-size: .6rem;
              }

              img {
                margin-left: 4px;
              }
            }
          }

          .card-bottom {
            height: 292px;
            background: {
              size: cover;
              position: center;
            }
            border-radius: 0 0 4px 4px;

            @media (max-width: 1322px) {
              height: 272px;
            }

            @media (max-width: $bp-large) {
              height: 300px;
            }

            @media (max-width: $bp-small) {
              height: 260px;
            }
          }
        }
      }
    }

    .card-pagination,
    .tns-nav{
      display: none;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      @media (max-width: $bp-large) {
        display: flex;
      }

      button {
        display: block;
        width: 8px;
        height: 8px;
        margin: 0 4px;
        padding: 0;
        border-radius: 100%;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
        background: #9DA8B6;
        border: none;

        &.tns-nav-active {
          background: $color-primary;
        }
      }
    }
  }

  .tns-nav{
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    @media (max-width: $bp-large) {
      display: flex;
    }

    button {
      display: block;
      width: 8px;
      height: 8px;
      margin: 0 4px;
      border-radius: 100%;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
      background: #9DA8B6;

      &.tns-nav-active {
        background: $color-primary;
      }
    }
  }

  .modal-outer {
    display: none;
    &.visible {
      display: block;
    }
    .card-navigation-overlay-bg,
    .card-navigation-overlay {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: .2s ease;
      will-change: opacity, visibility;

      .card-modal-active & {
        visibility: visible;
        pointer-events: auto;
      }
    }

    .card-navigation-overlay-bg {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: $color-white-dark__alt;
      z-index: 13;

      .card-modal-active & {
        opacity: .7;
      }
    }

    .card-navigation-overlay{
      @extend .l-section;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: $color-white-dark__alt;
      z-index: 15;

      @media (min-width: $bp-small) {
        padding-top: 4em;
        padding-bottom: 5em;
      }

      .card-modal-active & {
        opacity: 1;
      }

      .overlay-close {
        max-width: 40px;
        position: absolute;
        top: 88px;
        left: 32px;
        cursor: pointer;

        @media (max-width: $bp-large) {
          left: 0;
          top: 0;
        }

        @media (max-width: $bp-small) {
          left: 20px;
          max-width: 32px;
        }
      }

      .card-row {
        margin: 0 auto;

        @media (min-width: $bp-large) {
          max-width: 830px;
        }

        //.card-col {
        //  @media (max-width: $bp-large) {
        //    max-width: 32%;
        //  }
        //
        //  @media (max-width: $bp-small) {
        //    max-width: unset;
        //  }
        //}
      }
    }
  }
}