/* -------------------------------------------------------------------------- *\
   #CTA
\* -------------------------------------------------------------------------- */

.cta__banner {
    text-align: center;
}

.cta {
    background-color: $color-primary;
    padding-top: 1em;
    padding-bottom: 1em;

    /**
     * Centered
     */

    &.cta--centered {
        text-align: center;
    }

    /**
     * Fixed
     */

    &.cta--fixed {
        box-shadow: 0 -0.125em 0.25em rgba($color-secondary, 0.2);
        bottom: 0;
        position: fixed;
        width: 100%;
        z-index: $z-index-cta;

        .btn-group {

            .btn {

                &.btn--white {
                    display: none;
                }
            }
        }
    }

    /**
     * Transparent
     */

    &.cta--transparent {
        background-color: transparent;
        padding-top: 3em;
        padding-bottom: 0 !important;
        position: static;

        .cta__title {
            color: $color-gray-dark;
        }
    }



    /* Dismiss */

    .cta__dismiss {
        position: absolute;
        right: 0.6375em;
        top: 0.25em;

        a {
            color: $color-white;
            opacity: 0.3;
            text-decoration: none;
            transition: opacity 0.25s;

            &:focus,
            &:hover {
                opacity: 1;
            }
        }
    }

    /* Title */

    .cta__title {
        color: $color-white;
        font-size: 1em;
        font-weight: $weight-normal;
        margin-bottom: 0;
        text-align: center;
    }

    /* SubTtle */

    .cta__subtitle {
        color: $color-white;
        font-size: 0.8em;
        font-weight: $weight-normal;
        margin-bottom: 0;
        text-align: center;
    }

    .btn-group {
        margin-top: 1em;
    }
}


//New Angled shape CTA

.cta {

   &.cta--angled {
        background-color: $color-white-dark;
        padding-left: 20px;
        padding-right: 20px;
        
        .cta__angle-block {
            background-color: $color-primary;
            text-align: center;
            padding: 0 30px;
            color: $color-white;
            width: 230px;
            height: 200px;
            margin: 0 auto 100px auto;
            position: relative;
            filter: drop-shadow(0px 11px 22px rgba(0,0,0,0.3));
            font-size: 22px;
            line-height: 1.1em;
            text-transform: uppercase;
            
            span {
                position: absolute;
                top: 40%;
                left: 0;
                right: 0;
            }
            
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0; 
                height: 0; 
                border-left: 115px solid transparent;
                border-right: 115px solid transparent;
                border-top: 70px solid $color-primary;
                margin-bottom: -70px;
            }
        }

        .cta__title {
            font-size: 20px; 
            margin: 0 auto 40px auto;
            font-weight: normal;
        }

        @media all and (min-width: $bp-small) {
            
            .cta__title { 
                width: 30%;
            }    
        }
    } 
}

    @media (max-width: 450px) {
        .cta--small {
            padding-top: 3em;
            padding-bottom: 3em;
            background-color: #00aeef;
        }
    }

    @media (min-width: 450px) {
        .cta--small {
            padding-top: 3em;
            padding-bottom: 3em;
            background-color: #00aeef;
        }
    }

    @media (min-width: 500px) {
        .cta.cta--fixed.js-dismiss {
            display: none;
        }
    }


