/**
 * MIXINS
 */

/* -------------------------------------------------------------------------- *\
   #TABLE OF CONTENTS
\* -------------------------------------------------------------------------- */

/**
 * ANCHOR
 * ANIMATION
 * BLOCKQUOTE
 * CLEARFIX
 * FIGURE
 * HEADING
 * HORIZONTAL RULE
 * KEYFRAMES
 * LIST
 */





/* -------------------------------------------------------------------------- *\
   #ANCHOR
\* -------------------------------------------------------------------------- */

@mixin anchor($border-bottom-color: $color-silver,
              $border-bottom-color-hover: $color-blue,
              $color: $color-gray-dark,
              $color-hover: $color-blue) {
    border-bottom: 0.0625em solid $border-bottom-color;
    color: $color;
    text-decoration: none;
    transition: border-color 0.25s, color 0.25s;

    &:focus,
    &:hover {
        border-bottom-color: $border-bottom-color-hover;
        color: $color-hover;
    }
}





/* -------------------------------------------------------------------------- *\
   #ANIMATION
\* -------------------------------------------------------------------------- */

/**
 * The animation CSS property is a shorthand property for animation-name, animation-duration, animation-timing-function,
 * animation-delay, animation-iteration-count, animation-direction, animation-fill-mode and animation-play-state.
 * https://developer.mozilla.org/en/docs/Web/CSS/animation
 *
 * E.g.:
 *
 * @include animation('<animation-name> <animation-duration> <animation-iteration-count>');
 */

@mixin animation($str) {
    animation: #{$str};
}





/* -------------------------------------------------------------------------- *\
   #BLOCKQUOTE
\* -------------------------------------------------------------------------- */

@mixin blockquote() {
    margin: 0;
    padding: 0;

    /* Paragraph */

    p {
        font-size: 0.875em; /* 14 / 16 */
        margin-bottom: 0;
        quotes: "\201C""\201D""\2018""\2019";
        padding-right: 0;
        padding-left: 0;
        text-align: center;

        &:before {
            content: open-quote;
        }

        &:after {
            content: close-quote;
        }
    }

    /* Cite */

    cite {
        display: block;
        font-size: 0.75em; /* 12 / 16 */
        font-style: normal;
        font-weight: $weight-light;
        margin-top: 1em;
        padding-right: 10%;
        position: relative;
        text-align: right;

        a {
            @include anchor();
        }
    }

    @media all and (min-width: $bp-small) {

        p {
            font-size: 1.125em; /* 28 / 16 */
            padding-right: 0.5em;
            padding-left: 0.5em;
        }

        cite {
            font-size: 1.125em; /* 18 / 16 */
        }

    }
}


/* -------------------------------------------------------------------------- *\
   #CLEARFIX
\* -------------------------------------------------------------------------- */

/**
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

@mixin clearfix() {
    &:before,
    &:after {
        content: " "; /* 1 */
        display: table; /* 2 */
    }

    &:after {
        clear: both;
    }
}





/* -------------------------------------------------------------------------- *\
   #FIGURE
\* -------------------------------------------------------------------------- */

@mixin figure() {
    margin: 2em 0;

    /* Image */

    img {
        box-shadow: 0 0.125em 2em rgba($color-black, 0.1);
        margin-bottom: 0.5em;
    }

    /* Caption */

    figcaption {
        color: $color-gray-dark;
        font-size: 0.75em;
        font-style: italic;

        a {
            @include anchor();
        }
    }

    @media all and (min-width: $bp-small) {

        figcaption {
            font-size: 1em;
        }
    }
}





/* -------------------------------------------------------------------------- *\
   #HEADING
\* -------------------------------------------------------------------------- */

@mixin heading($font-size, $percentage, $line-height: 1.5) {
    font-size: $font-size;
    font-weight: $weight-bold;
    line-height: $line-height;
    margin-top: 0;
    margin-bottom: 1rem;

    @media all and (min-width: $bp-small) {
        font-size: ($font-size / $percentage) * 100; /* Increase the font size by a percentage */
        line-height: 1.2;
    }
}





/* -------------------------------------------------------------------------- *\
   #HORIZONTAL RULE
\* -------------------------------------------------------------------------- */

@mixin hr() {
    border: 0;
    border-top: 0.125em solid $color-white-dark;
    display: block;
    margin: 2em 0;
    padding: 0;

    /**
     * Short
     */

    &.hr--short {
        border-top: 0.375em solid $color-primary;
        max-width: 5em; /* 80 / 16 */
        text-align: left;
    }

    /**
     * Transparent
     */

    &.hr--transparent {
        border-top-color: transparent;
        margin-top: 0;
        margin-bottom: 0;
    }

    &.hr--centered {
       margin-left: auto;
       margin-right: auto;
    }
}





/* -------------------------------------------------------------------------- *\
   #LIST
\* -------------------------------------------------------------------------- */

@mixin list() {
    list-style: none;
    margin: 0;
    padding: 0;

    /* Lists */

    dl,
    ol,
    ul {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    /* Items */

    dd,
    li {
        margin-bottom: 1rem;
        margin-left: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
}





/* -------------------------------------------------------------------------- *\
   #KEYFRAMES
\* -------------------------------------------------------------------------- */

/**
 * The @keyframes CSS at-rule lets authors control the intermediate steps in a CSS animation sequence by establishing
 * keyframes (or waypoints) along the animation sequence that must be reached by certain points during the animation.
 * https://developer.mozilla.org/en-US/docs/Web/CSS/@keyframes
 *
 * E.g.
 *
   @include keyframes(<identifier>) {
       from { <properties> }
         to { <properties> }
   }
 */

@mixin keyframes($animation-name) {
    @keyframes #{$animation-name} {
        @content;
    }
}






/* -------------------------------------------------------------------------- *\
   #SECTION ORDERING
\* -------------------------------------------------------------------------- */

@mixin section-order() {
    @media all and (max-width: $bp-small + 1) {
        display: flex;
        flex-direction: column;

        .content-block__body {
            order: 0;
            margin-bottom: 2em;
        }

        .content-block__img {
            order: 1;
        }

        .video-play {
            order: 2;
        }
    }
}