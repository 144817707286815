/* -------------------------------------------------------------------------- *\
   Slick | 1.6.0 | http://github.com/kenwheeler/slick
\* -------------------------------------------------------------------------- */

/* Slider */
.slick-slider {
    display: block;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-touch-callout: none;
    user-select: none;
}

.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
}

.slick-list:focus {
    outline: 0;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    display: block;
    left: 0;
    position: relative;
    top: 0;
}

.slick-track:before,
.slick-track:after {
    content: "";
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    border: 1px solid transparent;
    display: block;
    height: auto;
}

.slick-arrow.slick-hidden {
    display: none;
}
