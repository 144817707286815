/*
EAC Calculator Styling
 */

$blue-bg: #3E8FE2;
$white: #fff;
$black: #374456;
$blue: #4A90E2;

.eacSelect:invalid {
  color: #9B9B9B;
}

.l-main.l-main-pad {
  padding-top:4em;

  @media (min-width: 1310px) {
    padding-top: 5.5em;
  }

}

.l-main {
  .nav.nav--secondary{
    position: relative;
    top: unset;
    .nav__list {
      li {
        font-size: 1.4em;
      }
    }
  }
}

#eacCalcPage {

  #eacCalcSection {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(0,0,0,0.5) 0px 2px 4px;
    border-radius: 4px;
    background-color: hsla(210, 25%, 99%, 1);

    @media (max-width: 1309px) {
      width: 70%;
    }

    @media (max-width: 425px) {
      width: 90%;
    }

  }

  .circle-text {
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background: #4A90E2;
    color: #fff;

    height: 20px;
    width: 20px;
    line-height: 20px;
    display: inline-block;
  }

  //Icon Styles
  .icon.icon--info {
    display: inline-block;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border: none;

    &:before {
      content: url('../../../assets/images/calculator-introductions/info.svg');
    }
  }

  .header {
    margin-bottom: 0;
    text-align: center;

    p a {
      color: #00aeef;
      border-bottom: 0.0625em solid #00aeef;

      @media (max-width: 425px) {
        font-size: 1.2em;
        width: 100%;
      }

    }
  }

  .l-wrapper {
    .header__synopsis {
      width: 65%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0px;

      @media (max-width: 425px) {
        font-size: 1em;
        width: 100%;
      }

    }
    .row {

      .col {
        margin-bottom: 0%;
        width: 100%;

        .form__body {
          margin-bottom: 0;
        }

      }

      #eacCalc {
        display: block;
        margin-left: auto;
        margin-right: auto;
        float: none;



        #box-1 {

          background-color: rgba(0,0,0,0);
          box-shadow: unset;
          padding: 0px;

          .steps {
            .btn-group {

              margin-top: 0;

              .btn {
                margin-bottom: unset;
                background-color: #60C39B;
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                display: block;
              }

              #eacSendSuccess {
                display: block;
                margin: 1em auto;
                text-align: center;

                img {
                  width: 30px;
                }
                p {
                  display: inline-block;
                  color: rgb(99, 194, 157);
                }
              }
            }
          }
        }

        @media (min-width: 1310px){
          /*width: 47.5% !important;*/
        }
      }

      @media (min-width: 46.25em){
        .eacSelect {
          height: 56px;
        }
      }



      h4{
        width: 100%;
        display: inline-block;
        font-weight: 400;
        font-size: 1em;
        margin-top: 0;
        margin-bottom: 10px;
      }

      .has-error-message {

        &.error {
          input {
            box-shadow: 0 0 10px #00AEEF;
          }

          .form-error {
            opacity: 1 !important;
            visibility: visible !important;
            pointer-events: auto !important;
          }
        }

        .error-container {
          position: relative;
          .form-error {
            position: absolute;
            //bottom: 72px;
            left: 0;
            padding: 16px 20px;
            background: $blue;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
            opacity: 0;
            visibility: hidden;
            //pointer-events: none;
            z-index: 2;
            top: -120px;

            @media (max-width: 1920px) {
              top: -200px;
            }

            &:before {
              content: '▼';
              display: block;
              position: absolute;
              bottom: -32px;
              left: 160px;
              transform: scaleX(1.5);
              font-size: 27px;
              color: #4A90E2;
              text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
              z-index: 1;
            }

            &:after {
              content: '';
              display: block;
              width: 60px;
              height: 10px;
              background: $blue;
              position: absolute;
              bottom: 0;
              left: 10px;
              z-index: 2;
            }

            .form-error-text {
              font-size: 18px;
              line-height: 1.5;
              color: $white;
              margin-bottom: 0.5em;
            }

            .form-error-close {
              text-align: right;

              a {
                display: inline-block;
                margin-bottom: -4px;
                padding: 4px;
                font: {
                  size: 16px;
                  weight: bold;
                }
                color: $white;
                text-decoration: none;
                border: none;
              }
            }
          }
          .form-error-2 {
            &:before {
              content: '▼';
              display: block;
              position: absolute;
              bottom: -32px;
              left: 207px;
              transform: scaleX(1.5);
              font-size: 27px;
              color: #4A90E2;
              text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
              z-index: 1;
            }
          }
        }
      }

      .custom-select {
        position: relative;
        font-family: "Open Sans", sans-serif;

        select {
          display: none;
        }
      }

      .select-selected {
        background-color: #fff;
        background-image: url(/assets/images/general/chevron-down.svg);
        background-position: 96%;
        background-size: 17px;
        background-repeat: no-repeat;
        border-radius: 0;
        box-shadow: 0 0.125em 1em rgba(32, 35, 37, 0.1);
        color: #474c50;
        font-family: "Open Sans", sans-serif;
        font-size: 1em;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 30px;
        padding: 0.75em 1em;
        transition: border-color 0.25s;
        width: 100%;
        height: 56px;
        border: 0.2px solid #374456;

      }

      .select-items {

        position: absolute;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: rgba(0,0,0,0.4) 0px 0px 8px;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        padding: 5px;

        div {
          background-color: #fff;
          padding: 10px;
          border-radius: 5px;

          &:hover {
            background-color: #00adef;
            color: #fff
          }
        }


      }

      .select-hide{
        display: none;
      }








      .inputRands {
        display: inline !important;
        input {
          width: calc(100% - 56px);
          float: right;

          @media (max-width: 425px) {
            width: calc(100% - 42px);
          }

        }
      }

      .inputRands:before {
        content: 'R';
        margin-right: 0;
        padding-top: 0;
        width: 56px;
        height: 56px;
        top: 0;
        font-size: 16px;
        font-weight: 500;
        background: #F3F4F5;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        line-height: 56px;
        color: #5B697D;
        border: 0.2px solid #374456;
        border-right-style: none;
        float: left;

        @media (max-width: 425px) {
          width: 42px;
          height: 42px;
          line-height: 42px;
        }
      }

      input::placeholder {
        opacity: 0.6;

      }

      input {
        background-color: #fff;
        border-radius: 0;
        box-shadow: 0 0.125em 1em rgba(32, 35, 37, 0.1);
        color: #474c50;
        font-family: "Open Sans", sans-serif;
        font-size: 1em;
        font-weight: 300;
        line-height: 1;
        margin-top: 0;
        margin-bottom: 30px;
        padding: 0.75em 1em;
        transition: border-color 0.25s;
        width: 100%;
        height: 56px;
        border: 0.2px solid #374456;
        
        @media (max-width: 425px) {
          height: 42px;
          font-size: 1em;
        }
        
      }

      .input--select {
        padding: 0.3em 0.6em;
        font-size: 1em;
        height: 56px;
        border: 0.2px solid #374456;
        background-image: url('/assets/images/general/chevron-down.svg');
        background-position: 96%;
        background-size: 17px;
        background-repeat: no-repeat;
        margin-top: 0;
        margin-bottom: 30px;
        -moz-appearance: none;
        -webkit-appearance: none;
        line-height: 1.6em;


        @media (max-width: 425px) {
          height: 42px;
          font-size: 1em;
          background-size: 12px;
        }

        select::-ms-expand {
          display: none;
        }



      }

      .lumpsumHeading {
        height: 28px;
        float: left;
      }

      .calc_rands:before {
        bottom: 0 !important;
        top: unset !important;
        border: 0.2px solid #374456;

        @media (max-width: 425px) {
          height: 42px;
          font-size: 1em;
          width: 42px;
          line-height: 38px;
        }

      }

      .calc_adv-fee {

        .input {
          margin-top: 0px;
          float: left;
        }
      }

      .calc_adv-fee:after {
        content: '%';
        margin-right: 0;
        padding-top: 0;
        width: 56px;
        height: 56px;
        font-size: 16px;
        font-weight: 500;
        background: #F3F4F5;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        line-height: 56px;
        color: #474c50;
        border: 0.2px solid #374456;
        float: right;
        position: absolute;
        right: 0;

        @media (max-width: 425px) {
          height: 42px;
          width:42px;
          font-size: 1em;
          line-height: 38px;
        }

      }

      @media (min-width: 46.25em) and (max-width: 1309px){
        .calc_adv-fee:after {
          line-height: 52px;
          width: 56px;
          height: 56px;
          font-size: 21px;
          float: right;
          position: absolute;
          right: 0;
        }

        .header.header--small {
          margin-right: auto;
          margin-left: auto;
          width: 100%;
          max-width: unset;
        }

        #eacCalc {
          float: left;
          margin-bottom: 0;
          width: 100%;
        }

        .row .col {
          margin-bottom: 0%;
          max-width: 100%;
        }
  }


}
}



}