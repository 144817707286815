/* -------------------------------------------------------------------------- *\
   #HORIZONTAL RULE
\* -------------------------------------------------------------------------- */

.hr {
    @include hr();

    &.light_hr {
        border-color: #ffffff !important;
        margin-bottom: 3em;
    }
}
