/* -------------------------------------------------------------------------- *\
   #CLIENTS SLIDER
\* -------------------------------------------------------------------------- */

.clients-slider {
  opacity: 0;

  @media all and (min-width: $bp-small) {
    height: 16rem;
  }

  .clients-slide {
    opacity: 0;
    transition: .2s ease;

    &.tns-slide-active {
      opacity: 1;
    }

    .clients-slide-inner {
      display: flex;
      flex-direction: column;
      background: $color-green;

      @media all and (min-width: $bp-small) {
        flex-direction: row;
      }

      .clients-slide-image {
        height: 12.25rem;
        width: 100%;
        background: {
          repeat: no-repeat;
          size: cover;
          position: top center;
        }

        @media all and (min-width: $bp-small) {
          width: 16rem;
          min-width: 16rem;
          height: 16rem;
        }
      }

      .clients-slide-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-grow: 1;
        padding: 2.5rem 2rem;

        @media all and (min-width: $bp-small) {
          padding: 1.4rem;
        }

        .quote-icon {
          position: absolute;
          max-width: 2.75rem;
          left: 1.4rem;
          top: -1.375rem;

          @media all and (min-width: $bp-small) {
            max-width: 100%;
            left: -2.188rem;
            top: 50%;
            margin-top: -2.188rem;
          }
        }

        .clients-slide-blockquote {
          margin: 0;

          p {
            font-weight: 600;
            margin-bottom: 1rem;
          }

          @media all and (min-width: $bp-small) {
            max-width: 90%;

            p {
              font-weight: 400;
            }
          }

          p,
          cite {
            font-size: 1.125rem;
            color: $color-white;
          }

          cite {
            font-style: normal;
          }
        }

        .btn {
          display: none;
          position: absolute;
          bottom: 2.25rem;
          right: 2.25rem;
          border: 2px solid $color-white;

          @media all and (min-width: $bp-medium) {
            display: block;
          }
        }
      }
    }
  }
}

.client-blocks-2col {
  @media all and (min-width: $bp-medium) {
    display: flex;
  }

  .client-block-2col {
    display: flex;
    flex: 1;

    &:first-of-type {
      margin-bottom: 2em;

      @media all and (min-width: $bp-medium) {
        margin-right: 5em;
        margin-bottom: 0;
      }
    }

    .client-block-2col-inner {
      display: flex;
      flex-direction: column;
      width: 100%;

      .client-block-2col-title {
        margin-top: 1rem;
        text-align: left;
        font-size: 1rem;

        @media all and (min-width: $bp-small) {
          text-align: center;
        }

        span {
          @media all and (min-width: $bp-small) {
            font-size: 1.5rem;
          }
        }
      }

      .client-block-2col-content {
        flex-grow: 1;

        @media all and (min-width: $bp-small) {
          font-size: 1.12rem;
          margin-bottom: 2.4em;
        }
      }

      .client-block-2col-button {
        text-align: center;
      }
    }
  }
}