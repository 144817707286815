/* -------------------------------------------------------------------------- *\
   #VIDEO PLAY
\* -------------------------------------------------------------------------- */

.video-play {
  &.video-play-desktop {
    display: none;

    @media all and (min-width: $bp-small) {
      display: block;
    }
  }

  &.video-play-mobile {
    @media all and (min-width: $bp-small) {
      display: none;
    }
  }

  .video-play-circle-investmentreview {
    border-style: none !important;
    box-shadow: 0 0 3px rgba(0, 0, 0, .6) !important;


    @media all and (min-width: $bp-small) {
      width: 8rem !important;
      height: 8rem !important;
    }

    .hero & {
      margin: 0.75em auto .4em;
    }

  }

  .video-play-circle {
    display: inline-block;
    position: relative;
    margin: 0 .8rem 0 0;
    width: 3.6rem;
    height: 3.6rem;
    border: 2px solid $color-white;
    border-radius: 8em;
    background: {
      repeat: no-repeat;
      size: cover;
    }
    overflow: hidden;
    box-shadow: 0 0 1px rgba(0, 0, 0, .3);
    vertical-align: middle;



    .hero & {
      display: block;
      margin: 1em auto .2em;
    }

    @media all and (min-width: $bp-xs) {
      width: 4rem;
      height: 4rem;

      .hero & {
        margin: 1.8em auto .2em;
      }
    }

    @media all and (min-width: $bp-small) {
      width: 5rem;
      height: 5rem;

      .hero & {
        margin: 2.75em auto .4em;
      }
    }

    &:hover {
      cursor: pointer;

      .play-overlay {
        opacity: 1;
      }
    }

    .play-overlay {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: .5s ease;
      border-radius: 100%;
    }

    .play-overlay-investmentreview {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: .5s ease;
      border-radius: 100%;
    }

    .play-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      max-width: 18px;
      margin-left: -.4em;
      margin-top: -.6em;
    }
  }

  .video-play-link-investmentreview {
    font-size: 1.5em !important;
  }

  .video-play-link {
    display: inline-block;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: .02em;
    color: #ffffff;
    vertical-align: middle;

    .hero & {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .video-play-link {
    display: inline-block;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: .02em;
    color: #00AEF0;
    vertical-align: middle;

    .hero & {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .video-play-link-promo {
    display: inline-block;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: .02em;
    color: #ffffff;
    vertical-align: middle;
    text-decoration: none;

    .hero & {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }
}