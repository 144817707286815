/**
 * VARIABLES
 */

/* -------------------------------------------------------------------------- *\
   #TABLE OF CONTENTS
\* -------------------------------------------------------------------------- */

/**
 * BREAKPOINTS
 * COLORS
 * FONTS
 * .....Family
 * .....Size
 * .....Weight
 * Z-INDEX
 */




/* -------------------------------------------------------------------------- *\
   #BREAKPOINTS
\* -------------------------------------------------------------------------- */

$bp-xs:      30.00em; /*  480 / 16 */
$bp-small:   46.25em; /*  740 / 16 */
$bp-medium:  61.25em; /*  980 / 16 */
$bp-medium-2:  62em; /*  992 / 16 */
$bp-large:   75.00em; /* 1200 / 16 */
$bp-xl:      87.50em; /* 1400 / 16 */
$bp-xxl:    100.00em; /* 1600 / 16 */





/* -------------------------------------------------------------------------- *\
   #COLORS
\* -------------------------------------------------------------------------- */

/**
 * Brand
 */

$color-primary:     #00aeef;
$color-secondary:   #103E72;

/**
 * Grayscale
 */

$color-black:       #202325;
$color-gray-dark:   #474c50;
$color-gray:        #898d91;
$color-silver:      #d0d3d5;
$color-white-dark:  #f5f7f9;
$color-white-dark__alt:  #E7F0FF;
$color-white:       #fff;

/**
 * Utility
 */

$color-red:         #e63246;
$color-orange:      #ff851b;
$color-yellow:      #ffdd00;
$color-green:       #60c39b;
$color-blue:        #00aeef;

/**
 * Social
 */

$color-facebook:    #3b5998;
$color-linkedin:    #0077b5;
$color-twitter:     #55acee;





/* -------------------------------------------------------------------------- *\
   #FONTS
\* -------------------------------------------------------------------------- */

/* ==========================================================================
   #Family
   ========================================================================== */

$font:           "Open Sans", sans-serif;





/* ==========================================================================
   #Size
   ========================================================================== */

$h1-font-size: 2.2500em; /* 36 / 16 */
$h2-font-size: 1.5000em; /* 24 / 16 */
$h3-font-size: 1.3125em; /* 21 / 16 */
$h4-font-size: 1.1250em; /* 18 / 16 */
$h5-font-size: 1.0000em; /* 16 / 16 */
$h6-font-size: 0.8750em; /* 14 / 16 */





/* ==========================================================================
   #Weight
   ========================================================================== */

$weight-light:  300;
$weight-normal: 400;
$weight-bold:   700;
$weight-heavy:  800;





/* -------------------------------------------------------------------------- *\
   #Z-INDEX
\* -------------------------------------------------------------------------- */

/**
 * Tooltips
 */

$z-index-tooltips:          1100;

/**
 * Modal
 */

$z-index-modal:             1050;
$z-index-modal-backdrop:    1040;

/**
 * Menu Dropdown
 */

$z-index-menu-dropdown:     1010;

/**
 * Logo
 */

$z-index-logo:              1000;

/**
 * Header
 */

$z-index-header:            950;

/**
 * CTA
 */

$z-index-cta:               950;

/**
 * Secondary Nav
 */

$z-index-nav-secondary:     9;

/**
 * Button Dropdown
 */

$z-index-btn-dropdown:      900;

/**
 * Scroll
 */

$z-index-scroll:            850;

/**
 * Social
 */

$z-index-social:            840;
