/* -------------------------------------------------------------------------- *\
   #GRID
\* -------------------------------------------------------------------------- */

/* Row */

.row {
    @include clearfix();

    &:last-child {
        margin-bottom: 0;
    }

    /* Column */

    .col {
        margin-bottom: 15%;
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }
    }

    & + .row {
        margin-top: 5%;
    }

    /**
     * Flat
     */

    &.row--flat {

        .col {
            margin-bottom: 0;
        }

        & + .row--flat {
            margin-top: 0;
        }
    }
}

@media all and (min-width: $bp-small) {

    .row {

        .col {
            min-height: 1px; /* Prevent horizontal collapse: https://css-tricks.com/make-sure-columns-dont-collapse-horizontally */

            /**
             * Switch the display order on wider screens; doesn’t effect small screens
             */

            &.col--opposite {
                float: right !important;

                & + .col {
                    margin-left: 0;
                }
            }

            /* 1/2 */

            &.col--1-2 {
                float: left;
                margin-bottom: 0;
                margin-left: 5%;
                width: 47.5%;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

@media all and (min-width: $bp-medium) {

    .row {

        .col {
            float: left;
            margin-left: 5%;

            &:first-child {
                margin-left: 0;
            }

            &.col--opposite {
                float: right;

                & + .col {
                    margin-left: 0;
                }
            }

            /* 1/3 */

            &.col--1-3 {
                float: left;
                margin-bottom: 0;
                margin-left: 5%;
                width: 30%;

                &:first-child {
                    margin-left: 0;
                }
            }

            /* 1/6 */

            &.col--1-6 {
                margin-bottom: 0;
                width: 12.5%;
            }

            /* 1/5 */

            &.col--1-5 {
                margin-bottom: 0;
                width: 16%;
            }

            /* 1/4 */

            &.col--1-4 {
                margin-bottom: 0;
                width: 21.25%;
            }

            /* 2/3 */

            &.col--2-3 {
                float: left;
                margin-bottom: 0;
                margin-left: 5%;
                width: 65%;

                &:first-child {
                    margin-left: 0;
                }
            }

            /* 3/4 */

            &.col--3-4 {
                width: 73.75%;
            }

            /* 4/5 */

            &.col--4-5 {
                width: 79%;
            }

            /* 5/6 */

            &.col--5-6 {
                width: 82.5%;
            }
        }
    }
}
