.hero-slider-campaign-promo {
  background-image: url('/assets/images/heros/campaign/Dancing_dad_Desktop_Screen_1_1920_1080.png');

  @media all and (max-width: 900px) {
    background-image: linear-gradient(#517ab9, #842d89);
  }
}

.tns-outer {
  height: 100%;
}

.tns-ovh {
  height: 100%;
}

.tns-inner {
  height: 100%;
}

.tns-nav {
  z-index:10;
}


.campaign__callback {
  margin-top: 2rem;
  position: relative;
  bottom: 0;

  @media all and (max-width: 900px) {
    margin-top: 2rem;
    position: absolute;
    bottom: 175px;

    padding: 0.5em 1em 1em 1em !important;
  }

  @media all and (max-width: 550px) {
    margin-top: 1rem;
    position: relative;
    bottom: 0;
    padding: 1em 2em 2em 2em !important;
  }
}

.hero-tablet-break {
  display: none;
  @media all and (max-width: 550px) {
    display: block;
  }
}

.hero-mobile-break {
  display: none;

  @media all and (max-width: 900px) {
    display: block;
  }
  @media all and (max-width: 550px) {
    display: none;
  }
}

.campaign__spacing-mobile {
  padding: 3em 2em !important;
  line-height: 0.8 !important;

  @media all and (max-width: 900px) {
    padding: 2em 3.5em!important; line-height: 0.8!important;
  }
  @media all and (max-width: 550px) {
    padding: 2em 0.75em!important; line-height: 0.8!important;
  }
}

.campaign__spacing-mobile-2 {
  padding: 3em 2em !important;
  line-height: 0.8 !important;

  @media all and (max-width: 900px) {
    padding: 2em 5em!important; line-height: 0.8!important;
  }
  @media all and (max-width: 550px) {
    padding: 2em 2.25em!important; line-height: 0.8!important;
  }
}

.campaign__spacing-mobile-3 {
  padding: 2em 2em !important;
  line-height: 0.8 !important;

  @media all and (max-width: 900px) {
    padding: 2em 2em!important; line-height: 0.8!important;
  }
  @media all and (max-width: 600px) {
    .tablet-image {
      display: none;
    }
    .mobile-image {
      display: block;
    }
  }
  @media all and (max-width: 550px) {
    padding: 1em 0em!important; line-height: 0.8!important;
  }
}

.callback_break {

  display: none;

  @media all and (max-width: 900px) {
    display: block;
  }

  @media all and (max-width: 550px) {
    display: none;
  }

}


.your-future-mobile.campaign-image {
  position: absolute;
  bottom: -20px;
  width: 700px;
  right: 0;
  display: none;

  @media all and (max-width: 900px) {
    display: block;
  }

  @media all and (max-width: 550px) {
    width: 400px;
  }

}

.hero__span-text {
  font-size: 2.25rem !important;
  font-weight: 900 !important;

  @media all and (max-width: 900px) {
    font-size: 2.75rem !important;
  }

  @media all and (max-width: 550px) {
    font-size: 1.5rem !important;
  }
}
.hero__span-text-2 {
  font-size: 1.5rem !important;
  font-weight: 500 !important;

  @media all and (max-width: 900px) {
    font-size: 2.125rem !important;
    font-weight: 400 !important;
  }

  @media all and (max-width: 550px) {
    font-size: 1rem !important;
  }
}
.hero__span-text-3 {

  margin-top: 1em;

  @media all and (max-width: 550px) {
    margin-top: 0.25em;
    margin-bottom: 0.275em !important;
  }
}

.hero-slider-container.hero-slider-container-campaign {
  @media all and (max-width: 850px) {
    height: 100% !important;
  }
}

.hero-slider-container-max {
  //@media all and (min-width: $bp-small) {
  //  max-width: 90%;
  //}

  @media all and (max-width: 850px) {
    height: 100% !important;
  }
}

.hero-slider-campaign-promo-la {
  background-image: url('/assets/images/heros/campaign/July-2023-Update/la_banner.jpg');

  @media all and (max-width: 900px) {
    background-image: linear-gradient(#842e89, #3a3975) !important;
  }
}

.hero-slider-ra-campaign {
  background-image: url('/assets/images/heros/campaign/July-2023-Update/desktop-small/ra_banner.jpg');

  @media all and (max-width: 900px) {
    background-image: linear-gradient(#842e89, #3a3975) !important;
  }
}

.hero-slider-desktop-campaign {
  background-image: url('/assets/images/heros/campaign/July-2023-Update/home_banner.jpg');

  @media all and (max-width: 900px) {
    background-image: linear-gradient(#842e89, #3a3975) !important;
  }
}
.hero-slider-presi-campaign {
  background-image: url('/assets/images/heros/campaign/July-2023-Update/presi_banner.jpg');

  @media all and (max-width: 900px) {
    background-image: linear-gradient(#842e89, #3a3975) !important;
  }
}

.hero-slider-la-campaign {
  background-image: url("/assets/images/heros/campaign/July-2023-Update/la_banner.jpg") !important;
}

.your-future-main-div {
  background-image: url('/assets/images/heros/campaign/July-2023-Update/home_banner.jpg');

  @media all and (max-width: 650px) {
    background-image: linear-gradient(#517ab9, #842d89);
  }
}

.living-annuity-main-div {
  background-image: url('/assets/images/heros/campaign/Dancing_dad_Desktop_Screen_1_1920_1080.jpg');

  @media all and (max-width: 650px) {
    background-image: linear-gradient(#7a3685, #253d6a);
  }
}

.campaign-drop-shadow {
  text-shadow: 0 4px rgba(8, 32, 60, 0.30)  !important;
}

.fee-free-header {
  @media all and (max-width: 738px) {
    font-size: 3rem !important;
  }
  @media all and (max-width: 600px) {
    font-size: 2.75rem !important;
  }
  @media all and (max-width: 500px) {
    font-size: 2.25rem !important;
  }
  @media all and (max-width: 400px) {
    font-size: 2.125rem !important;
  }
  @media all and (max-width: 350px) {
    font-size: 1.875rem !important;
  }
  @media all and (max-width: 300px) {
    font-size: 1.425rem !important;
  }
}

#future-promo-10x-logo {


  @media all and (max-width: 738px) {
    //font-size: 1.25rem !important;
    max-width: 82.5%;
  }
}

.your-future-desktop {
  display: block;
  max-width: 100%;

  /*
  @media all and (min-width: 1920px) {

    max-width: 67.5%;
  }
  @media all and (min-width: 1440px) {

    max-width: 70%;
  }
  @media all and (max-width: 1440px) {

    max-width: 70%;
  }
  @media all and (max-width: 1280px) {

    max-width: 72.5%;
  }

  @media all and (max-width: 1024px) {
    max-width: 72.5%;
  }
  */

  @media all and (max-width: 900px) {
    display: none;
  }
}

.campaign__hero-title {
  background: linear-gradient(to right, #29A8DF,#29A8DF, #E023AE);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bolder;
  text-shadow: none;

  font-size: 3rem;

  @media all and (max-width: 900px) {
    font-size: 6rem !important;
  }
  @media all and (max-width: 550px) {
    font-size: 2.5rem !important;
  }
}

.campaign__container {
  @media all and (min-width: 900px) {
    max-width: 95% !important;
  }

  @media all and (max-width: 900px) {
    max-width: 100% !important;
  }
}

.your-future-mobile {
  display: none;

  .hero__title {
    text-transform: none;
    font-size: 2rem;
  }

  .tablet-image {
    display: block;
  }
  .mobile-image {
    display: none;
  }

  @media all and (max-width: 900px) {
    display: block;
    max-width: 100%;
  }

  @media all and (max-width: 600px) {
    .tablet-image {
      display: none;
    }
    .mobile-image {
      display: block;
    }
  }
}

#yourfuture-container-1:before {
  content:'';
  position: absolute;
  width: 220px;
  height: 140px;
  margin: auto;
  z-index: -1;
  border: 5px solid lightgray;
  left:40px;
}

.slide2 {
  display: block;
  content: '';
  background: {
    image: linear-gradient(to bottom right, #517ab9, #842d89);
    position: center;
    size: cover;
    repeat: no-repeat;
  }

  @media all and (max-width: 738px) {
    //display: none !important;
    background: {
      image: linear-gradient(#7a3685, #253d6a);;
      position: center;
      size: cover;
      repeat: no-repeat;
    }
  }


  transition: .4s;
}

.slide2-la {
  display: block;
  content: '';
  background: {
    image: url('/assets/images/heros/campaign/July-2023-Update/la_banner.jpg');
    position: center;
    size: cover;
    repeat: no-repeat;
  }

  @media all and (max-width: 900px) {
    //display: none !important;
    background: {
      image: linear-gradient(#7a3685, #253d6a);
      position: center;
      size: cover;
      repeat: no-repeat;
    }
  }


  transition: .4s;
}

.slide3 {
  display: block;
  content: '';
  background: {
    image: url('/assets/images/heros/campaign/July-2023-Update/ra_banner.jpg');
    position: center;
    size: cover;
    repeat: no-repeat;
  }

  @media all and (max-width: 1600px) {
    //display: none !important;
    background: {
      image: url('/assets/images/heros/campaign/July-2023-Update/desktop-small/la-banner-small.png');
      position: center;
      size: cover;
      repeat: no-repeat;
    }}

  @media all and (max-width: 900px) {
    //display: none !important;
    background: {
      image: linear-gradient(#7a3685, #253d6a);;
      position: center;
      size: cover;
      repeat: no-repeat;
    }
  }


  transition: .4s;
}

.slide4 {
  display: block;
  content: '';
  background: {
    image: url('/assets/images/heros/campaign/July-2023-Update/presi_banner.jpg');
    position: center;
    size: cover;
    repeat: no-repeat;
  }

  @media all and (max-width: 900px) {
    //display: none !important;
    background: {
      image: linear-gradient(#7a3685, #253d6a);;
      position: center;
      size: cover;
      repeat: no-repeat;
    }
  }


  transition: .4s;
}

.your-future-promo {

  #future-promo_10x-logo {
    @media all and (max-width: 738px) {
      //font-size: 1.25rem !important;
      display: none !important;
    }
  }


  .hero-promo-text {
    display: inline;
  }

  .promo-text-left {
    text-align:right;
    vertical-align: middle;
    letter-spacing: 1px !important;

    @media all and (max-width: 738px) {
      text-align: center;
    }
  }

  .promo-hr-desktop {

    display: block;

    @media all and (max-width: 738px) {
      //font-size: 1.25rem !important;
      display: none !important;
    }
  }

  #future-promo-10x-image {
    @media all and (max-width: 738px) {
      width: 250px
    }
  }

  .promo-hr-mobile {
    text-align:right;
    vertical-align: middle;
    border-bottom: 1px solid white;
    width: 70%;
    margin: 20px;
    display:block;

    @media all and (min-width: 738px) {
      //font-size: 1.25rem !important;
      display: none !important;
    }
  }

  .hero-slider-container-campaign {
    height:auto;
    @media all and (max-width: 738px) {
      height: 95%;
    }
  }

  .hero-slider-inner-promo {

    @media all and (max-width: 768px) {
      //font-size: 1.25rem !important;
      //margin-top: 45% !important;
      max-width:100% !important;
    }

    /*    @media all and (max-width: 738px) {
          //font-size: 1.25rem !important;
          margin-top: 55% !important;
        }

        @media all and (max-width: 600px) {
          //font-size: 1.25rem !important;
          margin-top: 80% !important;
        }

        @media all and (max-width: 400px) {
          //font-size: 1.25rem !important;
          margin-top: 85% !important;
        }

        @media (min-height: 800px) and (max-width: 500px) {
          margin-top: 85% !important;
          //12 for 1440p +
          // 7 1080p
          // 5 for the rest
        }*/
  }

  .hero-slider-inner-promo-homepage {
    margin-top: 0!important;
    max-width: 100% !important;

    //@media all and (max-width: 738px) {
    //  //font-size: 1.25rem !important;
    //  margin-top: 25% !important;
    //}
  }

  .hero-promo-cta {
    width: 250px;
    margin-left: auto;
    margin-right: auto;

    @media all and (max-width: 738px) {
      width: 200px !important;
      margin-top: 20px;

      text-align:center;
    }
  }

  //.hero-slider-wrapper {
  //  position:absolute !important;
  //  width:100%;
  //
  //  display: flex !important;
  //
  //  @media all and (max-width: 768px) {
  //    display: none !important;
  //  }
  //}
  //
  //.hero-slider-wrapper-mobile {
  //  position:absolute !important;
  //  width:100%;
  //
  //  display: none !important;
  //
  //  @media all and (max-width: 768px) {
  //    display: flex !important;
  //  }
  //}

  #wistia_oz9i5hynae {
    width:100%;
    height:100%;
    top:-28px;
    display:none
  }

  #future-promo_play-image {
    width:230px;

    @media all and (max-width: 1600px) {
      width:200px;
    }

    @media all and (max-width: 1280px) {
      width:175px;
    }

    @media all and (max-width: 378px) {
      width:200px;
    }

    @media all and (max-width: 350px) {
      width:175px;
    }
  }

  .hero-slide-inner {
    text-align:left;
    padding: 0 1.5em !important;

    @media all and (min-width: 768px) {
      padding: 0 2.5em !important;
    }
  }

  .hero-slide-1 {

    //@media all and (min-width: 768px) {
    //  //font-size: 1.25rem !important;
    //  margin-top: 0 !important;
    //}
    //
    //@media all and (max-width: 767px) {
    //  //font-size: 1.25rem !important;
    //  margin-top: 6em !important;
    //}
    //
    //@media all and (max-width: 400px) {
    //  //font-size: 1.25rem !important;
    //  margin-top: 7em !important;
    //}
  }

  #hero-promo_play-div {
    padding-top: 0;
    text-align: center;

    @media all and (min-width: 768px) {
      //font-size: 1.25rem !important;
      padding-top: 0em;
      text-align: left;
    }

    @media all and (max-width: 368px) {
      //font-size: 1.25rem !important;
      padding-top: 0;
    }
  }

  .campaign__headline {

    height: 10px; width: 87.5%;
    margin: 3.5em 0;
    border: 0;

    background: -moz-linear-gradient(left, #54A6DA 0%, #842D89 33%, #EAC951 33%, #54A6DA 66%, #D88B7A 66%, #CE39A9 100%);
    background: -webkit-linear-gradient(left, #54A6DA 0%, #842D89 33%, #EAC951 33%, #54A6DA 66%, #D88B7A 66%, #CE39A9 100%);
    background: -o-linear-gradient(left, #54A6DA 0%, #842D89 33%, #EAC951 33%, #54A6DA 66%, #D88B7A 66%, #CE39A9 100%);
    background: -ms-linear-gradient(left, #54A6DA 0%, #842D89 33%, #EAC951 33%, #54A6DA 66%, #D88B7A 66%, #CE39A9 100%);
    background: linear-gradient(to right, #54A6DA 0%, #842D89 33%, #EAC951 33%, #54A6DA 66%, #D88B7A 66%, #CE39A9 100%);

    @media all and (max-width: 968px) {
      border: 0; height: 10px; width: 75%;
    }
    @media all and (max-width: 600px) {
      border: 0; height: 5px; width: 75%;
    }
  }


  .hero__title-campaign {

    color: white;
    font-weight: 900;

    font-size: 4rem !important;

    img {
      width: 250px;
    }

    @media all and (max-width: 1650px) {

      img {
        width: 220px;
      }
      font-size: 3.5rem !important;
    }
    @media all and (max-width: 1450px) {
      img {
        width: 200px;
      }
      font-size: 3rem !important;
    }
    @media all and (max-width: 1250px) {
      img {
        width: 200px;
      }
      font-size: 2.5rem !important;
    }
    @media all and (max-width: 1100px) {
      font-size: 2.25rem !important;
    }
    @media all and (max-width: 900px) {
      img {
        width: 215px;
      }
    }
    @media all and (max-width: 600px) {
      font-size: 2rem !important;
      img {
        width: 140px;
      }
    }

  }

  .hero__undertitle-campaign {

    font-size: 2.25rem !important;
    color: #103E72;
    line-height:1.125;
    margin-bottom: 0;
    font-weight: 600;
    padding-top: 0;

    @media all and (max-width: 1900px) {

      font-size: 2.125rem !important;
    }

    @media all and (max-width: 1700px) {
      font-size: 2rem !important;
    }

    @media all and (max-width: 900px) {
      font-size: 1.75rem !important;
    }

  }

  #campaign_cta_bottom {

    @media all and (max-width: 900px) {
      margin-left: 4rem;
    }

    @media all and (max-width: 600px) {
      margin-left: 1.75rem;
    }
  }
  #campaign_cta_bottom-2 {

    @media all and (max-width: 900px) {
      margin-left: 2rem;
    }
    @media all and (max-width: 600px) {
      margin-left: 0;
    }
  }


  .hero-promo_play-div-mobile {
    padding-top: 0;
    text-align: center;

    .hero__title {
      font-size: 3rem;
      max-width: 100%;
    }

    .hero__title-text-bold {
      font-size: 1.75rem; color: #103E72; line-height:1.125; margin-bottom: 0; font-weight: 900; padding-top: 3em;
    }
    .hero__title-text {
      font-size: 1.75rem; color: #103E72; line-height:1.125; margin-bottom: 0; font-weight: 600; padding-top: 3em;
    }

    @media all and (max-width: 968px) {
      //font-size: 1.25rem !important;
      margin-top: 475px;

      .hero__title-text {
        font-size: 3.5rem !important; color: #103E72; line-height:1.125; margin-bottom: 0; font-weight: 600; padding-top: 3em;
      }

      .hero__title-text-bold {
        font-weight: 900;
      }
    }

    @media all and (max-width: 900px) {
      //font-size: 1.25rem !important;
      .hero__title {
        font-size: 3.5rem !important;
      }
      .hero__title-text {
        font-size: 2rem !important;
      }
    }

    @media all and (max-width: 600px) {
      //font-size: 1.25rem !important;
      .hero__title {
        font-size: 2rem !important;
      }

      .tablet-image{
        display: none;
      }

      .mobile-image {
        display: block;
      }

      margin-top: 325px;

      .hero__title-text {
        font-size: 1.5rem !important;
        color: #103E72; line-height:1.125;
        margin-bottom: 0; font-weight: 600; padding-top: 3em;
      }
    }

    @media all and (max-width: 400px) {
      //font-size: 1.25rem !important;
      .hero__title {
        font-size: 1.825rem !important;
      }

      margin-top: 300px;

      .hero__title-text {
        font-size: 1.325rem !important;
        color: #103E72; line-height:1.125;
        margin-bottom: 0; font-weight: 600; padding-top: 3em;
      }
    }

    @media all and (max-width: 380px) {
      //font-size: 1.25rem !important;
      .hero__title {
        font-size: 1.75rem !important;
      }

      margin-top: 300px;

      .hero__title-text {
        font-size: 1.25rem !important;
        color: #103E72; line-height:1.125;
        margin-bottom: 0; font-weight: 600; padding-top: 3em;
      }
    }


  }


  .hero-slide-title {

    font-size: 1.75rem !important;
    font-weight: 300 !important;

    margin: 0 !important;

    @media all and (max-width: 1800px) {
      //font-size: 1.25rem !important;
      font-size: 1.5rem !important;
    }

    @media all and (max-width: 1280px) {
      //font-size: 1.25rem !important;
      font-size: 1rem !important;
    }

    @media all and (min-width: 768px) {
      //font-size: 1.25rem !important;
      padding: 0.5em 0 !important;
    }

    @media all and (min-width: 768px) {
      //font-size: 1.25rem !important;
      padding: 0.5em 0 !important;
    }

    @media all and (max-width: 768px) {
      font-size: 1.25rem !important;
    }

    @media all and (max-width: 500px) {
      font-size: 1rem !important;
    }

    @media all and (max-width: 400px) {
      font-size: 0.9rem !important;
    }

    @media all and (max-width: 350px) {
      font-size: 0.8rem !important;
    }
  }

}

.mobile-video-playing {
  height: 20vh !important;
}

//Phase 2
.yourfuture-container {
  width: 143.9%;
  box-sizing: border-box;

  margin-left: -22%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-position: 65% 50%;
  background-size: cover;
  margin-top: 190px;
  //TODO: change width of surrounding div if page request is retirement annuity

  @media (max-width:1199px) {
    height: 110vh;
  }

  @media (max-width:768px) {
    background-position: 80% 10%;
    background-size: 400%;
    background-repeat: no-repeat;
    background-color: #00aeef;
    background-image: url("/assets/images/your-future/1_la_ra_cover2.jpg");

  }

  .hero-slider-la-campaign {

  }

  .yourfuture-inner {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width:768px) {
      //background-image: linear-gradient(0deg,rgba(0,0,0,1) 25%,rgba(168,144,77,0.3));
      //background-image: url("/assets/images/your-future/1_la_ra_cover2.jpg");
      height: 100vh;
    }

    @media (min-height: 900px) and (max-width:850px) {
      //background-image: linear-gradient(0deg,rgba(0,0,0,1) 35%,rgba(168,144,77,0.3));
      height: 90vh;
    }

    @media (min-height: 800px) and (max-width: 750px) {
      //background-image: linear-gradient(0deg,rgba(0,0,0,1) 45%,rgba(168,144,77,0.3));
      height: 100vh;
    }

    #your-future-header {
      font-size:3em !important;

      @media (max-width: 1280px) {
        font-size: 2.25em !important;
      }

      @media (max-width: 1024px) {
        font-size: 2em !important;
      }

      @media (max-width: 768px) {
        font-size: 1.575em !important;
      }

      @media (max-width: 400px) and (max-height: 650px){
        font-size: 1.275em !important;
      }

      @media (max-height: 1024px) and (max-width: 350px) {
        font-size: 1.325em !important;
      }
    }

    .yourfuture_text_1 {

    }

    .hr {
      margin-bottom: 0;
      border-top: 2px solid #f5f7f9;
      width:80%;
      margin-top:40px;

      @media (max-width: 1280px) {
        margin-top:20px;
      }

      @media (max-width: 768px) {
        width:30%;
        margin:auto;
        margin-top:20px;
      }

      @media (max-width: 400px) and (max-width: 700px) {
        width:30%;
        margin:auto;
        margin-top:05px;
      }

      @media (max-width: 350px) {
        width:30%;
        margin:auto; margin-top:10px;
      }
    }

    .yourfuture-container:before {
      content:'';
      position: absolute;
      width: 220px;
      height: 140px;
      margin: auto;
      z-index: -1;
      border: 5px solid lightgray;
      left:40px;
    }


    .yourfutureimg-container {
      width: 400px;
      height: auto;
      margin: 0;
      //margin-top: 0;
      //margin-bottom: 0;

      @media (max-width:1024px) {
        width: auto;
        margin:0;
        box-sizing: border-box;
      }

      @media (min-width: 426px) and (max-width:600px) {
        width: auto;
        margin: 50px 90px;
        box-sizing: border-box;
      }

      @media (max-width:425px) {
        width: auto;
        margin: 240px 90px 50px;
        box-sizing: border-box;
      }

      @media (max-height: 1024px) and (max-width: 750px) {
        margin-top: 500px !important;
      }

      @media (min-height: 900px) and (max-width:850px) {
        margin-top: 280px;
      }

      @media (max-height: 1024px) and (max-width: 768px) {
        margin-top: 550px !important;
      }

      @media (max-height: 1024px) and (max-width: 500px) {
        margin-top: 340px !important;
      }

      @media (max-height: 1024px) and (max-width: 400px) {
        margin-top: 325px !important;
      }

      @media (max-height: 1024px) and (max-width: 400px) {
        margin-top: 325px !important;
      }

      @media (max-width: 700px) and (max-width: 375px) {
        margin-top: 290px !important;
      }

      @media (max-height: 1024px) and (max-width: 350px) {
        margin-top: 250px !important;
      }



    }
    .video-play {
      &.yourfuturevid {
        .video-play-circle {
          margin-top: 0 !important;
          background-position: 63% 50%;
          .play-overlay {
            display: none;
          }
          @media (max-width:425px) {
            width: 111px;
            height: 111px;
          }
        }
        .video-play-link {
          text-decoration: none;
        }
      }
    }

    #yourfutureimg {
      @media (min-width: 2000px) {
        max-width: 110%;
      }

      @media (max-width: 1280px) {
        width: 250px;
      }

      @media (max-width: 768px) {
        width: 200px;
      }

      @media (max-width: 450px) {
        width: 175px;
        margin-top: 10px;
      }

      @media (max-width: 350px) {
        width: 150px;
        margin-top: 10px;
      }
    }

    .video-play-circle {
      @media (min-width: 2000px) {
        width: 7rem;
        height: 7rem;
      }
    }

    .yourfuture_text {
      margin: 51px auto 110px;
      color: white;
      h1 {
        font-size: 22px;
        font-weight: 300;

        line-height: 26px !important;

        span {
          font-weight: 700;
        }

        @media (max-width:500px) and (max-width: 700px)  {
          font-size: 14px;
        }

        @media (max-width:600px) {
          font-size: 16px;
        }
        @media (max-width:1280px) {
          margin-top: 20px;
        }
        @media (max-width:380px) and (max-width: 700px){
          font-size: 14px;
        }
        @media (min-width: 1280px) {
          font-size: 22px;
          line-height: 36px !important;
        }

        @media (min-width: 2000px) {
          font-size: 30px;
          line-height: 41px !important;
        }
        @media (max-width:380px) and (max-width: 700px) {
          margin-top: 5px;
          margin-bottom: 5px;
        }

        @media (max-width:350px) {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
      @media (max-width:1280px) {
        margin-top: 20px;
      }
      @media (max-width:768px) {
        margin-top: 20px;
      }
      @media (max-width:400px) {
        margin-top: 05px;
      }
      @media (max-width:350px) {
        margin-top: 10px;
      }
    }
  }
}
