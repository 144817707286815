.accordion {
  padding: 0 1rem;
  margin-bottom: 2em;
  background: $color-white;
  font-size: 1.125rem;
  box-shadow: 0 8px 25px rgba(0, 0, 0, .07);
  overflow: hidden;

  @media all and (min-width: $bp-small) {
    margin-bottom: 3em;
    padding: 0 2rem;
  }


  &:last-of-type {
    margin-bottom: 0;
  }

  p,
  a {
    @media all and (min-width: $bp-small) {
      font-size: 1.125rem;
    }
  }

  .accordion-header {
    padding: 1.5rem 1rem;

    @media all and (min-width: $bp-small) {
      padding: 2.5rem 1rem;
    }
  }

  .accordion-header {
    @include clearfix();
    display: flex;

    .accordion-header-left {
      position: relative;
      flex-grow: 1;

      .accordion-link {
        display: inline-block;
        margin-top: 1em;

        @media all and (min-width: $bp-small) {
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -15px;
        }

        &:hover {
          border-bottom: none !important;
          box-shadow: 0 1px 0 $color-blue;
        }

        &:after {
          content: url('/assets/images/illustrations/chevron-down-blue.svg');
          display: inline-block;
          transition: .2s ease;
          margin-left: .4em;
        }
      }

      .accordion-header-title {
        display: block;
        margin-bottom: .5rem;
      }

      .accordion-header-content {
        margin: 0;

        @media all and (min-width: $bp-small) {
          padding-right: 10em;
        }
      }

      .accordion-link {
        color: $color-blue;
        border-bottom: none;
        text-decoration: none;

        &:hover {
          border-bottom: 1px solid;
        }
      }
    }

    .accordion-header-right {
      display: none;
      //display: flex;
      align-items: center;
      justify-content: center;
      width: 15%;

      &:hover {
        cursor: pointer;
      }

      .accordion-icon {
        width: 17px;

        &:before {
          content: url('/assets/images/illustrations/chevron-down.svg');
          display: block;
        }

        &:after {
          content: url('/assets/images/illustrations/close.svg');
          display: none;
        }
      }
    }
  }

  .accordion-body {
    display: none;

    .accordion-body-content {
      padding: 2.5rem 1rem 3.2rem;
      box-shadow: 0 -1px #e7e7e7;

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &.open {
    .accordion-header {
      border-bottom: 1px solid #ddd;

      .accordion-header-right {
        .accordion-icon {
          &:before {
            display: none;
          }

          &:after {
            display: block;
          }
        }
      }
    }

    .accordion-link {
      &:after {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
}