/* -------------------------------------------------------------------------- *\
   #10X INTRODUCTIONS PAGE
\* -------------------------------------------------------------------------- */

//Todo: rework sass and less work.

.birthday-hero {

  .hero__synopsis-text {
    @media all and (max-height: 600px) {
      font-size: 0.8em !important;
    }
  }

  .information-bar {
    background-color: #3a4455;
    text-align: center;
    padding: 15px 15px;

    p {
      @media all and (max-width: 768px) {
        font-size: 0.675em;
      }

    }
  }

  .introductions-mobile {
    display: none;
    @media all and (max-width: 768px) {
      display: block;
    }
  }
  .introductions-desktop {
    display: block;
    @media all and (max-width: 768px) {
      display: none;
    }
  }

  #hero-subheader {
    @media all and (max-height: 600px) {
      font-size: 0.8em !important;
    }
  }

  //margin-left: 30%;
  #phone-image {

    @media all and (max-width: 1440px) {
      width: 350px;
      padding-top: 4em;
    }

    @media all and (max-height: 800px) {
      height: auto;
      width: 300px;
      padding-top: 2.5em;
    }

    @media all and (max-height: 600px) {
      width: 160px;
      padding-top: 3.5em;
    }


    @media all and (max-width: 768px) {
      width: 350px;
      padding-top: 2em;

      margin-top: 10px;
      margin-bottom: 19px;
      margin-left: 19px;
      margin-right: 19px;
    }

    @media all and (max-width: 480px) {
      width: 250px;
      padding-top: 2em;
    }

    @media all and (max-width: 360px) {
      width: 200px;
      padding-top: 2em;
    }
  }

  #main-div {
    @media all and (max-width: 1024px) {
      height: auto;
    }
  }

  .hero-text-below-line {
    padding-top: 0em;
    @media all and (min-height: 980px) {
      padding-top: 3em;
    }
  }

  .hero-subtext {
    letter-spacing: 1px;
    text-align: left;

    @media all and (max-height: 600px) {
      font-size: 1em !important;
    }
  }

  .hero-subheader {
      font-size: 1.5em !important;

    @media all and (max-height: 800px) {
      font-size: 1.25em !important;
    }

    @media all and (max-height: 600px) {
      font-size: 0.875em !important;
    }
  }

  .bottom-spacing {
    margin-left: 2em;
    margin-right: 2em;

    @media all and (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  #intro-button-gap {
    @media all and (max-height: 600px) {
      margin-bottom: 0;
    }
  }

  #hero-button {
    height: 2.5em !important;
    padding-top: 0.625em !important;
    margin: 0 !important;

    @media all and (max-height: 600px) {
      font-size: 0.75em !important;
    }
  }

  .information-button {
    display: none;

    @media all and (max-width: 768px) {
      display: block;
    }
  }

  .tcs-link {
    display: block;
    text-align: center;
    max-width: 17em;
    color: white;

    margin-top: 0.75em;

    a {
      color: white;
      text-decoration: none;
      span {
        font-size: 1.25em;
      }
    }

    @media all and (max-height: 600px) {
      max-width: 10em;
      a {
        span {
          font-size: 0.75em;
        }
      }
    }

    @media all and (max-width: 768px) {

      max-width: inherit;
      margin-top: 0em;
      margin-bottom: 0.75em;
      span {
        font-size: 0.875em !important;
      }
    }
  }


  @media all and (max-width: 1200px) {

    //margin-left: 17.5%;

  }

  @media all and (max-width: $bp-medium) {

    //margin-left: 10%;



    .hero-container {
      max-width: 10em;
      display: block;
      text-align: center;

      @media all and (max-width: $bp-medium) {
        max-width: 8em;
      }

    }

    .hero__text-large {
      margin-top: 1em;
      font-size: 4em;

      @media all and (max-width: $bp-medium) {
        font-size: 2.5em;
      }

      @media all and (max-width: $bp-xs) {
        font-size: 1.5em;
      }
    }

    .hero__text-medium {
      font-size: 2.75em;


      @media all and (max-width: $bp-xs) {
        font-size: 1em;
      }
    }

    .hero__text-small {
      font-size: 1.25em;
      padding-top: 0.5em;

      @media all and (max-width: $bp-small) {
        font-size: 0.75em;
      }

    }

    hr {
      margin-top: 2em;
      margin-bottom: 1.5em;
      margin-left: 0;
      width: 76px;
      height: 2px;
      text-align:left;
      border: #FFFFFF;
      background-color: #FFFFFF;
    }

    .btn {
      @media all and (max-width: $bp-xs) {
        margin-left: 0 !important;
      }
    }

  }

  .hero-promo-small {
    padding-bottom: 8em !important;
  }

  .hero-promo-background {

    background-image: url('../../../assets/images/heros/mid-year-campaign-desktop.jpg');

    @media all and (max-width: $bp-small) {
      background-image: url('../../../assets/images/heros/boost-mobile-2.jpg');
    }
  }

  .hero-birthday-promo-mobile {
    display: none;

    @media all and (max-width: $bp-small) {
      display: block;
    }
  }

  .hero-birthday-promo {
    display: block;

    @media all and (max-width: $bp-small) {
      display: none;
    }
  }

  .birthday-hero--body {
    padding-top: 4em !important;
    padding-bottom: 8em !important;

    @media all and (max-height: 800px) {
      padding-bottom: 3em !important;
    }

    @media all and (max-width: $bp-medium) {
      padding-bottom: 6em !important;
    }

    @media all and (max-width: $bp-small) {
      padding-top: 1em !important;
      padding-bottom: 20em !important;
    }

    @media all and (min-width: $bp-xxl) {

      padding-top: 8em !important;
      padding-bottom: 12em !important;

    }

  }

  .homepage-birthday-hero--body {
    padding-top: 8em !important;
    padding-bottom: 12em !important;


    @media all and (max-width: $bp-small) {
      padding-top: 2em !important;
      padding-bottom: 10em !important;
    }

  }


  .hero {
    .fa-chevron-right{
      padding-left: 0.5em !important;
    }

    .hero__left-image {
      background-position: bottom 0 left 0 !important;
      background-size: auto !important;
    }

    hr.hr--short {
      max-width: 1.5em;
      border-top: .375em solid #ffffff !important;
      margin-top: 1em;

      @media all and (max-height: 800px) {
        margin-bottom: 0.5em !important;
      }
    }

    hr.hr--invisible{
      max-width: 2em;
      border-top: .375em solid transparent !important;
      margin-bottom: .1rem;
    }

    .hero__synopsis.hero__synopsis--small-print{
      font-size: 1em;
    }

    .col {
      .col__relative-child {
        position: relative;
        width: 100%;
        margin: 0;

        .col__relative-child--top-right-absolute {
          top: 0;
          right: 0;
          position: absolute;
        }
      }
    }

    .image-left-container--width {
      width: 50%;
    }

    .content-right-container--width {
      width: 50%;
      margin-left: 0;
    }

    .bordered {
      border: 1px solid black;
    }

    .no-margin-left {
      margin-left: 0;
    }

    .padding-top-medium {
      padding-top: 2em;
    }

    .no-margin-sides {
      margin-left: 0;
      margin-right: 0;
    }

    .hero__candle {
      padding-top: 3em;
      padding-bottom: 2em;
    }

    .hero__body--transparent {
      background-color: transparent;
      padding-top: 6em !important;

      @media all and (max-width: 1280px) {
        padding-top:8em !important;
      }

      @media all and (max-height: 600px) {
        padding-top: 7em !important;
      }
    }

    .hero__btn {
      background-color: #334152;
    }



    @media all and (max-width: $bp-xs) {
      .col {
        float: left;
      }

      .hr.hr--short {
        margin: 2em 0 3em 0;

      }

      .image-left-container--width {
        width: 50%;
        display: inline-block;
        padding-left: 0;
        padding-right: 0;
      }

      .content-right-container--width {
        width: 50%;
        display: inline-block;
        padding-left: 0;
        padding-right: 0;
      }

      .hero__left-image {
        background-position: bottom 0% left 0 !important;
        background-size: 50% 100% !important;
        background: none !important;
      }

      .padding-top-medium {
        padding-top: 1em;
      }

      .hero__body{
        p{
          margin-left: -70%;
          margin-right: -1em;

        }

        a.btn{
          margin-left: -70%;
        }
      }
    }

    @media all and (min-width: $bp-medium) and (max-width: $bp-large) {
      .image-left-container--width {
        width: 50%;
      }

      .content-right-container--width {
        width: 50%;
        margin-left: 0;
      }

      .hero__left-image {
        background-position: bottom 0 left 0 !important;
        background-size: auto !important;
      }

      .padding-top-medium {
        padding-top: 2em;
      }
    }

    @media all and (min-width: $bp-small) and (max-width: $bp-medium) {
      .col {
        float: left;
      }

      .image-left-container--width {
        width: 50%;
        display: inline-block;
        padding-left: 0;
        padding-right: 0;
      }

      .content-right-container--width {
        width: 50%;
        display: inline-block;
        padding-left: 0;
        padding-right: 0;
      }

      .hero__left-image {
        //background-position: bottom 0 left 0 !important;
        //background-size: auto !important;
        background: none !important;
      }

      .padding-top-medium {
        padding-top: 2em;
      }

    }

    @media all and (min-width: 480px) and (max-width: 739px) {
      .col {
        float: left;
      }

      .image-left-container--width {
        width: 40%;
        display: inline-block;
        padding-left: 0;
        padding-right: 0;
      }

      .content-right-container--width {
        width: 60%;
        display: inline-block;
        padding-left: 0;
        padding-right: 0;
      }

      .hero__left-image {
        //background-position: bottom 0 left 0 !important;
        //background-size: auto !important;
        background: none !important;
      }

      .padding-top-medium {
        padding-top: 2em;
      }

    }

    @media all and (min-width: $bp-xxl) {
      .hero__left-image {
        background-position: bottom 0 left 0 !important;
        background-size: auto !important;
      }

      .padding-top-medium{
        padding-top: 3em;
      }
    }}

}


.introductions {
  &#main-div {
    .hero-image-inner {
      @media all and (min-width: $bp-medium) {
        margin: 3em auto 0;
      }
    }

    #hero-subheader {
      font-size: 0.8em !important;
    }

    .hero__title {
      margin-bottom: .4em;
      font-size: 2.75em;
      line-height: 1.25em;

      @media all and (max-height: 800px) {
        font-size: 2em !important;
        line-height: 1.25em;
      }
      @media all and (max-height: 600px) {
        font-size: 1em !important;
        line-height: 1.25em;
      }

      @media all and (max-width: $bp-xl) {
        margin-bottom: .4em;
        font-size: 2.75em;
      }

      @media all and (min-width: $bp-small) {
        margin-bottom: .1em;
      }

      br {
        @media all and (min-width: $bp-small) {
          display: none;
        }
      }
    }

    @media all and (min-width: $bp-small) {
      height: calc(76vh - 80px);

      .btn {
        height: 3.5em;
        max-width: 20em;
        padding: {
          top: 1.3em;
          bottom: 1.3em;
        }
        font-size: 1.2em;
        margin: 2em auto .6em;

        .birthday-hero {
          height: 0;
          padding: 0;
          margin:0;
        }
      }
    }
  }
}

.l-section {
  &.introductions {
    .header {
      .header__title {
        @media all and (min-width: $bp-medium) {
          font-size: 2.5rem;
        }
      }
    }

    &#the-more-you-give {
      .content-block {
        .header {
          .header__title {
            margin-bottom: 1.75em;
          }
        }

        .info-blocks-2col {
          @media all and (min-width: $bp-medium) {
            display: flex;
          }

          .info-block-2col {
            display: flex;
            flex: 1;

            &:first-of-type {
              margin-bottom: 2em;

              @media all and (min-width: $bp-medium) {
                margin-right: 5em;
                margin-bottom: 0;
              }
            }

            .info-block-2col-inner {
              display: flex;
              flex-direction: column;
              background: $color-white;
              padding: 2em 3em 3.2em;
              box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);

              .info-block-2col-header {
                text-align: center;
                margin-bottom: 1em;

                .info-block-2col-title {
                  line-height: 1.4;
                  font-size: 1.75rem;
                }
              }

              .info-block-2col-content {
                flex-grow: 1;
                text-align: left;
                margin-bottom: 0;

                .bolder {
                  font-weight: 600;
                }

                @media all and (min-width: $bp-small) {
                  font-size: 1.12rem;
                }
              }
            }
          }
        }
      }
    }

    &#introductions-cta {
      background: {
        color: $color-blue;
        repeat: no-repeat;
        position: right 3% center;
        size: contain;
      }

      @media all and (min-width: $bp-small) {
        background-position: right 5% center;
      }

      @media all and (min-width: $bp-large) {
        background-position: right 15% center;
      }

      .introductions-cta-title {
        color: $color-white;
        line-height: 1.4;
        margin: 0;
        padding-right: 5em;

        br {
          display: none;

          @media all and (min-width: $bp-medium) {
            display: block;
          }
        }

        .introductions-cta-title-bonus,
        .introductions-cta-title-text {
          display: inline-block;
          vertical-align: top;
        }

        .introductions-cta-title-bonus {
          margin-right: 1em;
          margin-bottom: 1em;
          font-weight: 700;
          text-transform: uppercase;

          @media all and (min-width: $bp-medium) {
            margin-bottom: 0;
          }
        }

        .introductions-cta-title-text {
          font-weight: 300;

          strong {
            font-weight: 600;
          }
        }
      }
    }

    &#how-do-i {
      .l-wrapper {
        width: 100%;

        @media all and (min-width: $bp-small) {
          width: 85%;
        }
      }

      .content-block {
        margin: 0;
      }

      .header {
        position: relative;
        margin: 0;

        .header__title {
          width: 85%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 2em;

          @media all and (min-width: $bp-small) {
            width: 100%;
          }
        }
      }

      .tns-outer {
        width: 95%;
        margin: 0 auto 1em;

        @media all and (min-width: $bp-small) {
          width: 100%;
          margin-bottom: 0;
        }
      }

      .introduction-steps-slider {
        .introduction-steps-slide {
          opacity: 0;
          transition: .5s ease;

          &.tns-slide-active {
            opacity: 1;
          }

          .introduction-steps-slide-inner {
            .introduction-steps-slide-image {
              float: left;
              max-width: 8em;
              padding-right: 1.2em;
              padding-bottom: 1em;

              @media all and (min-width: $bp-small) {
                float: left;
                width: 30%;
                max-width: 100%;
              }

              img {
                @media all and (min-width: $bp-small) {
                  max-height: 80vh;
                }
              }
            }

            .introduction-steps-slide-content {
              padding-right: 1.4em;

              @media all and (min-width: $bp-small) {
                text-align: left;
                float: left;
                width: 70%;
                padding-top: 1em;
                padding-left: 5em;
                padding-right: 0;
              }

              @media all and (min-width: $bp-large) {
                padding-top: 1.5em;
                padding-left: 7.5em;
              }

              h3 {
                display: inline-block;
                padding-bottom: .1em;
                margin-bottom: .5em;
                font-size: 1.2rem;
                border-bottom: 3px solid #c98da2;
                text-transform: uppercase;

                @media all and (min-width: $bp-small) {
                  font-size: 2.4rem;
                  padding-bottom: .35em;
                  margin-bottom: .6em;
                  border-bottom: 6px solid #c98da2;
                }

                @media all and (min-width: $bp-medium) {
                  font-size: 3rem;
                }
              }

              p {
                font-size: 1rem;
                color: #818385;
                line-height: 1.6;
                margin-bottom: 0;

                @media all and (min-width: $bp-small) {
                  font-size: 1.4rem;
                  line-height: 1.9;
                }

                @media all and (min-width: $bp-large) {
                  font-size: 1.65rem;
                }

                a {
                  border-bottom-color: #00aeef;
                  color: #00aeef;
                }
              }
            }
          }
        }
      }

      .introduction-steps-slider-controls-wrapper {
        margin-bottom: 1.6em;

        @media all and (min-width: $bp-medium) {
          margin-left: 30%;
          padding-left: 5em;
          position: absolute;
          bottom: 5em;
          margin-bottom: 0;
        }

        @media all and (min-width: $bp-large) {
          padding-left: 7.5em;
          bottom: 8em;
        }


        .introduction-steps-slider-controls {
          width: 85%;
          margin-left: auto;
          margin-right: auto;
          outline: none;

          @media all and (min-width: $bp-small) {
            width: 100%;
          }

          .introduction-steps-slider-control {
            display: inline-block;
            max-width: 3em;
            outline: none;

            @media all and (min-width: $bp-medium) {
              max-width: 4em;
            }

            &.prev {
              margin-right: 1em;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .introduction-steps-slider-nav {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        outline: none;

        @media all and (min-width: $bp-small) {
          width: 100%;
          text-align: center;
        }

        @media all and (min-width: $bp-medium) {
          margin-left: 30%;
          padding-left: 5em;
          position: absolute;
          bottom: 1em;
          text-align: left;
        }

        @media all and (min-width: $bp-large) {
          padding-left: 7.5em;
          bottom: 4em;
        }

        .introduction-steps-slider-nav-item {
          display: inline-block;
          padding: .5em .2em;
          outline: none;

          &:before {
            content: '';
            display: block;
            width: .7em;
            height: .7em;
            background: #dae8ed;
            border-radius: 5em;
            outline: none;
          }

          &:hover {
            cursor: pointer;
          }

          &.tns-nav-active {
            &:before {
              background: #b7b7b7;
            }
          }
        }
      }
    }

    &#ready-to-help {
      .header {
        .header__title {
          margin-bottom: .3em;
        }
      }

      .content-block {
        margin-bottom: 0;

        .content-block__img {
          @media all and (min-width: $bp-small) {
            width: 55%;
          }

          h4 {
            margin-top: 1em;

            @media all and (min-width: $bp-small) {
              text-align: center;
            }
          }
        }

        .content-block__body {
          margin-bottom: 2rem;

          @media all and (min-width: $bp-large) {
            padding-top: 1.5em;
          }

          @media all and (min-width: $bp-small) {
            float: right;
            width: 40%;
            margin-bottom: 0;
          }

          img {
            margin: 2em 0;

            @media all and (min-width: $bp-small) {
              max-width: 200px;
              margin: 0;
            }
          }

          .btn {
            display: block;
            margin-bottom: 1em;

            .birthday-hero {
              height: 0;
              padding: 0;
              margin:0;
            }

            @media all and (min-width: $bp-small) {
              display: block;
              height: 4em;
              max-width: 20em;
              padding: {
                top: 1.3em;
                bottom: 1.3em;
              }
              margin: {
                top: 1em;
                bottom: 1em;
              }
              font-size: 1.2em;
            }
          }

          .terms-link {
            color: $color-blue;
            font-size: .875rem;
            text-decoration: none;
            border-bottom: 1px solid;
          }
        }
      }
    }

    &#faq {
      .header {
        .header__title {
          @media all and (min-width: $bp-medium) {
            font-size: 2.5em;
          }
        }
      }

      .faq__title {
        line-height: 1.4;
        margin-bottom: 1.4em;
      }
    }
  }
}

.introductions-desktop {
  width: 100%;
}

//desktop fixes
@media screen and (min-width: 1024px) {
  .birthday-hero {
    #main-div {
      .introductions-desktop {
        width: 100%;

        .content-right-container--width {
          .l-wrapper {
            .row {
              .col {

                h2{
                  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
                }
                hr {
                  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.6);
                }
                #hero-subheader {
                  @media all and (max-height: 600px) {
                    font-size: 0.8em !important;
                  }
                }

              }
            }
          }
        }
      }
      .introductions-mobile {
        display: none;
      }
    }
    .information-bar {
      .information-button {
        display: none;
      }
    }
  }
}