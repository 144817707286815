/**
 * POST
 */

/* -------------------------------------------------------------------------- *\
   #TABLE OF CONTENTS
\* -------------------------------------------------------------------------- */

/**
 * POST
 * POST META
 */

/* -------------------------------------------------------------------------- *\
   #POST
\* -------------------------------------------------------------------------- */

.post {

    &:not(:first-child) {
        margin-top: 2em;
    }

    /**
     * Featured
     */

    &.post--featured {

        .post__title {
            font-size: 1.25em; /* 20 / 16 */
        }
    }

    /* Link */

    .post__link {
        display: block;
    }

    /* Image */

    .post__img {
        box-shadow: 0 0.125em 2em rgba($color-black, 0.1);
        margin-bottom: 1em;
        max-width: 20em;
        transition: box-shadow 0.25s;

        &:focus,
        &:hover {
            box-shadow: 0 0.25em 2.5em rgba($color-black, 0.2);
        }

        img {
            width: 100%;
        }
    }

    /* Title */

    .post__title {
        font-size: 1em;
        margin-bottom: 1em;

        a {
            @include anchor();
        }
    }

    /* Synopsis */

    .post__synopsis {
        margin-bottom: 0;
    }

    & + .post {
        
        padding-top: 2em;
    }

    & + .pagination {
        margin-top: 3em;
    }
}

.post--left{
    border-top: 0.0625em solid #d0d3d5;
    padding: 4em 0 2em 0 !important;
}

@media all and (min-width: $bp-small) {

    .post {
        @include clearfix();

        /**
         * Horizontal
         */

        &.post--horizontal {

            &:nth-child(even) {

                .post__img {
                    float: right;
                    margin-right: 0;
                    margin-left: 5%;

                    & + .post__body {
                        float: left;
                    }
                }
            }

            .post__img__left{
                float: left !important;
                margin-right: 5% !important;
                margin-left: 0 !important;
            }

            .post__img {
                float: left;
                margin-right: 5%;
                margin-bottom: 0;
                max-width: none;
                width: 45%;

                & + .post__body {
                    float: right;
                    width: 50%;
                }
            }

            & + .pagination {
                margin-top: 5em;
            }
        }

        &.post--featured {
            position: relative;

            .post__img {
                margin-bottom: 0;
                max-width: none;
                width: 100%;

                & + .post__body {
                    width: 75%;
                }
            }

            .post__body {
                background-color: $color-white;
                bottom: 0;
                left: 2em;
                padding: 2em;
                position: absolute;
            }

            .post__title {
                font-size: 2em;
            }
        }

        /**
         * Stacked
         */

        &.post--stacked {

            .post__img {
                float: none;
                margin-right: 0;
                max-width: 100%;
            }
        }

        .post__title {
            font-size: 1.5em; /* 24 / 16 */
        }

        .post__img {
            float: left;
            margin-right: 2em;
            max-width: 10em;
        }
    }
}

@media all and (min-width: $bp-large) {

    .post {

        &.post--horizontal {

            .post__img {

                & + .post__body {
                    margin-top: 2em;
                }
            }
        }

        &.post--featured {
            min-height: 31.25em; /* 500 / 16 */

            .post__img {

                & + .post__body {
                    width: 50%;
                }
            }
        }
    }
}





/* -------------------------------------------------------------------------- *\
   #POST META
\* -------------------------------------------------------------------------- */

.post-meta {
    color: $color-gray;
    display: inline-block;
    font-size: 0.625em; /* 10 / 16 */
    letter-spacing: 0.0625em;
    margin-bottom: 0.5em;
    text-transform: uppercase;

    &.post-meta--author {
        color: $color-black;
        font-size: 1em;
        text-transform: none;
    }

    a {
        color: inherit;
        text-decoration: none;
        transition: color 0.25s;

        &:focus,
        &:hover {
            color: $color-primary;
        }
    }

    & + .post-meta {
        display: block;
    }
}

@media all and (min-width: $bp-small) {

    .post-meta {
        font-size: 0.75em; /* 12 / 16 */

        & + .post-meta {
            display: inline-block;
            padding-left: 1em;
        }
    }
}
