/* -------------------------------------------------------------------------- *\
   #PRODUCT PAGES
\* -------------------------------------------------------------------------- */

body {
  &.noscroll {
    overflow: hidden;
  }
}

.l-section {
  &.homepage,
  &.retirement-annuity,
  &.preservation-fund,
  &.living-annuity,
  &.unit-trust,
  &.private-client {
    .content-block {
      @include section-order();

      .content-block__features {
        margin-bottom: 3rem;

        @media all and (min-width: $bp-small) {
          margin-bottom: 0;
        }
      }

      .content-block__body {
        .header {
          .header__title {
            @media all and (min-width: $bp-medium) {
              font-size: 2.5rem;
            }
          }
        }

        .content-block__synopsis {
          @media all and (min-width: $bp-small) {
            font-size: 1.125rem;
          }
        }
      }

      .content-block__img {
        @media all and (min-width: $bp-large) {
          text-align: center;
          padding: 0;

          h4 {
            margin: {
              top: 2em;
              bottom: 1.5em;
            }
          }
        }

        .content-block__disclaimer {
          margin-top: 3em;

          p {
            padding: 0;
          }
        }
      }

      .sub-header {
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 0.6rem;

        @media all and (min-width: $bp-small) {
          font-weight: 700;
          margin-bottom: 1.125em;
          letter-spacing: 2px;
        }
      }
    }



    &#low-fees  {
      .content-block__body {
        @media all and (min-width: $bp-large) {
          width: 42%;
        }
      }

      .hr {
        border-top: 0.063rem solid #D5D9E0;
        margin: 1em 0 3em;

        @media all and (min-width: $bp-small) {
          margin: 3em 0 4em;
        }
      }
    }

    &#superior-returns {
      .content-block__body {
        @media all and (min-width: $bp-large) {
          width: 43%;
        }
      }

      .content-block__img {
        @media all and (min-width: $bp-large) {
          margin-top: 3.5em;
        }

        .content-block__disclaimer {
          text-align: left;
          margin-top: 2em;
          margin-bottom: 1em;
        }
      }

      .header {
        &#fact-sheet {
          @include clearfix();
          padding-top: 0rem;

          @media all and (min-width: $bp-small) {
            padding-top: 2.6rem;
          }
        }
      }
    }

    &#transparency {
      .rellax {
        display: none;
        position: absolute;

        @media all and (min-width: $bp-large) {
          display: block;
        }

        &.cloud-sun {
          top: 3em;
          right: 7em;
        }

        &.cloud-1 {
          top: 7em;
          left: 25em;
        }

        &.cloud-2 {
          top: 24em;
          right: 28em;
        }
      }

      .content-block__img {
        @media all and (min-width: $bp-large) {
          width: 53%;
          margin-left: 0;
          padding: 0;
        }

        img {
          display: none;

          @media all and (min-width: $bp-small) {
            display: block;
          }

          @media all and (min-width: $bp-large) {
            max-width: 100%;
            margin-top: 15em;
          }
        }
      }

      .content-block__body {
        @media all and (min-width: $bp-large) {
          width: 47%;
        }

        .transparency-img-mobile {
          max-width: 18em;
          margin: 1em 0 4em;

          @media all and (min-width: $bp-small) {
            display: none;
          }
        }

        .video-play {
          margin-bottom: 2rem;

          @media all and (min-width: $bp-large) {
            margin-bottom: 0;
          }
        }

        .header {
          &#direct-investing {
            @media all and (min-width: $bp-large) {
              padding-top: 3.6em;
            }
          }
        }
      }

      .btn--secondary {
        margin-right: 1em;
      }
    }
  }
}

#superior-returns {
  &.retirement-annuity {
    .content-block__img {
      @media all and (min-width: $bp-large) {
        margin-top: 0.5em !important;
      }
    }
  }
}

.logos {
  &.homepage,
  &.retirement-annuity,
  &.preservation-fund,
  &.living-annuity,
  &.unit-trust,
  &.private-client {
    .logos__body {
      //animation: none !important;
      //white-space: normal;
    }

    .logos__title {
      margin-bottom: 1.2em;

      @media all and (min-width: $bp-small) {
        font-size: 1.56em;
      }
    }

    .logos__item {
      width: auto;
      margin-top: 2em;
      max-width: 104px;
      max-height: 63px;

      @media all and (min-width: $bp-large) {
        margin: {
          right: 2.4em;
          left: 2.4em;
        }
      }
    }
  }
}

#clients {
  //text-align: center;

  .clients__title {
    margin-bottom: 2rem;
    font-size: 1.4rem;

    @media all and (min-width: $bp-small) {
      text-align: center;
      font: {
        size: 2.5rem;
      }
      margin-bottom: 4rem;
    }
  }

  .tns-outer {
    display: flex;
    flex-direction: column;

    .tns-nav {
      order: 1;
      margin-top: 1rem;
      text-align: center;

      @media all and (min-width: $bp-small) {
        display: block;
        margin-top: 3rem;
      }

      button {
        -webkit-appearance: none;
        background: none;
        box-shadow: none;
        border: none;
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 .3rem;
        padding: 0;
        outline: none;

        &:before {
          content: '';
          display: block;
          width: 2.5rem;
          height: 0.25rem;
          background: #C3C5C7;
        }

        &.tns-nav-active {
          &:before {
            background: $color-green;
          }
        }
      }
    }
  }

  .btn {
    margin-top: 2rem;
  }
}

#personalised-plan {
  &.retirement-annuity {
    .header__title {
      @media all and (min-width: $bp-medium) {
        font-size: 2.5rem;
      }
    }
  }

  &.preservation-fund {
    .header__title {
      @media all and (min-width: $bp-medium) {
        font-size: 2.188rem;
      }
    }
  }
}

#heres-how {
  .header {
    margin-bottom: 0;

    .header__title {
      @media all and (min-width: $bp-medium) {
        font-size: 2.5rem;
      }
    }

    .header__synopsis {
      @media all and (min-width: $bp-medium) {
        font-size: 1.125rem;
        max-width: 35em;
        margin: 0 auto;
      }
    }
  }
}

#low-fees {
  &.living-annuity {
    .content-block__img {
      img {
        max-width: 20em;
      }

      .content-block__disclaimer {
        text-align: left;

        @media all and (min-width: $bp-large) {
          margin-left: 3rem;
        }

        .icon {
          margin-right: 10px;
        }
      }
    }
  }

  &.private-client {
    .content-block__disclaimer {
      margin-top: 10px;
      padding-left: 56px;
      text-align: left;

      @media all and (min-width: $bp-large) {
        margin-bottom: 3rem;
      }
    }

    .table {
      order: 3;
    }
  }
}

.video-placeholder-text-2 {
  color: #ffffff; left: 45px; font-style:italic; position: absolute;

  @media all and (max-width: $bp-small) {
    color: #ffffff; left: 25px; font-style:italic; position: absolute;
  }
}
.video-placeholder-text-3 {
  position: absolute;top: 175px;left: 45px;

  @media all and (max-width: $bp-small) {
    position: absolute;top: 115px;left: 25px;
  }  @media all and (max-width: 350px) {
    position: absolute;top: 100px;
  }
}

.video-placeholder-text {
  width: 37.5%;
  font-weight: 600;
  vertical-align: middle;
  font-size: 1em !important;
  line-height: 20px;
  letter-spacing: px;
  padding-left: 2.75em !important;

  @media all and (max-width: $bp-small) {
    width: auto;
    font-size: 20px !important;
    line-height: 25px;
    padding-left: 1.5em !important;
  }
  @media all and (max-width: $bp-xs) {
    font-size: 15px !important; line-height: 20px;
  }
}

.video-placeholder {
  max-width: 100%;
  height: 300px;

  @media all and (max-width: $bp-small) {
    height: auto;
  }
}

.video-text-container {
  position: absolute;top: 32px;left: -8px; color: #fff; text-align: left;
  @media all and (max-width: $bp-small) {
    top:8px;
  }
}

.video-image-container {
  position: absolute;top: 32px;left: -8px; color: #fff; text-align: left;
  @media all and (max-width: $bp-small) {
    top:8px;
  }
}

.video-button-text {
  font-weight: bold; text-decoration: underline;text-decoration-color: #00adf2; font-size: 1em;
@media all and (max-width: $bp-small) {
  font-size: 0.875em;
}
}

.ut-text-area {

  @media all and (max-width: 850px) {
    display: none;
  }
}

.swiper-pagination {
  position: relative;
}

.swiper-pagination-bullet {
  margin: 0 8px !important;
}

#unit-trust {



  .content-block__body {
    &.content-block__body-full-width {
      @include clearfix();
      width: 100%;

      @media all and (min-width: $bp-small) {
        margin-top: 2rem;
      }
    }
  }

  .content-block__img {
    @media all and (min-width: $bp-medium) {
      float: right;
      margin-top: 1rem;
      margin-right: 0;
      margin-left: 5%;
    }

    @media all and (min-width: $bp-large) {
      margin-top: 11rem;
    }
  }
}

#private-client-intro {
  small {
    font-size: .85rem;
    font-weight: 300;
    display: inline-block;
  }
}

#performance {
  &.private-client {
    .content-block__img {
      @media all and (min-width: $bp-large) {
        width: 50%;
      }

      h4 {
        @media all and (min-width: $bp-large) {
          margin-top: 0;
          font-size: 1.8rem;
        }
      }

      img {
        max-width: 100%;
      }

      .content-block__disclaimer {
        text-align: left;
      }
    }

    .content-block__body {
      @media all and (min-width: $bp-large) {
        width: 45%;
      }
    }
  }
}

.performance {
  &.performance-desktop {
    display: none;

    @media all and (min-width: $bp-small) {
      display: block;
    }
  }

  &.performance-mobile {
    margin-top: 2.5rem;

    @media all and (min-width: $bp-small) {
      display: none;
    }
  }
}

#benefit-statements {
  .content-block {
    .content-block__img {
      @media (min-width: $bp-small) {
        width: 48%;
      }
    }

    .content-block__body {
      @media (min-width: $bp-small) {
        width: 47%;
      }
    }
  }
}


 .custom_subheader {
   color:#fff;
 }
.custom_header {
  color: #fff;
    h3, .h3 {
      font-weight: 500;
      line-height: 1.8;
      font-size: 40px;
    }
}

.box{
 border-radius: 5px;
  .custom_subheader{
    color: #000000;
  }
}

.box{
  .custom_header{
    color: #000000;
  }
}


.content-block__features {
  span {
    color: #fff;
    font-size: 12px;
  }
}

  .box{
    .custom_subheader {
      hr {
        width: 100%;
      }

      p {
        font-size: 14px;
        margin-bottom: 0px;
      }
    }
  }



#view-pdf {
  background: #fff;
  color: #000;
  font-weight: 200;
  border: solid 1px #00adf2;
  padding-top: 10px;
  padding-bottom: 10px;
}

#table {
  margin-top: 5.3%;

  &.unit-trust-table {
    box-shadow: 0 0.125em 2em rgba(32, 35, 37,0.1);
    @media (max-width: 1206px) {
      display: none;
    }
  }
}


.content-block .content-block__img img {
  width: 100%;
  max-width: 32em;
}



#horizon {
  &.features {
    margin-top: 0em;
  }
}


div {
  #opt_in {
    margin-top: 10%;
    background: #00adf2;
    color: #fff;
    border-radius: 0px;
  }
}


#opt_in{
  .sub-header {
    color: #fff;
  }
}

div{
  #opt_in{
    &.box{
      hr {
        width: 50%;
      }
    }
  }
}


.button_container {
  text-align: center;
}



.btn.btn-inverse {
  background: transparent;
  border: solid;
  margin-right: 5px;
}

.content-block__features.future_investments {
  line-height: 8;
}


.custom_header h3, .custom_header .h3 {
  font-weight: 300;
  line-height: 1.8;
  font-size: 40px;
}

#opt_in {
  padding: 2em;
}


div {
  #custom_box {
    text-align: left;
  }

}
.raised-paragraph {
  margin-top:15px;
}

//Modal CSS



div{
  .modal-item {
    text-align: center;
    margin: 5% 0;
  }
}
div{
  .modal-content {
    border: 0;
    border-radius: 10px;
  }
}

.hr2{
    border: 0;
    border-top: 0.125em solid #f5f7f9;
    display: block;
    margin: 1em 0;
    padding: 0;
}
hr{
  &.hr2 {
    width: 95% !important;
  }
}


div {
  #opt_in {
    //font-family: OpenSans-Extrabold;
    font-weight: 700;
    margin-top: 29%;
    background: #00adf2;
    color: #fff;
    border-radius: 0px;
    padding: 3em;
  }
}


.download-butto{
  width:19em;
  display: flex;
}

.button-image{
  width:25px;
  height: 25px;
  margin: 0 2%;
  display: inline-block;
  transform: rotate(180deg);
}

a.download-link {
  //font-family: OpenSans-Extrabold;
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  border-bottom: 1px solid #4A90E2;
  color: #374456;
}

h2{
  &.header__title {
  //font-family: OpenSans-Bold;
    font-weight: 700;
  font-size: 40px;
  color: #374456;
  letter-spacing: 0;
}
}

span{
  &.light-title {
  font-weight: 200;
  }
}

.header{
  &.white {
    letter-spacing: 3px;
  }
}
.table-wrapper {
  max-width: 72rem;
}

.header_custom{
  &.white {
    //font-family: OpenSans-Bold;
    font-weight: 700 !important;
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: 2.57px;
    margin-bottom: 5%;
  }
}

span{
    &.box--custom--header {
      //font-family: OpenSans;
      font-size: 18px;
      color: #4A4A4A;
      letter-spacing: 0;
    }
}



.blue-header {
  //font-family: OpenSans-Extrabold;
  font-weight: 700 !important;
  font-size: 20px;
  color: #FFFFFF;
}

p{
    &.feature__synopsis{
      &.checked_feature {
        //font-family: OpenSans;
        font-size: 20px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 31px;
    }
  }
}





.box-unit-trust{
  p {
    //font-family: OpenSans;
    font-size: 18px;
    color: #4A4A4A;
    letter-spacing: 0;
    line-height: 36px;
  }
}



h3.cta__title__bold {

  font-weight: 700;
  font-size: 40px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 55px;
}

// Add Media Queries

  @media (min-width: 46.25em){
  #custom_box {
      padding: 1em;
  }
    .l-section {
      padding-top: 3em;
      padding-bottom: 3em;
    }
}

  @media (min-width: 75em) {
    #unit-trust .content-block__img {
      margin-top: 5rem;
      width: 570px;
    }
    .cta.cta--fixed.js-dismiss {
      display: none;
    }
  }
  @media (min-width: 61.25em) {
    .l-section.homepage .content-block .content-block__body .header .header__title, .l-section.retirement-annuity .content-block .content-block__body .header .header__title, .l-section.preservation-fund .content-block .content-block__body .header .header__title, .l-section.living-annuity .content-block .content-block__body .header .header__title, .l-section.unit-trust .content-block .content-block__body .header .header__title, .l-section.private-client .content-block .content-block__body .header .header__title {
      font-size: 2.5rem;
      font-weight: 700;
    }
  }

  @media (min-width: 60.25em) {
    div {
      #custom_box {
        margin-top: 7%;
      }
      #table-responsive {
        display: none;
      }
      #opt_in {
        text-align: left;
      }
    }
  }

  @media (min-width: 64em) {
    div {
      #table-responsive {
        display: none;
      }
    }
  }

  @media (min-width: 65.25em) {
    div {
      #custom_box {
        margin-top: 7%;
        margin-bottom: 4%;
      }
      #table-responsive {
        display: none;
      }
      #opt_in {
        text-align: left;
      }
      #hidden_banner_button{
        display: none;
      }
    }
  }

  @media (max-width: 30.25em) {
    #view-pdf {
      background: #fff;
      color: #000;
      font-weight: 200;
      border: solid 1px #00adf2;
      padding-top: 10px;
      padding-bottom: 46px;
    }
    #table {
      display: none;
    }
    .custom_header {
      h3 {
        font-weight: 500;
        line-height: 1.8;
        font-size: 22px;
      }
    }
    div {
      #opt_in {
        margin-top: 0;
      }
    }
    .retirement-annuity {
      &.l-section {
        padding-bottom: 0;
      }
    }
  }


.box{
  .custom_subheader{
    p{
      font-size: 18px;
      margin-bottom: 0px;
    }
  }
}

div#custom_box {
  p:first-child {
    margin-top: 4px;
  }
}

a{
  &.download-link.js-modal.icon {
    text-decoration: none;
    border-bottom: none;
  }
}
span{
  &.box--custom--header {
    /* font-family: OpenSans; */
    font-size: 18px;
    color: #4A4A4A;
    letter-spacing: 0;
    font-weight: 400;
  }
}

.unit-trust-button {

  width: 300px;

  @media only screen and (max-width: $bp-small)  {
    width: 260px;
  }

}

@media only screen and (max-width: 1340px) and (min-width: 500px)  {

  #unit-trust .content-block__img {
    float: none;
  }
}

@media only screen and (max-width: 1206px) and (min-width: 500px)  {

  #unit-trust .content-block__img {
    float: none;
    margin: 7% auto;
    width: 54%;
  }
    img#table-responsive {
      margin: auto;
      display: flow-root;
  }
}

@media (max-width: 64.25em) {
  #table {
    display: none !important;
  }
}
@media (max-width: 64.25em) {
  #ut-table-wrapper {
    display: none !important;
  }
}

#msci-index-fund {
  hr {
    display: block;
    height: 0px;
    border-color: #fff;
    color: #FFFFFF;
    border-top: 0.5em;
    margin: 2em 0;
    padding: 0;
    width: 50%;
    margin-left: 25%;
    margin-right: 25%
  }

  .contact-10x {
    background-color: #00aeef;
    color: #FFFFFF
  }

  .equity-fund {
    max-width: 55em !important;
    margin-left: 0 !important;
    text-align: left;
    color: #737577
  }

  .equity-fund-content{
    max-width: 55em;
    margin-left: 0;
    text-align: left;
    color: #9ea0a2
  }

  .equity-fund-header {
    margin-top: 2em;
    display: block;
  }

  .image-desktop {
    display: block;

    @media (max-width: $bp-small) {
      display: none;
    }
  }

  .image-mobile {
    display: none;
    font-weight: 600;

    @media (max-width: $bp-small) {
      display: inline-block;
    }
  }

  .contact-desktop {
    display: block;

    @media (max-width: $bp-small) {
      display: none;
    }
  }
  .contact-mobile {
    display: none;

    @media (max-width: $bp-small) {
      display: block;
    }
  }

  .index-fund-btn-container {
    margin-top: 1em;
  }

  .dark-grey {
    color: #414649
  }

  .index-fund-header {
    font-weight: 700;
    color: #737577;
  }

  .index-fund-tooltip {
    margin-top: 32px !important;
    padding-left: 35px;
    margin-bottom: 0px !important;

    @media (max-width: $bp-small) {
      padding-left: 0 !important;
    }
  }

  .index-fund-tooltip-2 {
    margin-top: 0;
    margin-left: 25%;

    @media (max-width: $bp-small) {
      margin-left: 0;
    }
  }



  .fact-sheet-link {
    background-color: #FFFFFF;

    @media (max-width: $bp-small) {
      width: 100%;
      font-size:0.775em;
    }
  }

  .btn-item {
    text-align:center;
  }

  .btn-group .btn {
    width: 100%;
  }

  #apply-online-bottom-center {
    margin-right: 1em;
  }

  #clients .btn {
    margin-top:0;
  }

  #contact-me-bottom-center {
    width: 300px;
  }

  .contact-desktop .clients__title {
    font-weight: 500;
  }

  .index-sub-header {

    @media (max-width: $bp-xs) {
      font-size:1.7rem;
    }

    @media (min-width: $bp-xs) and (max-width: $bp-medium) {
      font-size:2rem !important;
    }

    @media (min-width: $bp-medium) {
      font-size:2.5rem !important;
    }
  }
}

//TFSA Page
.unit-trust.l-section.l-wrapper--light.tfsa {
  .box-unit-trust.box.tfsa {
    .sub-header {
      margin-bottom: 0 !important;
    }
    .spacer {
      padding: 15px;
    }
    .features.tfsa {
      margin-top: 0;
      p.feature__synopsis {
        font-weight: 300;
      }
      .feature.feature--small {
        padding-bottom: 0 !important;
      }
    }
  }
}


.tax-section.l-section {
  background: #F6F7F8;
}
.tax-section.l-section .content-block.content-block--opposite .content-block__body.content-block__body--offset {
  width: 50%;
}

.goal-content__container {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 650px;
  background: linear-gradient(140deg, rgba(15,163,232,1) 0%, rgba(21,145,230,1) 35%, rgba(28,129,228,1) 100%);
  .vl {
    border-left: 1px solid #fff;
    height: 37rem;
    margin: auto;
  }
  .left-container {
    width: 55%;
    padding: 45px 45px 0 45px;
    p.content-block__synopsis.white {
      z-index: 1;
      position: relative;
      padding-right: 14%;
    }
    .blue-header {
      font-size: 40px;
    }
    img.goal-asset {
      position: absolute;
      bottom: 0;
      z-index: 0;
    }
  }
  .right-container {
    width: 45%;
    padding: 45px 75px;
    .header-container {
      text-align: center;
    }
  }
}
div#goal {
  padding-bottom: 9rem;
  padding-top:5rem;
  .top-section {
    padding-bottom: 20px;
    font-weight: 800;
    .sub-text {
      .text {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
  .header {
    .sub-header {
      padding-bottom: 20px;
      font-weight: 700;
    }
  }
}
.goal-content__container {
  .modal-header {
    .sub-header {
      color: #474c50;
      font-weight: 500;
      text-transform: none;
      font-size: 1.25rem;
    }
    color: #00aeef;
    text-align: center;
    font-weight: 700;
    margin-bottom: 2.125rem;
    letter-spacing: 2px;
    padding-top: 44px;
  }

  .modal-container {
    background: #E8EFF8;
    width: 103%;
    height: 94.5%;
    position: absolute;
    display: none;
    outline: none;
    border-radius: 20px;
    z-index: 2;
    .bold-blue {
      color: #00aeef;
      font-weight: 700;
    }
    span {
      position: absolute;
      right: 24px;
      width: 48px;
      top: 27px;
    }
    span:hover {
        cursor: pointer;
    }
    .goal-content__container {
      background: transparent;
      .left-container {
        .sub-text {
          .text {
            font-size: 12px;
            padding-right: 60px;
          }
        }
        .feature-heading {
          color: #474c50;
          font-size: 1.5em;
          font-weight: 300;
        }
        .text.first {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .button_container {
          display: grid;
          padding-right: 39%;
          margin-top: 10%;
          a#download-fact-sheet-center-left {
            font-size: 12px;
            height: 45px;
            padding: 14px;
            border: 2px solid #00aeef;
            border-radius: 4px;
          }
          a.tfsa {
            border-radius: 20px;
            border: 1px solid #fff;
            margin-top: 25px;
          }
          span.icon.icon--left.fa.fa-file-pdf-o {
            all: unset;
          }
        }
        width: 45%;
        padding: 0 0 0 45px;
      }
      .right-container {
        .header-container {
          text-align: left;
          padding-bottom:25px;
          .header {
            color: #474c50;
            font-size: 24px;
            font-weight: 300;
          }
          .sub-header {

          }
        }
        .image-container {
          display: flex;
          justify-content: center;
          img.modal-feature__image {
            width: 320px;
          }
        }
        .disclaimer-text {
          font-size: 10px;
          margin-top: 25px;
        }
        .sub-container {
          font-size: 14px;
          font-weight: 300;
        }
        padding: 0 45px 0 45px;
      }
    }
  }
  .right-container {
    .button-container {
        a.btn.btn--large {
          background: #00aeef;
          width: 100%;
          border: 1px solid #fff;
          margin-bottom: 20px;
      }
    }
    .fee-free-campaign_button:last-of-type {
      a.btn.btn--large {
        margin-bottom: 0;
      }
    }
  }
}
div#goal.backdrop {
  background: #78B8F3;
}
body.Chrome.modal-open {
  padding-right: 0 !important;
}

.l-section.cta-tfsa {
  background-size: cover !important;
  background-position: center !important;
  background: url(/assets/images/general/tfsa-cta__background.jpg);
  h4.header__title {
    color: #ffffffd1;
    font-size: 1.625rem !important;
    font-weight: 600;
  }
  .header.header--centered {
    margin-bottom: 1em;
  }
  .fee-free-campaign_button {
    a#speak-to-a-retirement-expert-middle {
      border: 1px solid #fff;
    }
  }
}

@media screen and (max-width: 500px) {
  .l-section.cta-tfsa {
    background: url(/assets/images/general/cta-mobile.png);
  }
}

div#our-approach.tfsa {
  .row {
    display: flex;
    width: 74%;
    margin: auto;
    .column {
      flex: 50%;
      padding: 0 5%;
      .header {
        font-size: 1.65rem;
        padding-bottom: 1.875rem;
        padding-right: 5.75rem;
      }
      .content {
        padding-right: 11%;
      }
    }
  }
  .button-container {
    text-align: center;
    padding-top: 30px;
    a.btn.btn--large.tfsa {
      background: #00aeef;
      border: 1px solid #fff;
      padding: 1rem 3.75rem;
    }
  }
}

.logos.unit-trust.l-section.l-section--light.tfsa {
  background: #fff;
}

.retirement-annuity.l-section.tfsa {
  background-position: center !important;
  background-size: cover !important;
  background: url('/assets/images/general/tfsa-cta__background.jpg');
  padding-top: 1em;
  padding-bottom: 1em;
  h3.cta__title__bold {
    font-size: 1.625rem;
    line-height: 2rem;
    padding-left: 13.75rem;
  }
  .content-block__body.content-block__body--offset {
    padding-top: 2.5em;
    padding-bottom: 2.5em;
  }
  .btn-group.tfsa {
    a {
      border-radius: 13px !important;
    }
    a.contact {
      border-radius: 40px !important;
      padding: 0.97em 2em;
      border: 1px solid #fff;
    }
  }
}

@media screen and (max-width: 500px) {
  .retirement-annuity.l-section.tfsa {
    background: url('/assets/images/general/cta-mobile.png');
  }
}

.tax-section.tfsa.l-section {
  h2.header__title {
    font-size: 2.625em;
  }
  .sub-header {
    font-weight: 700;
  }
}

.tax-section.tfsa.l-section {
  .content-block__img.is-transparent {
    p.content-block__synopsis {
      font-size: 1.125rem;
    }
  }
}

//Media queries
@media only screen and (max-width: 1454px) {
  div#our-approach.tfsa .row {
    width: 82%;
  }
}
@media only screen and (max-width: 1440px) {
  div#our-approach.tfsa .row .column .content {
    padding-right: 14%;
  }
  div#our-approach.tfsa .row .column .header {
    font-size: 1.65rem;
    padding-bottom: 1.875rem;
    padding-right: 2.75rem;
  }
}
@media only screen and (max-width: 1246px) {
  div#our-approach.tfsa .row {
    width: 87%;
  }
}

@media only screen and (max-width: 1200px) {
  .tax-section.tfsa.l-section .content-block__body.content-block__body--offset {
    float: left;
  }
  .tax-section.tfsa.l-section .content-block__img.is-transparent {
    width: 50%;
    margin-left: 0;
  }
  .retirement-annuity.l-section.tfsa h3.cta__title__bold {
    padding-left: 0;
  }
  .retirement-annuity.l-section.tfsa .content-block__img.is-transparent {
    width: 50%;
  }
}

@media only screen and (max-width: 1162px) {
  .goal-content__container .right-container {
    width: 45%;
    padding: 45px 61px;
  }
}

@media only screen and (max-width: 1100px) {
  div#our-approach.tfsa .row .column .header {
    padding-right: 2.75rem;
  }
  div#our-approach.tfsa .row .column .content {
    padding-right: 0;
  }
}

@media only screen and (max-width: 1058px) {
  .goal-content__container .right-container {
    width: 45%;
    padding: 45px 40px;
  }
  .goal-content__container .modal-container {
    height: 100%;
  }
}

@media only screen and (max-width: 1036px) {
  .goal-content__container .right-container {
    width: 45%;
    padding: 45px 26px;
  }
}



@media only screen and (max-width: 969px) {
  .tax-section.tfsa.l-section .content-block__img.is-transparent {
    width: 100%;
    margin-left: 0;
  }
  .tax-section.l-section .content-block.content-block--opposite .content-block__body.content-block__body--offset {
    width: 100%;
    margin-bottom: 50px;
  }
  div#our-approach.tfsa .row .column .header {
    padding-right: 8.75rem;
  }
}

@media only screen and (min-width: 953px) {
  .button_container.mobile {
    margin-top: 50px !important;
    display: none;
  }
}

@media only screen and (max-width: 965px) {
  div#our-approach.tfsa .row .column .header {
    padding-right: 0.75rem;
  }
}

@media only screen and (max-width: 953px) {
  div#our-approach.tfsa .row .column .header {
    padding-right: 0;
  }
}

@media only screen and (min-width: 944px) {
  .button_container.mobile {
    margin-top: 50px !important;
    display: none ;
  }

}


@media only screen and (max-width: 944px) {

  .mobile-hr {
    border-top: 1px solid #000;
    height: 0;
    margin: 13px auto;
    width: 93%;
  }
  .button_container.mobile >:first-child {
    margin-bottom: 15px !important;
  }
  .button_container.mobile a{
    width: 53% !important;
    margin: auto !important;
  }
  .goal-content__container .modal-container .goal-content__container .right-container .disclaimer-text {
    max-width: 365px;
    margin: auto;
    margin-bottom: 15px;
  }
  .button_container.mobile span.icon.icon--left.fa.fa-file-pdf-o {
    all: unset;
  }
  .button_container {
    display: none !important;
  }
  .button_container.mobile {
    margin-top: 50px !important;
    display: grid !important;
  }
  .goal-content__container {
    display: block;
  }
  .goal-content__container .left-container {
    width: 100%;
    padding: 45px 45px 0 45px;
  }
  .goal-content__container .right-container {
    width: 100%;
    padding: 45px 45px 200px 45px;
  }
  img.goal-asset {
    display: none;
  }
  .goal-content__container .vl {
    border: 1px solid #ffffff91;
    height: 0;
    margin: auto;
    width: 80%;
  }
  .goal-content__container .right-container .button-container a.btn.btn--large {
    width: 66%;
  }
  .button-container {
    text-align: center;
  }
  .goal-content__container {
    .modal-container {
      .goal-content__container .left-container {
        width: 100%;
      }
      background: #E8EFF8;
      width: 104%;
      height: 103%;
      left: -15px;
      position: absolute;
      display: none;
      outline: none;
      top: 56px;
      border-radius: 20px;
    }
    .modal-header {
      text-align: left;
      font-weight: 700;
      margin-bottom: 1.125rem;
      padding-top: 20px;
      .sub-header {
        color: #474c50;
        font-weight: 500;
        text-transform: none;
        font-size: 1.9rem;
        padding-right: 10px;
      }
    }
  }
}

@media only screen and (max-width: 906px) {
  .button_container.mobile a {
    width: 89% !important;
    margin: auto !important;
  }
  .button_container.mobile a:last-of-type {
    border-radius: 11px;
  }
  div#our-approach.tfsa .row .column .header {
    padding-right: 0.75rem;
  }
}
@media only screen and (max-width: 765px) {
  .goal-content__container .modal-container {
    height: auto;
  }
  .button_container.mobile {
    margin-top: 50px !important;
    padding-bottom: 25px;
  }
  div#goal {
    padding-bottom: 5rem;
    padding-top: 5rem;
  }
}
@media only screen and (max-width: 752px) {
  .retirement-annuity.l-section.tfsa .content-block__img.is-transparent {
    width: 100%;
  }
  .retirement-annuity.l-section.tfsa .btn-group.tfsa {
    margin-top: 0;
  }
}
@media only screen and (max-width: 739px) {
  .unit-trust.l-section.l-wrapper--light.tfsa .content-block__body {
    order: -1;
  }
  body.modal-open {
    div#goal {
      padding-bottom: 15rem;
      padding-top: 5rem;
    }
  }
  .goal-content__container .modal-header .sub-header {
    font-size: 1.6rem;
  }
  .goal-content__container .modal-container span {
    top: 12px;
  }
  .goal-content__container .modal-container .goal-content__container .left-container {
    padding: 0 0 0 20px;
  }
  .goal-content__container .modal-container .goal-content__container .right-container {
    padding: 0 20px 0 20px;
  }
  .goal-content__container .modal-container .goal-content__container .left-container .feature-heading {
    color: #474c50;
    font-size: 1.2em;
  }
  .goal-content__container .modal-container .goal-content__container .left-container .text.first {
    padding-top: 10px;
    padding-bottom: 0;
  }
  div#our-approach.tfsa .row {
    display: block;
    .column {
      flex: 50%;
      padding: 0 0;
    }
  }
  div#our-approach.tfsa .button-container a.btn.btn--large.tfsa {
    line-height: 1px;
  }
}
@media only screen and (max-width: 736px) {
  .goal-content__container .modal-container {
    height: auto;
    top: 40px;
  }
}
@media only screen and (max-width: 614px) {
  .goal-content__container .right-container .button-container a.btn.btn--large {
    width: 90%;
  }
}
@media only screen and (max-width: 518px) {
  .goal-content__container .modal-container .goal-content__container .left-container {
    padding: 0 20px 0 20px;
  }
  .goal-content__container .modal-header .sub-header {
    font-size: 1.2rem;
    padding-right: 7%;
  }
  .goal-content__container .modal-header .header {
    padding-right: 9%;
  }
  body.modal-open div#goal {
    padding-bottom: 10rem;
    padding-top: 5rem;
  }
}
@media only screen and (max-width: 500px) {
  .retirement-annuity.l-section.tfsa .content-block__body.content-block__body--offset {
    padding-bottom: 0;
  }
  .l-section.cta-tfsa h4.header__title {
    text-align: center;
  }
  .retirement-annuity.l-section.tfsa h3.cta__title__bold {
    text-align: center;
  }
  div#our-approach.tfsa .row .column .header {
    font-size: 1.25em;
  }
  div#our-approach.tfsa .row {
    width: 85%;
  }
  .tax-section.tfsa.l-section h2.header__title {
    font-size: 1.25em;
    margin-bottom: 0.75em;
  }
  .goal-content__container .modal-header .header {
    font-size: 0.9375em;
  }
  .goal-content__container .modal-header .sub-header {
    font-size: 1rem;
  }
  .goal-content__container .modal-container .goal-content__container .right-container .header-container .header {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 414px) {

  .hero-slide-inner .parent .banner_copy h2.hero__title hr.hero_your_future_hr.tfsa {
    width: 13rem !important;
  }
  .goal-content__container .modal-container .goal-content__container .left-container .sub-text .text {
    padding-right: 0;
  }
  .goal-content__container .modal-header .header {
    padding-right: 14%;
  }
  body.modal-open .goal-content__container {
    min-height: 900px !important;
  }
  .goal-content__container .right-container {
    width: 100%;
    padding: 45px 20px 115px 20px;
  }
  .button_container.mobile a {
    width: 99% !important;
    margin: auto !important;
  }
  .goal-content__container .modal-container .goal-content__container .right-container {
    padding: 0 20px 0 20px;
  }
  .button_container.mobile a {
    width: 85% !important;
    margin: auto !important;
  }
  .button_container.mobile a {
    font-size: 0.7rem;
  }
  .goal-content__container .modal-container .goal-content__container .right-container .image-container img.modal-feature__image {
    width: 282px;
  }
  body.modal-open div#goal {
    padding-bottom: 5rem;
    padding-top: 5rem;
  }
  .goal-content__container .modal-container {
    top: 48px;
  }
  .goal-content__container .right-container {
    padding: 45px 20px 107px 20px;
  }
  .goal-content__container .left-container {
    width: 100%;
    padding: 20px 20px 0 20px;
  }
  .goal-content__container .right-container {
    padding: 45px 20px 107px 20px;
  }
  .goal-content__container .left-container .blue-header {
    font-size: 27px;
  }
  .goal-content__container .modal-container .goal-content__container .right-container .header-container .header {
    font-size: 1.2em;
  }
  .goal-content__container .modal-container {
    top: 29px;
    width: 107%;
  }
}

@media only screen and (max-width: 500px) {
  div#our-approach.tfsa .column:last-of-type {
    padding-top: 30px !important;
  }
  .retirement-annuity.l-section.tfsa .btn-group.tfsa a.contact {
    line-height: 0.5;
  }
  .l-section.cta-tfsa h4.header__title {
    font-size: 1.325rem !important;
  }
}

@media only screen and (max-width: 414px) {
  body.modal-open {
    .goal-content__container .right-container {
      padding: 45px 20px 312px 20px;
    }
  }
  div#goal .header .sub-header {
    padding-bottom: 5px;
    font-weight: 700;
  }
  .hero-slide.hero-slide-0.tfsa .parent {
    padding-top: 2em !important;
  }
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    left: -4.5em ;
  }
  .goal-content__container .right-container .button-container a.btn.btn--large {
    width: 100%;
  }
  body.modal-open div#goal {
    padding-bottom: 12rem;
    padding-top: 5rem;
  }
  .button_container.mobile a {
    width: 100% !important;
    margin: auto !important;
  }
}

@media only screen and (max-width: 375px) {
  .goal-content__container .modal-header .header {
    padding-right: 19%;
  }
  .hero-slide.hero-slide-0.tfsa .ut_hero-text::after {
    top: 3em;
  }
}

@media only screen and (max-width: 320px) {
  .goal-content__container .right-container {
    padding: 45px 10px 107px 10px;
  }
  .goal-content__container .modal-header .header {
    padding-right: 13%;
  }
  .button_container.mobile a {
    font-size: 0.5rem !important;
  }
  body.modal-open .goal-content__container .right-container {
    padding: 45px 10px 258px 10px;
  }
  .goal-content__container .modal-header .header {
    padding-right: 26%;
  }
}


