/* -------------------------------------------------------------------------- *\
   #LOGOS
\* -------------------------------------------------------------------------- */

.logos {
    background-color: $color-white;
    padding: 1.5em 0 2em;
    text-align: center;

    /* Title */

    .logos__title {
        font-size: 1em;
        padding-right: 1.5em;
        padding-left: 1.5em;
    }

    .logos__body {
        animation: slow-scroll 12s linear infinite;
        animation-delay: 1s;
        overflow-x: auto;
        white-space: nowrap;
    }

    /* Item */

    .logos__item {
        display: inline-block;
        margin-top: 0.5em;
        margin-right: 1em;
        margin-left: 1em;
        opacity: 0.2;
        vertical-align: middle;
        width: 5em;

        /**
        * Wide
        */

        &.logos__item--wide {
            width: 8em;
        }

        /**
        * Alt
        */

        &.logos__item--alt {
            opacity: 1;
            width: 7em;
        }
    }
}

@media all and (min-width: $bp-small) {

    .logos {
        padding-top: 3em;
        padding-bottom: 3.5em;
        white-space: normal;

        .logos__title {
            font-size: 1.375em; /* 22 / 16 */
        }

        .logos__body {
            animation: none;
        }
    }
}

@keyframes slow-scroll {

    0% {
        margin-left: 0;
    }

    93% {
        margin-left: calc(100% - 960px);
        opacity: 1;
    }

    96% {
        margin-left: calc(100% - 960px);
        opacity: 0;
    }

    97% {
        margin-left: 0;
        opacity: 0;
    }

    100% {
        margin-left: 0;
        opacity: 1;
    }
}










