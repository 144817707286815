/* -------------------------------------------------------------------------- *\
   #MODAL
\* -------------------------------------------------------------------------- */

.modal {
    z-index: $z-index-modal;
}

.modal.fade .modal-dialog {
    opacity: 0;
    transition: opacity 0.25s;
}

.modal.in .modal-dialog {
    opacity: 1;
}

.modal-backdrop {
    z-index: $z-index-modal-backdrop;
}

.modal-content {
    border: 0;
    border-radius: 0;
}

.modal-header {
    border-bottom: 0;
    padding: 1.5em 1.5em 0 1.5em;
    position: relative;

    .modal-close {
        background-color: transparent;
        border: 0;
        color: $color-silver;
        cursor: pointer;
        font-size: 1.25em; /* 20 / 16 */
        position: absolute;
        right: 0.5em;
        top: 0.6875em;
        transition: color 0.25s;

        &:focus,
        &:hover {
            color: $color-black;
            outline: 0;
        }
    }
}

.modal-title {
    font-size: 1.25em; /* 20 / 16 */
}

.modal-body {
    padding: 1.5em;
}

.modal-footer {
    background-color: $color-white-dark;
    border-top: 0;
    padding: 1em;
}

.modal.modal--large .modal-dialog {
    max-width: 60em; /* 960 / 16 */
    width: auto;
 }

.modal.modal--transparent {

    .modal-content {
        background-color: transparent;
        box-shadow: none;
    }

    .modal-header {
        height: 3em;
        padding: 0;

        .modal-close {
            right: 0;
        }
    }

    .modal-body {
        padding: 0;
    }
}

@media all and (min-width: $bp-small) {

    .modal-dialog {
        margin-top: 4em;
    }

    .modal-header {
        padding-top: 2em;
        padding-right: 2em;
        padding-left: 2em;
    }

    .modal-title {
        font-size: 2em;
    }

    .modal-body {
        padding: 2em;
    }
}

/* -------------------------------------------------------------------------- *\
   #EXISTING CLIENT SECTION
\* -------------------------------------------------------------------------- */

.existingclient {
    margin: 0 -2em -2em -2em;
    padding: 2em;
    background: #103E72;;
    border-radius: 0 0 10px 10px;

    @media screen and (max-width: 739px) {
        margin: 0 -25px -25px -25px;
    }

    .whitetext {
        color: #ffffff;

        a {
            color: #ffffff;
        }
    }

    .center {
        text-align: center;
        margin-bottom: 1.5em;
    }

    .centered {
        text-align: center;
        display: block;
        margin: 0 auto;
        width: 50%;
    }
}