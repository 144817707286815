/**
 * BUTTON
 */

/* -------------------------------------------------------------------------- *\
   #TABLE OF CONTENTS
\* -------------------------------------------------------------------------- */

/**
 * BUTTON
 * DROPDOWN
 * GROUP
 */





/* -------------------------------------------------------------------------- *\
   #BUTTON
\* -------------------------------------------------------------------------- */

.btn {
    background-color: $color-green;
    border: 0.125em solid transparent;
    border-radius: 0.125em;
    box-shadow: 0 0.25em 0.5em rgba($color-green, 0.2);
    color: $color-white;
    cursor: pointer;
    display: inline-block;
    font-family: $font;
    font-size: 0.875em; /* 14 / 16 */
    font-weight: $weight-bold;
    height: 2.75em; /* 44 / 16 */
    line-height: 1;
    padding: 0.75em 1.25em;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow 0.25s, transform 0.25s;
    vertical-align: middle;
    white-space: nowrap;

    &.is-active,
    &:focus,
    &:hover {
        box-shadow: 0 0.25em 0.75em rgba($color-green, 0.6);
        outline: 0;
        transform: translateY(-0.125em);
    }

    /**
     * Disabled
     */

    &[disabled] {
        background-color: $color-silver;
        box-shadow: none;
        color: $color-white;

        &.is-active,
        &:focus,
        &:hover {
            background-color: $color-silver;
            box-shadow: none;
            color: $color-white;
            transform: translateY(0);
        }
    }

    /**
     * Icon
     */

    &.btn--icon {
        padding-right: 1em;
        padding-left: 1em;

        .icon {
            margin-left: 0.5em;

            &.icon--left {
                margin-right: 0.5em;
                margin-left: 0;
            }

            &.icon--only {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    /**
     * Secondary
     */

    &.btn--secondary {
        background-color: $color-blue;
        box-shadow: 0 0.25em 0.5em rgba($color-blue, 0.2);

        &.is-active,
        &:focus,
        &:hover {
            box-shadow: 0 0.25em 0.75em rgba($color-blue, 0.6);
        }
    }

    /**
     * Bordered
     */

    &.btn--bordered {
        background-color: transparent;
        border-color: $color-blue;
        box-shadow: 0 0.25em 0.5em rgba($color-blue, 0.2);
        color: $color-secondary;

        &.is-active,
        &:focus,
        &:hover {
            box-shadow: 0 0.25em 0.75em rgba($color-blue, 0.6);
        }

        &.is-white {
            color: $color-white;
        }
    }

    /**
     * Transparent
     */

    &.btn--transparent {
        background-color: transparent;
        box-shadow: none;
        padding-right: 0;
        padding-left: 0;

        &.is-active,
        &:focus,
        &:hover {
            box-shadow: none;
            color: $color-blue;
        }
    }

    /**
     * White
     */

    &.btn--white {
        background-color: transparent;
        border-color: $color-white;
        box-shadow: 0 0.25em 0.5em rgba($color-blue, 0.2);
        color: $color-white;

        &.is-active,
        &:focus,
        &:hover {
            box-shadow: 0 0.25em 0.75em rgba($color-secondary, 0.3);
        }
    }
}

@media all and (min-width: $bp-small) {

    .btn {
        font-size: 1em;

        /**
         * Large
         */

        &.btn--large {
            font-size: 1em; /* 18 / 16 */
            height: 3.25em; /* 54 / 18 */
            padding-top: 0.97em;
            padding-bottom: 0.97em;
        }
    }
}





/* -------------------------------------------------------------------------- *\
   #DROPDOWN
\* -------------------------------------------------------------------------- */

.btn-dropdown {
    display: inline-block;
    position: relative;

    /* Dropdown */

    .dropdown {
        background-color: $color-primary;
        box-shadow: 0 0.125em 2em rgba($color-secondary, 0.3);
        display: none;
        left: 0;
        min-width: 12.5em; /* 200 / 16 */
        padding: 1em;
        position: absolute;
        text-align: left;
        top: 110%;
        z-index: $z-index-btn-dropdown;

        &.is-open {
            display: block;
        }

        /* List */

        .dropdown__list {
            @include list();

            /* Item */

            .dropdown__item {
                padding-right: 0.375em;
                padding-left: 0.375em;

                &:not(:last-child) {
                    border-bottom: 0.125em solid rgba($color-white, 0.1);
                    margin-bottom: 0.75em;
                    padding-bottom: 0.75em;
                }
            }
        }

        /* Link */

        .dropdown__link {
            color: $color-white;
            display: block;
            text-decoration: none;
            transition: color 0.25s;

            &:focus,
            &:hover {
                color: $color-secondary;
            }
        }
    }
}





/* -------------------------------------------------------------------------- *\
   #GROUP
\* -------------------------------------------------------------------------- */

.btn-group {
    @include clearfix();
    margin-top: 2em;
    margin-bottom: -1em;
    width: 100%;

    /* Button */

    .btn {
        margin-bottom: 1em;
        width: 100%;
    }

    /* Dropdown */

    .btn-dropdown {
        margin-bottom: 1em;
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }

        .btn {
            float: left;
            margin-right: 0;
            margin-bottom: 0;
            width: auto;

            & + .btn {
                margin-left: 0.0625em;
            }
        }
    }

    /**
     * Flat
     */

    &.btn-group--flat {
        margin-top: 0;
    }

    /**
     * Right-aligned
     */

    &.btn-group--right {

        .btn-dropdown {
            margin-right: 1em;
            margin-left: 0;

            &:last-child {
                margin-right: 0;
            }

            .btn {
                margin-right: 0;
            }
        }
    }
}

@media all and (min-width: $bp-small) {

    .btn-group {
        margin-top: 4em;

        .btn {
            margin-right: 1em;
            width: auto;

            &:last-child {
                margin-right: 0;
            }
        }

        .btn-dropdown {
            margin-bottom: 0;
            margin-left: 1em;
            width: auto;

            &:first-child {
                margin-left: 0;
            }
        }

        &.btn-group--center {
            text-align: center;
        }

        &.btn-group--right {
            text-align: right;

            .btn {
                margin-right: 0;
                margin-left: 1em;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

.bottom-btn {
    position: absolute;
    right:0;
    bottom:0;
    width:100%;
    background-color: #222;
    color: #fff;
}

.hidden {
    display:none;
}

.second-card-btn {
    top: 5em !important;
}

.annual-review-link {
    display: inline-block;

    @media (max-width: $bp-small) {
        display: block;
        margin-bottom: 1em;
    }
}