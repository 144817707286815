/* -------------------------------------------------------------------------- *\
   #HEADER
\* -------------------------------------------------------------------------- */

.header {

    /**
     * Centered
     */

    &.header--centered {
        margin-bottom: 2em;
    }

    /**
     * Small
     */

    &.header--small {

        .header__title {
            font-size: 1.125em; /* 18 / 16 */
        }
    }

    .header__title.title--medium {
        font-size: 36px;
        margin: 20px auto;
    }

    /* Title */

    .header__title {
        font-size: 1.25em; /* 20 / 16 */
        margin-bottom: 0.75em;

        .icon {

            &.icon--info {
                background-color: $color-primary;
                border-radius: 100%;
                color: $color-white;
                display: inline-block;
                font-size: 14px; /* Use pixels to avoid resizing based on parent font-sizes */
                height: 1.5em;
                padding-top: 0.25em;
                text-align: center;
                text-decoration: none;
                vertical-align: middle;
                width: 1.5em;
            }
        }

        .tooltip {
            font-size: 14px; /* Use pixels to avoid resizing based on parent font-sizes */
        }
    }

    /* Label */

    .header__label {
        font-size: 0.5em;
        font-weight: $weight-light;
    }
}

@media all and (min-width: $bp-small) {

    .header {

        &.header--centered {
            margin-bottom: 3em;
            text-align: center;
        }

        &.header--small {
            margin-right: auto;
            margin-left: auto;
            max-width: 45em; /* 720 / 16 */

            .header__title {
                font-size: 1.875em; /* 30 / 16 */
            }
        }

        .header__title {
            font-size: 2.25em; /* 36 / 16 */
        }

        /* Synopsis */

        .header__synopsis {
            font-size: 1.125em; /* 18 / 16 */
        }
    }
}

@media all and (min-width: $bp-medium) {

    .header {

        &.header--small {

            .header__title {
                font-size: 2.25em; /* 36 / 16 */
            }
        }

        .header__title {
            font-size: 3em; /* 48 / 16 */
        }

        .header__synopsis {
            font-size: 1.25em; /* 20 / 16 */
        }
    }
}











