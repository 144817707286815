/* -------------------------------------------------------------------------- *\
   #BREADCRUMBS
\* -------------------------------------------------------------------------- */

.breadcrumbs {
    margin-bottom: 1em;

    /* List */

    .breadcrumbs__list {
        @include clearfix();
        @include list();

        /* Item */

        .breadcrumbs__item {
            color: $color-gray;
            float: left;
            font-weight: $weight-bold;
            margin-right: 1.5em;
            margin-bottom: 0;
            position: relative;

            &:after {
                content: "/";
                font-size: 1em;
                position: absolute;
                right: -0.875em;
                top: 0;
            }

            &:last-child {
                margin-right: 0;

                &:after {
                    content: "";
                    display: none;
                }
            }

            /**
             * Active
             */

            &.is-active {
                color: $color-gray;
            }
        }
    }

    /* Link */

    .breadcrumbs__link {
        border-bottom: 0.0625em solid transparent;
        color: $color-gray-dark;
        display: block;
        text-decoration: none;
        transition: border-color 0.25s, color 0.25s;

        &:focus,
        &:hover {
            border-bottom-color: $color-black;
            color: $color-black;
        }
    }
}
