/* -------------------------------------------------------------------------- *\
   # INSTITUTIONAL PAGE
\* -------------------------------------------------------------------------- */
.institutional-header {
  text-align: center;
}

.institutional-section {
  .sub-header {
    @media all and (max-width: $bp-small) {
      font-weight: 700;
      margin-bottom: 1.125em;
      letter-spacing: 2px;
    }
  }
}

.hero-institutional {
  height: 38vh !important;
  background: {
    image: url('/assets/images/heros/hero-institutional-mobile.jpg');
    repeat: no-repeat;
    size: cover;
    position: center;
  }
  justify-content: flex-end !important;

  @media all and (min-width: $bp-small) {
    justify-content: center !important;
    background: {
      image: url('/assets/images/institutional/institutional-banner.jpg');
    }

    &:before {
      display: none;
    }
  }

  @media all and (min-width: 940px) {
    height: 44vh !important;
  }

  .hero-one-percent-inner {
    width: 100%;
    opacity: .99;

    @media all and (min-width: $bp-small) {
      opacity: 1;
    }

    img {
      max-width: 80%;
      margin-bottom: 3em;

      @media all and (min-width: $bp-small) {
        display: none;
      }
    }

    .hero-one-percent-inner-left,
    .hero-one-percent-inner-right {
      display: none;
      width: 53%;

      @media all and (min-width: $bp-small) {
        display: block;
      }

      .hero__title {
        max-width: 100%;
        font: {

        }
        text-transform: none;
      }
    }

    .hero-one-percent-inner-left {
      width: 53%;
      padding-left: 1em;

      .hero__title {
        font: {
          size: 2.8em;
          weight: 700;
        }

        @media all and (min-width: 1365px) {
          font: {
            size: 3.2em;
          }
        }
      }
    }

    .hero-one-percent-inner-right {
      text-align: left;
      width: 54%;
      margin-left: 33%;

      @media all and (min-width: 1276px) {
        margin-left: 32%;
      }

      .hero__title {
        font: {
          size: 2.8em;
          weight: 300;
        }
        line-height: 1.1;

        @media all and (min-width: 1365px) {
          font: {
            size: 3em;
          }
        }

        @media all and (min-width: 1456px) {
          font: {
            size: 3.2em;
          }
        }
      }
    }
  }

  .hero-one-percent-btn-container {
    margin-bottom: 8%;

    @media all and (min-width: $bp-small) {
      margin-bottom: 0;
      text-align: left;
      margin-left: 51%;
      width: 50%;
      margin-top: 3em;
    }
  }
}

.industry-revolution {
  padding-bottom: 2em;

  h3,
  .hero-slider-btn-container {
    text-align: left !important;

    @media all and (min-width: $bp-small) {
      text-align: center !important;
    }
  }

  h3 {
    margin-bottom: .8em;
  }
}

.institutional-content-block {
  .content-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    @media all and (min-width: $bp-small) {
      display: block;
    }

    .content-block__img {
      order: 2;
      margin-top: 2.5em;
      margin-bottom: 0;

      @media all and (min-width: $bp-small) {
        margin-top: 0;
      }
    }
  }
}

.institutional-image {
  order: 1;
  margin-top: 2.5em;
  margin-bottom: 0;

  @media all and (max-width: $bp-medium) {
    display: none;
  }

  @media all and (min-width: $bp-small) {
    margin-top: 0;
  }
}


.l-section {
  &.for-business {
    .header {
      .header__title {
        @media all and (min-width: $bp-medium) {
          font-size: 2.5rem;
        }
      }
    }

    .content-block__synopsis {
      @media all and (min-width: $bp-small) {
        font-size: 1.125rem;
      }
    }

    .sub-header {
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 0.6rem;

      @media all and (min-width: $bp-small) {
        font-weight: 700;
        margin-bottom: 1.125em;
        letter-spacing: 2px;
      }
    }

    .content-block {
      .content-block__img {
        @media all and (min-width: $bp-large) {
          text-align: center;
          padding: 0;

          h4 {
            margin: {
              top: 2em;
              bottom: 1.5em;
            }
          }
        }

        .content-block__disclaimer {
          margin-top: 3em;

          p {
            padding: 0;
          }
        }
      }
    }

    .btn {
      display: block;
      height: auto;
      max-width: 16.25em;
      padding: 1.4em;

      @media all and (min-width: $bp-small) {
        margin: 0 auto;
      }
    }

    &#my10x-intro {
      padding: 0;

      @media all and (min-width: $bp-small) {
        padding-top: 2em;
        padding-bottom: 2em;
      }

      .hr-small {
        border: 0;
        border-top: 1px solid #333;
        display: block;
        padding: 0;
        width: 50%;
        margin: 0 auto;
        opacity: .3;
      }

      .content-block {
        padding: 3em 0;

        &.how-are-you-doing,
        &.whats-your-plan {
          .content-block__img {
            img {
              margin-left: -1em;

              @media all and (min-width: $bp-small) {
                margin-left: 0;
              }
            }
          }
        }

        &.whats-your-number,
        &.whats-your-plan {
          margin-top: 1em;

          @media all and (min-width: $bp-small) {
            margin-top: 1.5em;
          }
        }

        @media all and (min-width: $bp-small) {
          padding: 5em 0;
          margin-bottom: 0;

          &.whats-your-number,
          &.whats-your-plan {
            margin-top: 2em;
          }
        }

        &.whats-your-number {
          .content-block__img {
            @media all and (min-width: $bp-large) {
              float: left;
              margin-left: 0;
              margin-right: 5%;
            }
          }

          .content-block__body {
            @media all and (min-width: $bp-large) {
              float: right;
              margin-top: 8em;
            }
          }
        }

        .content-block__body {
          ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              position: relative;
              padding-left: 1.5em;
              font-size: 1.125rem;

              &:before {
                content: '•';
                display: inline-block;
                color: $color-blue;
                position: absolute;
                left: 0;
                font-size: 1.5em;
                top: -8px;
              }
            }
          }
        }
      }
    }
  }

  &#my10x-intro,
  &#my10x-introductions {
    .content-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      @media all and (min-width: $bp-small) {
        display: block;
      }

      .content-block__img {
        order: 1;
        margin-top: 2.5em;
        margin-bottom: 0;

        @media all and (min-width: $bp-small) {
          margin-top: 0;
        }
      }
    }
  }

  &#my10x-introductions {
    @media all and (min-width: $bp-small) {
      padding-top: 6em;
      padding-bottom: 4em;
    }

    .content-block {
      margin-bottom: 0;

      .content-block__img {
        text-align: left;

        @media all and (min-width: $bp-large) {
          text-align: center;
          float: left;
          margin-left: 0;
          margin-right: 5%;
        }

        img {
          max-width: 20em;
          margin-left: -3em;
        }
      }

      .content-block__body {
        @media all and (min-width: $bp-large) {
          float: right;
        }

        .content-block__synopsis {
          @media all and (min-width: $bp-small) {
            margin-bottom: .6em;
          }
        }

        .terms-link {
          display: inline-block;
          margin-bottom: 1.6em;
          color: $color-blue;
          font-size: .875rem;
          text-decoration: none;
          border-bottom: 1px solid;

          @media all and (min-width: $bp-small) {
            margin-bottom: 3.6em;
          }

          &:hover {
            border-color: $color-white;
          }
        }

        .btn {
          margin: 0;
        }
      }
    }
  }

  &#better-retirement {
    @media all and (min-width: $bp-small) {
      padding-top: 6em;
      padding-bottom: 6em;
    }

    .content-block {
      margin-bottom: 0;

      .header {
        margin: 0;

        .header__title {
          margin-bottom: 1.75em;

          @media all and (min-width: $bp-medium) {
            font-size: 2.5em;
          }
        }
      }
    }

    .info-blocks-2col {
      @media all and (min-width: $bp-medium) {
        display: flex;
      }

      .info-block-2col {
        display: flex;
        flex: 1;

          &:first-of-type {
            margin-bottom: 2em;

            @media all and (min-width: $bp-medium) {
              margin-right: 5em;
              margin-bottom: 0;
            }
        }

        .info-block-2col-inner {
          display: flex;
          flex-direction: column;
          background: $color-white;
          padding: 3em;
          box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);

          .info-block-2col-header {
            @media all and (min-width: $bp-xs) {
              display: flex;
              align-items: flex-start;
            }

            @media all and (min-width: $bp-small) {
              font-size: 1.5rem;
              margin-bottom: 1.4em;
            }

            .info-block-2col-title,
            .info-block-2col-number {
              display: inline-block;
              vertical-align: top;
            }

            .info-block-2col-title {
              line-height: 1.4;
              margin-top: -4px;
            }

            .info-block-2col-number {
              padding-right: .6em;
              padding-bottom: .6em;
              margin-right: 1.8em;
              border-bottom: 2px solid $color-blue;
              margin-bottom: 1em;

              @media all and (min-width: $bp-xs) {
                margin-bottom: 0;
              }
            }
          }

          .info-block-2col-content {
            flex-grow: 1;

            @media all and (min-width: $bp-small) {
              font-size: 1.12rem;
              margin-bottom: 2.4em;
            }
          }

          .info-block-2col-button {
            text-align: center;
          }
        }
      }
    }
  }

  &#existing-client {
    .content-block {
      margin-bottom: 0;

      .header {
        margin-bottom: 0;
      }
    }
  }

  &#faq {
    .header {
      .header__title {
        @media all and (min-width: $bp-medium) {
          font-size: 2.5em;
        }
      }
    }
  }
}

.pad-block {
  padding: 3em 0;
}

.break-button {
  height: 4.6em;
  line-height: 1.4;

  @media all and (min-width: 440px) {
    height: 2.75em;
    line-height: 1;

    br {
      display: none;
    }
  }
}

.fee-comparison-table-image {
  margin-top: 1em;
  box-shadow: 0 0.4em 2em rgba(32, 35, 37, 0.1);
}