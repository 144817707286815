/* -------------------------------------------------------------------------- *\
   #COLLAPSE
\* -------------------------------------------------------------------------- */

.collapse {
    display: none;

    /**
     * Open
     */

    &.is-open {
        animation: animation-fade 0.5s forwards;
        clear: both;
        display: block;
    }
}
