/* -------------------------------------------------------------------------- *\
   #COVID BANNER
\* -------------------------------------------------------------------------- */

#covid19-banner {
  background-color: #150031;

  &.closed {
    display: none;
  }

  .covid-19-banner-container {
    position: relative;

    .covid-19-banner-text {
      text-align: center;
      color: #00adef;
      padding: 26px 15px 20px;

      p {
        margin-bottom: 0;

        a {
          color: #00adef;
          border-bottom-color: #00adef;
          font-style: italic;
        }

        span {
          font-weight: 700;
        }
      }
    }

    .covid-19-banner-close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 15px;

      @media screen and (max-width: 425px){
        padding-right: 0px;
      }

      button {
        background-color: unset;
        border: unset;
        .icon {
          color: #00adef;
          font-size: 1.5em
        }
      }
    }
  }

}