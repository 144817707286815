/* -------------------------------------------------------------------------- *\
   #10X INTRODUCTIONS PAGE
\* -------------------------------------------------------------------------- */

#governance-logos {

  .row-small {
    margin-top: 0px;

    @media all and (min-width: $bp-medium) {
      margin-top: 2px;
    }

  }

  .row-medium {
    margin-top: 0px;

    @media all and (min-width: $bp-medium) {
      margin-top: 10px;
    }
  }

  .row-large {
    margin-top: 0px;

    @media all and (min-width: $bp-medium) {
      margin-top: 25px;
    }
  }

  .row-extra-large {
    margin-top: 0px;

    @media all and (min-width: $bp-medium) {
      margin-top: 30px;
    }
  }

  .no-margin-top {
    margin-top: 0px;
  }
}