/* -------------------------------------------------------------------------- *\
   #TABLE
\* -------------------------------------------------------------------------- */

.table {
    border-collapse: collapse;
    box-shadow: 0 0.125em 2em rgba($color-black, 0.1);
    font-size: 0.75em; /* 12 / 16 */
    margin: 2rem 0;
    width: 100%;

    /* Caption */

    .table__caption {
        font-size: 1.25em; /* 20 / 16 */
        font-weight: $weight-bold;
        margin-bottom: 1em;
        text-align: left;
    }

    /* Head */

    thead {
        display: table-header-group;

        th {
            background-color: $color-primary;
            color: $color-white;
            padding: 0.5em 1em;
            text-align: left;
        }
    }

    /* Body */

    tbody {

        tr {

            &:nth-child(odd) {
                background-color: #E7F0FF;
            }
            
            &:nth-child(even) {
                background-color: $color-white;
            }

            td {
                display: table-cell;
                @include clearfix();
                padding: 0.5em 1em;
            }
        }
    }

    /* Foot */

    tfoot {
        font-style: italic;

        tr {

            &:nth-child(odd) {
                background-color: inherit;
            }
        }

        td {
            padding: 0.5em 1em;

            a {
                @include anchor();
            }
        }
    }
}

/* Mobile */
@media only screen and (max-width: 767px) {	
    table.responsive { margin-bottom: 0; }

    .pinned { position: absolute; left: 0; top: 0; width: 35%; overflow: hidden; overflow-x: scroll; }
    .pinned table { border-right: none; border-left: none; width: 100%; }
    .pinned table th, .pinned table td { white-space: nowrap; border-right: 1px solid #ccc; }
    .pinned td:last-child { border-bottom: 0; }

    div.table-wrapper { position: relative; margin-bottom: 20px; overflow: hidden; }
    div.table-wrapper div.scrollable table { margin-left: 35%; }
    div.table-wrapper div.scrollable { overflow: scroll; overflow-y: hidden; }	

    table.responsive td, table.responsive th { position: relative; white-space: nowrap; overflow: hidden; }
    table.responsive th:first-child, table.responsive td:first-child, table.responsive td:first-child, table.responsive.pinned td { display: none; }
}

@media all and (min-width: $bp-small) {

    .table {
        font-size: 0.875em; /* 14 / 16 */
    }
}
