/* -------------------------------------------------------------------------- *\
   #PERSON
\* -------------------------------------------------------------------------- */

.person {
    text-align: center;

    /**
     * Left
     */

    &.person--left {
        @include clearfix();
        text-align: left;
        margin-bottom: 2em;

        img {
            float: left;
            margin-right: 1.5em;
            margin-bottom: 1em;
        }
    }

    /**
     * Small
     */

    &.person--small {

        img {
            width: 4.6875em; /* 75 / 16 */
        }
    }

    /* Image */

    img {
        border-radius: 100%;
        box-shadow: 0 0.125em 2em rgba($color-black, 0.1);
        display: inline-block;
        width: 6.25em; /* 100 / 16 */
    }

    /* Body */

    .person__body {
        margin-top: 1em;
    }

    /* Name */

    .person__name {
        font-size: 1.125em; /* 18 / 16 */
        font-weight: $weight-bold;
        line-height: 1;
        margin-bottom: 0.5em;
    }

    /* Title */

    .person__title {
        font-size: 0.75em; /* 12 / 16 */
        letter-spacing: 0.0625em;
        margin-bottom: 0.75em;
        text-transform: uppercase;
    }

    /* Synopsis */

    .person__synopsis {
        font-size: 0.875em; /* 14 / 16 */
        font-style: italic;
        margin-bottom: 0;
    }

    & + .blockquote {
        margin-top: 2em;
    }
}

@media all and (min-width: $bp-small) {

    .person {

        &.person--left {

            .person__body {
                margin-top: 0;
                padding-left: 8em;
            }
        }

        .person__name {
            font-size: 1.25em; /* 24 / 16 */
        }

        .person__title {
            font-size: 0.875em; /* 14 / 16 */
        }
    }
}
