/* -------------------------------------------------------------------------- *\
   #MAP
\* -------------------------------------------------------------------------- */

.map {
    height: 100%;
    margin-bottom: 1em;
    width: 100%;

    /* Image */

    .map__img {
        margin-bottom: 1em;
        width: 100%;

        &.is-hidden {
            display: none;
        }
    }

    /* Canvas */

    .map__canvas {
        border: 0;
        display: block;
        height: 100%;
        width: 100%;

        &.is-hidden {
            display: none;
        }
    }

    /* Button */

    .btn {
        width: 100%;

        &.is-hidden {
            display: none;
        }
    }
}

@media all and (min-width: $bp-small) {

    .map {
        background-color: $color-white-dark;
        height: auto;
        padding-bottom: 56.25%;
        position: relative;

        .map__img {
            margin-bottom: 0;
        }

        .map__canvas {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}
