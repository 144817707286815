/* -------------------------------------------------------------------------- *\
   #PULLQUOTE
\* -------------------------------------------------------------------------- */

.pullquote {
    padding-top: 2em;
    padding-bottom: 2em;

    /**
     * Centered
     */

    &.pullquote--centered {
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;
    }

    p {
        font-size: 1.5em;
        font-weight: $weight-bold;
        line-height: 1.5;
    }

    /* Synopsis */

    .pullquote__synopsis {
        font-size: 1em;
        font-weight: $weight-light;
        line-height: inherit;
        margin-bottom: 0;
    }

    .sub-header {
        color: $color-white;
    }
}

@media all and (min-width: $bp-small) {

    .pullquote {
        padding-top: 4em;
        padding-bottom: 4em;

        &.pullquote--centered {
            padding-top: 2em;
            padding-bottom: 2em;
        }

        p {
            font-size: 3em;
        }

        .pullquote__synopsis {
            font-size: 1.25em;
        }
    }
}










