/* -------------------------------------------------------------------------- *\
   #MY 10X PAGES
\* -------------------------------------------------------------------------- */

.my10x-hero {
  .hero-image-inner {
    margin: 7vh auto 0 !important;
    padding: 0 !important;

    .btn {
      margin: 3em 0;
    }
  }

  .video-play {
    .video-play-link {
      opacity: .99;
    }
  }
}

.l-section {
  &.my10x {
    .header {
      .header__title {
        @media all and (min-width: $bp-medium) {
          font-size: 2.5rem;
        }
      }
    }

    .content-block__synopsis {
      @media all and (min-width: $bp-small) {
        font-size: 1.125rem;
      }
    }

    .sub-header {
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 0.6rem;

      @media all and (min-width: $bp-small) {
        font-weight: 700;
        margin-bottom: 1.125em;
        letter-spacing: 2px;
      }
    }

    .content-block {
      .content-block__img {
        @media all and (min-width: $bp-large) {
          text-align: center;
          padding: 0;

          h4 {
            margin: {
              top: 2em;
              bottom: 1.5em;
            }
          }
        }

        .content-block__disclaimer {
          margin-top: 3em;

          p {
            padding: 0;
          }
        }
      }
    }

    .btn {
      display: block;
      height: auto;
      max-width: 16.25em;
      padding: 1.4em;

      @media all and (min-width: $bp-small) {
        margin: 0 auto;
      }
    }

    &#my10x-intro {
      padding: 0;

      @media only screen and (min-width:1400px) {
        padding-left: 10em;
        padding-right: 10em;
      }
      @media all and (min-width: $bp-small) {
        padding-top: 2em;
        padding-bottom: 2em;
      }

      .hr-small {
        border: 0;
        border-top: 1px solid #333;
        display: block;
        padding: 0;
        width: 50%;
        margin: 0 auto;
        opacity: .3;
      }

      .content-block {
        padding: 3em 0;

        &.how-are-you-doing,
        &.whats-your-plan {
          .content-block__img {
            img {
              margin-left: -1em;

              @media all and (min-width: $bp-small) {
                margin-left: 0;
              }
            }
          }
        }

        &.whats-your-number,
        &.whats-your-plan {
          margin-top: 1em;

          @media all and (min-width: $bp-small) {
            margin-top: 1.5em;
          }
        }

        @media all and (min-width: $bp-small) {
          padding: 5em 0;
          margin-bottom: 0;

          &.whats-your-number,
          &.whats-your-plan {
            margin-top: 2em;
          }
        }

        &.whats-your-number {
          .content-block__img {
            @media all and (min-width: $bp-large) {
              float: left;
              margin-left: 0;
              margin-right: 5%;
            }
          }

          .content-block__body {
            @media all and (min-width: $bp-large) {
              float: right;
              margin-top: 8em;
            }
          }
        }

        .content-block__body {
          ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              position: relative;
              padding-left: 1.5em;
              font-size: 1.125rem;

              &:before {
                content: '•';
                display: inline-block;
                color: $color-blue;
                position: absolute;
                left: 0;
                font-size: 1.5em;
                top: -8px;
              }
            }
          }
        }
      }
    }
  }

  &#my10x-intro,
  &#my10x-introductions {
    .content-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      @media all and (min-width: $bp-small) {
        display: block;
      }

      .content-block__img {
        order: 1;
        margin-top: 2.5em;
        margin-bottom: 0;

        @media all and (min-width: $bp-small) {
          margin-top: 0;
        }
      }
    }
  }

  &#my10x-introductions {
    @media all and (min-width: $bp-small) {
      padding-top: 6em;
      padding-bottom: 4em;
    }

    .content-block {
      margin-bottom: 0;

      .content-block__img {
        text-align: left;

        @media all and (min-width: $bp-large) {
          text-align: center;
          float: left;
          margin-left: 0;
          margin-right: 5%;
        }

        img {
          max-width: 20em;
          margin-left: -3em;
        }
      }

      .content-block__body {
        @media all and (min-width: $bp-large) {
          float: right;
        }

        .content-block__synopsis {
          @media all and (min-width: $bp-small) {
            margin-bottom: .6em;
          }
        }

        .terms-link {
          display: inline-block;
          margin-bottom: 1.6em;
          color: $color-blue;
          font-size: .875rem;
          text-decoration: none;
          border-bottom: 1px solid;

          @media all and (min-width: $bp-small) {
            margin-bottom: 3.6em;
          }

          &:hover {
            border-color: $color-white;
          }
        }

        .btn {
          margin: 0;
        }
      }
    }
  }

  &#better-retirement {
    @media all and (min-width: $bp-small) {
      padding-top: 6em;
      padding-bottom: 6em;
    }

    .content-block {
      margin-bottom: 0;

      .header {
        margin: 0;

        .header__title {
          margin-bottom: 1.75em;

          @media all and (min-width: $bp-medium) {
            font-size: 2.5em;
          }
        }
      }
    }

    .info-blocks-2col {
      @media all and (min-width: $bp-medium) {
        display: flex;
      }

      .info-block-2col {
        display: flex;
        flex: 1;

          &:first-of-type {
            margin-bottom: 2em;

            @media all and (min-width: $bp-medium) {
              margin-right: 5em;
              margin-bottom: 0;
            }
        }

        .info-block-2col-inner {
          display: flex;
          flex-direction: column;
          background: $color-white;
          padding: 3em;
          box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);

          .ie & {
            width: 100%;
          }

          .info-block-2col-header {
            @media all and (min-width: $bp-xs) {
              display: flex;
              align-items: flex-start;
            }

            @media all and (min-width: $bp-small) {
              font-size: 1.5rem;
              margin-bottom: 1.4em;
            }

            .info-block-2col-title,
            .info-block-2col-number {
              display: inline-block;
              vertical-align: top;
            }

            .info-block-2col-title {
              line-height: 1.4;
              margin-top: -4px;
            }

            .info-block-2col-number {
              padding-right: .6em;
              padding-bottom: .6em;
              margin-right: 1.8em;
              border-bottom: 2px solid $color-blue;
              margin-bottom: 1em;

              @media all and (min-width: $bp-xs) {
                margin-bottom: 0;
              }
            }
          }

          .info-block-2col-content {
            flex-grow: 1;

            @media all and (min-width: $bp-small) {
              font-size: 1.12rem;
              margin-bottom: 2.4em;
            }
          }

          .info-block-2col-button {
            text-align: center;
          }
        }
      }
    }
  }

  &#existing-client {
    .content-block {
      margin-bottom: 0;

      .header {
        margin-bottom: 0;
      }
    }
  }

  &#faq {
    .header {
      .header__title {
        @media all and (min-width: $bp-medium) {
          font-size: 2.5em;
        }
      }
    }
  }
}