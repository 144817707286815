/* -------------------------------------------------------------------------- *\
   #ARTICLE
\* -------------------------------------------------------------------------- */

.article {
    padding-bottom: 1em;

    /* Body */

    .article__body {

        /* Block Quote */

        blockquote {
            @include blockquote();
        }

        /* Figure */

        figure {
            @include figure();

            &:first-of-type {
                margin-top: 0;
            }
        }

        /* Horizontal Rule */

        hr {
            @include hr();
        }

        /* Lists */

        dl,
        ol,
        ul {
            @include list();
            margin-bottom: 1.5rem;

            dd,
            li {

                a {
                    @include anchor();
                }
            }
        }

        /* Ordered */

        ol {
            list-style: decimal;
            margin-left: 1.5rem;
        }

        /* Unordered */

        ul {
            list-style: square;
            margin-left: 1.5rem;
        }

        p {
            &:first-child {

                &:first-letter {
                    float: left;
                    font-size: 3.375em;
                    line-height: 1;
                    padding-right: 0.125em;
                }
            }
        }

        .card {
            margin-bottom: 2em;
        }
    }
}

@media all and (min-width: $bp-small) {

    .article {
        @include clearfix();

        .article__body {

            dl,
            ol,
            ul {
                font-size: 1.125em; /* 18 / 16 */

                dl,
                ol,
                ul {
                    font-size: 1em;
                }
            }

            p {
                font-size: 1.125em; /* 18 / 16 */
            }

            .u-pull--right {
                margin-left: 2em;
            }

            .u-pull--left {
                margin-right: 2em;
            }

            .card {
                float: left;
                margin-right: 2em;
                margin-left: -4em;
                max-width: 21.375em;

                p {

                    &:first-child {

                        &:first-letter {
                            float: none;
                            font-size: inherit;
                            line-height: inherit;
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        .social {
            position: fixed;
            right: 0;
            top: 10em;
            z-index: $z-index-social;
        }
    }
}
