/* -------------------------------------------------------------------------- *\
   #CARD
\* -------------------------------------------------------------------------- */

.card {
    box-shadow: 0 0.125em 2em rgba($color-black, 0.1);

    /* Body */

    .card__body {
        @include clearfix();
        padding: 1.5em;

        p {

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    /* Link */

    .card__link {
        color: inherit;
        display: block;
        text-decoration: none;
    }

    /* Image */

    .card__img {
        background: $color-secondary no-repeat center center;
        background-size: cover;
        position: relative;
    }

    /* Overlay */

    .card__overlay {
        background-color: rgba($color-secondary, 0.5);
        color: $color-white;
        height: 100%;
        padding: 2em;
        position: relative;
        width: 100%;
    }

    /* Title */

    .card__title {
        color: $color-white;
        font-size: 1.125em; /* 18 / 16 */
        margin-bottom: 0;
    }

    /* Sub Header */

    .sub-header {
        color: $color-white;
    }

    /* Button */

    .btn {
        width: 100%;
    }
}

@media all and (min-width: $bp-small) {

    .card {

        .card__title {
            font-size: 1.75em; /* 28 / 16 */
            line-height: 1.2;
        }
    }
}
