/* -------------------------------------------------------------------------- *\
   # INSTITUTIONAL PAGE
\* -------------------------------------------------------------------------- */

.comparison-clients-slider {
  opacity: 1;

  @media all and (min-width: $bp-small) {
    height: 16rem;
  }

  .clients-slide {
    opacity: 0;
    transition: .2s ease;

    &.tns-slide-active {
      opacity: 1;
    }

    .clients-slide-inner {
      display: flex;
      flex-direction: column;
      background: $color-green;

      @media all and (min-width: $bp-small) {
        flex-direction: row;
      }

      .clients-slide-image {
        height: 12.25rem;
        width: 100%;
        background: {
          repeat: no-repeat;
          size: cover;
          position: top center;
        }

        @media all and (min-width: $bp-small) {
          width: 16rem;
          min-width: 16rem;
          height: 16rem;
        }
      }

      .clients-slide-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-grow: 1;
        padding: 2.5rem 2rem;

        @media all and (min-width: $bp-small) {
          padding: 1.4rem;
        }

        .quote-icon {
          position: absolute;
          max-width: 2.75rem;
          left: 1.4rem;
          top: -1.375rem;

          @media all and (min-width: $bp-small) {
            max-width: 100%;
            left: -2.188rem;
            top: 50%;
            margin-top: -2.188rem;
          }
        }

        .clients-slide-blockquote {
          margin: 0;

          p {
            font-weight: 400;
            margin-bottom: 1rem;
          }

          @media all and (min-width: $bp-small) {
            max-width: 90%;

            p {
              font-weight: 400;
            }
          }

          p,
          cite {
            font-size: 1.125rem;
            color: $color-white;
          }

          cite {
            font-style: normal;
          }
        }

        .btn {
          display: none;
          position: absolute;
          bottom: 2.25rem;
          right: 2.25rem;
          border: 2px solid $color-white;

          @media all and (min-width: $bp-medium) {
            display: block;
          }
        }
      }
    }
  }
}

.fee-comparison-l-section {
  @media all and (min-width: $bp-small) {
    padding-top: 3em !important;
  }

  .header--centered {
    @media all and (min-width: $bp-small) {
      margin-bottom: 2em;
    }
  }
}

.hero-comparison {
  height: 38vh !important;
  background: {
    image: url('/assets/images/heros/comparison-bg-mobile.jpg');
    repeat: no-repeat;
    size: cover;
    position: center;
  }
  justify-content: center !important;

  @media all and (min-width: $bp-small) {
    height: calc(38vh) !important;
    background-image: url('/assets/images/heros/hero-comparison.png');
  }

  .hero-comparison-inner {
    width: 100%;

    img {
      max-width: 80vw;
      padding-top: 1em;

      @media all and (min-width: $bp-small) {
        display: none;
      }
    }

    .hero-comparison-inner-left,
    .hero-comparison-inner-right {
      display: none;

      @media all and (min-width: $bp-small) {
        display: block;
      }

      .hero__title {
        max-width: 100%;
        font: {
          size: 3vw;
          weight: 700;
        }
        line-height: 1.4;
        text-transform: none;
        text-shadow: none;
      }
    }

    .hero-comparison-inner-left {
      width: 49%;
      text-align: right;
      padding-left: 1em;
      padding-right: 0;
    }

    .hero-comparison-inner-right {
      width: 50%;
      text-align: left;
      margin-left: 50.7%;
    }
  }

  .hero-comparison-btn-container {
    margin-bottom: 8%;

    @media all and (min-width: $bp-small) {
      margin-bottom: 0;
      text-align: left;
      margin-left: 51%;
      width: 50%;
      margin-top: 3em;
    }
  }
}

.comparison-content-block {
  .content-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    @media all and (min-width: $bp-small) {
      display: block;
    }

    .content-block__img {
      order: 2;
      margin-top: 2.5em;
      margin-bottom: 0;

      @media all and (min-width: $bp-small) {
        margin-top: 0;
      }
    }
  }
}

.comparison-image {
  order: 1;
  margin-top: 2.5em;
  margin-bottom: 0;

  @media all and (max-width: $bp-medium) {
    display: none;
  }

  @media all and (min-width: $bp-small) {
    margin-top: 0;
  }
}

.comprison-hero__scroll {
  animation: bounce 3s infinite;
  color: black;
  font-size: 2em;
  margin-top: 1em;
  opacity: 0.7;
  text-align: center;
  z-index: $z-index-scroll;
}
