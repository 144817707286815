/**
 * CALCULATORS
 */

.ReactModal__Content {
  z-index: 1;
}

.pb-56 {
  padding-bottom: 0;
}

.Card_card__2oCA2 {
  padding-top: 0;
  padding-bottom: 10px;
}

#modals {
  position: absolute;
  min-height:85.25vh;
  max-height: 100vh;
  overflow: scroll;

  h3 {
    font-size: 1em;
    margin-bottom: 0.5rem;
  }

  .ReactModal__Content {
    display: grid;
    position: absolute;
    z-index: 1;
  }

  .PanelModal_panelmodal__1in1O {
    z-index: 0;
  }

  .pb-56 {
    padding-bottom: 12px;
  }

  .px-24 p {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .p-24 {
    padding: 18px;
    padding-bottom: 12px;
  }
}

.mx-auto {
  padding-top: 0;
}

.Button_button__2Iz0I {
  color: #00aeef;
}

.Button_primary__36M1h {
  margin-top:0;
  font-size:12px;
}

.Button_secondary__veQr_ {
  font-size:12px;
}

.px-24 {
  h2 {
    font-size: 18px;
    margin-top: 1rem;
  }
  p {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .Button_button__2Iz0I {
    color: #00aeef;
  }



  .mb-24 {
    padding: 4px !important;
  }
}

// LA Calculator

.calculator-page {
  .l-footer {
    display: none;

    @media (min-width: 860px) {
      display: block;
    }
  }

  .la .rc-tooltip-arrow {
    left: 50%;
    margin-left: -3px;
  }
}

#giraffe-calculator {
  &.la-calculator {
    @media all and (min-width: $bp-large) {
      margin-bottom: 60px;
    }

    .l-wrapper {
      @media all and (min-width: $bp-large) {
        height: 950px !important;
      }
    }

    .px-40.pt-40 {
      padding-top:0
    }

    .px-40 {
      padding-top: 0;
      padding-bottom: 0;
    }

    .b-40.text-left {
      padding-bottom: 0;
    }

    h2 { font-size: 1.5em !important; }

    .framing-row {
      margin-top: 14px;

      @media (min-width: 860px) {
        margin-top: 46px;
      }

      @media (min-width: 980px) {
        margin-top: 30px;
      }
    }

    .btn {
      font-weight: 600;
    }

    .swiper-nav-wrapper {
      .swiper-pagination {
        .past {
          background-color: #00aeef;
          opacity: 1;
        }
      }
    }

    .calc-content-header {
      width: 100%;
      padding: 28px 16px;

      @media (min-width: 860px) {
        padding: 36px 48px;
      }

      h4 {
        margin-bottom: 0;
        font-size: 20px;
        text-align: center;
        line-height: 30px;

        @media (min-width: 860px) {
          font-size: 22px;
        }
      }
    }

    .calc-content-wrapper {
      .calc-push-bottom {
        width: 100%;

        @media (max-width: 860px) {
          margin-top: 2em;
        }

        .btn {
          width: 100%;

          @media (min-width: 860px) {
            width: 360px !important;
          }
        }
      }
      .call-to-action {
        @media (max-height: 680px) {
          margin-top: 7em;
        }
        @media (max-height: 570px) {
          margin-top: 8em;
        }
      }
    }

    .swiper-wrapper {
      .swiper-slide {
        opacity: 0;
        transition: .2s;

        &.swiper-slide-active {
          opacity: 1;
        }

        &#calc-page-1-content {
          #giraffe-row {
            height: 320px;
            position: relative;
            background: {
              size: cover;
              repeat: no-repeat;
              position: center;
            }
            overflow: hidden;

            #giraffe-only,
            #speech-1,
            #speech-2 {
              position: absolute;
            }

            #giraffe-only {
              bottom: 0;
              left: 55%;
              -webkit-animation-delay: 1s;
              animation-delay: 1s;
              -webkit-animation-duration: 1.2s;
              animation-duration: 1.2s;
            }

            #speech-1 {
              left: 53%;
              top: 19px;
              -webkit-animation-delay: 2.5s;
              animation-delay: 2.5s;
              transform: translateY(69px);
              transition: .5s;
            }

            #speech-2 {
              left: 53%;
              top: 87px;
              -webkit-animation-delay: 4s;
              animation-delay: 4s;
            }
          }

          #giraffe-animation {
            width: 100%;
            height: auto;
            -webkit-backface-visibility:hidden;
            backface-visibility:hidden;

            #giraffe {
              -webkit-animation-delay: .5s;
              animation-delay: .5s;
              -webkit-animation-duration: 1.5s;
              animation-duration: 1.5s;

              .Safari & {
                transform: translateY(166px) translateZ(0) translate3d(0, 0, 0);
                transition: 1s;
                -webkit-transform: translateY(166px) translateZ(0) translate3d(0, 0, 0);
                -webkit-transition: 1s;
                -webkit-backface-visibility:hidden;
              }
            }

            #speech-1 {
              -webkit-transform: translateY(67px);
              -ms-transform: translateY(67px);
              transform: translateY(67px);
              -webkit-transition: .8s;
              -o-transition: .8s;
              transition: .8s;
              -webkit-animation-delay: 2s;
              animation-delay: 2s;
            }

            #speech-2 {
              -webkit-animation-delay: 3.7s;
              animation-delay: 3.7s;
            }
          }

          .text-animate {
            -webkit-animation-delay: 4.5s;
            animation-delay: 4.5s;
            -webkit-animation-duration: .8s;
            animation-duration: .8s;
          }

          .btn-animate {
            -webkit-animation-delay: 4.7s;
            animation-delay: 4.7s;
            -webkit-animation-duration: .8s;
            animation-duration: .8s;
          }

          .calc-intro-centered {
            padding: 2.1em 16px 0;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 2rem;

            @media (min-width: 860px) {
              padding: 2em 40px 0;
            }

            @media (max-width: 860px) {
              margin-bottom: 0;
            }
          }
        }

        &#calc-page-2-content {
          .calc-content-wrapper {
            align-items: center;
          }

          .calc-content-header {
            padding: 28px 16px;

            @media (min-width: 860px) {
              padding: 48px 48px 32px;
            }

            h4 {
              margin-bottom: 16px;
            }

            .calc-intro-centered {
              font: {
                size: 16px;
                weight: 400;
              }
              color: #A6ACB4;
              margin: 0;
            }
          }



          .info {
            width: 300px;
            margin-top: 12px;
          }

          .features {
            margin: {
              top: 0;
              bottom: 32px;
            }

            .feature {
              .feature__body {
                padding-left: 50px;
                line-height: 34px;

                .feature__synopsis {
                  font: {
                    size: 16px;
                    weight: 400;
                  }
                }
              }
            }

            .circle-text {
              height: 34px;
              width: 34px;
              line-height: 34px;
            }
          }

          #watch-our-ceo-video {
            p {
              padding-top: 8px;
              font-size: 14px;
              font-weight: 400;
              text-align: center;
            }

            a {
              display: block;
              position: relative;

              &:hover {
                cursor: pointer;
              }

              .ceo-thumb {
                width: 100%;
              }

              .play-icon {
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -28px;
                margin-top: -28px;
              }
            }
          }
        }

        &#calc-page-3-content {
          .calc-content-header {
            padding: 28px 16px;

            @media (min-width: 860px) {
              padding: 32px 48px;
            }
          }

          .hero {
            .hero__body {
              padding-top: 6em;
              padding-bottom: 5.2em;
            }
          }

          .pre-tax-slider {
            margin-top: 0;
            padding: 0 16px;

            @media (min-width: 860px) {
              margin-top: 20px;
              padding: 0 32px;
            }

            h4 {
              font-size: 18px;
              font-weight: 400;
              margin-bottom: 8px;
            }

            p {
              font-size: 14px;
              font-weight: 400;
              margin: 0;
            }

            small {
              display: block;
              font-size: 13px;
              margin-top: 36px;
            }

            #slider {
              margin-top: 24px;

              .slider-wrap {
                .slider-inner {
                  .slider-range {
                    margin: {
                      top: 0;
                      bottom: 2px;
                    }
                  }
                }

                .slider-number {
                  color: $color-primary;
                }
              }
            }
          }
        }

        &#calc-page-4-content {
          .row {
            height: 100%;

            @media (max-width: 860px) {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
            }
          }

          #livingAnnuityPlanEmbed {
            height: 100%;

            @media (max-width: 860px) {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
            }

            #vidOverlay {
              position: absolute;
            }

            .la-public {
              max-width: 100%;
              height: 100%;
              overflow: hidden;

              @media (max-width: 860px) {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                .Safari & {
                  height: unset;

                  .info-cont {
                    flex-grow: 1;
                  }
                }
              }

              .total-amount {
                font-weight: 700;
              }

              .main-graphs-container {
                height: 100%;
              }

              .reboron-parent {
                display: none !important;
              }

              @media (min-width: 860px) {
                height: 660px;
              }

              .bold {
                font-weight: 700;
              }

              .input-wrapper p label.radio-custom-label {
                padding: 8px 10px 8px 0;
              }

              .giraffe-container {
                z-index: 2;
              }

              .graph-switcher {
                a {
                  span  {
                    font-weight: 600;
                  }
                }
              }

              .graph-header {
                p {
                  font-weight: 400;
                }
              }

              .main-adjustment-container {
                p {
                  font-weight: 400;
                }

                .target {
                  width: 16px;
                  height: 16px;
                }

                .info {
                  display: inline-block;

                  svg {
                    top: -2px;
                  }
                }
              }

              .input-block {
                padding: 0 20px;
              }

              .info {
                svg {
                  top: 0;
                  left: 2px;
                }
              }

              .input-wrapper {
                padding: 0 20px;

                .info {
                  svg {
                    top: 3px;
                  }
                }
              }

              .radio-custom+.radio-custom-label:before {
                min-width: 22px;
                max-width: 22px;
                width: 22px;
                height: 22px;
              }

              .adjustments-container-drawer {
                .input-block {
                  .info {
                    width: 16px;
                  }
                }

                .vidlink {
                  font-weight: 600;
                }
              }

              .adjustments-container-drawer header {
                margin-bottom: 20px;
                padding: 0 20px;

                .info {
                  left: 10px;
                }
              }

              div.togrey {
                margin: {
                  top: 0;
                  bottom: 0;
                }
              }

              .graph-switcher a .icon {
                margin-top: 3px;
              }

              .adjustments-container {
                .slider-parent {
                  background: none;
                  border: none;

                  .slider {
                    border: none;
                  }
                }
              }
            }

            .card {
              box-shadow: none;
            }
          }
        }

        &#calc-page-5-content {
          .calc-content-header {
            padding: 36px 32px 16px;
            margin-bottom: 32px;
            border-bottom: 1px solid #CFD3D8;

            @media (max-width: 860px) {
              padding: 36px 16px 16px;
            }

            h4 {
              margin-bottom: 16px;
            }

            p {
              font-weight: 400;
              margin-bottom: 0;
            }

            .btn-group {
              button {
                font-weight: 400;
                font-size: 14px;
                color: #334152;

                @media (max-width: 860px) {
                  height: 40px;
                  padding: 10px;
                  line-height: 1.1;
                }
              }
            }
          }

          .btn-group {
            margin: {
              top: 0;
              bottom: 16px;
            }
          }

          .calc-push-bottom {
            p {
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 1em;
            }
          }

          .graph-row {
            position: relative;
            height: 211px;
          }

          .graph-wrapper {
            display: block;
            width: 536px;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -268px;
            text-align: center;
            overflow: hidden;

            &.graph-wrapper-equal {
              padding-top: 76px;

              .graph-circle {
                top: 32px;
              }

              .tenx {
                .tenx-inner {
                  height: 111px;
                }
              }
            }

            .graph-circle {
              height: 86px;
              width: 86px;
              background: #334152;
              border-radius: 50%;
              border: 2px solid #F7F9FF;
              box-shadow: 0 2px 17px rgba(0, 0, 0, .05);
              position: absolute;
              left: 50%;
              margin-left: -43px;
              top: 32px;
              overflow: hidden;
              display: inline-block;
              vertical-align: bottom;
              z-index: 1;
              animation-delay: 2.5s;

              .graph-circle-figure {
                display: block;
                line-height: 24px;
                color: white;
                font-weight: 700;
                font-size: 16px;
                margin-top: 15px;
              }

              .graph-circle-text {
                font-size: 12px;
                display: block;
                color: white;
                font-weight: 600;
                line-height: 1.1;
                margin-top: 2px;
              }
            }

            .industry-average {
              margin-right: 49px;
              width: 109px;
              display: inline-block;
              vertical-align: bottom;

              @media (max-width: 860px) {
                margin-right: 40px;
              }

              .industry-average-inner {
                background: #C5C9CE;
                width: 120px;
                height: 111px;
                margin-bottom: 24px;

                .industry-average-figure {
                  color: white;
                  display: inline-block;
                  font-weight: 600;
                  margin-top: 16px;
                  font-size: 17px;
                  animation-delay: 1.5s;
                }
              }

              .industry-average-text {
                color: #A6ACB4;
                font-size: 11px;
                display: block;
                line-height: 24px;
                position: absolute;
                left: 125px;
                width: 122px;
                bottom: 0;
                background: white;

                @media (max-width: 860px) {
                  left: 130px;
                }
              }
            }

            .tenx {
              display: inline-block;
              vertical-align: bottom;

              .tenx-inner {
                background: linear-gradient(to bottom, rgba(161,220,248,0.28) 0%,rgba(160,219,248,1) 1%,rgba(155,216,248,1) 6%,rgba(0,159,226,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#47a1dcf8', endColorstr='#009fe2',GradientType=0 );
                width: 120px;
                height: 187px;
                margin-bottom: 24px;

                .tenx-figure {
                  color: white;
                  display: inline-block;
                  font-weight: 600;
                  margin-top: 16px;
                  font-size: 17px;
                  animation-delay: 1.5s;
                }
              }

              .tenx-text {
                color: #00AEEF;
                font-size: 11px;
                display: block;
                line-height: 24px;
                background: white;
                position: absolute;
                right: 126px;
                width: 122px;
                bottom: 0;

                @media (max-width: 860px) {
                  right: 131px;
                }
              }
            }

            .graph-border {
              display: none;
              height: 1px;
              background: #C4C9CF;
              position: absolute;
              bottom: 23px;
              left: 0;
              width: 100%;
              vertical-align: bottom;

              &.display-graph-border {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}


// RA Calculator

html {
  &.calc-page {
    body {
      & > .modal-backdrop.show {
        display: none !important;
      }
    }

    .px-24 {
      font-size: 1em;
      h2 {
        font-size: 1em;
      }
    }

    @media all and (max-width: 860px) {
      height: 100%;

      body {
        height: 100%;

        .l-main {
          height: 100%;
          min-height: 800px;

          .l-wrapper {
            height: 100%;

            .calc-swiper {
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: column;

              .calc-box {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
              }
            }
          }
        }
      }
    }

    @media all and (max-width: 860px) and (max-height: 740px) {
      body:not(.Safari) {
        .l-main {
          .l-wrapper {
            height: unset;
          }
        }
      }
    }
  }
}

html,
body {
  overflow-x: hidden;
}

.l-footer {
  &.footer-hide {
    display: none !important;
  }
}

.giraffe-modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999999;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba(0,0,0,.5);
  font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;

  &#GoalAssumptions {
    .modal-dialog {
      @media (min-width: $bp-small) {
        width: auto;
        max-width: 1120px;
      }
    }
  }

  .modal-dialog {
    position: relative;
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0);
    transition: ease 200ms;

    .modal-content {
      position: relative;
      background-color: #fff;
      border: 1px solid rgba(55,68,86,.2);
      border-radius: 4px;
      -webkit-box-shadow: 0 7px 10px 0 rgba(55,68,86,.08);
      box-shadow: 0 7px 10px 0 rgba(55,68,86,.08);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      outline: 0;

      .modal-header {
        padding: 15px;
        border-bottom: 1px solid #e5e5e5;

        .close {
          float: right;
          font-size: 21px;
          font-weight: bold;
          line-height: 1;
          outline: 0;
          color: #000;
          text-shadow: 0 1px 0 #fff;
          opacity: 1;
          filter: alpha(opacity=100);
          padding: 0;
          cursor: pointer;
          background: transparent;
          border: 0;
          -webkit-appearance: none;
          margin-top: -2px;
        }

        .modal-title {
          margin: 0;
          font-size: 1.2em;
          font-weight: 600;
          line-height: 1.42857143;
        }
      }

      .modal-body {
        position: relative;
        padding: 15px;
        font-size: 14px;

        p {
          font-size: 15px;
          font-weight: 400;
          margin: 0;
        }
      }
    }
  }
}

.calc-swiper {
  transition: .2s;
  flex-grow: 1;

  &.calc-swiper-hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.calc-spinner {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
  transition: .2s;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #00aeef;
    border-radius: 50%;
    animation: calc-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #00aeef transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes calc-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

#giraffe-calculator {
  width: 100%;

  .swiper-container {
    width: 100%;

    @media all and (min-width: 860px) {
      width: 600px;
    }

    @media all and (max-width: 859px) {
      overflow: visible;
    }

    .swiper-slide {
      display: flex;
      flex-direction: column;
      height: auto;
      //height: 100%;
      box-sizing: border-box;
      width: 100% !important;

      @media all and (min-width: 860px) {
        width: 600px !important;
      }

      @media (max-width: 860px) {
        min-height: 600px;
      }
    }
  }

  svg {
    text,
    tspan {
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
    }
  }

  .swiper-nav-wrapper {
    width: 100%;
    margin: 0 auto;
    z-index: 0;
    position: relative;
    padding: 10px;
    text-align: center;
    border-top: 1px solid rgba(207, 211, 216, .6);

    @media all and (min-width: 860px) {
      max-width: 600px;
      display: block;
      border-top: none;
      padding: 20px;
    }

    .arrow-container {
      background: {
        size: 20px;
        repeat: no-repeat;
        position: center;
      }
      box-sizing: border-box;
      transition: .2s;

      @media all and (min-width: 860px) {
        position: absolute;
        bottom: 360px;
        background: none;
        padding: 20px;

        .ie & {
          bottom: auto;
          top: -370px;
        }
      }

      @media all and (min-width: $bp-medium) {
        margin: 0 !important;
      }

      img {
        opacity: 0;

        @media all and (min-width: 860px) {
          opacity: 1;
        }
      }

      &.arrow-hide {
        opacity: 0;
        pointer-events: none;

        img {
          opacity: 0;
          pointer-events: none;
        }
      }

      .nav-btn {
        display: none;
        transition: opacity .2s;

        &:hover {
          cursor: pointer;
        }
      }

      &.arrow-container-left {
        float: left;
        background: {
          image: url('../../../assets/images/calculator-introductions/arrow-left-m.svg');
        }

        @media all and (min-width: 860px) {
          background: none;
          float: none;
          left: -100px;

          .ie & {
            width: 90px;
            left: -110px;

            img {
              min-width: 41px;
              min-height: 41px;
            }
          }
        }
      }

      &.arrow-container-right {
        float: right;
        background: {
          image: url('../../../assets/images/calculator-introductions/arrow-right-m.svg');
        }

        @media all and (min-width: 860px) {
          background: none;
          float: none;
          right: -100px;

          .ie & {
            width: 90px;
            right: -110px;

            img {
              min-width: 41px;
              min-height: 41px;
            }
          }
        }
      }

      @media all and (min-width: 860px) {
        display: block;
      }
    }

    .swiper-pagination {
      background: #fff;
      position: relative;
      display: inline-block;
      margin-top: 4px;

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        padding: 0;
        margin: auto 8px;
        text-align: center;
        position: relative;
        background-color: rgba(174, 180, 189, .85);

        &.swiper-pagination-bullet-active {
          background-color: #00aeef;
        }

        @media all and (min-width: 860px) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  #retirementPlanEmbed {
    display: flex;
    flex-grow: 1;

    .pointer {
      cursor:pointer;
    }


    .slider-container .ui-slider .ui-slider-handle {
      border: 8px solid #334152;
      width: 23px;
      height: 23px;
      margin-top: -8px;

      .Firefox & {
        top: -7px;
        margin-top: -3px;
      }
    }

    .ie & {
      .graph-left {
        width: 390px;
        max-width: 390px;
      }

      .adjust-salary {
        .handle {
          &:after {
            display: none;
          }
        }
      }

      .video-container {
        width: 390px;
        max-width: 390px;

        video {
          width: 390px;
          max-width: 390px;
        }
      }

      .all-adjustments {
        height: 660px;
        max-height: 660px;
      }
    }

    .graph-container .graph .growth .projected .projected-value p {
      margin: -8px 5px 0 5px;
      font-size: 1rem;
      line-height: 1.4;
    }

    .main-graph-container {
      overflow: visible;
    }

    .tooltip {
      opacity: 1;
    }

    .inline-link {
      font-size: 16px !important;
    }

    .sliders-right header h2 {
      font-weight: 400;
    }

    .giraffe-modal {
      display: none !important;
    }

    .block-notification {
      max-width: 100%;

      p {
        font-size: 1rem;
        margin: 1em 0;
      }
    }

    .graph-left {
      h1 {
        font-size: 1.4em;
        padding: 0;
      }

      .h3 {
        margin: 1em 0;
        font-size: .9375rem;
        font-weight: 400;
      }
    }

    .flex-container {
      max-width: 100%;
      border: none;

      //@media all and (min-width: $bp-small) {
      //  max-height: 620px;
      //}
    }

    .video-container .your-goal p:first-child {
      line-height: 1.4;
    }

    .video-container .vertical-progress-container .projected p:first-child {
      line-height: 1.2;
    }

    .spinner-center {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -24px;
      margin-left: -24px;
    }

    .tabbed-buttons {
      a {
        height: 50px;
        padding-top: 10px;
      }
    }

    .slider-container,
    .slider {
      border: none !important;
    }

    .all-adjustments-link {
      &:after {
        margin-top: 1px;
      }
    }

    .adjustments-container {
      .bottom {
        @media (max-width: 520px) {
          bottom: -110%;
        }
      }
    }

    .all-adjustments {
      overflow-x: hidden;

      h2 {
        font-size: 1.44em;
        margin-bottom: 0;
      }

      p {
        font-size: inherit;
        margin: {
          top: .4em;
          bottom: 1.4em;
        }

        &.no-margin {
          margin: 0;
        }
      }
    }
  }

  .l-wrapper {
    width: 100%;

    @media all and (min-width: $bp-large) {
      width: 85%;
      height: 920px;

      .giraffe-final-slide & {
        height: 920px;
      }
    }
  }

  .framing-row {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 0;
    height: auto;

    @media all and (min-width: 860px) {
      margin-top: 46px;
      margin-bottom: 30px;
      height: auto;
    }

    @media all and (min-width: 980px) {
      margin-top: 30px;
      margin-bottom: 30px;
      height: auto;
    }

    .main-container {
      margin-left: auto;
      margin-right: auto;
      height: auto;
      flex-grow: 1;

      @media all and (min-width: 860px) {
        width: 600px;

        .giraffe-final-slide & {
          width: 710px;

          .swiper-container {
            width: 710px;

            .swiper-slide-active {
              width: 710px !important;
            }
          }
        }
      }

      @media all and (min-width: 1024px) {
        .giraffe-final-slide & {
          width: 800px;

          .swiper-container {
            width: 800px;

            .swiper-slide-active {
              width: 800px !important;
            }
          }

          .box {
            &.calc-box {
              height: 800px;
            }
          }
        }
      }

      &:not(.is-giraffe) {
        @media (max-width: 859px) {
          display: flex;
          flex-direction: column;
        }

        .swiper-container {
          @media all and (max-width: 859px) {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }

          .swiper-wrapper {
            @media all and (max-width: 859px) {
              flex-grow: 1;
            }
          }
        }
      }
    }

    .form-email-me {
      display: none;
    }

    .giraffe-final-slide & {
      .form-email-me {
        display: block;
      }

      .swiper-nav-wrapper {
        .arrow-container {
          &.arrow-container-left {
            @media (min-width: 860px) {
              left: -132px;
            }

            @media (min-width: 1024px) {
              left: -220px;
            }
          }
        }
      }
    }
  }

  #calc-page-6-content {
    .calc-content-wrapper {
      padding: 40px 60px;
      background: {
        image: url('../../../assets/images/calculator/background.svg');
        size: cover;
        position: left top;
      }

      @media (max-width: 860px) {
        padding: 32px 24px;
        background-position: -140px top;
      }

      .form-top-content {
        max-width: 420px;
        margin: 0 auto;
        color: $color-white;

        .form-top-title {
          font: {
            size: 30px;
            weight: 500;
          }
          letter-spacing: .5px;
          text-align: center;
          line-height: 1.3;

          @media (max-width: 860px) {
            font-size: 24px;
          }
        }

        .form-top-list {
          padding-left: 16px;
          margin: 36px auto 48px;
          font-size: 12px;
          font-weight: 400;
          line-height: 2;

          @media (max-width: 860px) {
            margin: 24px auto 36px;
          }

          .figure-wrap {
            background: $color-secondary;
            padding: 3px 6px;
            font-size: 11px;
            font-weight: bold;
            margin: 0 2px;
            border-radius: 3px;
            letter-spacing: .5px;
          }
        }
      }

      .form-email-me {
        .inputs-wrapper {
          background: rgba($color-white, .9);
          border-radius: 6px;
          padding: 32px 40px 36px;

          @media (max-width: 860px) {
            padding: 24px 24px 32px;
          }

          .form-row {
            display: flex;
            margin: 0 -20px;

            @media (max-width: 860px) {
              display: block;
              margin: 0;
            }

            .text-input {
              margin: 0 20px;

              @media (max-width: 860px) {
                margin: 0 0 20px;
              }
            }
          }

          .checkboxes-wrapper {
            margin-top: 36px;

            @media (max-width: 860px) {
              margin-top: 24px;
            }

            .checkbox-label {
              display: flex;
              align-items: center;
              margin: 0 0 16px;
              position: relative;

              &:last-child {
                margin: 0;
              }

              &:hover {
                cursor: pointer;

                .checkbox-input {
                  border-color: $color-primary;
                }
              }

              .checkbox-input {
                display: block;
                width: 18px;
                height: 18px;
                position: relative;
                background: $color-white;
                border: 1px solid #ddd;
                border-radius: 4px;
                transition: .1s;

                &:before {
                  content: '';
                  transform: rotate(45deg);
                  height: 12px;
                  width: 6px;
                  position: absolute;
                  left: 5px;
                  top: 1px;
                  border-bottom: 2px solid $color-gray-dark;
                  border-right: 2px solid $color-gray-dark;
                  opacity: 0;
                  visibility: hidden;
                  transition: .1s;
                }
              }

              .label {
                margin-left: -26px;
                padding-left: 34px;
                font-size: 12px;
                font-weight: 400;
                line-height: 1.2;

                a {
                  font-weight: 400;
                  text-decoration: none;
                }
              }

              .input {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 3px;

                &:checked + .checkbox-input {
                  border-color: $color-primary;

                  &:before {
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }
            }
          }

          .text-input {
            font-size: 15px;
            font-weight: 700;
            color: $color-gray-dark;

            @media (max-width: 860px) {
              margin-bottom: 20px;
              font-size: 13px;
            }

            .input {
              box-shadow: none;
              border-radius: 4px;
              border: 1px solid #ddd;

              &::placeholder {
                color: #aaa;
              }

              &:focus {
                border-color: $color-primary;
              }
            }
          }
        }

        .form-button-holder {
          margin-top: 40px;
          text-align: center;

          .btn {
            height: 60px;
            padding: 0 44px;
            font-weight: 300;
            font-size: 20px;
            box-shadow: 2px 4px 6px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .75);

            @media (max-width: 860px) {
              font-size: 18px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .ts_and_cs-link{
    color: #27b6ee;
    border-bottom: 0.0625em solid #27b6ee;
  }

  .calc-content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    .calc-push-bottom {
      margin: {
        top: auto;
        bottom: 1em;
      }
      padding: {
        left: 1em;
        right: 1em;
      }

      @media (max-height: 680px) {
        margin-bottom: unset;
        padding-bottom: 1em;
        padding-left: 1em;
        padding-right: 1em;
        height: 10vh;
        box-sizing: border-box;
        display: flex;
        width: 100%;
        min-width: 100%;
        flex-grow: 1;
        flex-direction: column;
        justify-content: flex-end;
      }

      @media all and (min-width: 860px) {
        margin-bottom: 2em;
        padding: {
          left: 0;
          right: 0;
        }
      }

      .btn {
        font-size: 1em;
        height: 3.25em;
        padding-top: 0.97em;
        padding-bottom: 0.97em;

        @media all and (min-width: 860px) {
          width: 360px !important;
        }
      }

      #next-goal-button {
        font-size: 1em;
        height: 3.25em;
        padding-top: 0.97em;
        padding-bottom: 0.97em;

        @media (max-height: 680px) {
          font-size: 1em;
          height: 3em;
          padding-top: 0.90em;
          padding-bottom: 0.90em;
        }

        @media all and (min-width: 860px) {
          width: 160px !important;
        }
      }
    }
  }

  .calc-content-text {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .adjustments-sliders-container {

    p:last-child {
      margin-bottom: 2em;
    }

  }

  .nav-btn.hide {
    visibility: hidden;
    pointer-events: none;
  }

  .features {
    margin-top: 1.3em;
    font-size: 17px;
  }

  .hero {
    background-color: #3F8FE3;
  }

  .hero__body {
    background-color: transparent;

    @media all and (min-width: 860px) {
      padding-top: 8em;
      padding-bottom: 2.5em;
    }
  }

  .bottom__panel {
    position: relative;
    margin-top: 5em;

    @media all and (min-width:$bp-medium) {
      margin-top: 0;
    }
  }

  .bottom__panel_large {
    margin-top: 35%;
    @media all and (max-width:$bp-medium) {
      margin-top: 5em;
    }
  }

  .block-display button{
    margin-bottom:10px;

    cursor: pointer; /* Pointer/hand icon */
    width: 65%;

    @media all and (max-width: 860px) {
      width: 100%;
    }
  }

  .btn-group {

    margin-top: 0.5em;
    // Button Group
    button {
      background-color: #FFF;
      border: 1px solid #E0EEF6; /* Green border */
      color: black; /* White text */
      padding: 10px 24px; /* Some padding */
      cursor: pointer; /* Pointer/hand icon */
      width: 50%;
      float: left; /* Float the buttons side by side */
      font-weight: 300;
      font-size: 85%;

      @media all and (max-width: 860px) {
        height: 50px;
      }

    }

    .selected {
      background-color: #e6f5fc;
      border-color: #e6f5fc;
    }

    button:focus {outline:0;}

    /* Clear floats (clearfix hack) */
    .btn-group:after {
      content: "";
      clear: both;
      display: table;
    }

    .btn-group button:not(:last-child) {
      border-right: none; /* Prevent double borders */
    }
  }


  //Icon Styles
  .icon.icon--info {
    display: inline-block;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border: none;

    &:before {
      content: url('../../../assets/images/calculator-introductions/info.svg');
    }
  }

  tab-btns {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  fa {
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .goal-subheader {
    color: #A6ACB4;
    margin-bottom: -7px;
    font-size: 0.75em !important;
  }



  .main-container {
    @media all and (max-width: $bp-medium) {
      margin-bottom:0;
    }
  }

  .p {
    @media all and (max-width: 860px) {
      font-size: 0.875em;
    }
  }

  .calc-intro-text {
    @media all and (max-width: 860px) {
      font-size: 0.675em;
    }
  }

  .video-btn {
    width: 60%;
    font-weight: 400;
    background-color: #FFF;
    border: 1px solid #60c39b;
    color: #60c39b;
    box-shadow: none;

    span {
      padding-right: 0.75em;
    }


    @media all and (max-width: 860px) {
      width: 100%;
    }
  }

  .btn {
    font-weight: 400;
  }

  .calc-intro-btn {
    width: 60%;
    font-weight: 400;

    @media all and (max-width: 860px) {
      width: 100%;
    }
  }

  .calc-intro-btn-next {
    width: 60%;
    font-weight: 400;

    @media all and (max-width: 860px) {
      width: 100%;
    }
  }

  .calc-intro-btn goal{
    padding-left: 8em;
    padding-right: 8em;

    @media all and (max-width: 860px) {
      padding-left: 2em;
      padding-right: 2em;
    }
  }

  .circle-text {
    height: 28px;
    width: 28px;
    line-height: 28px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background: #00AEEF;
    color: #fff;

    .ie & {
      line-height: 26px;
    }
  }

  .slider-wrap {
    display: flex;
    align-items: baseline;
    padding-top: 4px;

    .slider-inner {
      flex-grow: 1;
      padding-right: 30px;
    }
  }

  .rand-input {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #f3f4f5;
    bottom: 0px;
    width: 55px;
    text-align: center;
    font-size: 1.25em;
    border: 1px solid #d8dbdf;
    line-height: 50px;
    color: #858f9d;

    @media all and (min-width: 740px) {
      line-height: 50px;
    }

    @media all and (max-width: 739px) {
      line-height: 35px;
    }
  }

  .input--underlined {
    padding-left: 75px;
    margin-left:0;
    border: 1px solid #d0d3d5;

    &#calc-ret-savings {
      padding-left: 42px;

      @media all and (min-width: 740px) {
        padding-left: 72px;
      }
    }
  }

  .nav-btn-wide {
    position: relative;
    outline:0;
  }

  .nav-btn {
    outline:0;
  }

  .row .col.col--1-6 {
    width: 10%;
  }

  #calc-page-2-content {
    .calc-intro-row {
      display: flex;
    }
  }

  .lumpsum-content,
  .monthly-income-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    svg {
      max-width: 100%;
    }
  }

  .box.calc-box {
    padding:0;
    height: 100%;
    border: none;
    box-shadow: none;
    background: none;

    & > div {
      height: 100%;
    }

    @media all and (min-width: 860px) {
      height: 790px;
      box-shadow: 0 0.125em 2em rgba(32, 35, 37, 0.1);
    }
  }

  #la-calc-intro {
    .box.calc-box {
      @media all and (min-width: 860px) {
        height: 800px;
        box-shadow: 0 0.125em 2em rgba(32, 35, 37, 0.1);
      }

    }
  }

  .final-slide {
    @media all and (min-width: 860px) {
      height: 650px !important;
    }
  }

  .boxed {
    border: 1px solid #c8cdd3;
    background-color: #f1f2f4;
    padding: 1.2em;

    @media all and (max-width: 860px) {
      background-color: #fff;
    }
  }

  .calc-intro-header {
    text-align: center;
    font-size: 1.2em;
    padding-left: 1em;
    padding-right: 1em;

    @media all and (min-width: $bp-medium) {
      font-size: 1.5em;
    }

    @media all and (min-width: 860px) {
      font-size: 1.2em;
      padding-left: 2em;
      padding-right: 2em;
    }
  }

  .calc-intro-subheader {
    font-size: 3em;
    text-transform: none;

    @media all and (max-width: $bp-medium) {
      font-size: 2em;
    }
  }

  hr {
    border-top:0.175em solid #cfd3d7 !important;
    margin-bottom:1em;
    margin-top:0;
    max-width:8.75em;

    @media all and (max-width: $bp-medium) {
      max-width: 8.75em;
    }

    @media all and (max-width: 860px) {
      max-width: 5.75em;
    }
  }

  .calc-intro-subheader-2 {
    text-transform:none;
    color: #334152;
    margin:0;
    font-size:1.75em;
    font-weight:600;
    line-height: 1.5em;

    &.blue {
      color: #00aeef;
      text-transform: none;
      letter-spacing: 2px;
      font-weight: 400;
    }

    @media all and (max-width: $bp-medium) {
      font-size: 1.5em;
    }

    @media all and (max-width: 860px) {
      font-size: 1.25em;
    }
  }

  .row {
    &.smalltopmargin {
      margin-top: 6px;
      h4 {
        font-weight: 600;
        font-size: 1.125em;
        @media all and (max-width: 860px) {
          font-size: 16px !important;
        }
      }
      p {
        font-size: 16px;
        line-height: 1.6;

        @media all and (max-width: 860px) {
          font-size: 12px;
        }
      }
    }
    &.calc-intro-row {
      margin-top:0;

      .col {
        @media all and (max-width: 860px) {
          margin-bottom: 5%;
        }
      }
    }
  }

  .page-content {
    height: 100%;
    padding: 1em;

    @media all and (min-width: 860px) {
      padding: 2em;
    }
  }

  .mobile-pad {
    @media all and (max-width: 860px) {
      padding-left: 1em !important;
      padding-right: 1em !important;
    }
  }

  .p.calc-intro {
    margin-bottom:0.5em;
    font-size:1em;

    @media all and (max-width: 860px) {
      font-size: 0.875em;
    }
  }

  .calc-intro-centered {
    text-align: center;
    margin-bottom: 1.2rem;
    font-size: 1rem;

    @media all and (max-width: $bp-medium) {
      font-size: 0.875em;
    }
  }

  .lumpsum-modal {
    margin-bottom: 0.75rem;
    margin-top: 0.75em;
  }

  .sub-header.calc-intro {
    font-size:3em;
    text-transform: none;
  }

  .confirm-text {
    font-size: 1em;

    @media all and (max-width: 860px) {
      font-size: 1.1em;
      margin-bottom: 1em !important;
    }
  }

  .calc-slider-container {

    #slider{
      margin-top: 30px;
    }

    .ui-widget-header {
      background: #00AEEF;
    }

    .ui-widget-content {
      background: #CCEFFC;
      border: none;
      border-radius: 10px;
      height: 7px;
      margin-bottom: 10px;
      margin-top: 88px;
    }

    .ui-slider .ui-slider-handle {
      width: 35px;
      height: 35px;
      position: absolute;
      top: -15px;
      cursor: pointer;
      border-radius: 50%;
      outline: none;
      margin-left: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;

      &.ui-state-hover {
        cursor: pointer;
      }

      &.ui-state-active {
        img {
          transform: scale(1.2);
        }
      }

      img {
        margin-left: -1px;
        transition: .1s;

        .ie & {
          min-width: 27px;
          min-height: 27px;
        }
      }

      .value-tooltip {
        position: absolute;
        background: #fff;
        padding: 10px 18px;
        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
        top: -58px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
        left: -15px;
        color: #333;
        white-space: nowrap;

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -6px;
          left: 50%;
          margin-left: -6px;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #fff;
        }
      }
    }
  }


  a {
    text-decoration: none;
  }

  .panel-inactive {
    display: none;
  }

  .calc_rands {
    float: none !important;
    position: relative;

    &:before {
      top: 0 !important;
    }
  }
}

#retirement {
  @media all and (min-width: 860px) {
    padding-bottom: 6rem;
  }

  .box {
    min-height: 100%;

    @media all and (min-width: 860px) {
      min-height: 400px;
      height: 100%;
      margin-bottom: 4rem;
    }
  }

  .form__body {
    margin-bottom: 0;
  }

  .btn-group {
    margin-top: 40px;
  }
}

.calc_rands {
  display: block !important;
  position: relative;
  float: none !important;

  &:before {
    content: 'R';
    margin-right: 0;
    padding-top: 0;
    width: 37px;
    height: 37px;
    position: absolute;
    top: 0;
    font-size: 16px;
    font-weight: 500;
    background: #F3F4F5;
    display: flex;
    justify-content: center;
    line-height: 34px;
    color: #5B697D;
    border: 1px solid #d0d3d5;

    @media (min-width: $bp-small) {
      line-height: 52px;
      border: 2px solid #d0d3d5;
      top: 0;
      width: 56px;
      height: 56px;
      font-size: 21px;
    }
  }

  .input {
    float: none !important;
    width: 100%;
    padding-left: 42px;
    border: 1px solid #d0d3d5;
    height: 37px;
    margin-top: 0 !important;

    @media (min-width: $bp-small) {
      height: 56px;
      padding-left: 64px;
      border: 2px solid #d0d3d5;
    }
  }

  &.calc_rands-alt {
    &:before {
      @media (min-width: $bp-small) {
        border: 1px solid #d0d3d5;
        top: 0;
      }
    }
  }
}

#home-calculator {
  padding-top: 4em;
  padding-bottom: 4em;

  @media (min-width: $bp-small) {
    padding-top: 4.5em;
    padding-bottom: 4.5em;
  }

  .header__title {
    margin-bottom: 1.75em;
  }

  .form {
    line-height: 2.8;

    @media all and (min-width: 860px) {
      text-align: center;
    }

    .input {
      width: 4em;

      &.input--underlined {
        border: {
          top: none;
          left: none;
          right: none;
          bottom: 0.125rem solid $color-primary;
        }
        color: $color-primary;
        font-weight: 600;
        text-align: center;
        text-align-last: center;
        line-height: 1.2;
      }
    }

    .select-wrapper {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 10px solid $color-primary;
        pointer-events: none;
      }

      select {
        width: 14em;
        appearance: none;
        -webkit-appearance: none;

        .ie & {
          option {
            &[disabled] {
              background: $color-white !important;
            }

            &:hover {
              background: $color-primary;
              color: $color-white;
            }
          }
        }

        span {
          text-align: center;
        }
      }
    }

    .form__rands {
      display: block;

      @media all and (min-width: 860px) {
        display: inline-block;
      }

      .input {
        width: 10em;
      }
    }

    .btn {
      min-width: 8em;
    }
  }
}

#ret-savings-error {
  display: flex;
  font-size: 0.875em;
  margin-top: 2em;
  font-weight: 400;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);

  span {
    background: #E87070;
    padding: 12px 16px;
    width: 48px;
    max-width: 48px;

    .ie & {
      width: 54px;
      max-width: 54px;
    }

    img {
      width: 16px;
      min-width: 16px;
    }
  }

  .ret-savings-error-inner {
    width: 100%;
    padding: 12px 16px;
    border: {
      top: 1px solid #eee;
      bottom: 1px solid #eee;
      right: 1px solid #eee;
    }
    line-height: 1.7;
    color: #727B87;
  }
}

#calc-page-1-content {
  .calc-intro {
    margin-top: .5em;
  }
}

#calc-page-4-content {
  .slider-number {
    width: 70px;
    text-align: right;
  }
}

#modal-call-me {
  select::-ms-expand {
    display: block;
  }
}

.pad-calc-input {
  @media (min-width: 1286px) {
    margin-bottom: 44px;
  }
}

.download-pdf-modal {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  .modal-backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color-black, .6);
    z-index: 0;
  }

  .modal-inner {
    width: 100%;
    max-width: 720px;
    margin: 128px auto 0;

    @media (max-width: 1200px) {
      margin: 92px auto 0;
    }

    @media (max-width: 860px) {
      margin: 88px auto 0;
    }

    .modal-content {
      width: calc(100% - 32px);
      padding: 32px 40px;
      margin: 0 16px;
      position: relative;
      background: {
        image: url('../../../assets/images/calculator/success-background.svg');
        size: cover;
        position: center;
      }
      box-shadow: none;
      text-align: center;

      @media (max-width: 860px) {
        padding: 32px;
      }

      .modal-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 8px 16px;
        cursor: pointer;

        .modal-close-img {
          width: 20px;
        }
      }

      .modal-title {
        line-height: 1.3;
        color: $color-secondary;
        font-size: 30px;

        @media (max-width: 860px) {
          font-size: 24px;
        }
      }

      .modal-image {
        min-width: 280px;

        @media (max-width: 860px) {
          max-width: 200px;
          min-width: unset;
        }
      }

      .modal-text {
        margin: 20px 0 0;
        color: $color-secondary;
        font-size: 18px;
        font-weight: bold;

        @media (max-width: 860px) {
          margin: 16px 0 0;
          font-size: 15px;
        }

        a {
          color: $color-secondary;
          border-color: $color-secondary;
        }
      }
    }
  }
}
