/**
 * LAYOUT
 */

/* -------------------------------------------------------------------------- *\
   #TABLE OF CONTENTS
\* -------------------------------------------------------------------------- */

/**
 * WRAPPER
 * HEADER
 * MAIN
 * FOOTER
 */





/* -------------------------------------------------------------------------- *\
   #WRAPPER
\* -------------------------------------------------------------------------- */

.l-wrapper {
    @include clearfix();
    margin-right: auto;
    margin-left: auto;
    max-width: 71.25em; /* 1140 / 16 */
    position: relative;
    width: 85%;

    /**
     * Medium
     */

    &.l-wrapper--medium {
        max-width: 55em; /* 880 / 16 */
    }

    /**
     * Small
     */

    &.l-wrapper--small {
        max-width: 45em; /* 720 / 16 */
    }

    /**
     * Top Margin
     */

    &.has-top-margin {
        animation: animate-margin-top 0.25s;
        margin-top: 2em;
    }

    &.has-bottom-margin {
        margin-bottom: 3em;
    }
}

@media all and (min-width: $bp-small) {

    .l-wrapper {
        width: 85%;
    }
}

@keyframes animate-margin-top {

    0% {
        margin-top: 3em;
    }

    100% {
        margin-top: 2em;
    }
}





/* -------------------------------------------------------------------------- *\
   #HEADER
\* -------------------------------------------------------------------------- */

.l-header {
    @include clearfix();
    min-height: 4.75em;
    padding: 0.75em;
    width: 100%;
    z-index: 12;
    position: fixed;
    background: none;
    transition: top 0.25s, transform 0.25s, background 0.25s;

    &.scrolled {
        background: $color-secondary !important;
    }

    &.force-bg {
        background: $color-secondary !important;
    }

    &.open {
        background: $color-secondary !important;
    }

    .ie & {
        background: $color-secondary !important;
    }

    .l-header__body {
        padding: 3em 0.5em 0.7em 0.5em;
    }

    /* Nav */

    .nav {

        .nav__link {
            border-bottom: 0;
        }

        .menu {

            .menu__link {
                border-bottom: 0;
            }
        }
    }

    /* Logo */

    .l-header__logo {
        position: absolute;
        width: 12em;
        top: -.1em;
        left: .75em;
        z-index: $z-index-logo;

        &.desktop-logo {
            display: none;
        }

        &.light_logo {
            margin-top: -0.75em;
        }
    }

    /* Utils */

    .l-header__utils {

        /**
        * Header with no navigation
        */

        &.no-nav {
            margin-top: 0;
        }

        .btn {
            margin-top: 1.5em;
            width: 100%;

            &.header-btn-mobile {
                display: none;
            }
        }
    }

    /* Link */

    .l-header__link {
        @include anchor($color: $color-white);
        font-size: 1em;
        margin-right: 1.625em;
        font-weight: 600;
        padding-bottom: 0.15em;
        border: {
            color: $color-primary;
            width: 0.125em;
        }

        &:hover,
        &:focus {
            color: $color-primary;
        }
    }

    .l-header__toggle {
        float: right;

        .btn {
            z-index: 12;

          &.light_toggle {
              color: #00aeef;
              background-color: #ffffff;
              box-shadow: 0 0.25em 0.5em rgba(0, 174, 239, 0.2);
          }
        }
    }
}

@media all and (min-width: $bp-xs) {

    .l-header {
        background: none;
    }

}

@media all and (min-width: $bp-small) {

    .l-header {
        .l-header__logo {
            width: 9em;
            top: 0.536em;
            left: .8em;

            &.mobile-logo {
                display: none;
            }

            &.desktop-logo {
                display: block;
            }
        }
    }

}

@media all and (min-width: $bp-large) {

    .l-header {
        position: fixed;
        background: none !important;
        padding: 1.5em 2.5em;
        transition: top 0.25s, transform 0.25s, background 0.25s;

        .l-header__logo {
            top: 1.3em;
            left: 2.5em;
        }

        &.scrolled {
            background: $color-secondary !important;
        }

        .nav {
            width: auto;
        }
    }
}

@media all and (min-width: 1024px) and (max-width: $bp-large) {
    .l-header {
        .nav {
            .nav__item {
                margin-left: 1em !important;

                &:first-child {
                    display: none !important;
                }

                &:nth-child(2) {
                    margin-left: 0 !important;
                }
            }
        }

        .l-header__logo {
            top: 0.8em !important;
        }

        .l-header__utils {
            .l-header__link {
                font-size: .9em;
                margin-right: 1em;
            }

            .btn {
                margin-right: 0 !important;

                &.header-btn-desktop {
                    display: none;
                }

                &.header-btn-mobile {
                    display: block;
                }
            }
        }
    }
}

@media all and (min-width: 1024px) {

    .l-header__toggle {
        display: none;
    }

    .l-header__body {
        display: block !important;
    }

    .l-header {
        .l-header__body {
            padding: 0;
        }

        .nav {
            float: left;
            padding: {
                left: 11.5em;
                top: 0.75em;
            }
            width: auto;

            &.light_menu {
                padding-left: 12.5em;
            }
        }

        .l-header__logo {
            top: 1.536em;

            &.light_logo {
                top: 0.75em !important;
                width: 11.5em;
            }
        }

        .l-header__utils {
            float: right;
            padding-left: 2em;
            padding-top: 0;
            width: auto;

            &.light_utils {
                padding-left: 0;
                .l-header__link {
                    border-color: #ffffff !important;
                }
                .btn {
                    background-color: #ffffff !important;
                    color: #00aeef !important;
                }
            }

            &.no-nav {
                float: right;
                margin-top: 1em;
                padding-left: 2em;
                width: auto;
            }

            .btn {
                margin-top: 0;
                width: auto;

                &:not(:last-child) {
                    margin-right: 1em;
                }
            }
        }
    }

}


/* -------------------------------------------------------------------------- *\
   #MAIN
\* -------------------------------------------------------------------------- */

.l-main {
    &.l-main-pad {
        padding-top: 3em;

        @media all and (min-width: $bp-medium) {
            padding-top: 4em;
        }

        @media all and (min-width: $bp-large) {
            padding-top: 5.5em;
        }

        @media all and (min-width: 1310px) {
            padding-top: 6em;
        }
    }
}

@media all and (min-width: $bp-large) {

    .l-main {
        padding-top: 0;
    }
}



/* -------------------------------------------------------------------------- *\
   #HERO
\* -------------------------------------------------------------------------- */

.l-hero {
    padding: 2em;
    background: rgba($color-primary, 0.5);
}

@media all and (min-width: $bp-small) {

    .l-hero {
        padding: 5em 2em 5em 5em;
       margin-left: 40%; 
    }
    
}

@media all and (min-width: $bp-xl) {

    .l-hero {
       margin-left: 60%; 
    }
    
}

/* -------------------------------------------------------------------------- *\
   #SECTION
\* -------------------------------------------------------------------------- */

.l-section {
    padding-top: 3em;
    padding-bottom: 3em;

    /**
     * Image
     */

    &.l-section--img {
        background: $color-secondary no-repeat center center;
        background-size: cover;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;

        /* Body */

        .l-section__body {
            background-color: rgba($color-white-dark, 0.8);
            height: 100%;
            padding-top: 3em;
            padding-bottom: 3em;
            position: relative;
            width: 100%;

            /**
             * Dark
             */

            &.l-section__body--dark {
                background-color: rgba($color-secondary, 0.7);
                color: $color-white;
                text-shadow: 0 2px 10px rgba($color-black, 0.2);
            }
        }
    }

    /**
     * Blue
     */

    &.l-section--blue {
        background-color: $color-blue;
        color: $color-white;
    }

    /**
     * Blue
     */

    &.l-section--light-blue {
        background-color: #E5F6FD;
    }

    /**
     * Light
     */

    &.l-section--light {
        background-color: $color-white-dark;
    }

    /**
     * Dark
     */

    &.l-section--dark {
        background-color: $color-secondary;
    }

    /**
     * Flat
     */

    &.l-section--flat {
        padding-bottom: 0;
    }

    /* Button */

    .l-section__btn {
        margin-top: 1em;
    }
}

@media all and (min-width: $bp-small) {

    .l-section {
        padding-top: 4em;
        padding-bottom: 4em;

        &.l-section--img {

            .l-section__body {
                padding-top: 4em;
                padding-bottom: 4em;
            }
        }

        /**
         * Fixed
         */

        &.l-section--fixed {
            background-attachment: fixed;
        }

        .l-section__btn {
            margin-top: 2em;
            text-align: center;
        }
    }
}







/* -------------------------------------------------------------------------- *\
   #FOOTER
\* -------------------------------------------------------------------------- */

.l-footer {
    @include clearfix();
    background-color: $color-secondary;
    color: $color-white;
    padding-top: 3em;
    padding-bottom: 3em;

    .footer-logo {
        margin-bottom: 3em;
    }

    /* Wrapper */

    .l-wrapper {
        width: 85%;
    }

    /* Anchor */

    a {
       @include anchor($color: inherit);
        border-bottom: none !important;
    }

    /* List */

    ul {
        font-size: 0.75em; /* 12 / 16 */
    }

    /* Item */

    .l-footer__item {
        display: inline-block;
        margin-bottom: 3em;
        padding-right: 0.5em;
        padding-left: 0.5em;
        vertical-align: top;
        width: 49%;
    }

    /* Label */

    .l-footer__label {
        color: $color-white;
        font-weight: $weight-bold;
        margin-bottom: 1.25em;
    }

    /* Legal */

    .l-footer__legal {
        border-top: 0.063em solid rgba($color-white, 0.2);
        color: $color-gray;
        padding-top: 3.125em;
        margin-top: 2.5em;

        .l-footer__legal__inner {
            @include clearfix();
            margin-bottom: 1em;

            @media all and (min-width: $bp-medium) {
                .copyright {
                    float: left;
                    margin-bottom: 0;
                }

                .list {
                    float: right;
                }
            }



            .list {
                li {
                    a {
                        position: relative;
                        transition: color 0.25s;

                        &:after {
                            background-color: $color-primary;
                            bottom: -0.5em;
                            content:"";
                            height: 0.15em;
                            left: 0;
                            position: absolute;
                            transform: scaleX(1);
                            transition: transform 0.25s ease-in-out;
                            width: 100%;
                        }

                        &:focus,
                        &:hover {
                            color: $color-white;

                            &:after {
                                transform: scaleX(0);
                            }
                        }
                    }
                }
            }
        }

        p {
            font-size: 0.75em;
            color: rgba(255, 255, 255, .5);
            margin: 0;
        }
    }

    /* Social */

    .social {
        margin-top: 1.5em;
    }

    .footer_social {
        .social__list {
            display: flex;
            align-items: flex-end;
            list-style: none;
            padding: 0;
            margin: 3em 0 0;

            li {
                margin-right: 1em;

                &:last-child {
                    margin-right: 0;
                }

                &:nth-child(4) {
                    a {
                        img {
                            margin-bottom: -2px;
                        }
                    }
                }

                a {
                    border: none;
                    opacity: .5;
                    transition: .1s;

                    &:hover,
                    &:focus {
                        opacity: 1;
                    }

                    img {
                        max-height: 20px;
                    }
                }
            }
        }
    }

    .copyright {
        display: block;
        margin-top: 2em;
        font: {
            size: 0.8em;
            weight: 600;
        }
        color: rgba(255, 255, 255, .5);
    }
}

@media all and (min-width: $bp-xs) {

    .l-footer {
        padding-top: 3.75em;
        padding-bottom: 3.75em;
    }
}

@media all and (min-width: $bp-small) {

    .l-footer {

        .list {
            font-size: 1em;

            li {
                margin-bottom: 0.7em;

                &:nth-child(2) {
                    .icon {
                        font-size: 1.2em;
                        margin-left: -0.1em;
                        margin-right: 0.6em;
                    }
                }

                a {
                    border-bottom: none;
                    color: rgba(255, 255, 255, .5);
                    font-weight: 500;

                    &:hover,
                    &:focus {
                        color: $color-white;
                    }
                }

                .icon {
                    font-size: 1.4em;
                    margin-right: 0.5em;
                    margin-top: 0.2em;
                    float: left;
                }
            }
        }

        .l-footer__item {
            width: 24.5%;
            margin-bottom: 1em;
        }
    }
}
