.investment-choices{
    display: flex;
    flex-direction: row;
    gap: 50px;
    width: 85%;
    
    .header-title{
        font-weight: 300;
        font-size: 16px;
        margin-top: 45px;
    }
    .content-section {
        width: 50%; 
        margin-left: -10%;
        p {
            font-size: 15px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            .dot {
                height: 15px;
                width: 15px;
                padding: 7px;
                margin-right: 10px;
                border: 2px solid antiquewhite;
                background: #00d4ff;
                background: linear-gradient(77deg, rgba(0, 212, 255, 0.940235) 1%, #009ad4 59%, #179aff 92%);
                border-radius: 50%;
                display: inline-block;
            }
        }
   }
   .investments-mobile{
       display: none;
   }
    .investments{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: -180px;
        width: 70%;
        a{
            color: #00aeef;
            margin-left: 70px;
        }
        img {
            height: auto;
            //max-width: 130%;
            //margin-left: 70px;
        }
    }
}

.l-section.l-section--img.ripple {
    background: #2189ec no-repeat center center;
    background-size: 100%;
    background-blend-mode: multiply;
    padding-top: 30px;
    position: relative;
    height: 270px;
    .quote{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        p {
            font-size: 2em;
            color: white;
        }
    }
    a#call-me-back-middle {
        border-radius: 40px;
        border: 1px solid;
    }
}

//Background Change for Logos on Living anunuity

.logos.living-annuity.l-section.l-section--light {
    background: white;
}

@media (min-width: 700px) and (max-width: 1000px) {
    .investment-choices{
       
        .content-section {
            margin: 0;
       }
       .investments-mobile{
        display: none;
    }
    }
    .l-section.l-section--img.ripple {
      
        height: 165px;
        .quote p {
            font-size: 1.2em;
        
        }
    }
    
}

@media (max-width: 699px){

    .investment-choices{
        display: flex;
        flex-direction: column;
        width: 85%;
        gap: 0;
        .content-section {
            width: 100%; 
            margin-left: 0;
       }
        .investments{
            display: none;
           
        }
        .investments-mobile{
            display: block;
            svg#Layer_2 {
                width: 120%;
            }
            a{
                margin-left: 17%;
                color: #00aeef;
            }
        }
    }
    .l-section.l-section--img.ripple {
        background-size: cover;
        background-blend-mode: multiply;
        padding-top: 0;
        position: relative;
        height: 130px;
        background-position: center;
        .quote p {
            font-size: 1.2em;
            margin-bottom: 10px;
        }
    }
}

.content-block__table {
    .table {
        tbody {
            tr {
                td {
                    display: table-cell;
                    padding: 0.5em 1em;
                    text-align: center;
                }
            }
        }
    }
}

a#submit-btn {
    margin-bottom: 30px;
}