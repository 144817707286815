/* -------------------------------------------------------------------------- *\
   #CONTENT BLOCK
\* -------------------------------------------------------------------------- */

.content-block {
    @include clearfix();
    margin-bottom: 1.5em;

    /* Image */

    .content-block__img {
        box-shadow: 0 0.125em 2em rgba($color-black, 0.1);
        margin-bottom: 1.5em;

        img {
            width: 100%;
            max-width: 30em;
        }

        img.small{
            width: 80%;
        }

        &.is-transparent {
            box-shadow: none;
        }
    }

    /* Features */

    .content-block__features {
        margin-bottom: 1.5em;
    }

    /* Disclaimer */

    .content-block__disclaimer {
        margin-top: 1.5em;

        p {
            font-size: 0.75em;
            font-weight: $weight-normal;
            margin-bottom: 0;
            padding-left: 2.75em;
        }

        .icon {

            &.icon--info {
                background-color: $color-gray;
                border-radius: 100%;
                color: $color-white;
                float: left;
                font-size: 14px; /* Use pixels to avoid resizing based on parent font-sizes */
                height: 1.5em;
                margin-top: 0.125em;
                margin-right: 0.25em;
                padding-top: 0.25em;
                text-align: center;
                text-decoration: none;
                width: 1.5em;
            }
        }
    }

    /**
     * Flat
     */

    &.content-block--flat {
        margin-bottom: 0;
    }


    &.content-block--secondary {
        .content-block__table {

            .table {
                margin: 2.5rem 0 0 0;
            }
        }
        .table-terms{
            font-size: 0.75em;
            margin: 20px 0 0 0;
        }
    }

}

@media all and (min-width: $bp-small) {

    .content-block {
        margin-bottom: 3em;

        .content-block__img {
            float: left;
            margin-right: 5%;
            width: 35%;
        }


        .content-block__features {
            float: left;
            margin-right: 5%;
            width: 45%;
        }

        .content-block__synopsis {
            font-size: 1.25em; /* 20 / 16 */
            margin-bottom: 1.5em;
        }

        /**
         * Opposite
         */

        &.content-block--opposite {

            .content-block__img,
            .content-block__features {
                float: right;
                margin-right: 0;
                margin-left: 5%;
            }
        }

        &.content-block--secondary {
            .content-block__table {
                float: right;
                margin-right: 5%;
                width: 55%;

                .table {
                    margin-bottom: 0;
                }
            }

            .content-block__paragraph {
                float: left;
                margin-right: 0;
                width: 35%;

                p {
                    font-size: 1.125rem;
                }
                a.btn.btn--large {
                    border-radius: 10px;
                }
            }
        }
    }
}

@media (min-width: 1199px) and (max-width: 1210px) {
    .content-block {

        .content-block__img {
            width: 100% !important;

            &.content-block__img--offset {
                padding-top: 4em !important;
            }

            &.is-transparent {
                margin-bottom: 0;
                padding: 2em;
            }
        }

        .content-block__body {
            float: left;
            width: 100% !important;

            /**
             * Offset
             */

            &.content-block__body--offset {
                padding-top: 2.5em;
            }
        }

        &.content-block--secondary {
            .content-block__table {
                float: right;
                margin-right: 5%;
                width: 43%;
            }

            .content-block__paragraph {
                float: left;
                margin-right: 0;
                width: 45%;
            }
        }
    }
}

@media all and (min-width: $bp-large) {

    .content-block {

        .content-block__img {
            width: 45%;

            &.content-block__img--offset {
                padding-top: 4em !important;
            }

            &.is-transparent {
                margin-bottom: 0;
                padding: 2em;
            }
        }

        .content-block__body {
            float: left;
            width: 45%;

            /**
             * Offset
             */

            &.content-block__body--offset {
                padding-top: 2.5em;
            }
        }

        &.content-block--secondary {
            .content-block__table {
                float: right;
                margin-right: 5%;
                width: 43%;
            }

            .content-block__paragraph {
                float: left;
                margin-right: 0;
                width: 45%;
            }
        }
    }
}




//Added Media Queries

@media (min-width: 46.25em) {
    .content-block {
        margin-bottom: 0;
    }
}

@media (max-width: 500px) {
    .header-table .header__title {
        font-size: 20px !important;
    }
    .content-block.content-block--secondary.table-content {
        display: flex;
        flex-direction: column-reverse !important;
    }
}