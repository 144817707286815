/**
 * FORMS
 */

/* -------------------------------------------------------------------------- *\
   #TABLE OF CONTENTS
\* -------------------------------------------------------------------------- */

/**
 * FORM
 * FIELDSET
 * LABEL
 * INPUT
 * HELP TEXT
 * TOGGLE PASSWORD
 */





/* -------------------------------------------------------------------------- *\
   #FORM
\* -------------------------------------------------------------------------- */

.form {
    @include clearfix();

    /**
     * Inline
     */

    &.form--inline {

        .label {
            margin-bottom: 1em;

            &.label--inline {

                .input {
                    margin-bottom: 0;
                }
            }
        }

        .input {
            margin-top: 0;
            margin-bottom: 1em;
        }
    }

    /**
     * Sentence
     */

    &.form--sentence {

        .input {
            display: inline-block;
            margin-right: 0.125em;
            margin-left: 0.125em;
            width: 6em;
        }
    }

    /**
     * Single
     */

    &.form--single {
        position: relative;

        .input {
            margin-top: 0;
            padding-right: 2.75em;

            &.input--large {

                & + .btn {
                    position: absolute;
                    right: 0.375em;
                    top: 0.375em;
                    width: auto;
                }
            }
        }

        .btn {
            width: 100%;
        }
    }

    /* Body */

    .form__body {
        @include clearfix();
        margin-bottom: 2em;
    }

    /* Phrase */

    .form__phrase {
        font-size: 1.125em; /* 18 / 16 */
    }

    /* Rands */

    .form__rands {
        display: inline-block;
        margin-right: 0.25em;
        margin-left: 0.25em;

        &:before {
            color: $color-gray-dark;
            content: 'R';
            display: inline-block;
            font-size: 1.125rem; /* 18 / 16 */
            font-weight: $weight-light;
        }

        &.form__rands--inline {
            display: block;

            &:before {
                float: left;
                margin-right: 0.25em;
                padding-top: 0.5em;
                width: 0.75em;
            }

            .input {
                float: left;
                width: calc(100% - 1.5em);
            }

            &.is-percentage {

                &:before {
                    content: "%";
                    float: right;
                    margin-right: 0;
                    margin-left: 0.25em;
                    text-align: right;
                }
            }
        }
    }
}

@media all and (min-width: $bp-small) {

    .form {

        &.form--single {

            .input {

                &.input--large {

                    & + .btn {
                        right: 0.6875em;
                        top: 0.6875em;
                    }
                }
            }

            .btn {
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
            }
        }
    }
}

@media all and (min-width: $bp-medium) {

    .form {

        &.form--inline {

            .label {
                clear: none;
                float: left;
                margin-right: 0.5em;
                width: auto;

                &.label--inline {
                    margin-bottom: 0;
                    padding-top: 0.5625em;

                    .input {
                        margin-top: 0.25em;
                    }
                }
            }

            .input {
                float: left;
                margin-top: 0;
                margin-right: 0.5em;
                margin-bottom: 0;
                width: auto;
            }

            .btn {
                float: left;
                margin-right: 0;
            }
        }

        &.form--sentence {
            text-align: center;
        }

        &.form--single {

            .input {
                min-width: 20em; /* 320 / 16 */
            }
        }

        .form__phrase {
            font-size: 1.75em; /* 28 / 16 */
        }

        .form__rands {

            &:before {
                font-size: 1.75rem; /* 28 / 16 */
            }
        }
    }
}





/* -------------------------------------------------------------------------- *\
   #FIELDSET
\* -------------------------------------------------------------------------- */

.fieldset {
    border: 0;
    margin: 0 0 1em 0;
    padding: 0;

    /* Legend */

    .legend {
        color: $color-gray-dark;
        font-weight: $weight-bold;
        margin-bottom: 1em;
    }

    /**
     * Bordered
     */

    &.fieldset--bordered {
        border: 0.125em solid $color-silver;
        padding: 0.5em 1.5em 0 1.5em;

        .legend {
            margin-bottom: 0.5em;
            padding-right: 0.5em;
            padding-left: 0.5em;
        }
    }

    /**
     * Inline
     */

    &.fieldset--inline {
        @include clearfix();

        /* Label */

        .label {
            clear: none;
            float: left;
            margin-right: 1.5em;
            margin-bottom: 1em;
            width: auto;

            &.no-float {
                float: unset !important;
            }

            &:last-child {
                margin-right: 0;
            }

            .input {
                margin-top: 0;
            }
        }
    }
}





/* -------------------------------------------------------------------------- *\
   #LABEL
\* -------------------------------------------------------------------------- */
.subscribe-consent {
    display: flex !important;
    align-items: baseline;

    @media all and (max-width: $bp-xs) {
        align-items: flex-start;
        .input[type="checkbox"] {
            margin-top: 0.375em !important;
        }
    }

}

.privacy-policy {
    display: flex !important;
    align-items: baseline;
    margin-top: 1em;
    margin-bottom: 0 !important;

    @media all and (max-width: $bp-small) {
        margin-top: 2em;
    }

    @media all and (max-width: $bp-xs) {
        align-items: flex-start;
        .input[type="checkbox"] {
            margin-top: 0.375em !important;
        }
    }

    .input{
        min-width: 5px !important;
        margin-top: 2em !important;
    }

}


.label {
    clear: both;
    color: $color-black;
    display: block;
    font-size: 0.875em; /* 14 / 16 */
    margin-bottom: 2em;
    width: 100%;

    /* Anchor */

    a {
        @include anchor();
    }

    /* Link */

    .label__link {
        float: right;
        font-size: 0.875em; /* 14 / 16 */
        font-weight: $weight-normal;
        margin-top: 0.125em;

        &.label__link--icon {
            background-color: $color-primary;
            border-bottom: 0;
            border-radius: 100%;
            color: $color-white;
            height: 1.375em;
            line-height: 1;
            padding-top: 0.125em;
            text-align: center;
            width: 1.375em;
        }
    }

    /**
     * Disabled
     */

    &.label--disabled {
        color: $color-gray;
        cursor: not-allowed;
    }

    /**
     * Inline
     */

    &.label--inline {
        font-weight: $weight-normal;
    }

    /**
     * Error
     */

    &.has-error {
        color: $color-red;

        .input {
            border-color: $color-red;
        }

        .help-text {
            color: $color-red;
        }
    }

    /**
     * Success
     */

    &.has-success {
        color: $color-green;

        .input {
            border-color: $color-green;
        }

        .help-text {
            color: $color-green;
        }
    }

    /**
     * Warning
     */

    &.has-warning {
        color: $color-orange;

        .input {
            border-color: $color-orange;
        }

        .help-text {
            color: $color-orange;
        }
    }
}

@media all and (min-width: $bp-small) {

    .label {
        font-size: 1em;
    }
}





/* -------------------------------------------------------------------------- *\
   #INPUT
\* -------------------------------------------------------------------------- */

.input,
.hs-input {
    background-color: $color-white;
    border: 0;
    border-bottom: 0.125em solid transparent;
    border-radius: 0;
    box-shadow: 0 0.125em 1em rgba($color-black, 0.1);
    color: $color-gray-dark;
    font-family: $font;
    font-size: 0.875em; /* 14 / 16 */
    font-weight: $weight-light;
    height: 2.75em; /* 44 / 16 */
    line-height: 1;
    margin-top: 0.375em;
    padding: 0.75em 1em;
    transition: border-color 0.25s;
    width: 100%;

    &:focus,
    &:hover {
        border-bottom-color: $color-primary;
        outline: none;
    }

    /**
     * Checkbox | Radio
     */

    &[type="checkbox"],
    &[type="radio"] {
        height: auto;
        margin-top: 0;
        margin-right: 0.25em;
        width: auto;
    }

    /**
     * Disabled
     */

    &[disabled] {
        background-color: $color-white-dark;
        border-color: $color-white-dark;
        color: $color-gray;
        cursor: not-allowed;

        &:focus,
        &:hover {
            border-color: $color-white-dark;
        }
    }

    /**
     * Readonly
     */

    &[readonly] {
        background-color: $color-white-dark;
        cursor: not-allowed;
    }

    /**
     * File
     */

    &.input--file {
        height: auto;
    }

    /**
     * Large
     */

    &.input--large {
        font-size: 1.125em; /* 18 / 16 */
    }

    /**
     * Select
     */

    &.input--select {
        border: 0.125em solid $color-silver;
        padding-top: 0;
        padding-bottom: 0;

        &:focus,
        &:hover {
            border-color: $color-primary;
        }
    }

    /**
     * Textarea
     */

    &.input--textarea {
        min-height: 8em;
        resize: vertical;
    }

    /**
     * Underlined
     */

    &.input--underlined {
        background-color: transparent;
        border: 0.0625em solid $color-silver;
        box-shadow: none;
        height: auto;
        padding: 0.25em 0.375em;
    }
}

@media all and (min-width: $bp-small) {

    .smallsize {
        font-size: 0.9em;
    }

    .input {
        font-size: 1em;

        &.input--large {
            font-size: 1.75em; /* 28 / 16 */
        }
    }
}

/**
 * Remove the inner shadow that appears in text inputs on iOS/OSX
 */

input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    margin: 0;
}

/**
 * Remove right padding on IE 10+ (to fix alignment of arrow)
 */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    .input {

        &.input--select {
            padding-right: 0;
        }
    }
}





/* -------------------------------------------------------------------------- *\
   #HELP TEXT
\* -------------------------------------------------------------------------- */

.help-text {
    color: $color-gray;
    display: block;
    font-size: 0.875em; /* 14 / 16 */
    font-weight: $weight-normal;
    padding-top: 0.5em;
    padding-left: 0.125em;

    /**
     * Right-aligned
     */

    &.help-text--right {
        padding-right: 0.125em;
        text-align: right;
    }
}





/* -------------------------------------------------------------------------- *\
   #TOGGLE PASSWORD
\* -------------------------------------------------------------------------- */

.toggle-password {
    position: relative;

    /* Button */

    .btn {
        position: absolute;
        right: 0;
        top: 2.125em;
    }
}


select::-ms-expand {
    display: none;
}

/* -------------------------------------------------------------------------- *\
   #MULTI-COLUMN ITEMS
\* -------------------------------------------------------------------------- */

.multiplecols {
    display: flex;

    .formcol-2 {
        width: 50%;

        .left-margin {
            margin-left: 20px;
        }
    }

    .selected-radio {
        background-color: #00aeef;
    }

    &.neomorphic_radios {
        border-radius: 15px;
        background-color: #d8d8d9;
        color: #ffffff;
        position: relative;
        margin-bottom: 5px;
        //padding: 10px;

        .formcol-2 {
            padding: 15px;
            position: relative;
            display: flex;
            align-items: center;

            @media screen and (max-width: $bp-xs) {
                padding: 8px;
            }

            &:first-child {
                border-right: solid #ffffff 3px;
            }
            input[type="radio"] {
                color: #ffffff !important;
                position: relative;
                display: inline-block;
                -webkit-appearance: none;
                cursor: pointer;
                border-radius: 100%;
                background-color: #ffffff;
                height: 25px;
                width: 25px;
                min-height: 25px;
                min-width: 25px;
                box-shadow: unset;
                box-sizing: border-box;
                padding: 0;
                border-bottom: unset;

                @media screen and (max-width: $bp-xs) {
                    display: block;
                    min-width: 18px;
                    max-width: 18px;
                    min-height: 18px;
                    max-height: 18px;
                }

                .customradio {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    height: 25px;
                    width: 25px;
                    border-radius: 100%;
                    background-color: #ffffff;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 9px;
                        left: 9px;

                        //background-color: #00adef;

                    }

                }

                &:checked {
                    &::after {
                        display: block;
                        content: "";
                        position: absolute;
                        top: 7px;
                        left: 5px;
                        width: 15px;
                        height: 15px;
                        background: url("/assets/images/icons/check-solid-01.png");
                        background-size: contain;
                        background-repeat: no-repeat;
                        border-bottom: unset;

                        @media screen and (max-width: $bp-xs) {
                            top: 4px;
                            left: 2px;
                        }
                    }
                }

                &:not(:checked) {
                    &::after {
                        display: none !important;
                    }
                }
            }
            .labeltext {
                margin-left: 15px;

                @media screen and (max-width: $bp-xs) {
                    margin-left: 8px;
                    line-height: 15px;
                }
            }

            &.radio-selected {
                background-color: #00aeef !important;

                .customradio {
                    &:checked {
                        /*&:after {
                            content: "";
                            position: absolute;
                            top: 7px;
                            left: 5px;
                            width: 15px;
                            height: 15px;
                            display: block;
                            background: url("http://localhost:8082/assets/images/icons/check-solid-01.png");
                            background-size: contain;
                            background-repeat: no-repeat;
                        }*/
                    }
                }

                &:first-child {
                    border-radius: 15px 0 0 15px !important;
                }

                &:last-child {
                    border-radius: 0 15px 15px 0 !important;
                }
            }
        }
    }

    &.explainertext {
        @media screen and (max-width: $bp-xs) {
            margin-bottom: 10px;
        }
        .formcol-2 {
            .smallsize {
                text-align: center;
                color: #b3b3b3;

                @media screen and (max-width: $bp-xs) {
                    font-size: 10px;
                    margin: 0 4px;
                }
            }
        }
    }
}

//Check SVG asset provided by FontAwesome: https://fontawesome.com/license

.hs-form {
    .input {
        background-color: transparent;
        border: none !important;
        box-shadow: none !important;
        height: auto;
        line-height: unset;
        margin-top: 0;
        padding: 0;
        width: auto;
    }

    input[type="text"],
    input[type="email"] {
        height: 3em;
        border-bottom-width: 2px;
    }

    .hs-form-field {
        clear: both;
        display: block;
        font-size: 1em;
        margin-bottom: 2em;
        width: 100%;
    }

    .inputs-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .hs-button {
        margin-top: 2em;
        background-color: #60c39b;
        border: 0.125em solid transparent;
        border-radius: 0.125em;
        box-shadow: 0 0.25em 0.5em rgba(96, 195, 155, .2);
        color: #fff !important;
        cursor: pointer;
        display: inline-block;
        font-family: "Open Sans", sans-serif;
        font-size: 1em;
        font-weight: 700;
        height: 2.75em;
        line-height: 1;
        padding: 0.75em 1.25em;
        position: relative;
        text-align: center;
        text-decoration: none;
        transition: box-shadow 0.25s, transform 0.25s;
        vertical-align: middle;
        white-space: nowrap;

        &:focus,
        &:hover {
            box-shadow: 0 0.25em 0.75em rgba(96, 195, 155, .6);
            outline: 0;
            transform: translateY(-0.125em);
        }
    }

    .hs-error-msg {
        font-size: .9em;
        font-weight: 400;
        color: indianred;
        display: block;
        margin-top: 12px;
    }
}