.home-tabs-wrapper {
  background: #27405E;

  @media (min-width: $bp-small) {
    padding-top: 4em;
    padding-bottom: 5em;
  }

  .l-wrapper {
    & > .header {
      text-align: center;

      .header__title {
        color: $color-white;
      }
    }
  }

  .home-tabs {
    background: none;
    border: none;
    font-family: inherit;

    .home-tab-links {
      display: flex;
      align-items: baseline;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;

      .tab-link-item {
        flex-grow: 1;
        float: none;
        margin: 0;
        padding: 24px 40px 16px;
        position: relative;
        background: #E7F0FF;
        border: none;
        border-radius: 20px 20px 0 0;
        box-shadow: inset 8px -10px 16px rgba(0, 0, 0, .2);

        &.ui-tabs-active {
          box-shadow: -10px -1px 20px rgba(0, 0, 0, .2);

          .tab-link {
            background: transparent;

            &:hover {
              background: transparent;
              pointer-events: none;
            }
          }
        }

        .tab-link {
          width: 100%;
          height: 100%;
          padding: 0;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 20px 20px 0 0;
          background: rgba(0, 0, 0, .3);
          transition: .1s;

          &:hover {
            background: rgba(0, 0, 0, .2);
          }
        }

        .tab-link-title {
          margin: 0;
          font-size: 1.6rem;

          span,
          strong {
            display: block;
          }

          span {
            font: {
              size: 1.4rem;
              weight: 300;
            }
            color: #334151;
          }

          strong {
            color: $color-primary;
            font-weight: 700;
          }
        }
      }
    }

    .home-tab-panes {
      background: #E7F0FF;
      border-radius: 0 0 20px 20px;
      position: relative;

      .tab-pane {
        padding: 60px 40px;

        .content-block {
          .content-block__body {
            @media (min-width: $bp-large) {
              width: 50%;
            }

            .header {
              .header__title {
                font-size: 2em;
                color: #334151;
              }
            }

            .content-block__synopsis {
              margin-bottom: 0;
              font-size: 1.1rem;
              letter-spacing: .8px;

              a {
                color: $color-primary;
                border-color: $color-primary;

                &:hover {
                  color: #334151;
                  border-color: #334151;
                }
              }
            }

            .video-play {
              margin-top: 2rem;

              .video-play-link {
                font-weight: 600;
                text-transform: none;
                color: #334151;
              }
            }

            .btn {
              margin-top: 2rem;
              color: $color-white;
              border-radius: 50px;

              @media (max-width: $bp-xs) {
                width: 100%;
              }
            }
          }

          .content-block__img {
            padding: 0 2em;

            @media (max-width: $bp-large) {
              float: none;
              width: auto;
              max-width: 500px;
              padding: 3em 0 0 !important;
              margin: 0;
            }

            img {
              max-height: 372px;

              @media (max-width: $bp-large) {
                max-width: unset;
                max-height: unset;
              }
            }
          }
        }
      }
    }

    @media (max-width: $bp-medium) {
      .home-tab-links {
        .tab-link-item {
          padding: 20px 24px 12px;
          border-radius: 16px 16px 0 0;
          margin: 0 0 -1px;

          .tab-link {
            border-radius: 16px 16px 0 0;
          }

          .tab-link-title {
            font-size: 1.2rem;

            span {
              font-size: 1rem;
            }
          }
        }
      }

      .home-tab-panes {
        border-radius: 0 0 16px 16px;

        .tab-pane {
          padding: 40px 32px;

          .content-block {
            .content-block__body {

              .header {
                .header__title {
                  font-size: 1.8em;
                }
              }

              .content-block__synopsis {
                font-size: 1rem;
              }

              .video-play {
                margin-top: 1.6rem;
              }
            }

            .content-block__img {
              padding: 0 2em;

              img {
                max-height: 372px;
              }
            }
          }
        }
      }
    }

    @media (max-width: $bp-small) {
      .home-tab-links {
        .tab-link-item {
          padding: 12px 16px 8px;
          border-radius: 12px 12px 0 0;

          .tab-link {
            border-radius: 12px 12px 0 0;
          }

          .tab-link-title {
            font-size: 1rem;

            span {
              font-size: .9rem;
            }
          }
        }
      }

      .home-tab-panes {
        border-radius: 0 0 12px 12px;

        .tab-pane {
          padding: 24px;

          .content-block {
            .content-block__body {
              .header {
                .header__title {
                  font-size: 1.4em;
                }
              }

              .content-block__synopsis {
                font-size: .9rem;
              }

              .btn {
                margin-top: 1.6rem;
              }
            }
          }
        }
      }
    }

    @media (max-width: $bp-xs) {
      .home-tab-links {
        flex-direction: column;

        .tab-link-item {
          width: 92%;
          padding: 12px 16px 8px;
          margin: 0 0 4px;
          border-radius: 8px;
          transition: .2s width ease;

          &.ui-tabs-active {
            width: 100%;
          }

          .tab-link {
            border-radius: 8px;
          }

          .tab-link-title {
            font-size: 1rem;

            span {
              font-size: .9rem;
            }
          }
        }
      }

      .home-tab-panes {
        border-radius: 8px;

        .tab-pane {
          padding: 20px;
          position: relative;

          .content-block {
            .content-block__body {
              .header {
                .header__title {
                  font-size: 1.2rem;
                }
              }

              .content-block__synopsis {
                font-size: .8rem;
              }

              .btn {
                margin-top: 1.6rem;
              }
            }

            .content-block__img {
              padding: 2em 0 0;

              img {
                max-height: 372px;
              }
            }
          }
        }
      }
    }
  }
}