/* -------------------------------------------------------------------------- *\
   #PAGINATION
\* -------------------------------------------------------------------------- */

.pagination {
    text-align: center;

    /* List */

    .pagination__list {
        @include clearfix();
        @include list();

        /* Item */

        .pagination__item {
            display: none;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;

            /* Previous */

            &:first-child {
                display: block;
                float: left;

                .icon {
                    margin-right: 0.5em;
                }
            }

            /* Next */

            &:last-child {
                display: block;
                float: right;

                .pagination__link {
                    padding-left: 0;
                    padding-right: 0;
                }

                .icon {
                    margin-left: 0.5em;
                }
            }

            /* Icon */

            .icon {
                font-size: 0.875em; /* 14 / 16 */
            }

            /**
             * Disabled
             */

            &.is-disabled {
                color: $color-silver;
                padding-top: 0.375em;
                padding-bottom: 0.375em;
            }

            /**
             * Selected
             */

            &.is-selected {

                .pagination__link {
                    color: $color-primary;
                }
            }

            /**
             * Spacer
             */

            &.is-spacer {
                padding: 0.375em 1em;
            }
        }
    }

    /* Link */

    .pagination__link {
        color: $color-gray-dark;
        display: block;
        font-weight: $weight-bold;
        padding: 0.375em 1em;
        text-decoration: none;
        transition: color 0.25s;

        &:focus,
        &:hover {
            color: $color-primary;
        }
    }
}

@media all and (min-width: $bp-small) {

    .pagination {

        .pagination__list {

            .pagination__item {
                display: inline-block;

                &:first-child,
                &:last-child {
                    display: inline-block;
                    float: none;
                }
            }
        }
    }
}
