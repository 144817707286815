.checkerboard {
	
	padding-bottom: 20px;
	
	.checkerboard__block {		
		display: block;
		position: relative;
		
		.block--text {	
			color: $color-white;
			font-size: 0.875em;
			line-height: 1.888em;
			display: table;
			z-index: -2;
			text-align: center;
			padding: 200px 20px 40px 20px;
			display: block;

			p {
				display: table-cell;
				vertical-align: middle;
				color: $color-gray-dark;	            
			}
		}

		.block--icon {
			padding: 40px 20px;
			z-index: 2;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			
			h5 {
				font-size: 22px;
			}
			
			img {
				margin: 0 auto;
				display: block;
				margin-bottom: 20px;
			}
		}
	}

	@media all and (min-width: $bp-small) {
		padding-bottom: 0;
		margin-bottom: 60px;
		box-shadow: 0px 12px 26px 0px rgba(0,0,0,0.3);

		.checkerboard__block {
			width: 50%;
			display: inline-block;
			float: left;

			.block--text {	
				background: $color-primary;
				height: 250px;
				padding-top: 20px;
				display: table;

				p {
					color: $color-white;
				}
			}

			.block--icon {
				height: 250px;
				position: static;
				position: relative;
			}
			
			.icon--positioner {
				position: absolute;
				left: 20px;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	@media all and (min-width: $bp-medium) {
		
		.checkerboard__block {
			width: 25%;
			
			.block--text {
				height: 300px;
			}
			
			.block--icon {
				height: 300px;
			}	
		}
	}

	@media all and (min-width: $bp-large) {
		
		.block--text {
			height: 250px;
		}
		.block--icon {
			height: 250px;
		}
	}

}