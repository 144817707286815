#heres-how {
  .video-wrapper {
    max-width: 50rem;
    margin: 0 auto 3rem;
    padding-top: 0;

    @media all and (min-width: $bp-small) {
      margin: 0 auto 5rem;
      padding-top: 4em;
    }
  }
}