/* -------------------------------------------------------------------------- *\
   UNIT TRUST PAGE
\* -------------------------------------------------------------------------- */

.unit-trust-link {
    color: $color-primary;
    border-color: $color-primary;

    &:hover {
        color: $color-black;
        border-color: $color-black;
    }
}

.how-to-get {
    background: #E6F0FF;
    text-align: center;

    @media (min-width: $bp-small) {
        padding-top: 4em;
        padding-bottom: 4em;
    }

    .header__title {
        margin-bottom: 2em;
        font-size: 2.25em;
    }

    .how-block {
        margin: 0 auto;
        max-width: 280px;

        .image {
            max-width: 180px;
            margin-bottom: 1.6em;
        }

        .text {
            margin: 0;
            padding-bottom: 1.2em;
            font-size: .9em;
            font-weight: 600;
            letter-spacing: .5px;
            line-height: 1.6;
            border-bottom: 2px solid #C9D0D8;

            @media (min-width: $bp-small) {
                font-size: 1em;
            }
        }
    }
}