/* -------------------------------------------------------------------------- *\
   #MENU
\* -------------------------------------------------------------------------- */

.menu {
    background-color: $color-secondary;
    box-shadow: 0 0.125em 2em rgba($color-secondary, 0.3);
    margin-top: 1em;
    padding: 1em;

    /* List */

    .menu__list {
        @include clearfix();
        @include list();

        /* Item */

        .menu__item {
            padding-right: 0.375em;
            padding-left: 0.375em;

            &:not(:last-child) {
                border-bottom: 0.125em solid rgba($color-white, 0.1);
                margin-bottom: 0.75em;
                padding-bottom: 0.75em;
            }

            /**
             * Active
             */

            &.is-active {

                .menu__link {
                    color: $color-primary;
                }
            }
        }

        /* Link */

        .menu__link {
            color: $color-white;
            display: block;
            font-size: 1.125em; /* 18 / 16 */
            text-decoration: none;
            transition: color 0.25s;

            &:focus,
            &:hover {
                color: $color-primary;
            }
        }
    }
}

@media all and (min-width: $bp-small) {

    .menu {

        .menu__list {

            .menu__link {
                font-size: 1em;
            }
        }
    }
}
