/* -------------------------------------------------------------------------- *\
   #FAQ
\* -------------------------------------------------------------------------- */

.faq {

    &:not(:first-child) {
        margin-top: 2em;
    }

    /* Title */

    .faq__title {

        a {
            @include anchor();
        }
    }

    & + .faq {
        border-top: 0.125em solid $color-white-dark;
        padding-top: 2em;
    }

    & + .pagination {
        margin-top: 3em;
    }
}











