/* -------------------------------------------------------------------------- *\
   #ANCHOR LINKS
\* -------------------------------------------------------------------------- */

.anchor-links {
    border: 1px solid #ddd;
    @include clearfix();

    .anchor-link {
        display: block;
        text-align: center;
        padding: 0;
        border: {
            right: 1px solid #ddd;
            bottom: 1px solid #ddd;
        }
        transition: .15s ease;
        text-decoration: none;
        color: inherit;
        height: 5rem;
        line-height: 5rem;
        outline: none;

        &:last-of-type {
            border-bottom: none;
        }

        @media all and (min-width: $bp-small) {
            float: left;
            width: 25%;
            border-bottom: none;
        }

        &:hover {
            background: #E9F6FC;
            cursor: pointer;
        }

        .anchor-link-image {
            margin-right: 1.25rem;
            margin-top: -5px;

            display: inline;

            @media all and (min-width: $bp-small) {
                margin-right: .25rem;
                max-width: 24px;
            }

            @media all and (min-width: $bp-large) {
                margin-right: 1.25rem;
            }
        }

        .anchor-link-title {
            display: inline-block;
            min-width: 8em;
            font-size: 1.25rem;
            text-align: left;

            @media all and (min-width: $bp-small) {
                display: inline;
                min-width: unset;
                font-size: 0.8rem;
            }

            @media all and (min-width: $bp-large) {
                font-size: 1.25rem;
            }
        }
    }
}