/**
 * FEATURE
 */

/* -------------------------------------------------------------------------- *\
   #TABLE OF CONTENTS
\* -------------------------------------------------------------------------- */

/**
 * FEATURE
 * FEATURES
 */





/* -------------------------------------------------------------------------- *\
   #FEATURE
\* -------------------------------------------------------------------------- */

.feature {
    @include clearfix();
    padding-bottom: 1em;

    &:last-child {
        padding-bottom: 0;
    }

    /**
     * Small
     */

    &.feature--small {

        .feature__img {
            width: 2.25em;
        }

        .tax_free_img {
            height: 80px !important;
        }

        .feature__body {
            padding-left: 2.5em;
        }
    }

    /* Link */

    .feature__link {
        color: inherit;
        display: block;
        text-decoration: none;

        &:focus,
        &:hover {

            .feature__img {
                transform: translateY(-0.25em);
            }

            .tax_free_img {
                height: 80px !important;
            }

            .feature__title {
                color: $color-primary;
            }
        }
    }

    /* Image */

    .feature__img {
        float: left;
        padding-right: 1em;
        transition: transform 0.25s;
        width: 5em;
    }

    .tax_free_img {
        height: 80px !important;
    }

    /* Body */

    .feature__body {
        padding-left: 5.5em;
    }

    /* Title */

    .feature__title {
        font-size: 0.875em;
        transition: color 0.25s;
    }

    /* Synopsis */

    .feature__synopsis {
        margin-bottom: 0;

        & + p {
            margin-top: 1em;
        }
    }
}

@media all and (min-width: $bp-small) {

    .feature {

        /**
         * Centered
         */

        &.feature--centered {
            display: block;
            float: left;
            padding-right: 2em;
            padding-bottom: 0;
            text-align: center;
            width: 20%;

            &:last-child {
                padding-right: 0;
            }

            /**
             * Quarter
             */

            &.is-quarter {
                width: 25%;
            }

            /**
             * Half
             */

            &.is-half {
                width: 50%;
            }

            .feature__img {
                display: inline-block;
                float: none;
                height: 8em;
                margin-bottom: 1.5em;
                padding-right: 0;
                width: auto;
            }

            .tax_free_img {
                height: 80px !important;
            }

            .feature__body {
                display: block;
                padding-left: 0;
            }

            .feature__title {
                font-size: 0.875em; /* 24 / 16 */
            }
        }

        &.feature--small {

            .feature__img {
                height: auto;
            }

            .tax_free_img {
                height: 80px !important;
            }
        }

        .feature__title {
            font-size: 0.875em; /* 20 / 16 */
        }

        .feature__synopsis {
            font-size: 1rem; /* 18 / 16 */
        }
    }
}





/* -------------------------------------------------------------------------- *\
   #FEATURES
\* -------------------------------------------------------------------------- */

.features {
    @include clearfix();
    margin-top: 2em;
}

@media (min-width: 46.25em) {
    .feature.feature--centered {
        display: block;
        float: left;
        padding-right: 1em;
        padding-bottom: 0;
        text-align: center;
        width: 20%;
    }
}



@media (min-width: 46.25em) {
    .feature.feature--centered.feature__small {
        display: block;
        float: left;
        padding-right: 1em;
        padding-bottom: 0;
        text-align: center;
        width: 20%;
    }
}



