/* -------------------------------------------------------------------------- *\
   #ONE PERCENT PAGE
\* -------------------------------------------------------------------------- */

.homepage-heading-promo {
  margin-bottom: 1.2em !important;
  margin-top: -1em !important;

  .header__title {
    margin-bottom: 0 !important;
  }
}

.hero-one-percent {
  height: 48vh !important;
  background-color: #0A223E !important;
  justify-content: flex-end;

  @media all and (min-width: $bp-small) {
    justify-content: center;
    background-color: transparent !important;
    background-image: url('/assets/images/heros/one-percent-bg-v2.svg');
  }

  .hero-one-percent-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media all and (min-width: $bp-small) {
      height: auto;
      display: block;
    }

    img {
      max-width: 80%;
      margin-top: 20vh;

      @media all and (min-width: $bp-small) {
        display: none;
      }
    }

    .hero-one-percent-inner-left,
    .hero-one-percent-inner-right {
      display: none;
      width: 50%;

      @media all and (min-width: $bp-small) {
        display: block;
      }

      .hero__title {
        max-width: 100%;
        font: {
          size: 3.2rem;
          weight: 700;
        }
        line-height: 1.4;
        text-transform: none;
        text-shadow: none;
      }
    }

    .hero-one-percent-inner-left {
      text-align: right;
      padding-right: .8em;
    }

    .hero-one-percent-inner-right {
      text-align: left;
      margin-left: 51%;
    }
  }

  .hero-one-percent-btn-container {
    margin-bottom: 4vh;

    @media all and (min-width: $bp-small) {
      margin-bottom: 0;
      text-align: left;
      margin-left: 51%;
      width: 50%;
      margin-top: 2em;
    }
  }
}

#one-percent-video {
  @media all and (min-width: $bp-small) {
    padding: {
      top: 6em;
      bottom: 6em;
    }
  }

  .header__title {
    text-align: center;
    margin-bottom: 2em;
  }

  .video-container {
    max-width: 900px;
    margin: 0 auto;
  }
}

.l-section {
  &.one-percent {
    .content-block {
      .header__title {
        text-align: center;
        margin-bottom: 1em;
      }

      .box {
        margin-bottom: 1em;

        @media all and (min-width: $bp-small) {
          margin-bottom: 0;
        }

        .one-percent-box-content {
          color: $color-blue;
          font-size: 1.2rem;

          @media all and (min-width: $bp-small) {
            line-height: 1.6;
            font-size: 1.4rem;
          }

          @media all and (min-width: $bp-medium) {
            font-size: 1.8rem;
          }

          strong {
            font-weight: 700;
          }
        }

        .terms-link {
          border-bottom: 1px solid $color-blue;
          text-decoration: none;

          small {
            color: $color-blue;
            font-size: 1rem;
            font-weight: bold;
          }
        }
      }
    }

    &.one-percent-intro {
      .header__title {
        position: relative;
        font-size: 1.4em;
        margin-bottom: 2em;

        @media all and (min-width: $bp-small) {
          font-size: 2.4em;
        }

        &:after {
          content: '';
          display: inline-block;
          height: 2px;
          width: 220px;
          background: $color-secondary;
          position: absolute;
          bottom: -20px;
          margin-left: -110px;
          left: 50%;
        }
      }

      .content-block__body {
        @media all and (min-width: $bp-small) {
          width: 50%;
        }
      }

      .content-block__features {
        .box {
          padding: 0;

          .box-top {
            padding: 1.4em;
            text-align: center;

            @media all and (min-width: $bp-small) {
              padding: 2em;
            }

            h3 {
              color: $color-primary;
              font-weight: 600;
              text-transform: uppercase;
              font-size: 1em;
              letter-spacing: 2px;

              @media all and (min-width: $bp-small) {
                font-size: 1.4em;
              }
            }

            img {
              margin: .4em 0 1.4em;
            }

            .one-percent-box-content {
              text-align: left;
              font-size: 1rem;
              font-weight: 400;

              @media all and (min-width: $bp-small) {
                font-size: 1.2rem;
              }
            }
          }

          .box-bottom {
            padding: 1.4em;
            background: $color-primary;
            text-align: center;

            @media all and (min-width: $bp-small) {
              padding: 2em;
            }

            h3 {
              position: relative;
              font-size: 1.2rem;
              margin-bottom: 1.6em;
              color: $color-white;

              @media all and (min-width: $bp-small) {
                font-size: 1.8rem;
                margin-bottom: 1.2em;
              }

              &:after {
                content: '';
                display: inline-block;
                height: 2px;
                width: 240px;
                background: $color-white;
                position: absolute;
                bottom: -16px;
                margin-left: -120px;
                left: 50%;
              }
            }

            span {
              display: block;
              color: $color-white;
              font-weight: 600;
              font-size: 1rem;

              @media all and (min-width: $bp-small) {
                font-size: 1.2rem;
              }
            }

            .promo-code {
              background: $color-white;
              padding: .1em 1em;
              font-size: 1.2rem;
              text-transform: uppercase;
              color: $color-primary;
              letter-spacing: 18px;
              display: block;
              text-indent: 18px;
              max-width: 236px;
              margin: .4em auto;
              font-weight: 600;
              text-decoration: none !important;

              @media all and (min-width: $bp-small) {
                max-width: 100%;
                font-size: 1.6rem;
                letter-spacing: 25px;
                display: inline-block;
                text-indent: 25px;
                margin: .4em 0;
              }
            }

            .btn {
              padding: inherit;
              display: block;
              height: inherit;
              line-height: .2;
              margin: 1em 0 0;

              @media all and (min-width: $bp-small) {
                display: inline-block;
                height: 4em;
                margin: 1em 0 .8em;
                padding: {
                  left: 6em;
                  right: 6em;
                }
              }
            }

            .terms-link {
              color: $color-white;

              small {
                color: $color-white;
                font-weight: 300;
              }
            }
          }
        }
      }

      .apply-btn-main {
        padding: inherit;
        display: block;
        height: 4em;
        line-height: 3.5;
        margin-bottom: 0;
        margin-top: 0;
        max-width: 100%;

        @media all and (min-width: $bp-small) {
          max-width: 300px;
          margin: 13em auto 0;
          height: 4em;
        }
      }
    }

    &#cta {
      text-align: center;

      img {
        display: block;
        width: 100%;
        max-width: 640px;
        margin: 0 auto 2em;
      }
    }
  }
}

.cta {
  &#invest-cta {
    background-color: $color-blue;
    text-align: center;
    padding: {
      top: 4em;
      bottom: 3em;
    }

    h3 {
      color: $color-white;
      font-weight: 300;
      margin-bottom: .8rem;

      @media all and (min-width: $bp-small) {
        font-size: 1.8rem;
        line-height: 1.4;
      }

      strong {
        font-weight: 600;
      }
    }

    p {
      margin-bottom: 0;

      a {
        color: $color-secondary;
        border-color: $color-secondary;
      }
    }
  }
}