/* -------------------------------------------------------------------------- *\
   #NAVIGATION
\* -------------------------------------------------------------------------- */

.nav {

    /* List */

    .nav__list {
        @include clearfix();
        @include list();
        white-space: nowrap;

        /* Item */

        .nav__item {
            position: relative;

            /**
             * Active
             */

            &.is-active {

                .nav__link {

                    &:after {
                        transform: scaleX(1);
                    }
                }
            }
        }

        /* Menu */

        .menu {
            display: none;

            .menu__list {
                margin-top: 0;
                margin-bottom: 0;
            }

            &.is-open {
                display: block;
            }
        }
    }

    /* Link */

    .nav__link {
        color: $color-white;
        display: block;
        font-weight: $weight-normal;
        position: relative;
        text-decoration: none;
        transition: color 0.25s;

        &:after {
            background-color: $color-primary;
            bottom: -0.125em;
            content:"";
            height: 0.125em;
            left: 0;
            position: absolute;
            transform: scaleX(0);
            transition: transform 0.25s ease-in-out;
            width: 100%;
        }

        &:focus,
        &:hover {
            color: $color-primary;

            &:after {
                transform: scaleX(1);
            }
        }

        /* Icon */

        .icon {
            float: right;
        }
    }

    /**
     * Primary
     */

    &.nav--primary {

        /* List */

        .nav__list {

            /* Item */

            .nav__item {
                margin-bottom: 1em;
            }
        }
    }

    /**
     * Secondary
     */

    &.nav--secondary {
        background-color: $color-white;
        box-shadow: 0 0.125em 2em rgba($color-black, 0.1);
        padding: 1em 1em 0.5em 1em;
        text-align: center;

        /**
         * Fixed
         */

        &.is-fixed {
            left: 0;
            position: fixed;
            top: 4rem;
            width: 100%;
            z-index: $z-index-nav-secondary;
        }

        /* List */

        .nav__list {
            display: inline-block;
            white-space: normal;

            /* Item */

            .nav__item {
                display: inline-block;
                margin-bottom: 0.5em;
                margin-left: 1.25em;
                margin-right: 1.25em;
            }
        }

        /* Link */

        .nav__link {
            color: $color-black;
            text-transform: uppercase;
            transition: color 0.25s;

            &:focus,
            &:hover {
                color: $color-primary;
            }
        }
    }

    /**
     * Transparent
     */

    &.nav--transparent {
        background-color: transparent;
        box-shadow: none;
        margin-bottom: 2em;
        padding: 0 !important;
        text-align: left;

        .nav__list {
            display: block;

            .nav__item {
                display: block;
            }
        }
    }
}

@media all and (min-width: 1024px) {

    .nav {

        .nav__link {

            .icon {
                float: none;
                margin-left: 0.7em;
                font-size: 0.875em;
            }
        }

        &.nav--primary {

            .nav__list {

                .nav__item {
                    display: inline-block;
                    margin-bottom: 0;
                    margin-left: 1.875em;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                .menu {
                    left: 0;
                    position: absolute;
                    top: 100%;
                    z-index: $z-index-menu-dropdown;
                }
            }
        }

        &.nav--secondary {
            padding-top: 1.5em;
            padding-bottom: 1em;

            &.is-fixed {
                top: 0;
            }
        }

        &.nav--transparent {
            margin-bottom: 3em;
            text-align: center;

            .nav__list {
                display: inline-block;

                .nav__item {
                    display: inline-block;
                }
            }
        }
    }
}

@media all and (min-width: $bp-large) {

    .nav {

        &.nav--secondary {

            &.is-fixed {
                top: 6.25em;
            }
        }
    }
}
