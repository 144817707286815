/* -------------------------------------------------------------------------- *\
   #HALF COLUMN BLUE
\* -------------------------------------------------------------------------- */

.half-col-blue {
    background-color: $color-white;

    .l-wrapper {
        width: 100%;
    }

    .col {
        margin: 0;
        padding: 2em 7.5% 1.5em;
    }

    //Only on mobile
    @media all and (max-width: $bp-small) {
        .col:last-child {
            background-color: $color-primary;
            * {
                color: #fff;
            }
            .hr {
                border-color: #fff;
            }
        }
    }

    @media all and (min-width: $bp-small) {
        display: block;
        position: relative;

        &:before {
            background-color: $color-primary;
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            width: 50%;
        }

        &.half-col-blue--right:before {
            right: 0;
        }

        &.half-col-blue--left:before {
            left: 0;
        }

        .l-wrapper {
            width: 85%;
        }

        .row {
            position: relative;
        }

        .col {
            padding: 2em 0;
            position: relative;
            z-index: 2;

            &:last-child {
                padding-top: 7.5em;
            }
        }

        &.half-col-blue--right .col:last-child,
        &.half-col-blue--left .col:first-child {
            background-color: $color-primary;
            * {
                color: #fff;
            }
            .hr {
                border-color: #fff;
            }
        }

    }
}



/* -------------------------------------------------------------------------- *\
   #FULL COLUMN BLUE ONE ROW
\* -------------------------------------------------------------------------- */

.half-col-blue-one-row {
    background-color: $color-white;

    .l-wrapper {
        width: 100%;
    }

    .col {
        margin: 0;
        padding: 2em 7.5% 1.5em;
        background-color: $color-primary;
       
    }

    //Only on mobile
    @media all and (max-width: $bp-small) {
        .col:last-child {
            background-color: $color-white;
        }
    }

    @media all and (min-width: $bp-small) {
        display: block;
        position: relative;

        &:before {
            background-color: $color-primary;
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            width: 50%;
        }

        .col {
   
            &:last-child {
                padding-left: 60px;
                background-color: $color-white;   
            }
            
            &:first-child {
                padding-right: 60px;
                
            }
        }

        &.half-col-blue--left:before {
            left: 0;
        }

        .l-wrapper {
            width: 85%;
        }

        .row {
            position: relative;
        }

        .col {
            padding: 3em 0;
            position: relative;
            z-index: 2;
        }

        &.half-col-blue--left .col:first-child {
            background-color: $color-primary;
           
        }

    }
}



/* -------------------------------------------------------------------------- *\
   #FULL COLUMN BLUE AND DARK SECONDARY
\* -------------------------------------------------------------------------- */



.half-col-blue-dark {
    background-color: $color-secondary;
    

    .l-wrapper {
        width: 100%;
    }

    .col {
        margin: 0;
        padding: 2em 7.5% 1.5em;
       
    }
    
    .hexagon__divider {
        display: none;
    }

    img {
        margin: 30px 0;
    }

    .feature__img img {
        margin: 0;
    }

    //Only on mobile
    @media all and (max-width: $bp-small) {
        .col:last-child {
            background-color: $color-primary;
            * {
                color: #fff;
            }
            .hr {
                border-color: #fff;
            }
        }
    }

    @media all and (min-width: $bp-small) {
        display: block;
        position: relative;

        .header .header__title.title--small {
            font-size: 20px;
            margin: 20px auto;
        }

      

        .hexagon__divider {
            display: block;
            position: absolute;
            left: 50%;
            margin-left: -70px;
            top: 170px;
            z-index: 99;
        
        p {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: $color-primary;
            font-size: 18px;
            line-height: 1.4em;
            margin-bottom: 0;
            font-weight: normal;
        }
    }

        &.has--hexagon {
            .col:last-child {
                padding-left: 60px;
            }
            .col:first-child {
                padding-right: 60px;
            }
        }

        &:before {
            background-color: $color-primary;
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            width: 50%;
        }


        .col {
   
            &:last-child {
                padding-left: 60px;   
            }
            
            &:first-child {
                padding-right: 60px;
            }
        }

        &.half-col-blue--right:before {
            right: 0;
        }

        &.half-col-blue--left:before {
            left: 0;
        }

        .l-wrapper {
            width: 85%;
        }

        .hr {
                border-color: #fff;
            }

        .row {
            position: relative;
        }
        
        img {
            margin: 30px;
        }

        .col {
            padding: 3em 0;
            position: relative;
            z-index: 2;
        }

        &.half-col-blue--right .col:last-child,
        &.half-col-blue--left .col:first-child {
            background-color: $color-primary;
            * {
                color: #fff;
            }
           
        }

    }
}