/* -------------------------------------------------------------------------- *\
   #TABS
\* -------------------------------------------------------------------------- */

.tabs {
    margin-bottom: 1em;

    /* List */

    .tab-list {
        @include list();
        margin-bottom: 2em;

        /* Tab */

        .tab-list__tab {
            margin-bottom: 0.25em;

            &:last-child {
                margin-bottom: 0;
            }

            /**
             * Selected
             */

            &.is-selected {

                .tab-list__link {
                    color: $color-primary;

                    &:after {
                        transform: scaleX(1);
                    }
                }
            }
        }

        /* Link */

        .tab-list__link {
            color: $color-gray-dark;
            display: block;
            font-size: 0.875em; /* 14 / 16 */
            font-weight: $weight-normal;
            margin-bottom: 0.5em;
            padding-bottom: 0.5em;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            transition: color 0.25s;

            &:after {
                background-color: $color-primary;
                bottom: -0.125em;
                content:"";
                height: 0.25em;
                left: 0;
                position: absolute;
                transform: scaleX(0);
                transition: transform 0.25s ease-in-out;
                width: 100%;
            }

            &:focus,
            &:hover {
                color: $color-primary;

                &:after {
                    transform: scaleX(1);
                }
            }
        }
    }

    /* Panel */

    .tab-panel {
        display: none;

        /**
         * Selected
         */

        &.is-selected {
            animation: animation-fade 1s forwards;
            display: block;
        }
    }
}


//angled tabs

.tabs {

    &.tabs--angled.tabs--centered {
        margin-bottom: 0;

        .tabs-list--positioner {
            margin-top: 60px;
        }

        .tab-list {
            text-align: center;

            .tab-list__tab {
                display: inline-block;
            }

            .tab-list__link {
                float: left;
                text-transform: initial;
                background-color: $color-secondary;
                text-align: center;
                height: 100px;
                padding: 20px 10px;
                color: $color-white;
                width: 80px;
                margin-left: 3px; 
                margin-right: 3px; 
                font-size: 14px;
                line-height: 1.2em;
                -webkit-backface-visibility: hidden;

                &:after {
                    background-color: $color-green;
                    top: 0;
                    height: 10px;  
                }  
            }   
        }

        .tab-list__tab.is-selected .tab-list__link{
            background-color: $color-primary;
            color: $color-white;
            transform: translateZ(0) scale(1.12);
            z-index: $z-index-nav-secondary;

            &:after {
                background-color: transparent;
                transform: none;  
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0; 
                height: 0; 
                border-left: 40px solid transparent;
                border-right: 40px solid transparent;
                border-top: 20px solid $color-primary;
                margin-bottom: -20px;
            }  
        }

        @media all and (min-width: $bp-small) {
            .tabs-list--positioner {
                margin-top: -60px;
                margin-bottom: 100px;
            }

            .tab-list {
                
                .tab-list__link {
                    height: 260px;
                    width: 230px;
                    font-size: 32px;
                    padding: 80px 20px;
                    text-transform: uppercase;
                    line-height: 1.1em;
                    filter: drop-shadow(0px 11px 22px rgba(0,0,0,0.4));
                    font-weight: bold;

                    span {
                        font-size: 20px;
                    }    
                }
            }

            .tab-list__tab.is-selected .tab-list__link {
                 
             &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0; 
                    height: 0; 
                    border-left: 115px solid transparent;
                    border-right: 115px solid transparent;
                    border-top: 70px solid $color-primary;
                    margin-bottom: -70px;
                }
            } 
        }
    }
}




@media all and (min-width: $bp-xs) {

    .tabs {

        &.tabs--centered {

            .tab-list {
                text-align: center;

                .tab-list__link {
                    margin-right: 1em;
                    margin-left: 1em;
                }
            }
        }

        .tab-list {
            @include clearfix();
            margin-bottom: 3em;

            .tab-list__tab {
                display: inline-block;
                margin-bottom: 0;
                margin-right: 0.125em;

                &:last-child {
                    margin-right: 0;
                }
            }

            .tab-list__link {
                font-size: 1em;
                margin-right: 2em;
            }
        }
    }
}

@media all and (max-width: $bp-xs) {

    .tabs .tab-list .tab-list__tab:last-child {
        margin-bottom: 4px; 
    } 
}

//Contact Us Form Tabs

.neomorphic_tabs {
    margin-bottom: 0 !important;
    background-color: #f5f7f9;
    border-radius: 10px 10px 0 0;
    display: flex;

    .tab-list__tab {
        padding: 19px;
        width: 50%;
        box-sizing: border-box;
        margin: 0;
        background-color: #c7cacf;
        border-radius: 0 10px;
        box-shadow: rgba(0,0,0,0.1) 2px -2px 6px inset;

        @media screen and (max-width: $bp-small) {
            padding: 12px;
            align-items: center;
            display: flex;
        }

        .tab-list__link {
            color: rgba(255,255,255,0.3) !important;
            text-align: center;
            font-size: 1.25em !important;
            margin: 0 !important;
            padding: 0 !important;

            @media screen and (max-width: $bp-small) {
                width: 100%;
            }

            &:after {
                display: none !important;
            }
        }

        &:first-child {
            border-radius: 10px 0 !important;
            box-shadow: rgba(0,0,0,0.1) -2px -2px 6px inset !important;
        }

        &.is-selected {
            background-color: #f5f7f9;
            width: 50%;
            padding: 19px;
            box-sizing: border-box;
            margin: -8px 0;
            border-radius: 10px 10px 0 0 !important;
            box-shadow: unset !important;

            @media screen and (max-width: $bp-small) {
                padding: 12px;
                align-items: center;
                display: flex;
            }

            .tab-list__link {
                color: #374456 !important;
                text-align: center;
                margin: 0 !important;
                padding: 0 !important;
                line-height: 2em;
                font-size: 1.25em;

                @media screen and (max-width: $bp-small) {
                    width: 100%;
                }

                &:after {
                    display: none !important;

                }
            }
        }
    }
}