/* -------------------------------------------------------------------------- *\
   #VCARD
\* -------------------------------------------------------------------------- */

.vcard {

    .adr {
        margin-bottom: 1em;
    }

    .icon {
        color: $color-primary;
        margin-right: 0.5em;
    }

    .org {
        font-weight: $weight-bold;
    }

    /* Link */

    .vcard__link {

        a {
            @include anchor();
        }

        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }
}
