/**
 * ANIMATION
 */

/* -------------------------------------------------------------------------- *\
   #TABLE OF CONTENTS
\* -------------------------------------------------------------------------- */

/**
 * FADE
 */





/* -------------------------------------------------------------------------- *\
   #FADE
\* -------------------------------------------------------------------------- */

@keyframes animation-fade {

    0% {
        display: none;
        opacity: 0;
        transform: translateY(-0.25em);
    }

    1% {
        display: block;
        opacity: 0;
        transform: translateY(-0.25em);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
