/* -------------------------------------------------------------------------- *\
   #WISTIA CUSTOM PLAYLISTS
\* -------------------------------------------------------------------------- */

.wistia_playlist {
  overflow-x: hidden;

  .wistia-custom-playlist {
    position: absolute;
    right: -73%;
    top: 0;
    background-color: #00adef;
    opacity: 0;
    transition: 0.3s;
    visibility: hidden;
    min-height: 100%;

    @media screen and (max-width: 830px) {
      right: -100px;
      width: 580px;
    }

    @media screen and (max-width: 650px) {
      width: 400px;
    }

    @media screen and (max-width: 450px) {
      width: 300px;
    }

    @media screen and (max-width: 360px) {
      width: 200px;
    }

    &.open-playlist {
      opacity: 1;
      visibility: visible;
      right: 0;
      background-color: white;
      box-shadow: rgba(0,0,0,0.5) -3px 0px 5px;
    }

    .wistia_playlist_items {
      list-style: none;
      padding: 0;
      margin: 0;

      .wistia-playlist-item {
        padding: 10px 20px 10px 40px;
        transition: 0.3s;

        @media screen and (max-width: 768px) {
          padding: 5px 50px 5px 5px;
        }

        &:hover {
          background-color: #00adef;

          .playlist-link {
            color: white;
          }
        }

        .playlist-link {
          display: flex;
          text-decoration: none;
          color: #2b2b2b;

          .playlist-item-thumbnail {
            width: 20%;
            align-self: center;
            height: auto;
            margin-right: 20px;

            @media screen and (max-width: 768px) {
              width: 30%;
              margin-right: 5px;
            }

          }

          p {
            @media screen and (max-width: 768px) {
              margin-bottom: 0;
              font-size: 0.5em;
            }
          }
        }

      }
    }

  }

  .mobile-playlist-toggle-container {
    ///display: none;
    background-color: #00adef;
    padding: 10px;
    //transform: rotate(90deg);
    position: absolute;
    right: -70px;
    top: 0;
    //transform-origin: 0% 0%;
    z-index: 5;
    transition: 0.2s;
    //width: 135px;

    @media screen and (max-width: 1100px) {
      display: block;
      right: 0px;
    }

    @media screen and (max-width: 1100px) {
      padding: 2px;
    }

    &.open-playlist {
      //left: 14.1vw;
      //right: 622px;
      display: block;
      //box-shadow: rgba(0,0,0,0.5) -3px 0px 5px;

      @media screen and (max-width: 830px) {
        //right: 445px;
      }

      @media screen and (max-width: 650px) {
        //right: 265px;
      }

      @media screen and (max-width: 450px) {
        //right: 165px;
      }

      @media screen and (max-width: 360px) {
        //right: 65px;
      }
    }

    .playlist-toggle-btn {
      a {
        text-decoration: none;
        color: white;
        img {
          width: 50px;
          @media screen and (max-width: 768px) {
            width: 30px;
          }
        }
      }
    }
  }
}