

.invest-hero.hero.hero--small{
  background-color: rgba(0,0,0,0) !important;
  background-image: url('/assets/images/heros/invest_online_header_desktop_image.png');

  .hero__body {
    background-color: rgba(0,0,0,0);
    padding-top: 3em;
    padding-bottom: 1em;
  }

  .fee-free-wrapper{
    max-width: 40em;
  }

  p {
    text-align: center;
    color: $color-white;
    margin-bottom: 0.5%;
  }

  .invest-hero-title {
    text-transform: unset;
    font-weight: 700;
    font-size: 1.875em;
    line-height: 1.4;
    padding: 0 4%;
  }

  .terms-conditions {
    color: $color-white;
    text-decoration: none;
    border-bottom-style: none;
  }

}


@media all and (max-width: 61.25em) {

  .invest-hero.hero.hero--small {
    background-color: #4a90e2 !important;
    background-image: none;
  }

  .invest-hero-title{
    font-size: 1.500em !important;
    line-height: 1.2 !important;
  }

}

