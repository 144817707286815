/* -------------------------------------------------------------------------- *\
   #STEPS
\* -------------------------------------------------------------------------- */

.steps {

    /* Item */

    .steps__item {
        animation: animation-fade 0.5s forwards;
    }

    /* Button Group */

    .btn-group {
        margin-top: 1em;
    }

    /* Row */

    .row {

        & + .row {
            margin-top: 0;
        }
    }

    
}

.hide-info-col .steps {
    .row:after, .row:before {
        display: block;
    }

    .col:first-child { width: 100%; }
    .col:last-child  { display: none; }
}