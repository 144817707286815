/* -------------------------------------------------------------------------- *\
   Tooltips | 3.3.7 | http://getbootstrap.com/javascript/#tooltip
\* -------------------------------------------------------------------------- */

.tooltip {
    display: block;
    filter: alpha(opacity=0);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.42857143;
    opacity: 0;
    position: absolute;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    z-index: 1070;
}

.tooltip.in {
    filter: alpha(opacity=90);
    opacity: 0.9;
}

.tooltip.top {
    margin-top: -3px;
    padding: 5px 0;
}

.tooltip.right {
    margin-left: 3px;
    padding: 0 5px;
}

.tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0;
}

.tooltip.left {
    margin-left: -3px;
    padding: 0 5px;
}

.tooltip-inner {
    background-color: #000;
    border-radius: 4px;
    color: #fff;
    max-width: 200px;
    padding: 3px 8px;
    text-align: center;
}

.tooltip-arrow {
    border-color: transparent;
    border-style: solid;
    height: 0;
    position: absolute;
    width: 0;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #000;
    border-width: 5px 5px 0;
    bottom: 0;
    left: 50%;
    margin-left: -5px;
}

.tooltip.top-left .tooltip-arrow {
    border-top-color: #000;
    border-width: 5px 5px 0;
    bottom: 0;
    margin-bottom: -5px;
    right: 5px;
}

.tooltip.top-right .tooltip-arrow {
    border-top-color: #000;
    border-width: 5px 5px 0;
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #000;
    border-width: 5px 5px 5px 0;
    left: 0;
    margin-top: -5px;
    top: 50%;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #000;
    border-width: 5px 0 5px 5px;
    margin-top: -5px;
    right: 0;
    top: 50%;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #000;
    border-width: 0 5px 5px;
    left: 50%;
    margin-left: -5px;
    top: 0;
}

.tooltip.bottom-left .tooltip-arrow {
    border-bottom-color: #000;
    border-width: 0 5px 5px;
    margin-top: -5px;
    right: 5px;
    top: 0;
}

.tooltip.bottom-right .tooltip-arrow {
    border-bottom-color: #000;
    border-width: 0 5px 5px;
    left: 5px;
    margin-top: -5px;
    top: 0;
}
