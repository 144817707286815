/* -------------------------------------------------------------------------- *\
   #BOX
\* -------------------------------------------------------------------------- */

.box {
    background-color: $color-white;
    box-shadow: 0 0.125em 2em rgba($color-black, 0.1);
    padding: 1.5em;

    /**
     * Light
     */

    &.box--light {
        background-color: $color-white-dark;
        box-shadow: none;
    }

    /**
     * Arrow
     */

    &.box--arrow {
        margin-bottom: 2em;
        position: relative;

        &:after {
            border-style: solid;
            border-width: 0 1.5em 1.5em 1.5em;
            border-color: transparent #fff transparent transparent;
            bottom: -1.5em;
            content: "";
            left: 50%;
            margin-left: -1.5em;
            position: absolute;
        }

        &.is-top {
            margin-top: 2em;

            &:after {
                border-width: 0 1.5em 1.5em 0;
                border-color: transparent transparent #fff transparent ;
                bottom: auto;
                top: -1.5em;
            }
        }

        &.is-right {

            &:after {
                left: 80%;
            }
        }

        &.is-left {

            &:after {
                left: 20%;
            }
        }
    }

    p {

        &:last-child {
            margin-bottom: 0;
        }
    }

    & + .person {
        margin-top: 3em;
    }
}

@media all and (min-width: $bp-small) {

    .box {
        padding: 2em;

        /**
         * Large
         */

        &.box--large {
            padding: 3.5em;
        }
    }
}











