/* -------------------------------------------------------------------------- *\
   #SLIDER
\* -------------------------------------------------------------------------- */

.slider {
    border-radius: 3em;
    box-shadow: 0 0.125em 2em rgba($color-black, 0.1);
    height: 2.75em;
    margin-bottom: 4em;
    position: relative;

    /* Bar */

    .slider__bar {
        border-radius: 3em;
        height: 100%;
        overflow: hidden;
    }

    /* Item */

    .slider__item {
        background-color: $color-orange;
        color: $color-white;
        float: left;
        font-size: 0.6875em; /* 14 / 16 */
        height: 100%;
        padding-top: 1em;
        text-align: center;
        width: 33.333%;

        &:first-child {
            background-color: $color-red;
        }

        &:last-child {
            background-color: $color-green;
        }
    }

    /* Marker */

    .slider__marker {
        color: $color-gray-dark;
        font-size: 2.875em; /* 46 / 16 */
        left: 0;
        position: absolute;
        top: 2%;
        transition: left 1.5s ease-out;
    }
}


.slider.slider--secondary {
    margin-bottom: 5em;

    /* Marker */

    .slider__marker {
        background-color: $color-blue;
        color: #fff;
        font-size: 0.75em; /* 12 / 16 */
        left: 0;
        line-height: 1.25em;
        padding: 5px 0 10px;
        position: absolute;
        top: 60px;
        text-align: center;
        transition: left 1.5s ease-out;
        width: 50px;
        margin-left: -25px;

        &:before {
            content: '';
            position: absolute;
            top: -50px;
            left: 0;
            border: 25px solid transparent;/*adjust size*/
            border-bottom-color: $color-blue;
        }
    }  
}


@media all and (min-width: $bp-small) {

    .slider {

        .slider__item {
            font-size: 0.875em; /* 14 / 16 */
            padding-top: 0.6875em;
        }
    }
}














