//Rebuild With Zero 2022 Campaign
#main-div {
  &.rebuild-with-zero-campaign {
    height: unset !important;
    .hero-slider-wrapper {
      background-color: unset;
      background-image: linear-gradient(29deg, #00AEEF 0%, #00AEEF 75%,#0072EC 100%);
      padding-bottom: 0;
      .hero-slider-container {
        .hero-slider-wrapper-inner {
          .hero-slider {
            .hero-slide {
              &.hero-slide-0 {
                .hero-slide-inner {
                  max-width: 75% !important;
                  @media (min-width: $bp-small) and (max-width: $bp-medium) {
                    max-width: 100% !important;
                  }

                  @media (max-width: $bp-small) {
                    max-width: 100% !important;
                  }
                  .campaign-outer {
                    display: block;
                    background-size: cover;
                    @media (min-width: $bp-small) and (max-width: $bp-medium) {
                      margin: 0 auto;
                      background-image: none;
                      max-width: 90%;
                    }

                    @media (max-width: $bp-small) {
                      margin: 0 auto;
                      background-image: none;
                      max-width: 90%;
                    }
                    .campaign-hero {
                      display: flex;
                      align-items: flex-start;
                      flex-direction: row;

                      @media (min-width: 1024px) and (max-width: $bp-large) {
                        column-gap: 65px;
                      }

                      @media (min-width: $bp-large) and (max-width: $bp-xl) {
                        column-gap: 95px;
                      }

                      @media (min-width: $bp-xl) and (max-width: 1849px) {
                        column-gap: 95px;
                      }

                      @media (min-width: $bp-small) and (max-width: $bp-medium) {
                        //display: block;
                      }

                      @media (max-width: $bp-small) {
                        //display: block;
                      }
                      .campaign-image {
                        width: 45%;
                        @media (min-width: 1024px) and (max-width: $bp-large) {
                          width: 45%;
                        }
                        @media (min-width: $bp-small) and (max-width: $bp-medium) {
                          width: 50%;
                          //margin: 0 auto;
                          //padding: 50px 0 40px;
                        }

                        @media (max-width: $bp-small) {
                          width: 50%;
                          //margin: 0 auto;
                          //padding: 50px 0 40px;
                        }
                      }
                      .campaign-hero-copy {
                        width: 45%;
                        padding: 35px 0;
                        @media (min-width: 1024px) and (max-width: $bp-large) {
                          width: 55%;
                          padding: 35px 0;
                        }
                        @media (min-width: $bp-small) and (max-width: $bp-medium) {
                          width: 65%;
                          //margin: 0 auto;
                          padding: 65px 0 15px;
                        }

                        @media (max-width: $bp-small) {
                          width: 65%;
                          //margin: 0 auto;
                          padding: 65px 0 15px;
                        }
                        .hero-copy-inner {
                          text-align: left;
                          h1 {
                            display: flex;
                            flex-direction: column;
                            color: #E7F0FF;
                            margin-bottom: 31px;
                            @media (min-width: 1024px) and (max-width: $bp-large) {
                              margin-bottom: 31px;
                            }
                            @media (min-width: $bp-small) and (max-width: $bp-medium) {
                              margin-bottom: 0;
                            }

                            @media (max-width: $bp-small) {
                              margin-bottom: 0;
                            }
                            .uppercase {
                              text-transform: uppercase;
                              letter-spacing: 10px;
                              font-size: 100px;
                              font-weight: 800;

                              @media (min-width: $bp-xl) and (max-width: 1849px) {

                              }

                              @media (min-width: $bp-large) and (max-width: $bp-xl) {
                                font-size: 4rem;
                                letter-spacing: 0.3rem;
                              }

                              @media (min-width: 1024px) and (max-width: $bp-large) {
                                font-size: 80px;
                                letter-spacing: 8px;
                              }

                              @media (min-width: $bp-small) and (max-width: $bp-medium) {
                                font-size: 50px;
                                letter-spacing: 3.5px;
                              }

                              @media (max-width: $bp-small) {
                                font-size: 30px;
                                letter-spacing: 3px;
                              }
                            }
                            .h1-second-line {
                              display: flex;
                              flex-direction: row;
                              column-gap: 27px;
                              @media (min-width: $bp-small) and (max-width: $bp-medium) {
                                column-gap: 13px;
                              }

                              @media (max-width: $bp-small) {
                                column-gap: 13px;
                              }
                              .heading-line {
                                width: 145px;
                                height: 17px;
                                margin-top: 32px;
                                background-color: #E7F0FF30;
                                display: block;
                                margin-bottom: 23px;
                                @media (min-width: 1024px) and (max-width: $bp-large) {
                                  margin-top: 16px;
                                  height: 10px;
                                  width: 100px;
                                }
                                @media (min-width: $bp-small) and (max-width: $bp-medium) {
                                  width: 50px;
                                  height: 6px;
                                  margin: 10px 0;
                                }

                                @media (max-width: $bp-small) {
                                  width: 35px;
                                  height: 4.5px;
                                  margin: 10px 0;
                                }
                              }
                              .lowercase {
                                text-transform: lowercase;
                                font-size: 56px;
                                letter-spacing: 5.6px;
                                font-weight: 400;

                                @media (min-width: $bp-xl) and (max-width: 1849px) {

                                }

                                @media (min-width: $bp-large) and (max-width: $bp-xl) {
                                  font-size: 2rem;
                                  letter-spacing: 0.15rem;
                                }

                                @media (min-width: 1024px) and (max-width: $bp-large) {
                                  font-size: 36px;
                                  letter-spacing: 3.6px;
                                }

                                @media (min-width: $bp-small) and (max-width: $bp-medium) {
                                  font-size: 30px;
                                  letter-spacing: 2.5px;
                                }

                                @media (max-width: $bp-small) {
                                  font-size: 20px;
                                  letter-spacing: 2.2px;
                                }
                              }
                            }

                          }

                          p {
                            color: white;
                            font-size: 1.6rem;
                            line-height: 1.25;
                            margin-bottom: 21px;

                            @media (min-width: $bp-large) and (max-width: $bp-xl) {
                              font-size: 1.7rem;
                              margin-bottom: 0.8rem;
                            }

                            @media (min-width: $bp-xl) and (max-width: 1849px) {
                              font-size: 1.7rem;
                              margin-bottom: 0.9rem;
                            }

                            @media (min-width: $bp-small) and (max-width: $bp-medium) {
                              display: none;
                            }

                            @media (max-width: $bp-small) {
                              display: none;
                            }
                            &.letter-spacing {
                              letter-spacing: 2.4px;
                              font-weight: 500;
                              @media (min-width: $bp-small) and (max-width: $bp-medium) {

                              }

                              @media (max-width: $bp-small) {
                                letter-spacing: 1.4px;
                              }
                            }
                            &.bold {
                              font-weight: 700;
                              letter-spacing: 0.28px;
                            }
                          }
                          a {
                            color: white;
                            @media (min-width: $bp-small) and (max-width: $bp-medium) {
                              display: none;
                            }

                            @media (max-width: $bp-small) {
                              display: none;
                            }
                            &.small {
                              font-size: 16px;
                              line-height: 34px;
                              text-decoration: underline;

                              @media (min-width: $bp-xl) and (max-width: 1849px) {

                              }

                              @media (min-width: $bp-large) and (max-width: $bp-xl) {
                                font-size: 0.7rem;
                                line-height: 1rem;
                              }

                              @media (min-width: $bp-small) and (max-width: $bp-medium) {

                              }

                              @media (max-width: $bp-small) {

                              }
                            }
                          }
                          .button_outer {
                            margin: 29px 0 59px;
                            @media (min-width: $bp-xl) and (max-width: 1849px) {
                              margin: 1rem 0 1.2rem;
                            }

                            @media (min-width: $bp-large) and (max-width: $bp-xl) {
                              margin: 1rem 0 1.5rem;
                            }
                            @media (min-width: $bp-small) and (max-width: $bp-medium) {
                              display: none;
                            }

                            @media (max-width: $bp-small) {
                              display: none;
                            }
                          }
                          .mobile-vid {
                            display: none;
                            @media (min-width: $bp-small) and (max-width: $bp-medium) {
                              display: block;
                            }

                            @media (max-width: $bp-small) {
                              display: block;
                            }
                            .video-slide {
                              height: 0;
                              margin: 4px;
                              padding: 55% 0 0 0;
                              position: relative;
                              overflow: hidden;
                              border-radius: 0;
                              box-shadow: unset;
                              will-change: box-shadow;
                              transition: .4s ease-out;

                              &:hover {
                                box-shadow: 2px 4px 6px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
                              }

                              &.active {
                                .video-slide-overlay {
                                  opacity: 0;
                                  visibility: hidden;
                                  pointer-events: none;
                                }

                                .video-wrapper {
                                  .wistia_responsive_padding {
                                    opacity: 1 !important;
                                  }
                                }
                              }

                              .video-slide-overlay {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                height: 100%;
                                padding: 1.6em;
                                box-sizing: border-box;
                                position: absolute;
                                top: 0;
                                left: 0;
                                background: {
                                  size: cover;
                                  position: center;
                                }
                                cursor: pointer;
                                transition: .4s ease-out;
                                z-index: 1;

                                @media (min-width: $bp-small) and (max-width: $bp-medium) {

                                }

                                @media (max-width: $bp-small) {
                                  padding: 0.8em;
                                }


                                .overlay-text {
                                  max-width: 65%;
                                  font-size: 1.85rem;
                                  font-weight: 400;
                                  color: $color-white;
                                  text-align: left;
                                  letter-spacing: 3px;
                                  line-height: 1.35;

                                  @media (max-width: $bp-small) {
                                    //font-size: .4rem;
                                    font-size: 15px;
                                    letter-spacing: 1.5px;
                                    line-height: 20px;
                                  }

                                  @media (min-width: $bp-large) and (max-width: $bp-xl) {
                                    font-size: 1.2rem
                                  }

                                  @media (min-width: $bp-xl) and (max-width: 1849px) {
                                    font-size: 1.5rem;
                                  }
                                }

                                .video-play-btn {
                                  max-width: 64px;
                                  margin-top: auto;
                                  display: block;

                                  @media (max-width: $bp-small) {
                                    max-width: 48px;
                                  }
                                }
                              }

                              .video-wrapper {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;

                                .wistia_responsive_padding {
                                  padding: 56.25% 0 0 0;
                                  position: relative;
                                  //opacity: 1 !important;

                                  .wistia_embed {
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    .campaign-copy-mobile {
                      display: none;
                      @media (min-width: $bp-large) and (max-width: $bp-xl) {

                      }

                      @media (min-width: $bp-xl) and (max-width: 1849px) {

                      }

                      @media (min-width: $bp-small) and (max-width: $bp-medium) {
                        display: block;
                      }

                      @media (max-width: $bp-small) {
                        display: block;
                      }
                      .hero-copy-inner {
                        text-align: left;
                        h1 {
                          display: flex;
                          flex-direction: column;
                          color: #E7F0FF;
                          margin-bottom: 41px;
                          .uppercase {
                            text-transform: uppercase;
                            letter-spacing: 12px;
                            font-size: 120px;
                            font-weight: 800;

                            @media (min-width: $bp-xl) and (max-width: 1849px) {

                            }

                            @media (min-width: $bp-large) and (max-width: $bp-xl) {
                              font-size: 4rem;
                              letter-spacing: 0.3rem;
                            }

                            @media (min-width: $bp-small) and (max-width: $bp-medium) {
                              font-size: 60px;
                              letter-spacing: 4px;
                            }

                            @media (max-width: $bp-small) {
                              font-size: 50px;
                              letter-spacing: 3px;
                            }
                          }
                          .h1-second-line {
                            display: flex;
                            flex-direction: row;
                            column-gap: 27px;
                            .heading-line {
                              width: 145px;
                              height: 17px;
                              margin-top: 32px;
                              background-color: #E7F0FF30;
                              display: block;
                              margin-bottom: 23px;
                              @media (min-width: $bp-small) and (max-width: $bp-medium) {
                                width: 80px;
                                height: 8px;
                              }

                              @media (max-width: $bp-small) {
                                width: 70px;
                                height: 7px;
                              }
                            }
                            .lowercase {
                              text-transform: lowercase;
                              font-size: 76px;
                              letter-spacing: 7.6px;
                              font-weight: 400;

                              @media (min-width: $bp-xl) and (max-width: 1849px) {

                              }

                              @media (min-width: $bp-large) and (max-width: $bp-xl) {
                                font-size: 2rem;
                                letter-spacing: 0.15rem;
                              }

                              @media (min-width: $bp-small) and (max-width: $bp-medium) {
                                font-size: 30px;
                                letter-spacing: 2.5px;
                              }

                              @media (max-width: $bp-small) {
                                font-size: 24px;
                                letter-spacing: 2.2px;
                              }
                            }
                          }

                        }

                        p {
                          color: white;
                          font-size: 1.7rem;
                          line-height: 1.25;
                          margin-bottom: 21px;

                          @media (min-width: $bp-large) and (max-width: $bp-xl) {
                            font-size: 1.7rem;
                            margin-bottom: 0.8rem;
                          }

                          @media (min-width: $bp-xl) and (max-width: 1849px) {
                            font-size: 1.7rem;
                            margin-bottom: 1rem;
                          }

                          @media (min-width: $bp-small) and (max-width: $bp-medium) {
                            margin-bottom: 5px;
                          }

                          @media (max-width: $bp-small) {
                            font-size: 14px;
                            line-height: 16px;
                            margin-bottom: 5px;
                          }
                          &.letter-spacing {
                            letter-spacing: 2.4px;
                            font-weight: 500;
                            @media (min-width: $bp-small) and (max-width: $bp-medium) {

                            }

                            @media (max-width: $bp-small) {
                              letter-spacing: 1.4px;
                            }
                          }
                          &.bold {
                            font-weight: 700;
                            letter-spacing: 0.28px;
                          }
                        }
                        a {
                          color: white;
                          &.small {
                            font-size: 16px;
                            line-height: 34px;
                            text-decoration: underline;

                            @media (min-width: $bp-xl) and (max-width: 1849px) {

                            }

                            @media (min-width: $bp-large) and (max-width: $bp-xl) {
                              font-size: 0.7rem;
                              line-height: 1rem;
                            }

                            @media (min-width: $bp-small) and (max-width: $bp-medium) {
                              font-size: 10px;
                              line-height: 20px;
                            }

                            @media (max-width: $bp-small) {
                              font-size: 10px;
                              line-height: 20px;
                            }
                          }
                        }
                        .button_outer {
                          margin: 20px 0 40px;
                          @media (min-width: $bp-xl) and (max-width: 1849px) {
                            margin: 1rem 0 1.2rem;
                          }

                          @media (min-width: $bp-large) and (max-width: $bp-xl) {
                            margin: 1rem 0 1.5rem;
                          }
                          @media (min-width: $bp-small) and (max-width: $bp-medium) {
                            width: 100%;
                            margin: 15px 0 30px;
                          }

                          @media (max-width: $bp-small) {
                            width: 100%;
                            margin: 15px 0 30px;
                          }

                          a {
                            @media (min-width: $bp-small) and (max-width: $bp-medium) {
                              width: 100%;
                            }

                            @media (max-width: $bp-small) {
                              width: 100%;
                            }
                          }
                        }
                        .play_vid {
                          @media (min-width: $bp-xl) and (max-width: 1849px) {
                            display: block;
                          }

                          @media (min-width: $bp-large) and (max-width: $bp-xl) {
                            display: block;
                          }
                          @media (min-width: $bp-small) and (max-width: $bp-medium) {
                            display: none;
                          }

                          @media (max-width: $bp-small) {
                            display: none;
                          }

                          img {
                            @media (min-width: $bp-xl) and (max-width: 1849px) {
                              display: block;
                              height: 4.2rem;
                            }

                            @media (min-width: $bp-large) and (max-width: $bp-xl) {
                              display: block;
                              height: 4rem;
                            }

                          }
                        }
                        .mobile-vid {
                          display: none;
                          @media (min-width: $bp-small) and (max-width: $bp-medium) {
                            display: block;
                          }

                          @media (max-width: $bp-small) {
                            display: block;
                          }
                          .video-slide {
                            height: 0;
                            margin: 4px;
                            padding: 55% 0 0 0;
                            position: relative;
                            overflow: hidden;
                            border-radius: 0;
                            box-shadow: unset;
                            will-change: box-shadow;
                            transition: .4s ease-out;

                            &:hover {
                              box-shadow: 2px 4px 6px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
                            }

                            &.active {
                              .video-slide-overlay {
                                opacity: 0;
                                visibility: hidden;
                                pointer-events: none;
                              }

                              .video-wrapper {
                                .wistia_responsive_padding {
                                  opacity: 1 !important;
                                }
                              }
                            }

                            .video-slide-overlay {
                              display: flex;
                              flex-direction: column;
                              width: 100%;
                              height: 100%;
                              padding: 1.6em;
                              box-sizing: border-box;
                              position: absolute;
                              top: 0;
                              left: 0;
                              background: {
                                size: cover;
                                position: center;
                              }
                              cursor: pointer;
                              transition: .4s ease-out;
                              z-index: 1;

                              @media (min-width: $bp-small) and (max-width: $bp-medium) {

                              }

                              @media (max-width: $bp-small) {
                                padding: 0.8em;
                              }


                              .overlay-text {
                                max-width: 65%;
                                font-size: 1.85rem;
                                font-weight: 400;
                                color: $color-white;
                                text-align: left;
                                letter-spacing: 3px;
                                line-height: 1.35;

                                @media (max-width: $bp-small) {
                                  //font-size: .4rem;
                                  font-size: 15px;
                                  letter-spacing: 1.5px;
                                  line-height: 20px;
                                }

                                @media (min-width: $bp-large) and (max-width: $bp-xl) {
                                  font-size: 1.2rem
                                }

                                @media (min-width: $bp-xl) and (max-width: 1849px) {
                                  font-size: 1.5rem;
                                }
                              }

                              .video-play-btn {
                                max-width: 64px;
                                margin-top: auto;
                                display: block;

                                @media (max-width: $bp-small) {
                                  max-width: 48px;
                                }
                              }
                            }

                            .video-wrapper {
                              width: 100%;
                              height: 100%;
                              position: absolute;
                              top: 0;
                              left: 0;

                              .wistia_responsive_padding {
                                padding: 56.25% 0 0 0;
                                position: relative;
                                //opacity: 1 !important;

                                .wistia_embed {
                                  width: 100%;
                                  height: 100%;
                                  position: absolute;
                                  top: 0;
                                  left: 0;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
  }
}

#home-card-navigation {
  &.rebuild-with-zero-campaign {
    position: relative;
    background: $color-white-dark__alt;
    padding-top: 2.5em !important;

    @media (max-width: $bp-small) {
      padding-top: 2.2em;
      padding-bottom: 4.5em;
    }

    .l-wrapper {
      max-width: calc(75% + 120px) !important;
      @media (max-width: 600px) {
        width: 100%;
      }
    }

    .header {
      max-width: 100%;
      text-align: center;

      @media (max-width: $bp-large) {
        margin: 0 72px;
      }

      @media (max-width: $bp-small) {
        margin: 0 40px;
      }

      @media (max-width: 600px) {
        margin: 0 60px;
      }

      .header__title {
        margin-bottom: 1.2em;
      }
    }

    .card-navigation {
      .tns-outer {
        .tns-inner {
          padding: 4px;

          //@media (max-width: 600px) {
          //  margin: 0 100px;
          //}
          //
          //@media (max-width: $bp-small) {
          //  margin: 0 60px;
          //}
          //
          //@media (max-width: 400px) {
          //  margin: 0 40px;
          //}
        }
      }

      .card-row {
        @media (min-width: $bp-small) and (max-width: $bp-medium) {

        }

        @media (max-width: $bp-small) {
          display: flex;
          flex-direction: column;
        }

        .card-col {
          &:nth-of-type(1) {
            margin-bottom: 17px;
          }
          padding: {
            left: 12px;
            right: 12px;
          }

          .card {
            position: relative;
            border-radius: 4px;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
            will-change: transform, box-shadow;
            transition: .4s ease-out;

            &:hover {
              transform: translateY(-6px);
              box-shadow: 2px 4px 6px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
            }

            .card-link {
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }

            .card-top {
              background: $color-white-dark__alt;
              padding: 16px 20px;
              border-radius: 4px 4px 0 0;
              position: relative;

              @media (min-width: $bp-small) and (max-width: $bp-medium) {
                padding: 15px 25px 22px;
              }

              @media (max-width: $bp-small) {
                padding: 15px 25px 22px;
              }


              .card-subtitle {
                margin-bottom: .3rem;
                font-size: 1rem;

                @media (max-width: 1322px) {
                  font-size: .7rem;
                }
              }

              .card-title {
                margin-bottom: 0;
                font-size: 1.3rem;
                line-height: 1.4;

                @media (max-width: 1322px) {
                  font-size: 1.1rem;
                }

                @media (max-width: $bp-large) {
                  font-size: 1rem;
                }
              }

              .card-top-link {
                display: inline-flex;
                align-items: center;
                margin-bottom: 18px;
                font: {
                  size: .8rem;
                  weight: 700;
                }
                color: $color-primary;
                text-decoration: none;
                border-bottom: 2px solid;
                white-space: nowrap;

                @media (max-width: 1322px) {
                  font-size: .7rem;
                }

                @media (max-width: $bp-large) {
                  font-size: .6rem;
                }

                @media (min-width: $bp-small) and (max-width: $bp-medium) {
                  margin: 0;
                }

                @media (max-width: $bp-small) {
                  margin: 0;
                }


                img {
                  margin-left: 4px;
                }


              }
              .button-holder {
                @media (min-width: $bp-small) and (max-width: $bp-medium) {
                  position: absolute;
                  left: 35%;
                  bottom: 10%;
                  transform: scale(0.7);
                }

                @media (max-width: $bp-small) {
                  position: absolute;
                  left: 35%;
                  bottom: 10%;
                  transform: scale(0.7);
                }

                .btn {
                  width: fit-content;
                  display: block;
                  border-radius: 40px;
                  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2), -2px -2px 4px rgba(255, 255, 255, 0.8);
                }
              }
            }

            .card-bottom {
              height: 292px;
              background: {
                size: cover;
                position: center;
              }
              border-radius: 0 0 4px 4px;

              @media (max-width: 1322px) {
                height: 272px;
              }

              @media (max-width: $bp-large) {
                height: 0;
                display: none;
              }

              @media (max-width: $bp-small) {
                height: 0;
                display: none;
              }
            }
          }
        }
      }

      .card-pagination,
      .tns-nav{
        display: none;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        @media (min-width: $bp-small) and (max-width: $bp-medium) {
          display: none;
        }

        @media (max-width: $bp-small) {
          display: none;
        }


        @media (max-width: $bp-large) {
          display: flex;
        }

        button {
          display: block;
          width: 8px;
          height: 8px;
          margin: 0 4px;
          padding: 0;
          border-radius: 100%;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
          background: #9DA8B6;
          border: none;

          &.tns-nav-active {
            background: $color-primary;
          }
        }
      }
    }

    .tns-nav{
      display: none;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      @media (min-width: $bp-small) and (max-width: $bp-medium) {
        display: none;
      }

      @media (max-width: $bp-small) {
        display: none;
      }


      @media (max-width: $bp-large) {
        display: flex;
      }

      button {
        display: block;
        width: 8px;
        height: 8px;
        margin: 0 4px;
        border-radius: 100%;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
        background: #9DA8B6;

        &.tns-nav-active {
          background: $color-primary;
        }
      }
    }

    .card-navigation-overlay-bg,
    .card-navigation-overlay {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: .2s ease;
      will-change: opacity, visibility;

      .card-modal-active & {
        visibility: visible;
        pointer-events: auto;
      }
    }

    .card-navigation-overlay-bg {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: $color-white-dark__alt;
      z-index: 13;

      .card-modal-active & {
        opacity: .7;
      }
    }

    .card-navigation-overlay {
      @extend .l-section;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: $color-white-dark__alt;
      z-index: 15;

      @media (min-width: $bp-small) {
        padding-top: 4em;
        padding-bottom: 5em;
      }

      .card-modal-active & {
        opacity: 1;
      }

      .overlay-close {
        max-width: 40px;
        position: absolute;
        top: 88px;
        left: 32px;
        cursor: pointer;

        @media (max-width: $bp-large) {
          left: 0;
          top: 0;
        }

        @media (max-width: $bp-small) {
          left: 20px;
          max-width: 32px;
        }
      }

      .card-row {
        margin: 0 auto;

        @media (min-width: $bp-large) {
          max-width: 830px;
        }

        //.card-col {
        //  @media (max-width: $bp-large) {
        //    max-width: 32%;
        //  }
        //
        //  @media (max-width: $bp-small) {
        //    max-width: unset;
        //  }
        //}
      }
    }
  }
}

#vid-carousel {
  .l-wrapper {
    width: 100%;
    max-width: unset;
    .header {
      @media (min-width: 1024px) and (max-width: $bp-large) {
        width: 85%;
        margin: 0 auto;
      }
      @media (min-width: $bp-small) and (max-width: $bp-medium) {
        width: 85%;
        margin: 0 auto;
      }

      @media (max-width: $bp-small) {
        width: 85%;
        margin: 0 auto;
      }

      h3 {
        font-size: 2.5em;
        @media (min-width: $bp-small) and (max-width: $bp-medium) {
          font-size: 1.5em;
          text-align: center;
        }

        @media (max-width: $bp-small) {
          font-size: 1.25em;
          text-align: center;
        }
      }
      p {
        font-size: 1.85em;
        line-height: 1.2;
        @media (min-width: $bp-small) and (max-width: $bp-medium) {
          font-size: 1em;
          text-align: center;
        }

        @media (max-width: $bp-small) {
          font-size: 0.9em;
          text-align: center;
        }
      }
    }
    .campaign-carousel {
      .client-video-slider-wrapper {
        position: relative;
        padding-top: 0;
        background-color: unset;

        @media (min-width: $bp-small) {
          padding-top: 0;
          padding-bottom: 0;
        }

        .client-video-slider {
          .video-slide {
            height: 0;
            margin: 4px;
            padding: 55% 0 0 0;
            position: relative;
            overflow: hidden;
            border-radius: 0;
            box-shadow: unset;
            will-change: box-shadow;
            transition: .4s ease-out;

            &:hover {
              box-shadow: 2px 4px 6px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
            }

            &.active {
              .video-slide-overlay {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
              }

              .video-wrapper {
                .wistia_responsive_padding {
                  opacity: 1 !important;
                }
              }
            }

            .video-slide-overlay {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: 100%;
              padding: 1.6em;
              box-sizing: border-box;
              position: absolute;
              top: 0;
              left: 0;
              background: {
                size: cover;
                position: center;
              }
              cursor: pointer;
              transition: .4s ease-out;
              z-index: 1;

              @media (min-width: $bp-small) and (max-width: $bp-medium) {

              }

              @media (max-width: $bp-small) {
                padding: 0.8em;
              }

              @media (min-width: $bp-large) and (max-width: $bp-xl) {
                padding: 1.2em;
              }

              @media (min-width: $bp-xl) and (max-width: 1849px) {

              }


              .overlay-text {
                max-width: 65%;
                font-size: 1.85rem;
                font-weight: 400;
                color: $color-white;
                text-align: left;
                letter-spacing: 3px;
                line-height: 1.35;

                @media (max-width: $bp-small) {
                  //font-size: .4rem;
                  font-size: 15px;
                  letter-spacing: 1.5px;
                  line-height: 20px;
                }

                @media (min-width: 1024px) and (max-width: $bp-large) {
                  max-width: 100%;
                }

                @media (min-width: $bp-large) and (max-width: $bp-xl) {
                  font-size: 1.2rem
                }

                @media (min-width: $bp-xl) and (max-width: 1849px) {
                  font-size: 1.5rem;
                }
              }

              .video-play-btn {
                max-width: 64px;
                margin-top: auto;
                display: block;

                @media (max-width: $bp-small) {
                  max-width: 48px;
                }
              }
            }

            .video-wrapper {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;

              .wistia_responsive_padding {
                padding: 56.25% 0 0 0;
                position: relative;
                //opacity: 1 !important;

                .wistia_embed {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
          }

          .swiper-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 26px;
            margin-bottom: 26px;

            .swiper-pagination-bullet {
              display: block !important;
              width: 16px;
              height: 16px;
              margin: 0 4px;
              border-radius: 100%;
              background: #334152;
              opacity: 0.6;
              //box-shadow: unset;

              &.swiper-pagination-bullet-active {
                background: $color-primary;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

#cta-rwz {
  background: linear-gradient(90deg, #00AEEF 0%, #007BEC 50%, #0072EC 100%) 0% 0% no-repeat;
  .l-wrapper {
    padding: 42px 0 48px;
    @media (min-width: $bp-small) and (max-width: $bp-medium) {

    }

    @media (max-width: $bp-small) {

    }
    .header {
      margin-bottom: 28px;
      @media (min-width: $bp-small) and (max-width: $bp-medium) {
        margin-bottom: 9px;
      }

      @media (max-width: $bp-small) {
        margin-bottom: 9px;
      }
      h3 {
        color: #E7F0FF;
        font-size: 2.25rem;
        line-height: 60px;
        text-align: center;
        @media (min-width: $bp-small) and (max-width: $bp-medium) {
          font-size: 18px;
          line-height: 30px;
        }

        @media (max-width: $bp-small) {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
    .copy {
      margin-bottom: 3rem;
      @media (min-width: $bp-small) and (max-width: $bp-medium) {
        margin-bottom: 21px;
      }

      @media (max-width: $bp-small) {
        margin-bottom: 21px;
      }
      p {
        text-align: center;
        color: #E7F0FF;
        font-size: 1.7em;
        line-height: 40px;
        @media (min-width: $bp-small) and (max-width: $bp-medium) {
          font-size: 15px;
          line-height: 20px;
        }

        @media (max-width: $bp-small) {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
    .button-holder {
      text-align: center;
      .btn {
        text-align: center;
        &.btn--bordered-blue {
          background: linear-gradient(90deg, #00AEEF 0%, #007BEC 100%) 0% 0% no-repeat;
          border: white 1px solid;
          border-radius: 40px;
          box-shadow: 2px 4px 6px rgba(0, 0, 0, .2), -2px -2px 4px rgba(255, 255, 255, .8);
          font-size: 1.35rem;
          @media (min-width: $bp-small) and (max-width: $bp-medium) {
            font-size: 15px;
          }

          @media (max-width: $bp-small) {
            font-size: 15px;
          }

        }
      }
    }
  }
}
