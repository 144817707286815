/* -------------------------------------------------------------------------- *\
   #WHY 10X PAGES
\* -------------------------------------------------------------------------- */

.l-section {
  &.our-performance,
  &.our-team,
  &.our-purpose,
  &.our-approach {
    .sub-header {
      font-weight: bold;
    }

    .header {
      @media all and (min-width: $bp-small) {
        margin-bottom: 4em;
      }

      .header__title {
        @media all and (min-width: $bp-small) {
          margin-bottom: 0.75em;
        }
      }
    }

    .header__synopsis,
    .content-block__synopsis {
      margin-bottom: 2rem;
      font-weight: 300;
    }

    .accordion {
      .table {
        @media all and (max-width: $bp-small) {
          thead {
            display: none;
          }

          tr,
          td {
            display: grid;
          }

          td {
            padding: .8em 1em;
            border-bottom: 1px solid #eee;
            text-align: right;

            &:before {
              content: attr(data-label);
              float: left;
              font-weight: 400;
              text-align:left;
            }
          }
        }
      }
    }

    &#our-performance {
      .header__title {
        @media all and (min-width: $bp-small) {
          margin-bottom: 1.25em;
        }
      }

      .wistia_responsive_padding {
        margin-bottom: 2.3em;

        @media all and (min-width: $bp-small) {
          margin-bottom: 4.3em;
        }
      }
    }

    &#fund-fact-sheets {
      .fact-sheet-links {
        max-width: 27.5em;
        margin: 0 auto;

        .fact-sheet-link {
          display: block;
          text-align: left;
          margin-bottom: 1em;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    &#our-performance-accordions {
      padding-top: 4.5em;
      padding-bottom: 4.5em;

      @media all and (min-width: $bp-small) {
        padding-top: 6em;
        padding-bottom: 6em;
      }

      .accordion {
        &.our-returns {
          .table {
            margin-top: 3em;
          }

          small {
            font-size: 0.618em;
          }

          h6 {
            margin: 2.6em 0;
            font-size: 1.125rem;
            text-align: center;
          }
        }

        &.how-we-rank {
          h6 {
            margin: 2.6em 0;
            font-size: 1.125rem;
            text-align: center;
          }

          .nav {
            padding: 0;
            margin-bottom: 2em;
            box-shadow: none;

            .nav__link {
              padding: 0 .6em;
              font-size: 0.875rem;

              &:hover {
                cursor: pointer;
              }

              &.active {
                color: $color-blue;

                &:after {
                  transform: scaleX(1);
                  height: 0.15em;
                }
              }
            }
          }

          .nav-table-container {
            .table-container {
              width: 100%;
              display: none;

              &.active {
                display: block;
              }
            }
          }

          small {
            font-size: 0.618em;
          }
        }

        &.life-stage-investing {
          img {
            display: block;
            margin: 2em auto 3em;
          }

          small {
            font-size: 0.618em;
          }
        }
      }
    }

    &#our-purpose {
      .wistia_responsive_padding {
        margin-bottom: 2.3em;

        @media all and (min-width: $bp-small) {
          margin-bottom: 4.3em;
        }
      }
    }

    &#our-manifesto {
      position: relative;

      @media all and (min-width: $bp-small) {
        padding-top: 6em;
        padding-bottom: 6em;
      }

      .our-manifesto-background {
        width: 100%;
        height: 700px;
        position: absolute;
        top: 0;
        left: 0;
        background: {
          repeat: no-repeat;
          size: cover;
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          //background: rgba(8, 32, 60, .8);
          background: transparent;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      .header {
        .header__title,
        .header__synopsis {
          color: $color-white;
        }
      }

      .content-block {
        padding: 2em 2.2em;
        background: $color-white;
        box-shadow: 0 8px 25px rgba(0, 0, 0, .07);

        @media all and (min-width: $bp-small) {
          padding: 4em 5em;
        }
      }
    }

    &#our-approach {
      .header {
        margin-bottom: 0;
      }

      .info-blocks {
        margin: 3em 0;
      }
    }

    &#our-approach-accordions {
      .accordion {
        .accordion-body {
          .accordion-body-content {
            .content-block {
              .content-block__body {
                padding: 0;
                margin-bottom: 2em;

                @media all and (min-width: $bp-small) {
                  padding-right: 3em;
                  margin-bottom: 0;
                }

                .content-block__synopsis {
                  font: {
                    size: 0.875em;
                  }

                  @media all and (min-width: $bp-small) {
                    font-size: 1.125rem;
                  }
                }
              }

              .content-block__img {
                padding-top: 0;

                h4 {
                  margin-bottom: 1.4em;
                  font: {
                    size: 18px;
                    weight: 600;
                  }

                  @media all and (min-width: $bp-small) {
                    text-align: center;
                  }
                }
              }

              small {
                font-weight: 300;
                font-size: 0.618em;
                margin-top: 2em;
                display: block;
              }
            }
          }
        }

        &.our-portfolio {
          .table {
            thead {
              th {
                width: 50%;
              }
            }

            tr {
              td {
                &:before {
                  @media all and (max-width: $bp-small) {
                    display: block;
                    float: none;
                    text-align: left;
                    padding-bottom: 1em;
                    font-weight: 600;
                  }
                }

                .table-cell-left {
                  @media all and (max-width: $bp-small) {
                    float: left;
                  }
                }

                .table-cell-right {
                  @media all and (max-width: $bp-small) {
                    float: right;
                  }
                }
              }
            }
          }

          .content-block {
            margin-top: 2em;
            padding-top: 2em;
            border-top: 1px solid #e7e7e7;

            @media all and (min-width: $bp-small) {
              margin-top: 3em;
              padding-top: 3em;
            }
          }
        }

        .portfolio-button-container {
          margin-top: 2em;

          @media all and (min-width: $bp-small) {
            text-align: center;
          }

          .btn {
            @media all and (max-width: 440px) {
              width: 100%;
              padding-left: 0;
              padding-right: 0;
            }
          }
        }

        &.life-stage-investing {
          .asset-allocation {
            margin-bottom: 2em;
            padding-bottom: 2em;
            border-bottom: 1px solid #e7e7e7;

            @media all and (min-width: $bp-small) {
              margin-bottom: 3em;
              padding-bottom: 3em;
            }

            h6 {
              margin-bottom: 2em;
              font: {
                size: 1em;
                weight: 600;
              }

              @media all and (min-width: $bp-small) {
                text-align: center;
              }
            }

            .asset-allocation-graph {
              display: block;
              width: 100%;
              max-width: 45em;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

#our-approach-video-container {
  height: calc(100vh - 100px);
}

.info-blocks {
  @include clearfix();

  @media all and (min-width: $bp-small) {
    display: flex;
  }

  .info-block {
    margin-bottom: 2em;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media all and (min-width: $bp-small) {
      display: flex;
      flex: 1;
      padding: 0 1em;
      margin-bottom: 0;
    }

    .info-block-inner {
      padding: 2.2em;
      background: $color-white;
      text-align: left;
      box-shadow: 0 8px 25px rgba(0, 0, 0, .07);

      .ie & {
        width: 100%;
      }

      .info-block-title {
        display: inline-block;
        padding: 0 1.5em .4em .15em;
        border-bottom: 2px solid $color-blue;
        font-size: 1rem;

        @media all and (min-width: $bp-small) {
          font-size: 1.875rem;
        }
      }

      .info-block-content {
        margin: 0;

        font: {
          size: 1rem;
        }

        @media all and (min-width: $bp-small) {
          font: {
            size: 1.125rem;
            weight: bold;
          }
        }
      }
    }
  }
}

.bold-blue {
  color: $color-blue;
  font-weight: 600;
}

#fund-fact-sheets {
  .l-wrapper {
    .header {
      .fact-sheet-dropdowns {
        transition: all ease 0.3s;
        .dropdowns-inner {
          display: flex;
          justify-content: space-between;
          @media (min-width: $bp-small) and (max-width: $bp-medium) {
            flex-direction: column;
            grid-gap: 56px;
          }

          @media (max-width: $bp-small) {
            flex-direction: column;
            grid-gap: 28px;
          }
          .dropdown {
            cursor: pointer;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            .dropdown_inner {
              box-shadow: 0 8px 25px rgba(0,0,0,0.07);
              position: relative;
              transition: all ease 0.3s;
              &.inactive {
                background-color: white;
                .heading {
                  padding: 30px 90px 30px;

                  @media (min-width: $bp-large) and (max-width: $bp-xl) {
                    padding: 30px 70px 30px;
                  }

                  @media (min-width: 1024px) and (max-width: $bp-large) {
                    padding: 30px 70px 30px;
                  }

                  @media (min-width: $bp-small) and (max-width: $bp-medium) {
                    padding: 30px 35px 30px !important;
                  }

                  @media (max-width: $bp-small) {
                    padding: 15px 20px 15px;
                  }
                }
                .body {
                  height: 0;
                  overflow: hidden;
                }
              }
              &.active {
                background-color: #e8f0fe;
                .heading {
                  padding: 30px 90px 10px;
                  @media (min-width: $bp-large) and (max-width: $bp-xl) {
                    padding: 30px 70px 10px;
                  }

                  @media (min-width: 1024px) and (max-width: $bp-large) {
                    padding: 30px 70px 10px;
                  }

                  @media (min-width: $bp-small) and (max-width: $bp-medium) {
                    padding: 30px 35px 10px;
                  }

                  @media (max-width: $bp-small) {
                    padding: 15px 20px 5px;
                  }
                  .icon {
                    .icon-inner {
                      transform: rotate(180deg);
                    }
                  }
                }
                .body {
                  position: absolute;
                  padding: 0 70px;
                  background-color: #e8f0fe;
                  height: fit-content;
                  overflow: visible;
                  @media (min-width: $bp-small) and (max-width: $bp-medium) {
                    position: relative;
                    padding: 0 35px;
                  }

                  @media (max-width: $bp-small) {
                    position: relative;
                    padding: 0 20px;
                  }
                  z-index: 11;
                  width: 100%;
                  box-shadow: 0 16px 25px rgba(0,0,0,0.07);
                }
              }
              .heading {
                .text{
                  span {
                    color: #334152;
                    font-size: 1.55em;
                    line-height: 34px;
                    font-weight: bold;
                    @media (min-width: $bp-small) and (max-width: $bp-medium) {
                      font-size: 1.55em;
                    }

                    @media (max-width: $bp-small) {
                      font-size: 1em;
                    }
                  }
                }
                .icon {
                  position: absolute;
                  right: 45px;
                  top: 45px;
                  @media (min-width: $bp-large) and (max-width: $bp-xl) {
                    right: 35px;
                  }

                  @media (min-width: 1024px) and (max-width: $bp-large) {
                    right: 35px;
                  }

                  @media (min-width: $bp-small) and (max-width: $bp-medium) {
                    right: 55px;
                    top: 40px;
                  }

                  @media (max-width: $bp-small) {
                    right: 15px;
                    top: 28px;
                  }

                  .icon-inner {
                    transition: transform ease 0.3s;
                    svg {

                    }
                  }
                }
              }
              .body {
                .body_inner {
                  padding: 40px 0 60px;
                  @media (min-width: $bp-small) and (max-width: $bp-medium) {
                    padding: 40px 0 60px;
                  }

                  @media (max-width: $bp-small) {
                    padding: 20px 0 30px;
                  }
                  .sheet_link_outer {
                    margin-bottom: 30px;
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                    .sheet_link_inner {

                      a{
                        width: 100%;
                        @media (min-width: $bp-small) and (max-width: $bp-medium) {
                          width: 100%;
                        }

                        @media (max-width: $bp-small) {
                          width: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}